import * as React from "react";
import { ProjectAPIToken } from "../../Services/ProjectAPI";
import { AxiosError } from "axios";

const useLmiTokens = () => {
  const [isLmiTokenLoading, setIsLmiTokenLoading] = React.useState(false);
  const [lmiToken, setLmiToken] = React.useState<string>("");
  const getProjectAPIToken = async (): Promise<any> => {
    try {
      const lmiApiToken = await ProjectAPIToken.getLmiToken();
      setIsLmiTokenLoading(false);
      return lmiApiToken;
    } catch (error: any | AxiosError) {
      setIsLmiTokenLoading(false);
      return "";
    }
  };

  React.useEffect(() => {
    setIsLmiTokenLoading(true);
    const fetchLmiToken = async () => {
      try {
        const lmiToken = await getProjectAPIToken();
        setLmiToken(lmiToken);
      } catch (error: any) {
        console.log(error);
      }
    };
    fetchLmiToken();
  }, []);

  return { isLmiTokenLoading, lmiToken };
};
export default useLmiTokens;


