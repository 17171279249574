export const featureMsgStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "26px",
    color: "#000000",
    marginTop: "25px",
    marginLeft: "10px",
    textAlign: "center"
}