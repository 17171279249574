import React from "react";
import { Reducer } from "redux";
import WelcomeMessage from "./WelcomeMessage";
//import Summary from "./Summary";
//import Create from "./Create";
import Ask from "./Ask";
import WelcomeInfo from "./WelcomeInfo";
import { Stack } from "@fluentui/react";
import { WelcomeChildStyles, WelcomeContainerStyles } from "./Styles/Welcome.Styles";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { IProjectAppState } from "../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import Summary from "./Summary";
//import Project from "../Project/Project";

const Welcome = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector, dispatch } = reduxContext;
  // const { isProjectChatInContext } = useSelector(
  //   (state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState
  // );
  return (
    <Stack>
      {/* {isProjectChatInContext && <Project />} */}
      <Stack styles={WelcomeContainerStyles}>
        <WelcomeMessage />

        <Stack styles={WelcomeChildStyles}>
          {/* <Summary /> */}
        </Stack>

        {/* <WelcomeInfo /> */}
      </Stack>
    </Stack>
  );
};

export default Welcome;
