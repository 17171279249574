import React from 'react'
import './CreateProject.module.css'
import { useLocation } from 'react-router-dom'
import BasicInformation from '../BasicInformation/BasicInformation';
import Review from '../Review/Review';
import FirmSelection from '../FirmSelection/FirmSelection';
import OutsideCounsel from '../OutsideCounsel/OutsideCounsel';
import FinancialInformation from '../FinancialInformation/FinancialInformation';
import CreateProjectPage from '../../CreateProjectPage';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation';
import FileManagement from '../CaseDocumentsReview/FileManagement';
import ViewProject from '../../ViewProject';

const CreateProject = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const componentName: string = queryParams.get('componentName');
  const isRPSelectedString: string | null = queryParams.get('isRPSelected');
  const isRPSelected: boolean = isRPSelectedString ? isRPSelectedString === 'true' : false;

  let componentToRender: JSX.Element = null;

  switch (componentName) {
    case "view":
    componentToRender = <ViewProject />
      break;
    case "basic":
      componentToRender = <CreateProjectPage />
      break;
    case "fileAttachment":
      componentToRender = <FileManagement isRPSelected={isRPSelected} />
      break;
    case "review":
      componentToRender = <Review />
      break;
    case "firm":
      componentToRender = <FirmSelection />
      break;
    case "outside":
      componentToRender = <OutsideCounsel />
      break;
    case "finance":
      componentToRender = <FinancialInformation />
      break;
    case "additional":
      componentToRender = <AdditionalInformation />
      break;
    default:
      componentToRender = <CreateProjectPage />
      break;

  }

  return (
    <div className='createContainer'>
      <div className='create'>
        {componentToRender}
      </div>
    </div>
  )
}
export default CreateProject