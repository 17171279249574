import React from 'react'
import ChatDisplay from '../../ChatDisplay/ChatDisplay'
import { Stack } from '@fluentui/react'
import Userprompt from '../../UserPrompt/Userprompt'
import './Middle.styles.css'
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../../Shared/IChatState';
import Welcome from '../../Welcome/Welcome'
import SystemPrompts from '../../SystemPrompts/SystemPrompts'
import ChatNavigation from '../../Navigation/ChatNavigation'
import ChatLogs from '../../ChatLogs/ChatLogs'
import { LitigationContext } from "../../ChatHome";

const Middle = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { files } = React.useContext(LitigationContext);
  const { topic, newUserPrompt, topicIdInContext, shouldDiplayChatLogs } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);

  
  return (
    <Stack style={{ height: '100%', position: 'relative', marginTop: -23 }}>
      <Stack className='vertical large' style={{ marginLeft: 10 }}>
        <Stack className="scrollable-content">
          <Stack style={{ width: '98%', marginLeft: 10 }}>
            <Stack style={{ marginTop: -10, marginLeft: 6 }}>
              <ChatNavigation />
            </Stack>
            {shouldDiplayChatLogs && (
              <Stack style={{ marginRight: 10 }}>
                <ChatLogs />
              </Stack>
            )}
            {!shouldDiplayChatLogs && (
              <>
                <Stack>
                  <Welcome />
                </Stack>
                <Stack>
                  <ChatDisplay />
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {!shouldDiplayChatLogs && (
        <Stack className='vertical small'>
          <Stack className='content'>
            <Stack className='sticky-content'>
            {files?.length > 0 && <SystemPrompts />}
              <Userprompt />
            </Stack>
          </Stack>
        </Stack>)}
    </Stack>
  )
}
export default Middle