import {Icon, Stack } from "@fluentui/react";
import * as React from "react";
import { cardTitle, generateInsightsCardStyle, generateInsightsLink, iconStyles, seeGenerateInsightsText } from "./Styles/GenerateInsight";
import { AISearch, Chat, ChatRequest } from "@docufyai/ai-search-ui";
import { AISearchChat } from "../AIChat/AIChat";
import { setNewUserPrompt } from "../../../Shared/Actions/Chat.action";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";

export interface IGenerateInsight{
    toggleCopilot (toggle:boolean): void,
    generateInsightsButtonClicked (toggle: boolean): void,
}
function GenerateInsight(generatensightsProps: IGenerateInsight): React.ReactElement {
    const {toggleCopilot ,generateInsightsButtonClicked } = generatensightsProps
    function openChatInstance(): void {
        toggleCopilot(true);
        generateInsightsButtonClicked(true);
    }
    
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    
    return (
        <>
            <Stack style={generateInsightsCardStyle}>
                <Stack style={{ height: 80 }}>
                <Stack horizontal>
                <Stack>
                    <Icon
                        iconName="CRMCustomerInsightsApp"
                        style={iconStyles}
                    />
                </Stack>
                <Stack>
                    <div style={cardTitle}>
                        Generate Insights
                    </div>
                </Stack>

            </Stack>
                <Stack>
                    <div style={seeGenerateInsightsText} tabIndex={0}>
                        {'Please click on generate insights button below to generate the insights from all the project documents'}
                    </div>
                    
                </Stack>
                <Stack>
                    <div style={generateInsightsLink} tabIndex={0}>
                        <Stack onClick={(e) => openChatInstance()}> Generate Insights </Stack>
                    </div>
                </Stack>
                </Stack>

            </Stack>
        </>
    );
}


export default GenerateInsight ;