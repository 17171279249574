import { Stack, Icon, TooltipHost } from '@fluentui/react';
import React from 'react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import {
    IRecentDocumentsResponse
} from '../../../../Models/IRecentDocumentsResponse';
import { getIconByFileExtension, truncateText } from '../../../../Helpers/ProjectHelper';
import {CarouselIconContainer, FileNameContainer, OpenButton, ButtonContainer, ModifiedContainer, BottomContainer} from './Styles/CarouselItem.styles'
export interface ICarouselItemProps {
    item?: IRecentDocumentsResponse;
}
export default function CarouselItem(carouselItemProps: ICarouselItemProps) {
    const { item } = carouselItemProps;
    const openSPOFile = (filePath: string)=>{
        window.open(`${filePath}?web=1`)
    }
    return (
        <Stack>
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <Stack
                    style={CarouselIconContainer}
                    aria-label={`${getIconByFileExtension(item?.fileName)}`}
                >
                    <Icon
                        {...getFileTypeIconProps({
                            extension: getIconByFileExtension(item?.fileName),
                            size: 32,
                            imageFileType: 'png'
                        })}
                    />
                </Stack>
                <Stack style={{ width: '210px' }}>
                    <Stack
                        style={FileNameContainer}
                        aria-label={`${getIconByFileExtension(item?.fileName)}`}
                    >
                        <Stack>
                            <TooltipHost content={item?.fileName}>
                                {truncateText(item?.fileName, 25, 25)}
                            </TooltipHost>
                        </Stack>
                    </Stack>
                    <Stack
                        horizontal
                        style={BottomContainer}
                    >
                        <Stack
                            style={ModifiedContainer}
                            aria-label="Document last modified"
                        >
                            <TooltipHost content={item?.lastModifiedDate}>
                                {item?.lastModifiedDate}
                            </TooltipHost>
                        </Stack>
                        <Stack
                            style={ButtonContainer}
                        >
                            <button
                                style={OpenButton}
                                tabIndex={0}
                                aria-label="Click on this button to open the document in a browser window"
                                onClick={()=>openSPOFile(item?.filePath)}
                            >
                                Open
                            </button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
