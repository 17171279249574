import * as React from "react";
import { Persona, PersonaSize, Stack, TooltipHost } from "@fluentui/react";
import { DisplaySpinner } from "../../../Common/Components/DisplaySpinner";
import useGetProjectMemberProfile from "../../Hooks/useGetProjectMemberProfile";

type IPersonaView = {
  userAlias: string;
  graphTokenParam?: string;
  shouldDisplayPicture?: boolean
};

const MemberView = (personaView: IPersonaView) => {
  const { userAlias, graphTokenParam, shouldDisplayPicture = true } = personaView;
  const { projectMemberProfile, isProjectMemberProfileLoading } = useGetProjectMemberProfile({ userAlias, graphTokenParam })

  return (
    <>
      {isProjectMemberProfileLoading && (
        <Stack style={{ marginTop: 30 }}>
          <span role="alert" aria-label="loading member details" aria-live="assertive" />
          <DisplaySpinner accessabilityMessage={"Loading ..."} spinnerPosition={"right"} spinnerText={"Loading..."} />
        </Stack>
      )}
      {!isProjectMemberProfileLoading && projectMemberProfile && (
        <span>
          <TooltipHost content={
            <div>
              <div>{projectMemberProfile?.text}</div>
              <div>{projectMemberProfile?.secondaryText}</div>
            </div>
          }>
            <Persona
              role="icon"
              aria-label={`${projectMemberProfile?.text}`}
              size={PersonaSize.size24}
              {...projectMemberProfile}
              secondaryText={`${projectMemberProfile?.secondaryText}`}
              showSecondaryText={true}
            ></Persona>
          </TooltipHost>
        </span>
      )}
      {!isProjectMemberProfileLoading && !projectMemberProfile && (
        <span>
          <TooltipHost content={
            <div>
              <div>{projectMemberProfile?.text}</div>
              <div>{projectMemberProfile?.secondaryText}</div>
            </div>
          }>
            <Persona
              role="icon"
              aria-label={`${projectMemberProfile?.text}`}
              size={PersonaSize.size24}
              text={userAlias}
              secondaryText={`${projectMemberProfile?.secondaryText}`}
              showSecondaryText={true}
            ></Persona>
          </TooltipHost>
        </span>
      )}
    </>
  );
};
export default MemberView;
