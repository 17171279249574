import * as React from 'react';
import { AISearch, Chat, ChatFeedback, ChatRequest, ChatType, DocumentReference } from '@docufyai/ai-search-ui';

interface IAISearchChatProps {
    showAiSearch: boolean;
    onDismissAiSearch: () => void;
    type: string;
}
export type AISearchChatProps = IAISearchChatProps;

export const AISearchChat = (props: AISearchChatProps) => {

    const [sysPrompts, setSysPrompts] = React.useState<string[]>([]);

    const { showAiSearch, onDismissAiSearch, type } = props;

    const addPrompts = (promptsToAdd: string[]) => {
        setSysPrompts(prevPrompts => [...prevPrompts, ...promptsToAdd]);
    };

    // On Component Mount
    React.useEffect(() => {
        if (type === 'create')
            setSysPrompts(["Summarize this project"]);
        else if(type==='attachment')
        {
            addPrompts(["Summarize this project", "Another prompt", "Yet another prompt"]);
        }
        else
            setSysPrompts(["preferred law firms for this case"]);

    }, [])


    // Function to handle fetching AI response from backend and
    // returning an answer to show in the chat window.
    const handleSendQuestion = async (prompt: ChatRequest): Promise<Chat | undefined> => {
        
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        // Wait for 3 seconds
        await delay(3000);

        if (type === 'create') {
            return {
                content: `Here's a summary of the document:
                Main ideas:
                •	Virtru's lawsuit against Microsoft for patent infringement: Virtru Corporation, a data protection and privacy company, filed a complaint against Microsoft Corporation for infringing three of its patents related to encryption and identity management technologies.1
                •	Virtru's patented technology and products: Virtru's patents, which were invented by its co-founder and CTO Will Ackerly, claim methods and systems for distributing cryptographic data to authenticated recipients via secured or unsecured channels. This technology allows users to share encrypted information securely without requiring a new username or password to access it. Virtru's products, which are based on this technology, serve more than 7,300 customers across various sectors and protect more than one billion data assets.
                •	Microsoft's partnership and collaboration with Virtru: Virtru began working with Microsoft in 2014 to integrate its encryption technology into Microsoft's Office 365 and Outlook products. In 2016, Microsoft invited Virtru to join its Seattle Accelerator Program, which offered strategic growth opportunities for startups on the Microsoft platform. Virtru presented its patent portfolio and core technological innovations to Microsoft's senior executives from various groups, and discussed partnership opportunities with Microsoft.
                •	Microsoft's launch of infringing features and products: In 2017, Microsoft launched new features and products that infringed Virtru's patents, such as the Office 365 Message Encryption feature, the Azure Portal feature, and the Azure Key Vault feature. These features and products allowed users to send encrypted and rights-protected messages to anyone and to use any identity system for per-object distribution of cryptographic data. Microsoft did not obtain a license or authorization from Virtru to use its patented technology.
                •	Virtru's claims and demands: Virtru filed this lawsuit to enforce its patent rights and prevent Microsoft from unfairly profiting from Virtru's inventions. Virtru alleges that Microsoft's infringement has caused and continues to cause irreparable harm to Virtru's business, market share, and goodwill. Virtru seeks a jury trial, a permanent injunction, damages, and attorneys' fees and costs.
                `,
                chatType: ChatType.ANSWER,
                references: []
            };
        }
        else {
            const lawFirm1Details = "Law Firm 1: 10-Square Solutions";
            const lawFirm2Details = "Law Firm 2: 110 Consulting";

            // Create two chat cards for each law firm
            const lawFirm1Card: Chat = {
                content: lawFirm1Details,
                chatType: ChatType.ANSWER,
                references: []
            };

            const lawFirm2Card: Chat = {
                content: lawFirm2Details,
                chatType: ChatType.ANSWER,
                references: []
            };

            // Return a single chat object containing an array of cards
            return {
                content: "Here are the details of the preferred law firms: 10-Square Solutions and 110 Consulting",
                chatType: ChatType.ANSWER,
                references: [
                    { documentName: "Law Firm 1: 10-Square Solutions", filePath: "", sourceUrl: "", sourceType: "" } as DocumentReference,
                    { documentName: "Law Firm 2: 110 Consulting", filePath: "", sourceUrl: "", sourceType: "" } as DocumentReference
                ]
            };
        }
    }

    const handleAiSearchDismiss = () => {
        onDismissAiSearch();
    }

    function sendFeedback(feedback: ChatFeedback): Promise<void> {
        throw new Error('Yet to be implemented');
    }
    const handleNewChatClick = () => {}

    return (
        <AISearch 
        showAiSearch={showAiSearch}
        onNewChatClick={handleNewChatClick}
        onDismiss={handleAiSearchDismiss}
        sendPrompt={handleSendQuestion}
        sendFeedback={sendFeedback}
        systemGeneratedPrompts={sysPrompts}
        showAllCitations={true}
        chatWindowIcon={{ iconUrl: "https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg" }}
      />
    );
}