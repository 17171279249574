export const container: React.CSSProperties = {
    marginTop: "10px",    
  };

  export const bookmarkIconStyle: React.CSSProperties = {
    cursor: "pointer",  
    marginLeft: "24px"  
  }; 

  export const projectNumberStyle: React.CSSProperties = {
    alignItems: "center"
  }; 
  