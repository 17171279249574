export enum ChatSuccessEventId {
  RequestSystemPromptsAction_Success=200,
  ChatQuestionRequestSuccess,
  RequestsetBookmarkAnswer_Success,
  RequestUserBookmark_Success,
  RequestUserBookmarks_Success,
  RequestChatLogs_Success,
  RequestChatLog_Success,
  SubmitIntakeFormRequest_Success,
  DeleteChatTopic_Success,
  EndSession_Success,
  PostFilesToTopic_Success,
  CreateRequestForAttorney_Success,
  EditChatTopic_Success,
  RequestDeleteBookmark_Success,
  FeedbackSubmit_Success,
  RequestBookmarkTopicByAnswerId_Success,
  RequestAttorney_Success,
  RequestFYLC_Success
}
export enum InformationeEventId {
  APIRetry = 600
}
export enum ChatFailureEventId {
  RequestSystemPromptsAction_Failure=500,
  ChatQuestionRequestFailure,
  RequestsetBookmarkAnswer_Failure,
  RequestUserBookmark_Failure,
  RequestUserBookmarks_Failure,
  RequestChatLogs_Failure,
  RequestChatLog_Failure,
  SubmitIntakeFormRequest_Failure,
  DeleteChatTopic_Failure,
  EndSession_Failure,
  PostFilesToTopic_Failure,
  CreateRequestForAttorney_Failure,
  EditChatTopic_Failure,
  EditChatTopic_Success,
  RequestDeleteBookmark_Failure,
  FeedbackSubmit_Failure,
  RequestBookmarkTopicByAnswerId_Failure,
  RequestAttorney_Failure,
  RequestFYLC_Failure
}
