import { DefaultButton, Dialog, DialogFooter, DialogType, IDropdownOption, IPersonaProps, Icon, PrimaryButton, SearchBox, Stack } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import { isObjectsSame } from "../../../Helpers/ProjectHelper";
import { IProjectMembers } from "../../../Models/IProjectMembers";
import { requestAddMembersToProject, requestDeleteMember, updateMembers, updateOwners } from "../../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import MemberPicker from "../../PickerComponents/MemberPicker";
import MemberDetails from "./MemberDetails";
import { addMember, memberSectionStyle, paddingTop4, sectionheaderStyle, teamContainer, userInputBoxStyle, userinput } from "./Styles/TeamMembers";
import useGraphToken from "../../Hooks/useGraphToken";
export const ProjectTeamContext = React.createContext(null);

const TeamMembers = () => {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch, httpClient } = reduxContext;
    const {
        projectMembers,
        projectOwner,
        projectContext,
        projectProps,
        deletedProjectMembers,
        isProjectMemberDeleting,
        isProjectMemberDeletingError,
        projectDeleteMemberError,
        projectMemberDeleteSuccess,
        isProjectMemberStatusUpdating,
        isProjectMemberStatusUpdatingError,
        projectMemberStatusUpdatingSuccess,
        projectMemberStatusUpdateError,
        newMembers,
        MemberAddingToProjectSuccess,
        isMemberAddingToProject,
        loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    const [membersList, setMembersList] = React.useState<IPersonaProps[]>([]);
    const [ownersList, setOwnersList] = React.useState<IPersonaProps[]>([]);
    const [originalmembersList, setOriginalMembersList] = React.useState<IPersonaProps[]>([]);
    const [originalownersList, setOriginalOwnersList] = React.useState<IPersonaProps[]>([]);
    const [hideDialog, setHideDialog] = React.useState(true);
    const [inputValue, setInputValue] = React.useState("");
    const [projectMember, setProjectMember] = React.useState<IProjectMembers>();
    const [projectOwners, setProjectOwners] = React.useState<IProjectMembers>();
    const {graphToken, isGraphTokenLoading} = useGraphToken();
    // Add Team Member Popup Start
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Add members",
    };

    const toggleHideDialog = () => setHideDialog(value => !value);

    // Removes the team member from the project
    const addTeamMember = (): void => {
        // console.log("Team Members", projectMember);
        // console.log("Project Members Object", projectMembers);
        dispatch(requestAddMembersToProject(projectContext, projectProps, projectMember,loginUserpersonaProps?.secondaryText))
        toggleHideDialog();
    }
    // Add Team Member Popup End

    //Filter Member Search Bar OnChange event
    const onChange = (value: string) => {
        setInputValue(value);
        //Filter on Members
        var updatedmemberList = originalmembersList;
        updatedmemberList = updatedmemberList.filter(function (item) {
            return item.text.toLowerCase().search(
                value.toLowerCase()) !== -1;
        });
        setMembersList(updatedmemberList);
        //Filter on Owners
        var updatedownerList = originalownersList;
        updatedownerList = updatedownerList.filter(function (item) {
            return item.text.toLowerCase().search(
                value.toLowerCase()) !== -1;
        });
        setOwnersList(updatedownerList);
    };

    React.useEffect(() => {
        if (projectMembers && projectMembers.membersPicked) {
            if (!isObjectsSame(projectMembers.membersPicked, originalmembersList)) {
                const uniquemember = projectMembers.membersPicked.filter((value, index) => {
                    const _value = JSON.stringify(value);
                    return index === projectMembers.membersPicked.findIndex(obj => {
                        return JSON.stringify(obj) === _value;
                    });
                });
                setOriginalMembersList(uniquemember);
                setMembersList(uniquemember);
            }
        }
    }, [projectMembers]);

    React.useEffect(() => {
        if (projectOwner && projectOwner.membersPicked) {
            if (!isObjectsSame(projectOwner.membersPicked, originalownersList)) {
                setOriginalOwnersList(projectOwner.membersPicked);
                setOwnersList(projectOwner.membersPicked);
            }
        }
    }, [projectOwner]);

    //Updates the Team Member State object is member is succesfully deleted from SPO
    const removeMemberFromState = (member: any, responsible: boolean) => {
        if (!isProjectMemberDeletingError && projectMemberDeleteSuccess) {
            const localprojectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: responsible };
            if (responsible) {
                projectOwner?.membersPicked.map((localmember: IPersonaProps, index: number) => {
                    if (member?.secondaryText && member?.secondaryText.toLowerCase() !== localmember?.secondaryText.toLowerCase()) {
                        const teamMember: IPersonaProps = {
                            text: localmember?.text,
                            secondaryText: localmember?.secondaryText,
                            optionalText: localmember?.optionalText,
                            showSecondaryText:true
                        };
                        localprojectMembers.membersPicked.push(teamMember);
                        localprojectMembers.alias.push(localmember?.secondaryText);
                    }
                });
            }
            else {
                projectMembers?.membersPicked.map((localmember: IPersonaProps, index: number) => {
                    if (member?.secondaryText && member?.secondaryText?.toLowerCase() !== localmember?.secondaryText.toLowerCase()) {
                        const teamMember: IPersonaProps = {
                            text: localmember?.text,
                            secondaryText: localmember?.secondaryText,
                            optionalText: localmember?.optionalText,
                            showSecondaryText:true
                        };
                        localprojectMembers.membersPicked.push(teamMember);
                        localprojectMembers.alias.push(localmember?.secondaryText);
                    }
                });
            }
            if (responsible) { dispatch(updateOwners(localprojectMembers)); }
            else { dispatch(updateMembers(localprojectMembers)); }
        }
    }

    //Updates the Member List based on State Change
    // If responsible=true, then remove the member object from ProjectMember list
    // If responsible=false, then add the member object to ProjectMember list
    const updateMemberListForStateChange = (member: any, responsible: boolean) => {
        if (!isProjectMemberStatusUpdatingError && projectMemberStatusUpdatingSuccess) {
            const localprojectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: responsible };
            if (responsible) {
                projectMembers.membersPicked.map((localmember: IPersonaProps, index: number) => {
                    if (member.secondaryText.toLowerCase() !== localmember?.secondaryText.toLowerCase()) {
                        const teamMember: IPersonaProps = {
                            text: localmember?.text,
                            secondaryText: localmember?.secondaryText,
                            optionalText: localmember?.optionalText,
                            showSecondaryText:true
                        };
                        localprojectMembers.membersPicked.push(teamMember);
                        localprojectMembers.alias.push(localmember?.secondaryText);

                    }
                });
            }
            else {
                var memberAlreadyAdded: boolean = false;
                localprojectMembers.membersPicked = projectMembers.membersPicked;
                localprojectMembers.alias = projectMembers.alias;
                localprojectMembers.membersPicked.map((localmember: IPersonaProps, index: number) => {
                    if (member.secondaryText.toLowerCase() === localmember?.secondaryText.toLowerCase()) {
                        memberAlreadyAdded = true;
                    }
                });
                if (!memberAlreadyAdded) {
                    const teamMember: IPersonaProps = {
                        text: member?.text,
                        secondaryText: member?.secondaryText,
                        optionalText: member?.optionalText,
                        showSecondaryText:true
                    };
                    localprojectMembers.membersPicked.push(teamMember);
                    localprojectMembers.alias.push(member?.secondaryText);
                }
            }
            dispatch(updateMembers(localprojectMembers));
        }
    }

    //Updates the Owner List based on State Change
    // If responsible=false, then remove the member object from ProjectOwner list
    // If responsible=true, then add the member object to ProjectOwner list
    const updateOwnerListForStateChange = (member: any, responsible: boolean) => {
        if (!isProjectMemberStatusUpdatingError && projectMemberStatusUpdatingSuccess) {
            const localprojectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: responsible };
            if (!responsible) {
                projectOwner.membersPicked.map((localmember: IPersonaProps, index: number) => {
                    if (member.secondaryText.toLowerCase() !== localmember?.secondaryText.toLowerCase()) {
                        const teamMember: IPersonaProps = {
                            text: localmember?.text,
                            secondaryText: localmember?.secondaryText,
                            optionalText: localmember?.optionalText,
                            showSecondaryText:true
                        };
                        localprojectMembers.membersPicked.push(teamMember);
                        localprojectMembers.alias.push(localmember?.secondaryText);

                    }
                });
            }
            else {
                var memberAlreadyAdded: boolean = false;
                localprojectMembers.membersPicked = projectOwner.membersPicked;
                localprojectMembers.alias = projectOwner.alias;
                localprojectMembers.membersPicked.map((localmember: IPersonaProps, index: number) => {
                    if (member.secondaryText.toLowerCase() === localmember?.secondaryText.toLowerCase()) {
                        memberAlreadyAdded = true;
                    }
                });
                if (!memberAlreadyAdded) {
                    const teamMember: IPersonaProps = {
                        text: member?.text,
                        secondaryText: member?.secondaryText,
                        optionalText: member?.optionalText,
                        showSecondaryText:true
                    };
                    localprojectMembers.membersPicked.push(teamMember);
                    localprojectMembers.alias.push(member?.secondaryText);
                }
            }
            dispatch(updateOwners(localprojectMembers));
        }
    }

    React.useEffect(() => {
        if (MemberAddingToProjectSuccess) {
            const tempProjectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };
            projectMembers?.membersPicked?.map((member: any) => {
                const teamMember: IPersonaProps = {
                    text: member?.text,
                    secondaryText: member?.secondaryText,
                    optionalText: member?.optionalText,
                    tertiaryText: "false",
                    showSecondaryText:true
                };
                tempProjectMembers.membersPicked.push(teamMember);
                tempProjectMembers.alias.push(member?.secondaryText);
            });
            if (projectMembers && projectMembers.membersPicked) {
                newMembers?.membersPicked?.map((member: any) => {
                    const teamMember: IPersonaProps = {
                        text: member?.alias,
                        secondaryText: member?.secondaryText,
                        optionalText: member?.optionalText,
                        tertiaryText: "false",
                        showSecondaryText:true
                    };
                    tempProjectMembers.membersPicked.push(teamMember);
                    tempProjectMembers.alias.push(member?.secondaryText);
                });
            }
            console.log("New Members after adding", tempProjectMembers);
            dispatch(updateMembers(tempProjectMembers));
        }
    }, [MemberAddingToProjectSuccess]);

    return (
        <>
            {isProjectMemberDeleting  && !isProjectMemberStatusUpdating && !isMemberAddingToProject && (
                <>
                    <span role="alert" aria-label={GenericMessages.deletingMember} aria-live="assertive" />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.deletingMember}
                        spinnerText={GenericMessages.deletingMember}
                        spinnerPosition={"right"}
                    />
                </>
            )}
            {isMemberAddingToProject && !isProjectMemberStatusUpdating && !isProjectMemberDeleting && (
                <>
                    <span role="alert" aria-label={GenericMessages.addingMembersToProject} aria-live="assertive" />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.addingMembersToProject}
                        spinnerText={GenericMessages.addingMembersToProject}
                        spinnerPosition={"right"}
                    />
                </>
            )}
            {isProjectMemberStatusUpdating && (
                <>
                    <span role="alert" aria-label={GenericMessages.updatingMemberStatus} aria-live="assertive" />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.updatingMemberStatus}
                        spinnerText={GenericMessages.updatingMemberStatus}
                        spinnerPosition={"right"}
                    />
                </>
            )}
            {!isProjectMemberDeleting && isProjectMemberDeletingError && (
                <>
                    <ErrorDisplay source={projectDeleteMemberError?.errorMessage} />
                </>
            )}
            {!isProjectMemberStatusUpdating && isProjectMemberStatusUpdatingError && (
                <>
                    <ErrorDisplay source={projectMemberStatusUpdateError?.errorMessage} />
                </>
            )}
            {!isProjectMemberDeleting && !isMemberAddingToProject && !isProjectMemberStatusUpdating && graphToken.length>0 && (
                <>

                    <div style={teamContainer}>
                        <div style={sectionheaderStyle}>
                            Team Members
                        </div>


                        <div style={userinput}>
                            <SearchBox
                                ariaLabel="Press enter key after entering text in the search box"
                                placeholder="Find a member"
                                onSearch={(searchValue) => onChange(searchValue)}
                                style={userInputBoxStyle}
                                onChange={(e, n) => onChange(n)}
                            />
                        </div>

                    </div>
                    <Stack style={memberSectionStyle}>
                        {ownersList?.map((member: IPersonaProps, index: number) => {
                            return (
                                <MemberDetails
                                    index={index}
                                    member={member}
                                    responsibleFor={true}
                                    removeMemberFromState={removeMemberFromState}
                                    updateMemberListForStateChange={updateMemberListForStateChange}
                                    updateOwnerListForStateChange={updateOwnerListForStateChange} 
                                    graphTokenParam={graphToken}
                                    />
                            )
                        })}
                        {membersList?.map((member: IPersonaProps, index: number) => {
                            return (
                                <MemberDetails
                                    index={index}
                                    member={member}
                                    responsibleFor={false}
                                    removeMemberFromState={removeMemberFromState}
                                    updateMemberListForStateChange={updateMemberListForStateChange}
                                    updateOwnerListForStateChange={updateOwnerListForStateChange} 
                                    graphTokenParam={graphToken}
                                    />
                            )
                        })}
                    </Stack>
                    {!projectContext.isBillable && (
                        <Stack>
                            <div style={addMember}>
                                <span onClick={toggleHideDialog}>
                                    Add member
                                    <Dialog
                                        hidden={hideDialog}
                                        onDismiss={toggleHideDialog}
                                        dialogContentProps={dialogContentProps}
                                    >
                                        <ProjectTeamContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers }}>
                                            <MemberPicker
                                                peopleType="member"
                                            />
                                        </ProjectTeamContext.Provider>
                                        <DialogFooter>
                                            <PrimaryButton onClick={addTeamMember} text="Add" />
                                            <DefaultButton onClick={toggleHideDialog} text="Close" />
                                        </DialogFooter>
                                    </Dialog>
                                </span>
                            </div>
                        </Stack>
                    )}
                </>
            )}
        </>
    )
}

export default TeamMembers;