import { Stack } from '@fluentui/react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import * as React from 'react';
import { Reducer } from 'redux';
import DisplaySpinner from '../../../Common/Components/DisplaySpinner';
import { GenericMessages } from '../../../Helpers/ProjectConstants';
import { IProjectAppState } from '../../../Shared/ProjectState';
import {
    ProjectReducerName,
    projectReducer,
    projectInitialState
} from '../../../Shared/Reducers/Project.reducer';
import { projectSagas } from '../../../Shared/Sagas/Project.sagas';
import SearchResponseItem from './SearchResponseItem';
import { container, spinnerMargin, titleText } from './Styles/SearchResponse';
import { IResponse } from 'src/Models/ISimilarProjectResponse';

export interface searchProps {
    projectNumber?: string;
}

const SearchResponse = (searchProps: searchProps) => {
    const { projectNumber } = searchProps;
    const reduxContext = React.useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [
        projectSagas
    ]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectSimilarResponse,
        isGettingSimilarProject,
        GettingSimilarProjectSuccess
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );
    
    const [uniqueResponses, setUniqueResponses] = React.useState<IResponse[]>(
        []
    );
    React.useEffect(() => {                
        if (projectSimilarResponse?.responses?.length) {  
            const uniqueObjectArray = projectSimilarResponse?.responses?.filter(
                (obj, index, array) =>
                    array.findIndex((o) => o.number === obj.number) === index
            );
            setUniqueResponses(uniqueObjectArray);
        }
    }, [projectSimilarResponse]);
    return (
        <>
            {isGettingSimilarProject && (
                <>
                    <Stack style={spinnerMargin}>
                        <span
                            role="alert"
                            aria-label={GenericMessages.searchingProject}
                            aria-live="assertive"
                        />
                        <DisplaySpinner
                            accessabilityMessage={
                                GenericMessages.searchingProject
                            }
                            spinnerText={GenericMessages.searchingProject}
                            spinnerPosition={'right'}
                        />
                    </Stack>
                </>
            )}
            {!isGettingSimilarProject && GettingSimilarProjectSuccess && (
                <>
                    {projectSimilarResponse.totalFileCount === 0 && (
                        <Stack style={titleText}>
                            {'No similar project found'}
                        </Stack>
                    )}
                    {projectSimilarResponse.totalFileCount > 0 && (
                        <Stack horizontal style={container}>
                            <Stack>
                                {uniqueResponses.map((item, index) => (
                                    <SearchResponseItem
                                        key={index}
                                        index={index}
                                        responseItem={item}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    )}
                </>
            )}
        </>
    );
};

export default SearchResponse;
