import React, { useState, useEffect, useRef } from 'react'
import { ISearchResponse } from '../../../../Models/Chat/ISearchResponse';
import { ISource } from '../../../../Models/Chat/ISource';
import { Stack, Icon } from '@fluentui/react';
import MarkdownIt from 'markdown-it';
import MessageSource from './MessageSource';
import { LitigationContext } from "../ChatHome";
import Feedback from '../Feedback/Feedback';
import './DisplayAnswer.styles.css'
import ChatCopy from './ChatCopy'
import FileContentDialog from '../../Project/CreateProject/CaseDocumentsReview/FileContentDialog';
import { ILitigationFileContentRequest } from 'src/Models/ILitigationCaseRequest';
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { requestLitigationFileContent, requestLitigationFileURL } from "../../../../../src/Shared/Actions/Project.action";
import { ILitigationCase } from 'src/Models/ILitigationCase';

export type IChatTopicResponseProps = {
	chatResponse?: ISearchResponse;
	isNewAnswer?: boolean;
	user?: string;
	userAlias?: string;
	qnaId?: string;
	topicId?: string;
	sourceType?: string;
	question?: string;
	sources: ISource[];
	topicProjectNumber?: string;
};
const DisplayAnswer = (chatTopicResponseProps: IChatTopicResponseProps) => {
	
	const { chatResponse, isNewAnswer, userAlias, qnaId, topicId, sourceType, sources, topicProjectNumber } = chatTopicResponseProps;
	const [showDisplaySources, setShowDisplaySources] = useState<boolean>(false);
	const [uniqueSources, setUniqueSources] = useState<ISource[]>([]);
	const [answer, setAnswer] = useState('');
	const containerRef = useRef<HTMLDivElement>(null);
	const [activeFileId, setActiveFileId] = useState<string | null>(null);
	const [hideChatFileDialog, setHideChatFileDialog] = React.useState(true);
	const [nativeChatFilePath, setNativeChatFilePath] = useState<string>('')
	const { user, onFolderClick, isCasePage, disableFolderButton,projectNumber } = React.useContext(LitigationContext);
	const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
	const { dispatch } = reduxContext;
	
	const [currentSourceTye, setCurrentSourceType] = useState<string | null>(null);
	useEffect(() => {
		if (sources?.length > 0) {
			const seenFilepaths = new Set();
			const filteredUrls = sources.filter((source: ISource) => {
				if (!seenFilepaths.has(source.filepath)) {
					seenFilepaths.add(source.filepath);
					return true;  // Include in the filtered result
				}
				return false;  // Exclude duplicates
			});
			setUniqueSources(filteredUrls);
		}
		let mdAnswer: string = chatResponse?.answer?.replace('(', '( ').replace(')', ' )')
		mdAnswer = new MarkdownIt().render(mdAnswer)
		mdAnswer = replaceEmailsWithLinks(mdAnswer)
		mdAnswer = replaceURLsWithOpenFile(mdAnswer)
		setAnswer(mdAnswer);
	}, [chatResponse?.sources]);
	const toggleSources = () => {
		setShowDisplaySources(!showDisplaySources);
	};

	const handleKeyDownOnSources = (event: any) => {
		if (event.key === 'Enter' || event.keyCode === 13) {
			setShowDisplaySources(!showDisplaySources);
		}
	}

	const replaceURLsWithOpenFile = (text: string): string => {
		// Regular expression to match URLs starting with https or existing anchor tags
		const urlRegex = /(?:<a[^>]*>.*?<\/a[^>]*>)|(?<!href=["'])(?<!<\/a>)\b(https?:\/\/(?:www\.)?[\w./?=%&-]+)\b(?!<\/a>)/g;
		const hrefRegex = /<a\s+[^>]*href=["']([^"']+)["'][^>]*>/i;
	  
		return text.replace(urlRegex, (match, p1) => {
		  const isBlobStorageUrl = p1?.includes('blob.core.windows.net');
	  
		  // Helper function to get sources URL from the href match
		  const getSourceUrl = (url: string) => {
			return sources?.filter(src => src?.documentTitle?.toLocaleLowerCase() === url.toLocaleLowerCase())?.[0];
		  };
	  
		  if (p1) { //Will be true when the matched part of the string is a URL like http://example.com or https://blob.core.windows.net/... and not inside an anchor tag. 
			if (isBlobStorageUrl) {
			  const source = getSourceUrl(p1);
			  if (source) {
				return `<a data-fileid="${source.filepath}" onClick="event => handleLinkClick(event, '${source.filepath}')" target="_blank" rel="noopener noreferrer" class="link">${p1}</a>`;
			  }
			  return ''; // No match for blob storage URL
			}
			return `<a href="${p1}" target="_blank" rel="noopener noreferrer" class="link">${p1}</a>`; // Regular URL
		  } else {
			// URL within existing anchor tag
			const matchUrl = match.match(hrefRegex);
			if (matchUrl && matchUrl[1]) {
			  const source = getSourceUrl(matchUrl[1]);
			  if (source) {
				return match.replace(/<a(.*?)>/, `<a$1 data-fileid="${source.filepath}" onClick="event => handleLinkClick(event, '${source.filepath}')" target="_blank" rel="noopener noreferrer" class="link">`);
			  }
			  return match.replace(/<a(.*?)>/, '<a$1 target="_blank" rel="noopener noreferrer" class="link">');
			}
			return ''; // No match for anchor URL
		  }
		});
	  };
	  

	const handleLinkClick = React.useCallback((e: MouseEvent, fileId: string) => {
		e.preventDefault();
		if (fileId !== activeFileId) {			
			const source: ISource = (sources?.filter(src => src?.filepath === fileId))?.[0];
			if(source){
				setActiveFileId(fileId);
				openFile(source)
			}
			else{
				console.error(`Source not found for fileId: ${fileId}`);
			}
		}
	}, [activeFileId, sources]);
	
	const openFile = async (source: ISource) => {
		try {

			if (source?.sourceUrl !== '' && source?.documentTitle !== '') {

				setHideChatFileDialog(false);
				let nativePath = combinePaths(source?.documentTitle, source?.sourceUrl);
				setNativeChatFilePath(nativePath);
				const litigationFileContentRequest: ILitigationFileContentRequest = {
					userAlias: user?.secondaryText,
					fileName: '',
					fileId: source.filepath,
					caseInfo: source?.custodianCase,
					isKeywordSearch: false,
					nativeFilePath: nativePath
				}
				dispatch(requestLitigationFileContent(litigationFileContentRequest));
				dispatch(requestLitigationFileURL(litigationFileContentRequest));
			}
		}
		catch (error: any) {
			console.log(error)
		}
	}

	function combinePaths(metadataStoragePath: string, exportNativePath: string): string {
		// Step 1: Find the index of '.net/' in metadataStoragePath
		const baseIndex = metadataStoragePath.indexOf('.net/') + 5; // Length of '.net/' is 5

		if (baseIndex === 4) {
			console.error('Failed to find .net/ in metadataStoragePath.');
			return '';
		}

		// Find the index of the next '/' after the baseIndex
		const nextSlashIndex = metadataStoragePath.indexOf('/', baseIndex);
		if (nextSlashIndex === -1) {
			console.error('No slash found after .net/ in metadataStoragePath.');
			return '';
		}

		// Find the next '/' to extract the base URL up to and including the container name and litigation ID
		const subsequentSlashIndex = metadataStoragePath.indexOf('/', nextSlashIndex + 1);
		if (subsequentSlashIndex === -1) {
			console.error('Failed to find the next slash after container name and litigation ID.');
			return '';
		}

		// Extract the base URL up to and including the container name and litigation ID
		const baseURL = metadataStoragePath.substring(0, subsequentSlashIndex + 1);

		// Step 2: Combine the base URL with exportNativePath
		const filePath = `${baseURL}${exportNativePath.startsWith('/') ? exportNativePath.slice(1) : exportNativePath}`;

		// Step 3: Return the combined file path
		return filePath;
	}


	const replaceEmailsWithLinks = (text: string): string => {
		// Regular expression to match email addresses
		const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

		// Replace each matched email with a mailto hyperlink
		return text.replace(emailRegex, (match) => {
			return `<a href="mailto:${match}" class="link">${match}</a>`;
		});
	}


	React.useEffect(() => {
		if (answer && answer.length > 0) {
			const container = containerRef.current;
			if (container) {
				const links = container.querySelectorAll('a');
				links.forEach(link => {
					const fileId = link.getAttribute('data-fileid'); // Get fileId from the data attribute
					link.addEventListener('click', (e) => handleLinkClick(e, fileId || ''));
				});
				return () => {
					links.forEach(link => {
						const fileId = link.getAttribute('data-fileid');
						link.removeEventListener('click', (e) => handleLinkClick(e, fileId || ''));
					});
				};
			}
		}
	}, [answer])

	const handleCloseChatFileDialog = () => {
		setHideChatFileDialog(true);
	};

	useEffect(() => {
		if(uniqueSources.length > 0){
			const sourcesUrls:ISource[] = uniqueSources.filter(x=>x.documentTitle.includes("blob.core.windows.net"));
			if(sourcesUrls.length===0 ){
				setCurrentSourceType('From CELA DMS the following information was found:')
			}
			else if(sourcesUrls.length>0 ){
				setCurrentSourceType(`From ${sourceType} the following information was found:`)
			}
		}		
	}, [uniqueSources])


	function folderClick(uniqueSources: ISource[]): void {
		if (!disableFolderButton) {
			let ids: string[] = [];
			uniqueSources.map((source: ISource)=>{
			   ids = [...ids,source?.filepath]
			})
			
			onFolderClick(ids);
		}
	}

	return (
		<>
			<Stack>
				<Stack>
					<Stack>
						<Stack>
							<div className='answer-container'>
								<Stack className='answer-display'>
									<Stack horizontal>
										<Stack className='source-text'>
											<Stack>{currentSourceTye}</Stack>
										</Stack>

									</Stack>
									<div>
										<div ref={containerRef} style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: answer }} />
										{activeFileId && (
											<FileContentDialog
												isOpen={!hideChatFileDialog}
												title={""}
												onDismiss={handleCloseChatFileDialog}
												dialogWidth="98vw"
												dialogHeight="98vh"
												nativeFilePath={nativeChatFilePath}
											/>
										)}
									</div>
								</Stack>
								<Stack style={{ marginTop: 5, width: '100%' }}>

									<Stack horizontal>
										<Stack style={{ marginRight: 10 }}>
											<Feedback userAlias={userAlias} topicId={topicId} QNAId={qnaId} answer={chatResponse?.answer} feedbackText="AI-generated content may be incorrect" />
										</Stack>
										<Stack>
											<ChatCopy
												chatResponse={chatResponse}
												stackStyles={{
													marginLeft: 'auto',
													marginTop: -3
												}}
											/>
										</Stack>
									</Stack>
									<Stack horizontal>
										{isCasePage !== undefined && isCasePage && (topicProjectNumber === undefined || projectNumber === topicProjectNumber) && 
										<Stack style={{ marginLeft: 'auto', marginTop: 10 }}>
											<Icon 
												iconName='ClipboardList' 
												style={{
													color: disableFolderButton ? "#A19F9D" : "#5b5fc7",  // Change color if disabled
													width: 20,
													height: 22,
													cursor: disableFolderButton  ? 'not-allowed' : 'pointer',  // Change cursor if disabled
													pointerEvents: disableFolderButton ? 'none' : 'auto'  // Prevent interaction if disabled
												}} // Added cursor style
												onClick={() => folderClick(uniqueSources)}
												
											/> {/* Added file icon */}
										</Stack>}
										<Stack className='reference-container' wrap={true} horizontal>
											<Stack className='reference-length'>{uniqueSources.length.toString()} references</Stack>
											{uniqueSources.length > 0 && (
												<Stack className='reference-icon'>
														<Icon iconName={showDisplaySources ? 'ChevronUpMed' : 'ChevronDownMed'} onClick={toggleSources} onKeyDown={handleKeyDownOnSources} tabIndex={0} />
													</Stack>
											)}
										</Stack>
									</Stack>
								</Stack>
								<Stack style={{ width: '100%' }}>
									{showDisplaySources &&
										<Stack style={{ position: 'relative' }}>
											<Stack
												style={{
													top: 5,
													background: 'aliceblue',
													zIndex: 1000,
													overflowX: 'hidden'
												}}
											>
												{uniqueSources.length > 0 && (
													<Stack>
														{chatResponse?.sources &&
															uniqueSources?.map((source: ISource) => {
																return (
																	<Stack >
																		<Stack className='response-text'>
																			<MessageSource source={source} isNewAnswer={isNewAnswer} />
																		</Stack>
																	</Stack>
																);
															})}
													</Stack>
												)}
											</Stack>
										</Stack>
									}
								</Stack>
							</div>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack style={{ marginTop: 40 }}>{''}</Stack>
		</>
	)
}

export default DisplayAnswer
