export const CarouselIconContainer: React.CSSProperties = {
    width: 32,
    height: 32,
    flexShrink: 0,
    marginTop: -7
};

export const FileNameContainer: React.CSSProperties = {
    color: '#424242',
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',

    alignItems: 'flex-start',
    marginLeft: 0
};

export const BottomContainer: React.CSSProperties = {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 0
};

export const ModifiedContainer: React.CSSProperties = {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px'
};

export const ButtonContainer: React.CSSProperties = {
    display: 'inline-flex',
    padding: '8px 8px 8px 8px',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '4px',
    border: '1px solid var(--Light-theme-Hover-Border-Default-Border-Hover, #C7C7C7)',
    marginRight: 25,
    marginTop:10
};

export const OpenButton: React.CSSProperties = {
    textAlign: 'center',
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '19px',
    backgroundColor: 'white',
    border: '0px'
};
