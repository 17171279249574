import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import {Dashboard} from './Components/Dashboard/Dashboard';
import CreateProject from './Components/Projects/CreateProject';
import ProjectList from './Components/Projects/ProjectList';
import Projects from './Components/Projects/Projects';
import SearchProject  from './Components/Projects/SearchProjects/SearchProject';
import ViewProjects from './Components/V2/Project/ViewProject';
import AllNotifications from './Components/Dashboard/Notifications/AllNotifications';
import BaseContainer  from './Components/V2/BaseContainer';
import { DashboardPage } from './Components/V2/Dashboard/DashboardPage';
import ProjectsListPage  from './Components/V2/Project/ProjectsListPage';
import  CreateProjectPage  from './Components/V2/Project/CreateProjectPage';
import UserHistoryPage from './Components/V2/History/UserHistoryPage';
import BookmarkPage from './Components/V2/Bookmark/BookmarkPage';
import ViewProject from './Components/V2/Project/ViewProject';
import CreateProjectLayout from './Components/V2/Project/CreateProject/CreateProjectLayout';
import BasicInformation from './Components/V2/Project/CreateProject/BasicInformation/BasicInformation';
import FirmSelection from './Components/V2/Project/CreateProject/FirmSelection/FirmSelection';
import OutsideCounsel from './Components/V2/Project/CreateProject/OutsideCounsel/OutsideCounsel';
import FinancialInformation from './Components/V2/Project/CreateProject/FinancialInformation/FinancialInformation';
import Review from './Components/V2/Project/CreateProject/Review/Review';


export function Routes(): React.ReactElement {

    // Toggle below to true for enabling V2 experience
    // This will be replaced with a feature flag shortly
    const v2Enabled = true;

    return (
        <>
            {!v2Enabled &&
                <Switch>
                    <Route path="/" component={Dashboard} exact={true} />
                    <Route path="/projects" component={Projects} exact={true} />
                    <Route path="/createproject" component={CreateProject} exact={true} />
                    <Route path="/viewproject" component={ViewProjects} exact={true} />
                    <Route path="/projectlist" component={ProjectList} exact={true} />
                    <Route path="/search" component={SearchProject} exact={true} />
                    <Route path="/allNotifications" component={AllNotifications} exact={true} />
                </Switch>
            }

            {v2Enabled &&
                <Switch>
                    <Route
                        path="/"
                        exact={true} 
                        render={() => <BaseContainer sidePanelSelection='dashboard' mainComponent={ <DashboardPage/> } />}
                    />
                    <Route
                        path="/projects"
                        exact={true} 
                        render={() => <BaseContainer sidePanelSelection='projects' mainComponent={ <ProjectsListPage/> } />}
                    />
                    <Route
                        path="/history"
                        exact={true} 
                        render={() => <BaseContainer sidePanelSelection='history' mainComponent={ <UserHistoryPage/> } />}
                    />
                    <Route
                        path="/bookmarks"
                        exact={true} 
                        render={() => <BaseContainer sidePanelSelection='bookmarks' mainComponent={ <BookmarkPage/> } />}
                    />
                    {/* <Route
                        path="/createproject"
                        exact={true} 
                        render={() => <BaseContainer sidePanelSelection='dashboard' mainComponent={ <CreateProjectPage/> } />}
                    /> */}
                    <Route
                        path="/viewproject"
                        
                        render={() => <BaseContainer sidePanelSelection='projects' mainComponent={ <CreateProjectLayout/> } />}
                    />
                    <Route path="/createproject" render={() => <BaseContainer sidePanelSelection='dashboard'  mainComponent={ <CreateProjectLayout/> } />}>                        
                    </Route> 
                </Switch>
            }
        </> 
    );
}
