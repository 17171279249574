import { Icon, Stack, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { Reducer } from "redux";
import { container, notificationText, notificationTextBold, notificationTitleMsg } from "./Notification";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import { isNotificationRead, requestProjectDetails, requestUserNotification, setProjectContext } from "../../../Shared/Actions/Project.action";
import { IUserNotificationRequest } from "../../../Models/UserNotification/IUserNotificationRequest";
import { IUserNotificationResponse } from "../../../Models/UserNotification/IUserNotificationResponse";
import { useHistory } from "react-router-dom";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { formatDateStringMMMdd } from "../../../Helpers/ProjectHelper";
import { ProjectContext } from '../BaseContainer'
import { IProjectContext } from "../../../Models/IProjectContext";
const NotificationPanel = () => {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        loadingUserNotifications,
        loadingUserNotificationsError,
        userNotifications,
        userNotificationError,
        projectContext,
        loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const { dismissPanel } = React.useContext(ProjectContext);

    const openProject = (e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string, notificationId?: string): void => {
        const userNotification: IUserNotificationResponse = {
            isRead: true,
            notificationId: notificationId
        }        
        dispatch(isNotificationRead(userNotification));
        const userNotificationRequest: IUserNotificationRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            currentPage: 1,
            recordsPerPage: 5,
            sortColumn: 'NotificationDate',
            sortOrder: 'desc'
        }
        dispatch(requestUserNotification(userNotificationRequest));
        dismissPanel();

        if(history.location.pathname==="/viewproject"){
            if (projectNumber?.length > 0) {
                const prjInContext: IProjectContext = {
                    number: projectNumber
                }
                dispatch(setProjectContext(prjInContext));
                dispatch(requestProjectDetails(prjInContext, loginUserpersonaProps?.secondaryText));
            }
        }
        else{
            history.push(`/viewproject?componentName=view&projectNumber=${projectNumber}`);
        }
    }



    return (
        <>
            <Stack style={container}>
                {loadingUserNotifications && (
                    <Stack style={{ marginTop: 50 }}>
                        <span role="alert" aria-label={GenericMessages.userNotificationsLoading} aria-live="assertive" />

                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.userNotificationsLoading}
                            spinnerText={GenericMessages.userNotificationsLoading}
                            spinnerPosition={"right"}
                        />

                    </Stack>
                )}
                {!loadingUserNotifications && loadingUserNotificationsError && (
                    <>
                        <ErrorDisplay source={userNotificationError?.errorMessage} customText=" to see notifications" />
                    </>
                )}
                {!loadingUserNotifications && !loadingUserNotificationsError && userNotifications?.totalRecords === 0 && (
                    <>
                        <Stack>
                            <div style={notificationTitleMsg}>
                                No notifications
                            </div>
                        </Stack>
                    </>
                )}
                {!loadingUserNotifications && !loadingUserNotificationsError && userNotifications?.totalRecords > 0 && (
                    <>
                        <Stack>
                            {userNotifications?.userNotificationResponseList?.map((userNotificationResponse: IUserNotificationResponse, index: number) => {

                                return (<>
                                    <Stack>
                                        <Stack onClick={(e) => openProject(e, userNotificationResponse.projectNumber, userNotificationResponse?.notificationId)}>
                                            <span style={!userNotificationResponse?.isRead ? notificationTextBold : notificationText}>
                                                {/* <span style={index % 4 === 0 ? notificationTextBold : notificationText}> */}
                                                <Icon iconName="FolderHorizontal" style={{ marginRight: 5 }} />
                                                <TooltipHost
                                                    content={userNotificationResponse?.notificationText}
                                                    aria-label={userNotificationResponse?.notificationText}
                                                    tabIndex={0}
                                                >
                                                    {userNotificationResponse?.notificationText}
                                                </TooltipHost>
                                            </span>
                                        </Stack>
                                        <Stack style={{ marginBottom: 10 }}>
                                            <span style={!userNotificationResponse?.isRead ? notificationTextBold : notificationText}>{formatDateStringMMMdd(userNotificationResponse.notificationDate)}</span>
                                        </Stack>
                                        <Stack>
                                            <span>
                                                <hr style={{ borderTop: '1px solid #eee', width: '100%', margin: '0 auto' }} />
                                            </span>
                                        </Stack>
                                    </Stack>
                                </>)
                            })}
                        </Stack>
                    </>
                )}
            </Stack>
        </>
    )
}

export default NotificationPanel;