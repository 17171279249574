import { IStackStyles } from "@fluentui/react";
export const WelcomeContainerStyles: IStackStyles = {
  root: {
    display: "flex",    
    padding: "12px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "8px",
    background: "var(--neutral-background-1-rest, #FFF)",
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.14)",
    marginLeft:5,
    marginTop: 2,
    width:400
  },
};


export const WelcomeChildStyles: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",    
    borderRadius: "var(--None, 0px)",
  },
};
