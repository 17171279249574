import { Icon, SearchBox, Stack } from '@fluentui/react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import * as React from 'react';
import { Reducer } from 'redux';
import DisplaySpinner from '../../../Common/Components/DisplaySpinner';
import ErrorDisplay from '../../../Common/Components/ErrorDisplay';
import { GenericMessages } from '../../../Helpers/ProjectConstants';
import { IProjectFiles, IProjectFile } from '../../../Models/IProjectFile';
import {
    requestUploadFilesToProject,
    updateProjectStateForFileUpload
} from '../../../Shared/Actions/Project.action';
import { IProjectAppState } from '../../../Shared/ProjectState';
import {
    ProjectReducerName,
    projectReducer,
    projectInitialState
} from '../../../Shared/Reducers/Project.reducer';
import { projectSagas } from '../../../Shared/Sagas/Project.sagas';
import FileView from './FileView';
import {
    addAttachment,
    addFileControl,
    attachmentContainer,
    paddingTop4,
    sectionheaderStyle,
    SectionStyle,
    uploadBtnStyle,
    userinput,
    userInputBoxStyle,
    viewAttachment
} from './Styles/Attachments';

const Attachements = () => {
    const reduxContext = React.useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [
        projectSagas
    ]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectFiles,
        projectProps,
        projectFileTags,
        projectContext,
        isProjectFileDeleting,
        isProjectFileDeletingError,
        projectDeleteFileError,
        newFiles,
        isFileUploadingToProject,
        isFileUploadingToProjectError,
        FileUploadingToProjectSuccess,
        FileUploadingToProjectError
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const [fileList, setFileList] = React.useState([]);
    const [originalfileList, setOriginalFileList] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [folderPath, setFolderPath] = React.useState('');

    //Filter Attachment Search Bar OnChange event
    const onChange = (value: string) => {
        setInputValue(value);
        var updatedList = originalfileList;
        updatedList = updatedList.filter(function (item) {
            return (
                item.file.name.toLowerCase().search(value.toLowerCase()) !== -1
            );
        });
        setFileList(updatedList);
    };

    React.useEffect(() => {
        if (projectFiles && projectFiles.projectFile) {
            setFileList(projectFiles.projectFile);
            setOriginalFileList(projectFiles.projectFile);
            setFolderPath(projectFiles?.folderPath);
        }
    }, [projectFiles]);

    // Updates the file project state if the file is successfully deleted from SPO
    const removeFileFromState = (file: any) => {
        const localprojectFiles: IProjectFiles = {
            projectFile: []
        };
        projectFiles?.projectFile.map((fileList: IProjectFile) => {
            if (
                fileList.file.name.toLowerCase() !==
                file.file.name.toLowerCase()
            ) {
                const projectFile: IProjectFile = {};
                projectFile.file = fileList.file;
                projectFile.isNew = fileList.isNew;
                localprojectFiles.projectFile.push(projectFile);
            }
        });
        dispatch(updateProjectStateForFileUpload(localprojectFiles));
    };

    const addNewFiles = (newFiles: any) => {
        const localprojectFiles: IProjectFiles = {
            projectFile: []
        };
        for (let file of newFiles) {
            const projectFile: IProjectFile = {};
            projectFile.file = file;
            projectFile.isNew = true;
            localprojectFiles.projectFile.push(projectFile);
        }
        dispatch(
            requestUploadFilesToProject(
                projectContext,
                projectProps,
                localprojectFiles
            )
        );
    };

    function handleFileEventChange(
        event: React.ChangeEvent<HTMLInputElement>
    ): void {
        addNewFiles(event.target.files);
    }

    React.useEffect(() => {
        if (!isFileUploadingToProjectError && FileUploadingToProjectSuccess) {
            console.log(newFiles);
            const localprojectFiles: IProjectFiles = {
                projectFile: [],
                folderPath: folderPath
            };
            projectFiles?.projectFile?.map((file: any) => {
                const projectFile: IProjectFile = {};
                projectFile.file = file.file;
                projectFile.isNew = false;
                localprojectFiles.projectFile.push(projectFile);
            });
            newFiles?.projectFile?.map((file: any) => {
                const projectFile: IProjectFile = {};
                projectFile.file = file.file;
                projectFile.isNew = true;
                localprojectFiles.projectFile.push(projectFile);
            });
            console.log('NewFileObject', localprojectFiles);
            dispatch(updateProjectStateForFileUpload(localprojectFiles));
        }
    }, [isFileUploadingToProjectError, FileUploadingToProjectSuccess]);

    return (
        <>
            {isProjectFileDeleting && (
                <>
                    <span
                        role="alert"
                        aria-label={GenericMessages.deletingFile}
                        aria-live="assertive"
                    />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.deletingFile}
                        spinnerText={GenericMessages.deletingFile}
                        spinnerPosition={'right'}
                    />
                </>
            )}
            {isFileUploadingToProject && (
                <>
                    <span
                        role="alert"
                        aria-label={GenericMessages.uploadingFileToProject}
                        aria-live="assertive"
                    />
                    <DisplaySpinner
                        accessabilityMessage={
                            GenericMessages.uploadingFileToProject
                        }
                        spinnerText={GenericMessages.uploadingFileToProject}
                        spinnerPosition={'right'}
                    />
                </>
            )}
            {!isProjectFileDeleting && isProjectFileDeletingError && (
                <>
                    <ErrorDisplay
                        source={projectDeleteFileError?.errorMessage}
                    />
                </>
            )}
            {!isFileUploadingToProject && isFileUploadingToProjectError && (
                <>
                    <ErrorDisplay
                        source={FileUploadingToProjectError?.errorMessage}
                    />
                </>
            )}
            {!isProjectFileDeleting && !isFileUploadingToProject && (
                <>
                    <div style={attachmentContainer}>
                        <div style={sectionheaderStyle}>Files</div>

                        <div style={userinput}>
                            <SearchBox
                                ariaLabel="Press enter key after entering text in the search box"
                                placeholder="Find an attachment"
                                onSearch={(searchValue) =>
                                    onChange(searchValue)
                                }
                                style={userInputBoxStyle}
                                onChange={(e, n) => onChange(n)}
                            />
                        </div>
                    </div>
                    <Stack style={SectionStyle}>
                        {fileList && fileList.length > 0 ? (
                            fileList.map((file: any, index: number) => (
                                <FileView
                                    key={index} // It's better to provide a unique key for each mapped element
                                    index={index}
                                    file={file}
                                    removeFileFromState={removeFileFromState}
                                    showDate={true}
                                />
                            ))
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%'
                                }}
                            >
                                <p>No attachments available.</p>
                            </div>
                        )}
                    </Stack>
                    {folderPath && (
                        <Stack>
                            <div style={viewAttachment}>
                                <a
                                    href={folderPath}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    View all documents
                                </a>
                            </div>
                        </Stack>
                    )}
                    <Stack>
                        <div style={addAttachment}>
                            <label
                                htmlFor="attachment"
                                style={{ cursor: 'pointer' }}
                            >
                                <button
                                    style={uploadBtnStyle}
                                    onClick={() =>
                                        document
                                            .getElementById('attachment')
                                            .click()
                                    }
                                >
                                    Upload files
                                </button>
                            </label>
                            <input
                                style={addFileControl}
                                type="file"
                                name="file[]"
                                id="attachment"
                                onChange={handleFileEventChange}
                                multiple
                            />
                        </div>
                    </Stack>
                </>
            )}
        </>
    );
};

export default Attachements;
