import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { ProjectReducerName, projectReducer } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { ProjectAPI } from "../../Services/ProjectAPI";
import { AxiosError } from "axios";
import useGraphToken from "./useGraphToken";
import { IPersonaProps } from "@fluentui/react";
export interface IProjectMemberProps {
  userAlias: string;
  graphTokenParam?: string;
}

const profileCache = {};
const useGetProjectMemberProfile = (projectmemberProps: IProjectMemberProps) => {
  const { userAlias, graphTokenParam } = projectmemberProps;
  const [isProjectMemberProfileLoading, setIsProjectMemberProfileLoading] = React.useState(false);
  const [projectMemberProfile, setProjectMemberProfile] = React.useState<any>();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { httpClient } = reduxContext;
  const graphToken = (graphTokenParam && graphTokenParam?.length > 0) ? graphTokenParam : useGraphToken().graphToken;
  const getProjectMemberProfile = async (): Promise<IPersonaProps> => {
    if (profileCache[userAlias]) {
      return profileCache[userAlias];
    }
    try {
      var usersFromGraph = await ProjectAPI.getFilteredUsers(userAlias, httpClient, 1, graphToken);
      const filteredPeoplesFromGraph = async () => {
        return Promise.all(
          usersFromGraph.map(async (userFromGraph: any) => {
            let userProfileImage: string = "";
            try {
              userProfileImage = URL.createObjectURL(
                await ProjectAPI.getUserPhoto(userFromGraph.mail, httpClient, graphToken)
              );
            } catch {
              userProfileImage = "";
            }
            let personaObjet: IPersonaProps = {
              text: userFromGraph?.displayName ?? userAlias,
              imageUrl: userProfileImage,
              secondaryText: userFromGraph?.userPrincipalName ?? userAlias,
              showSecondaryText: true,
              primaryText: userFromGraph?.jobTitle ?? userAlias,
              hidePersonaDetails: false,
              optionalText: userFromGraph?.onPremisesImmutableId ?? userAlias,
            };
            return personaObjet;
          })
        );
      };
      const filteredUsers = await filteredPeoplesFromGraph();
      const profileToCache = filteredUsers[0];

      profileCache[userAlias] = profileToCache;

      return profileToCache;
    } catch (error: any | AxiosError) {
      const fallbackPersonaObject: IPersonaProps = {
        text: userAlias,
        imageUrl: "",
        secondaryText: userAlias,
        showSecondaryText: true,
        primaryText: userAlias,
        hidePersonaDetails: false,
        optionalText: userAlias,
      };
      profileCache[userAlias] = fallbackPersonaObject;
      return fallbackPersonaObject;
    }
  };

  React.useEffect(() => {
    if (userAlias && graphToken) {
      setIsProjectMemberProfileLoading(true);
      const fetchProjectDetailsById = async () => {
        try {
          const projectMemberProfile = await getProjectMemberProfile();
          setIsProjectMemberProfileLoading(false);
          setProjectMemberProfile(projectMemberProfile);
        } catch (error: any) {
          const fallbackErrorPersonaObject: IPersonaProps = {
            text: userAlias,
            imageUrl: "",
            secondaryText: userAlias,
            showSecondaryText: true,
            primaryText: userAlias,
            hidePersonaDetails: false,
            optionalText: userAlias,
          };
          setProjectMemberProfile(fallbackErrorPersonaObject);
        }
      };
      fetchProjectDetailsById();
    }
  }, [userAlias, graphToken]);

  return { projectMemberProfile, isProjectMemberProfileLoading };
};
export default useGetProjectMemberProfile;
