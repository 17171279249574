import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { Reducer } from "redux";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { projectInitialState, projectReducer, ProjectReducerName } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import { closenumber, closeNumberStyle, container, dropdownStyle, dropdownStyles, numberStyle, opennumber, paddingTop, projectTitle, seeProjects, seeProjectsText, statustext, statustext2, titleStyle } from "./Styles/MyProject";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { requestProjectSearch, setProjectListContext } from "../../../Shared/Actions/Project.action";
import { getPastDate } from "../../../Helpers/ProjectHelper";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { useHistory } from "react-router-dom";
import { IProjectSearchRequest } from "../../../Models/IProjectSearchRequest";
import { pages } from "@microsoft/teams-js"; 
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
const MyProject = () => {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectSearchResponse,
        isSearchResultsLoading,
        isSearchResultsLoadingError,
        projectSearchError,
        loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    //State for Open and Closed
    const [openCount, setOpenCount] = React.useState(0);
    const [closedCount, setClosedCount] = React.useState(0);

    //State for Start and End Date
    const [startDate, setStartDate] = React.useState("");

    //DropDown Options
    const dropDownOptions: IDropdownOption[] = [
        { key: 30, text: 'Past 30 days', title: 'My projects in past 30 days' },
        { key: 60, text: 'Past 60 days', title: 'My projects in past 60 days' },
        { key: 90, text: 'Past 90 days', title: 'My projects in past 90 days' },
    ];

    const [selectedDropDownItem, setSelectedDropDownItem] = React.useState<IDropdownOption>();

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedDropDownItem(item);
        setStartDate(getPastDate(item.key));
        dispatch(requestProjectSearch(loginUserpersonaProps?.optionalText, getPastDate(item.key)));
    };

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            setSelectedDropDownItem(dropDownOptions[0]);
            setStartDate(getPastDate(30));
            dispatch(requestProjectSearch(loginUserpersonaProps?.optionalText, getPastDate(30)));
        }
    }, [loginUserpersonaProps]);

    React.useEffect(() => {
        setOpenCount(projectSearchResponse.countOpen);
        setClosedCount(projectSearchResponse.countClosed);
    }, [projectSearchResponse]);

    const openProjectList = (statusType: string): void => {
        const projectSearchRequest: IProjectSearchRequest = { status: statusType, startDate: startDate, endDate: "", owner: "", showStatusCountList: true };
        const tabNavigation:ITabNavigation= {
            isNewProjectCreation: false, 
            isFromDashboard: true, 
            projectSearchRequest: projectSearchRequest, 
            componentName:'/projectslist'}
        if(pages.currentApp.isSupported) {
            const navPromise = pages.navigateToApp({appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectslist', subPageId:JSON.stringify(tabNavigation)});
            navPromise.then(()=>{
                console.log('Navigated to project list page')
            }).catch((error)=>{
                console.log('Error occurred when navigating to project list page. So defaulting to history api')
                dispatch(setProjectListContext(projectSearchRequest));
                history.push("/projectslist");
            })
        }else{
            dispatch(setProjectListContext(projectSearchRequest));
            history.push("/projectslist");
        }
        
    }

    const createProject = ()=> {
        const projectSearchRequest: IProjectSearchRequest = {};

        const tabNavigation: ITabNavigation = { 
            isNewProjectCreation: true, 
            isFromDashboard: true, 
            componentName:'/createproject', 
            projectSearchRequest: projectSearchRequest };
        
        if(pages.currentApp.isSupported) {
            const navPromise = pages.navigateToApp({appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectslist', subPageId:JSON.stringify(tabNavigation)});
            navPromise.then(()=>{
                console.log('Navigated to project list page')
            }).catch((error)=>{
                
                history.push("/createproject");
            })
        }else{            
            history.push("/createproject");
        }
    }

    return (
        <Stack style={container}>
           
            {isSearchResultsLoading && (
                <Stack style={{marginTop: 30}}>
                    <span role="alert" aria-label={GenericMessages.myProjectsLoading} aria-live="assertive" />

                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.myProjectsLoading}
                        spinnerText={GenericMessages.myProjectsLoading}
                        spinnerPosition={"right"}
                    />

                </Stack>
            )}
            {!isSearchResultsLoading && isSearchResultsLoadingError && (
                <>
                    <ErrorDisplay source={projectSearchError?.errorMessage} />

                </>
            )}
            {!isSearchResultsLoadingError && !isSearchResultsLoading && (
                <>
                    <Stack horizontal style={titleStyle}>
                        <Stack>
                            <div style={projectTitle}>
                                Projects
                            </div>
                        </Stack>
                        <Stack style={dropdownStyle}>
                            <Dropdown
                                selectedKey={selectedDropDownItem ? selectedDropDownItem.key : undefined}
                                onChange={onChange}
                                options={dropDownOptions}
                                styles={dropdownStyles}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal style={numberStyle} >
                        <Stack>
                            <Stack onClick={(e) => openProjectList("Open")} style={opennumber} >{openCount}</Stack>
                        </Stack>
                        <Stack style={paddingTop}>
                            <Stack onClick={(e) => openProjectList("Open")} style={statustext} >Open</Stack>
                        </Stack>
                        <Stack style={closeNumberStyle}>
                            <Stack onClick={(e) => openProjectList("Closed")} style={closenumber} >{closedCount}</Stack>
                        </Stack>
                        <Stack style={paddingTop}>
                            <Stack onClick={(e) => openProjectList("Closed")} style={statustext} >Closed</Stack>
                        </Stack>
                    </Stack>

                    <Stack style={seeProjects}>
                        <div style={seeProjectsText}>
                            {/* <Link to="/createproject">Create a project</Link> */}
                            <Stack onClick={createProject} style={statustext2} >Create a project</Stack>
                        </div>
                    </Stack>
                </>
            )}
        </Stack>
    )
}

export default MyProject;