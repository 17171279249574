import * as React from "react";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from "@fluentui/react/lib/Pickers";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";

import { Reducer } from "redux";

import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { projectReducer, ProjectReducerName } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { ProjectAPI } from "../../Services/ProjectAPI";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { PaginationType } from "../../Helpers/ProjectConstants";
import { FC } from "react";
import useGraphToken from "../Hooks/useGraphToken";
import { container, memberPickerStyle } from "./Styles/PeoplePicker";
import { ProjectTeamContext } from "../V2/Components/TeamMembers";
import { Icon, TooltipHost } from "@fluentui/react";

interface loginUserProps {
    peopleType: string,
}
const MemberPicker: FC<loginUserProps> = ({ peopleType }) => {
    const { projectMember, setProjectMember, deletedProjectMembers } = React.useContext(ProjectTeamContext);
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { dispatch, httpClient } = reduxContext;
    const [selectedTeamMembers, setSelectedTeamMembers] = React.useState<IPersonaProps[]>([]);
    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: "Suggested People",
        mostRecentlyUsedHeaderText: "Suggested Contacts",
        noResultsFoundText: "No results found",
        loadingText: "Loading",
        showRemoveButtons: true,
        suggestionsAvailableAlertText: "People Picker Suggestions available",
        suggestionsContainerAriaLabel: "Suggested contacts",
    };
    const picker = React.useRef(null);

    React.useEffect(() => {
        if (projectMember && projectMember.membersPicked) {
            setSelectedTeamMembers(projectMember?.membersPicked);
        }
    }, [projectMember])

    React.useEffect(() => {
        setSelectedTeamMembers([]);
    }, [])

    const { isGraphTokenLoading, graphToken } = useGraphToken();

    // Resets the team member state variable
    const resetMembers = () => {
        const emptyProjectMembers: IProjectMembers = {
            isResponsible: false
        };
        setProjectMember(emptyProjectMembers);
    }

    //This function will get people profile from the Graph for the filteredText
    const onFilterChanged = async (usersToFilter: string, currentPersonas: IPersonaProps[]): Promise<IPersonaProps[]> => {
        const limitUsersFromGraph: number = +PaginationType.limitUsersFromGraph;
        if (usersToFilter) {
          try {
            var usersFromGraph = await ProjectAPI.getFilteredUsers(usersToFilter, httpClient, limitUsersFromGraph, graphToken);
            const filteredPeoplesFromGraph = async () => {
              return Promise.all(
                usersFromGraph.map(async (userFromGraph: any) => {
                  let userProfileImage: string = "";
                  try {
                    userProfileImage = URL.createObjectURL(await ProjectAPI.getUserPhoto(userFromGraph.mail, httpClient, graphToken));
                  } catch {
                    userProfileImage = "";
                  }
                  let personaObjet: IPersonaProps = {
                    text: userFromGraph?.displayName,
                    imageUrl: userProfileImage,
                    secondaryText: userFromGraph?.onPremisesImmutableId ? userFromGraph.userPrincipalName : userFromGraph?.mail,
                    showSecondaryText: true,
                    primaryText: userFromGraph?.jobTitle ?? userFromGraph?.displayName,
                    hidePersonaDetails: false,
                    tertiaryText: "true",
                    optionalText: userFromGraph?.onPremisesImmutableId ? userFromGraph?.onPremisesImmutableId : userFromGraph?.id,
                    onRenderPrimaryText: (props) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{userFromGraph?.displayName}</span>
                        {userFromGraph?.externalUserState === "PendingAcceptance" && (
                          <TooltipHost
                            content="This user is part of the Microsoft tenant but has not yet accepted the invitation"
                            id="tooltipHost"
                            calloutProps={{ gapSpace: 0 }}
                            closeDelay={300}
                          >
                            <Icon iconName="AwayStatus" style={{ marginLeft: 5, color: 'orange' }} aria-describedby="tooltipHost" />
                          </TooltipHost>
                        )}
                      </div>
                    ),
                  };
                  return personaObjet;
                })
              );
            };
    
            const filteredUsers = await filteredPeoplesFromGraph();
            if (Object.keys(filteredUsers)?.length === 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(usersToFilter)) {
              const username = usersToFilter.split('@')[0]; // Extract text before '@'
              let personaObjet: IPersonaProps = {
                text: username,
                secondaryText: usersToFilter,
                showSecondaryText: true,
                primaryText: username,
                hidePersonaDetails: false,
                tertiaryText: "true",
                optionalText: usersToFilter,
                onRenderPrimaryText: (props) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{username}</span>
                    <TooltipHost
                      content="This user is not currently part of the Microsoft tenant. An invitation link will be sent to them as part of the project notifications"
                      id="tooltipHost"
                      calloutProps={{ gapSpace: 0 }}
                      closeDelay={300}
                    >
                      <Icon iconName="ErrorBadge" style={{ marginLeft: 5, color: 'red' }} aria-describedby="tooltipHost" />
                    </TooltipHost>
                  </div>
                ),
              };
    
              filteredUsers.push(personaObjet);
            }
            return filteredUsers;
          } catch (error: any) {
            return [];
          }
        } else {
          return [];
        }
      };

    function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
        return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
    }

    function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter((item) => item.secondaryText === persona.secondaryText).length > 0;
    }

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    function onChangeSelection(projectMembersSelected: IPersonaProps[]): void {
        if (projectMembersSelected.length > 0) {
            projectMembersSelected = removeDuplicates(projectMembersSelected, projectMember)
            setSelectedTeamMembers(projectMembersSelected);
            const projectMembers: IProjectMembers = { membersPicked: projectMembersSelected, isResponsible: false };
            setProjectMember(projectMembers)
        }
        else {
            setSelectedTeamMembers([]);
            const projectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };
            setProjectMember(projectMembers)
        }
    }

    return (
        <div style={container}>
            <div style={memberPickerStyle}>
                <NormalPeoplePicker
                    onResolveSuggestions={onFilterChanged}
                    onChange={onChangeSelection}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={suggestionProps}
                    className={"ms-PeoplePicker"}
                    key={"normal"}
                    selectedItems={selectedTeamMembers}
                    removeButtonAriaLabel={"Remove"}
                    inputProps={{
                        "aria-label": "Enter the user's alias",
                        "aria-required": true,
                        required: true,
                        placeholder: peopleType === "member" ? "Enter user alias/name" : "Enter Owner's alias/name",
                    }}
                    componentRef={picker}
                    resolveDelay={1500}
                    styles={{ root: { maxWidth: 500, minHeight: "32px" } }}
                    itemLimit={10}
                />
            </div>
            {/* <div style={reset} onClick={resetMembers}><div>Reset </div></div> */}
        </div>
    );
};

export default MemberPicker;
