import React from 'react'
import { Checkbox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IBasePickerSuggestionsProps, Icon, IPersonaProps, ITag, MessageBarType, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import Button from "../Helpers/Button/Button";
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import { useHistory } from "react-router-dom";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { assingPadding, colorred, formText, textFieldStyles } from '../../../Styles/CreateProject';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { getIconByFileExtension } from '../../../../../../src/Helpers/ProjectHelper';
import { fileiconStyleCP } from '../../../../../../src/Components/V2/Components/Styles/Attachments';
import DisplayMessage from '../../../../../../src/Common/Components/DisplayMessage';
import DisplaySpinner from '../../../../../../src/Common/Components/DisplaySpinner';
import { spinnerCss } from '../../../../../../src/Components/V2/Styles/ProjectList';

export default function Review() {
  let history = useHistory();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);

  const { useSelector, dispatch } = reduxContext;
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const {
    projectCreate,
    isProjectGettingCreated,
    projectCreationSuccess,
    projectCreateError,
    projectCreationFailure,
    projectCreateRedirect,
    projectContext,
    projectFiles,
    projectFileTags,
    projectProps,
    projectOwner,
    projectMembers,
    deletedProjectMembers,
    projectTags,
    isProjectGettingEdited,
    projectEditSuccess,
    projectEditRedirect,
    projectEditFailure,
    projectEditError,
    loginUserpersonaProps,
    isGettingProjectWorkArea,
    isProjectWorkAreaSuccess,
    isProjectWorkAreaError,
    projectWorkAreaCollection,
    lawFirmAdaptiveDetails,
    isLoading,
    hasError

  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  const { updateNavItem } = useCreateNavContext();
  const [loader, setLoader] = React.useState(false);

  const handleContinueClick = () => {
    setLoader(true); // Set loader to true before waiting for 10 seconds

    setTimeout(() => {
      setLoader(false); // Set loader to false after 10 seconds
      setShowSuccessMessage(true); // Then set showSuccessMessage to true
    }, 5000); // 10000 milliseconds = 10 seconds
  }
  const handleCancelClick = () => {
    //clear the state
    history.push('/')
  }
  return (
    <>
      {showSuccessMessage && (
        <>
          <DisplayMessage
            accessabilityMessage={"Litigation matter intake demo record has been created."}
            messageType={MessageBarType.success}
            message={"Litigation matter intake demo record has been created."}
          />
        </>
      )}

      <div>

        {loader && (
          <>
            
              <span role="alert" aria-label={"Creating litigation matter"} aria-live="assertive" />
              <div style={{...spinnerCss,left:"67%"}}>
                <DisplaySpinner
                  accessabilityMessage={"Creating litigation matter"}
                  spinnerText={"Creating litigation matter"}
                  spinnerPosition={"right"}

                />
              </div>
            
          </>
        )}
      </div>


      <div style={{ marginLeft: 20 }} className="pageContainer">

        <div className="contentContainer">

          {!loader && (
            <>
              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h2 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#242424' }}>
                    Review
                  </h2>
                </div>
              </Stack>

              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Title
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    Litigation matter intake demo
                  </p>
                </div>

              </Stack>

              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Description
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    This record has been created to demo litigation matter intake creation
                  </p>
                </div>

              </Stack>

              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Owner
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    Mahendra Arvapalli, Sankar Tippala
                  </p>
                </div>

              </Stack>



              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Files
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    <Icon
                      {...getFileTypeIconProps({ extension: getIconByFileExtension('Litigation.docx'), size: 16, imageFileType: 'png' })}
                      tabIndex={0}
                      style={{ ...fileiconStyleCP, marginRight: 10 }}
                    />Litigation.docx
                  </p>
                </div>

              </Stack>



              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Responisble Professional
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    Mahendra Arvapalli, Sankar Tippala
                  </p>
                </div>

              </Stack>

              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Firm Selection
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    10-Square Solutions, 110 Consulting
                  </p>
                </div>

              </Stack>

              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Outside counsel Additional info.
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    10-Square Solutions budget - $10,000
                  </p>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    10-Square Solutions safe approver - Mahendra Arvapalli
                  </p>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    110 Consulting budget - $5,000
                  </p>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    110-Consulting safe approver - Sankar Tippala
                  </p>
                </div>

              </Stack>

              <Stack tokens={{ childrenGap: 10 }}>
                <div>
                  <h3 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#616161', marginTop: 15 }}>
                    Finance Information
                  </h3>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    Budget - $15,000
                  </p>
                  <p style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 400, color: '#616161', marginTop: 15, marginLeft: 10 }}>
                    IO Number - 101010
                  </p>
                </div>

              </Stack>
            </>
          )}

        </div>
        <div className="buttonContainer">
          <Stack horizontal tokens={{ childrenGap: 10 }} className='buttonLayout'>
            <Button type='primary' onClick={handleContinueClick}>
              Submit
            </Button>
            <Button type='cancel' onClick={handleCancelClick}>
              Cancel
            </Button>
          </Stack>
        </div>
      </div>
    </>
  )
}
