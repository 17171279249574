import React from 'react'
import './ChatDisplay.styles.css'
import { Stack } from '@fluentui/react'
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';

import ChatConversations from './ChatConversations';
const ChatDisplay = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { topic, newUserPrompt, topicIdInContext } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);


  return (
    <Stack>
      <Stack>
        <>
          <ChatConversations />          
        </></Stack>
    </Stack>
  )
}
export default ChatDisplay