import { IDetailsListStyles, IDropdownStyles, ITextFieldStyles } from "@fluentui/react"
import * as React from "react"

export const container: React.CSSProperties =
{
  flex: "1 1 auto",
  display:"flex",
 minHeight: "100vh",
 height:"auto",
 flexDirection:"column",
 marginLeft:12,
 overflowX:'auto',
 marginTop:10
}

export const linkCreate: React.CSSProperties =
{
  marginLeft: "10px",
  width: "162px",
  height: 32,
  color: "black",
  fontSize: "14px",
  marginTop: "10px",
  marginBottom: "10px",
  float: "left",
  fontFamily: "Segoe UI"
}
export const linkStyle: React.CSSProperties =
{
  color: "#242424",
  fontWeight: 600,
}
export const linkStyle2: React.CSSProperties =
{
  color: "#242424",
  marginLeft: "10px",
  width: "62px",
  height: 32,
  marginTop: "10px",
  marginBottom: "10px",
  float: "left",
  fontWeight: 600,
  fontSize: "14px",
  fontFamily: "Segoe UI",
  cursor:"pointer"
}
export const linkEdit: React.CSSProperties =
{
  marginLeft: "10px",
  width: "62px",
  height: 32,
  marginTop: "10px",
  marginBottom: "10px",
  float: "left",
  fontWeight: 600,
  color: "#C7C7C7",
  fontSize: "14px",
  fontFamily: "Segoe UI"
}
export const linkDelete: React.CSSProperties =
{
  marginLeft: "10px",
  width: "84px",
  height: 32,
  marginTop: "10px",
  marginBottom: "10px",
  float: "left",
  fontWeight: 600,
  color: "#C7C7C7",
  fontSize: "14px",
  fontFamily: "Segoe UI"
}

export const linkFilter: React.CSSProperties =
{
  marginLeft: "10px",
  width: "74px",
  marginTop: "10px",
  marginBottom: "10px",
  // float:"right",
  fontWeight: 600,
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  cursor:"pointer"
}

export const textFilter: React.CSSProperties =
{
  marginLeft: "10px",
  width: "222px",
  height: 32,
  color: "black",
  fontSize: "14px",
  marginTop: "5px",
  marginBottom: "10px",
  //float:"right",
  marginRight: 10
}
export const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { border:"none" }
};
export const iconCSS: React.CSSProperties =
{
  height: "20px",
  width: "20px",
  marginTop: 3,
  cursor:"pointer"
}

export const statusIcon: React.CSSProperties =
{
  height: "20px",
  width: "20px",
  marginTop: 3,
  color: "red"
}

export const statusIcon2: React.CSSProperties =
{
  height: "20px",
  width: "20px",
  marginTop: 3,
  marginLeft: -20,
  color: "red"
}

export const statusIconGreen: React.CSSProperties =
{
  height: "20px",
  width: "20px",
  marginTop: 3,
  color: "green"
}

export const statusIconGreen2: React.CSSProperties =
{
  height: "20px",
  width: "20px",
  marginTop: 3,
  marginLeft: -20,
  color: "green"
}

export const statusTextOpen: React.CSSProperties =
{
  color: "green"
}

export const statusTextClose: React.CSSProperties =
{
  color: "red"
}

export const wrapperIconCSS: React.CSSProperties =
{

}

export const divGridCSS: React.CSSProperties =
{
  overflow: 'auto', // Enable scrolling
    maxWidth: '100%', // Allow the div to occupy the available space
    width: '100%', // Ensure the div has a width to enable horizontal scrolling
    marginBottom: 25,
    maxHeight: '85vh',
};

export const columnCell: React.CSSProperties =
{
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  fontWeight: 400
}

export const columnCell2: React.CSSProperties =
{
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  fontWeight: 400,
  marginTop: "3px"
}
export const columnCell1: React.CSSProperties =
{
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  fontWeight: 400,
  marginTop: "-5px",
  overflow:'hidden'
  
}
export const columnClickableCell: React.CSSProperties =
{
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  fontWeight: 400,
  cursor: "pointer",
 
}

export const spinnerCss: React.CSSProperties =
{
  position: "fixed",
  top: "30%",
  left: "50%",
  /* bring your own prefixes */
  transform: "translate(-50%, -50%)",
  
 
}
export const spinnerDeleteCss: React.CSSProperties =
{
  position: "fixed",
  top: "30%",
  left: "50%",
  /* bring your own prefixes */
  transform: "translate(-50%, -50%)",
  
 
}
export const displayMessage: React.CSSProperties =
{
  display:"flex",
  justifyContent:"center",
  alignItems: "center",
  fontFamily: "Segoe UI",
  position:"relative",
  top:250,
  fontSize: "1.5rem",
  color: "#555",
  textAlign: "center"
}

export const gridCSS: Required<Pick<IDetailsListStyles, 'root'>> = {
  root: { color: "#242424 !important", fontFamily: "Segoe UI !important", fontStyle: "normal", fontWeight: "400 !important", fontSize: "14px !important", background: "#F5F5F5" }
};

export const goBack: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#242424",
  marginTop: "10px",
  marginLeft: "10px",
  float: "left",
  cursor: "pointer"
}

export const iconStyle: React.CSSProperties =
{
  // verticalAlign: "bottom", 
  height: "20px",
  width: "20px",
  marginTop: 3,
  color: "#242424 !important",
  fontSize:14
}

export const columnCellStyle = {
  flex: 1,
  overflow: 'hidden',
  padding: '8px',
  minWidth: '200px', // Adjust as needed
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  fontWeight: 400,
  background:'transparent !important'
};

export const columnCell2Style = {
  ...columnCellStyle,
  minWidth: '225px', // Adjust as needed
  marginTop: "8px"
};

export const columnClickableCellStyle = {
  ...columnCellStyle,
  cursor: 'pointer',
};