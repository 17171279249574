import { Stack, IColumn, DetailsList, DetailsListLayoutMode, SelectionMode, IDetailsListStyles, MessageBarType, } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { formatDateString } from "../../../../Helpers/ProjectHelper";
import { chatLogContainerStyles } from "./Styles/ChatLogStyles";
import { LitigationContext } from "../ChatHome";
import { ChatLogColumns } from "./ChatLogColumns";
import { IUserChatLogsResponse } from "src/Models/Chat/IUserChatLogsResponse";
import PromptTextColumn from "./PromptTextColumn";
import ChatLogsTab from "./ChatLogsTab";
import MemberView from "../../Components/MemberView";
import DisplayMessage from "../../../../Common/Components/DisplayMessage";
import PromptSelector from "./PromptSelector";
export interface IChatTopicProps {
  chatLogs?: IUserChatLogsResponse[]
  onAllPromptsSelected: (showCurrentProjectPrmpts: boolean) => void;
  selectedKey?: string;
}
const ChatLog = ({ chatLogs = [], onAllPromptsSelected, selectedKey }: IChatTopicProps) => {
  const { user } = React.useContext(LitigationContext);
  const [isMyPrompts, setIsMyPrompts] = useState<boolean>(true);

  const filteredHistory = useMemo(() => {
    if (isMyPrompts) {
      return chatLogs.filter(log => log.topicUser && log.topicUser?.toLowerCase() === user?.secondaryText?.toLowerCase());
    }
    return chatLogs.filter(log => log.topicUser && log.topicUser?.toLowerCase() !== user?.secondaryText?.toLowerCase());
  }, [chatLogs, isMyPrompts, user]);


  const renderHeader = (headerText: string) => (
    <Stack horizontal>
      <span style={{ marginTop: 12 }}>{headerText}</span>
    </Stack>
  );

  const filteredColumns = useMemo(() => {

    const baseColumns = ChatLogColumns.map((chaLogColumn: IColumn) => {
      if (chaLogColumn.key === 'promptText') {
        return {
          ...chaLogColumn,
          onRenderHeader: () => renderHeader('Prompts'),
          onRender: (item: IUserChatLogsResponse, index: number) => {
            return (
              <div role="gridcell" tabIndex={0} aria-label={`Prompt Text for row ${index}`}>
                <PromptTextColumn
                  item={item}
                  isMyPrompts={isMyPrompts}
                />
              </div>
            );
          },
        } as IColumn;
      }
      if (chaLogColumn.key === 'createdBy') {
        return {
          ...chaLogColumn,
          onRenderHeader: () => renderHeader('Created by'),
          onRender: (item: IUserChatLogsResponse) => {
            return (
              <Stack style={{ width: '100%' }}>
                <MemberView key={item?.topicUser} userAlias={item?.topicUser} shouldDisplayPicture={true} />
              </Stack>
            )
          }
        } as IColumn;
      }
      if (chaLogColumn.key === 'createdDate') {
        return {
          ...chaLogColumn,
          onRenderHeader: () => renderHeader('Date'),
          onRender: (item: IUserChatLogsResponse) => {
            return (
              <div style={{ whiteSpace: 'normal', width: 650, wordWrap: 'break-word', overflow: 'visible' }}>
                {formatDateString(item?.requestDate)}
              </div>
            )
          }
        } as IColumn;
      }
      return chaLogColumn
    });
    if (isMyPrompts) {
      return baseColumns.filter(column => column.key !== "createdBy").map(column =>
        column.key === 'promptText' ? { ...column, minWidth: 200, maxWidth: 200 } : column
      );
    }
    return baseColumns.map(column =>
      column.key === 'promptText' ? { ...column, minWidth: 75, maxWidth: 75 } : column
    );
  }, [isMyPrompts]);
  const [gridHeight, setGridHeight] = React.useState('100vh'); // Default height
  const updateGridHeight = () => {

    const gridMaxHeight = `calc(100vh - 260px)`;
    setGridHeight(gridMaxHeight);
  };

  React.useEffect(() => {
    //updateGridHeight(); // Set initial height
    window.addEventListener('resize', updateGridHeight); // Add resize event listener

    return () => {
      window.removeEventListener('resize', updateGridHeight); // Cleanup on unmount
    };
  }, []);
  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflow: 'hidden',
      //height: gridHeight,
      display: 'block',
      position: 'relative',
      selectors: {
        '.ms-DetailsRow': {
          fontSize: 14,
          color: '#242424',
          width: '100%',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },

  };

  const handlePromptSelection = (isMyPrompsSelected: boolean) => {
    setIsMyPrompts(isMyPrompsSelected)
  }
  const options = useMemo(
    () => [
      { key: "AllPrompts", text: "All prompts" },
      { key: "ProjectPrompts", text: "Prompts relevant to only this project" },
    ],
    []
  );

  const handlePromptSelectorChange = (isAllPromptsSelected: boolean) => {
    onAllPromptsSelected(isAllPromptsSelected);
  };


  return (
    <>
      <PromptSelector options={options}
        selectedKey={selectedKey}
        onChange={handlePromptSelectorChange} />

      <ChatLogsTab onSelect={handlePromptSelection} />

      <Stack styles={chatLogContainerStyles}>
        {filteredHistory?.length > 0 ? (
          <DetailsList
            isSelectedOnFocus={true}
            items={filteredHistory}
            columns={filteredColumns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select"
            onRenderItemColumn={(item, index, column) => {
              return (
                <div style={{ userSelect: 'text' }}>
                  {item[column.fieldName]}
                </div>
              )
            }}
            styles={gridStyles}
          />) : (
          <DisplayMessage message="No chat logs are present"
            accessabilityMessage="No chat logs are present"
            messageType={MessageBarType.info}
          />
        )}
      </Stack>
    </>
  )
}
export default ChatLog;
