import React from "react"
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { ILitigationCase, ILitigationCaseFiles } from "../../../../../../Models/ILitigationCase";
import { IProjectAppState } from "../../../../../../Shared/ProjectState";
import { Dialog, DialogType, DialogContent, Stack, TooltipHost, DialogFooter, DefaultButton, PrimaryButton, IChoiceGroupOption, ChoiceGroup, TextField, ITextField } from "@fluentui/react";
import CustomTagPicker from "src/Components/PickerComponents/CustomTagPicker";
import { truncateText } from "src/Helpers/ProjectHelper";
export interface IClassificationSettingsProps {
    isOpen?: boolean;
    title?: string;
    onDismiss(files?: ILitigationCaseFiles[]): void;
    dialogWidth?: string;
    dialogHeight?: string;
    files?: ILitigationCaseFiles[];
    cases?: ILitigationCase[];
}

const ClassificationSettingsModal = (classificationSettingsProps: IClassificationSettingsProps) => {
    const defaultKey = 'Confidence';
    const { files, cases, isOpen, title, onDismiss, dialogWidth, dialogHeight } = classificationSettingsProps
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const [selectedKey, setSelectedKey] = React.useState<string | undefined>(defaultKey);
    const { useSelector, dispatch } = reduxContext;
    const { loginUserpersonaProps } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const textFieldLevel = React.useRef<ITextField | null>(null);
    const textFieldInterval = React.useRef<ITextField | null>(null);
    const textFieldSample = React.useRef<ITextField | null>(null);
    const options: IChoiceGroupOption[] = [
        { key: 'Confidence', text: 'Sample based on confidence' },
        { key: 'Random', text: 'Randomly sample' }
    ];

    const onChoiceChange = (event: React.FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption) => {
        setSelectedKey(option ? option.key : undefined);
    };

    const onApplyClassificationSettings = () => {
        
        switch (selectedKey) {
            case 'Confidence':
                const fieldLevelValue = textFieldLevel.current ? textFieldLevel.current.value : '';
                const intervalValue = textFieldInterval.current ? textFieldInterval.current.value : '';
                //dispatch
                break;
            case 'Random':
                const sampleValue = textFieldSample.current ? textFieldSample.current.value : '';
                //dispatch
                break;
            default:
                break;
        }
    }

    const onGetErrorMessage = (value: string): string => {
        return /^\d*$/.test(value) ? '' : 'Only numeric values are allowed';
      };
    
      const onTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!/^\d*$/.test(newValue || '')) {
          event.preventDefault();
        }
      };
    
    const renderAdditionalControls = (key: string) => {
        switch (key) {
            case 'Confidence':
                return (
                    <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 20 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <span>Confidence level</span>
                            <Stack style={{ marginLeft: 40 }} horizontal tokens={{ childrenGap: 5 }}>
                                <TextField componentRef={textFieldLevel}
                                    placeholder="Confidence level" 
                                    onGetErrorMessage={onGetErrorMessage}
                                    onChange={onTextFieldChange} /><span>%</span>
                            </Stack>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <span>Confidence interval</span>
                            <Stack style={{ marginLeft: 25 }} horizontal tokens={{ childrenGap: 5 }}>
                                <TextField componentRef={textFieldInterval} 
                                    placeholder="Confidence interval" 
                                    onGetErrorMessage={onGetErrorMessage}
                                    onChange={onTextFieldChange}
                                /> <span>%</span>
                            </Stack>
                        </Stack>
                    </Stack>
                );
            case 'Random':
                return (
                    <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 20 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <span>Sample size</span>
                            <Stack style={{ marginLeft: 25 }} horizontal tokens={{ childrenGap: 5 }}>
                                <TextField componentRef={textFieldSample} 
                                    placeholder="Sample size" 
                                    onGetErrorMessage={onGetErrorMessage}
                                    onChange={onTextFieldChange}
                                />
                                <span>%</span>
                            </Stack>
                        </Stack>

                    </Stack>
                );
            default:
                return null;
        }
    };
    const customRenderOption = (option: IChoiceGroupOption, render: (option: IChoiceGroupOption) => JSX.Element) => {
        return (
            <div key={option.key}>
                {render(option)}
                {selectedKey === option.key && renderAdditionalControls(option.key)}
            </div>
        );
    };
    return (<>
        <Dialog
            hidden={!isOpen}
            onDismiss={() => onDismiss(null)}
            dialogContentProps={{
                type: DialogType.normal,
                title: title,
                showCloseButton: true,
                styles: { content: { minHeight: 550, height: 'auto', overflowY: 'none' } }
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 'none', overflowY: 'none' } },
            }}
            styles={{
                main: {
                    '@media(min-width: 768px)': {
                        minWidth: dialogWidth || '600px',
                    },
                }
            }}
        >
            <DialogContent>
                <Stack style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
                    <Stack wrap tokens={{ childrenGap: 1 }} style={{ width: 650, height: 350, borderRadius: 3 }}>
                        <ChoiceGroup
                            label="Pick one"
                            options={options.map(option => ({
                                ...option,
                                onRenderField: (props, render) => (
                                    <div key={props.key}>
                                        {render(props)}
                                        {selectedKey === props.key && renderAdditionalControls(props.key)}
                                    </div>
                                ),
                            }))}
                            onChange={onChoiceChange}
                            selectedKey={selectedKey}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogFooter>
                <div style={{ marginTop: 100 }}>
                    <DefaultButton onClick={() => onDismiss(null)} text="Cancel" />
                    <PrimaryButton style={{ marginLeft: 10 }} onClick={onApplyClassificationSettings}>Apply
                    </PrimaryButton>
                </div>
            </DialogFooter>
        </Dialog>
    </>)
}

export default ClassificationSettingsModal