import { Icon, MessageBarType, Stack, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import { GenericMessages, ProjectEventNames } from "../../../Helpers/ProjectConstants";
import { requestProjectListDetail, requestUserHistory, setProjectFilterListDetails } from "../../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { columnCell, columnCell1, columnCell2, displayMessage, divGridCSS, spinnerCss, spinnerDeleteCss, statusIcon, statusIcon2, statusIconGreen, statusIconGreen2, statusTextClose, statusTextOpen } from "../../Projects/Styles/ProjectList";
import { IProjectListResponse } from "../../../Models/IProjectListResponse";
import DisplayMessage from "../../../Common/Components/DisplayMessage";
import { useHistory, useLocation } from "react-router-dom";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { getFormattedDate, truncateText } from "../../../Helpers/ProjectHelper";
import Banner from "../../Banner/Banner";
import { FeatureFlag } from "../../FeatureFlag/FeatureFlag";
import useGraphToken from "../../Hooks/useGraphToken";
import { app } from "@microsoft/teams-js";
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
import "../Styles/ProjectListsPageStyles.css"
import { IHistoryResponse } from "src/Models/IHistoryResponses";
import { projectNumberStyle } from "./Styles/History.Styles";
import View from "./View";
import MemberView from "../../Projects/Components/MemberView";
export const clipBoardIconStyle: React.CSSProperties = {
    left: "16px",
    mixBlendMode: "normal",
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: 5
};

const UserHistoryPage = () => {
    let _selection: Selection;
    let history = useHistory();
    let location = useLocation();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        historyResponses,
        isUserHistoryResultsLoading,
        isUserHistoryResultsLoadingError,
        historySearchRequest,
        projectListDetails,
        projectListFilterDetails,
        projectListError,
        loginUserpersonaProps,
        projectSearchRequest,
        isProjectGettingCreated,
        projectCreationSuccess,
        projectCreationResponse,
        isProjectDeleting,
        projectDeleteSuccess,
        isLoadingBannerInformation,
        featureFlagInformation,
        isLoadingFeatureFlagInformation,
        loadingLoginUserProfile,
        isloadingLoginUserProfileError,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    const [selectedRecentFilter, updateSelectedRecentFilter] = React.useState("recent-all-projects-btn");
    const [tempProjectList, settempProjectList] = React.useState<IProjectListResponse[]>();
    const [detailListColumns, setDetailListColumns] = React.useState<IColumn[]>();
    const [selectedList, setSelectedList] = React.useState("");
    const [selectedCount, setselectedCount] = React.useState(0);
    const [isOwner, setOwner] = React.useState(true);
    const [isDeleteGridLoading, setDeleteGridLoading] = React.useState(false);
    const [showAccessError, setShowAccessError] = React.useState(false);
    const [isNavigationFromDashboard, setIsNavigationFromDashboard] = React.useState(false);
    const { graphToken } = useGraphToken();
    const [tabNavigation, setTabNavigation] = React.useState<ITabNavigation>({});

    React.useEffect(() => {
        app?.getContext().then((context) => {
            if (context?.page?.subPageId !== '') {
                setTabNavigation(JSON.parse(context.page.subPageId));
            }
        });
    }, []);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            historySearchRequest.userAlias = loginUserpersonaProps?.secondaryText;
            dispatch(requestUserHistory(historySearchRequest));
        }
    }, [loginUserpersonaProps, location.pathname]);


    React.useEffect(() => {
        if (historyResponses?.historyResponses?.length > 0) {
            settempProjectList(historyResponses.historyResponses);
            dispatch(setProjectFilterListDetails(historyResponses.historyResponses));
            setDetailListColumns(columns);
        }
    }, [historyResponses]);

    React.useEffect(() => {
        if (projectDeleteSuccess) {
            dispatch(requestProjectListDetail(loginUserpersonaProps?.optionalText, projectSearchRequest));
            setDeleteGridLoading(false);
        }
    }, [projectDeleteSuccess]);

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = _copyAndSort(projectListFilterDetails, currColumn.fieldName!, currColumn.isSortedDescending);

        setDetailListColumns(newColumns);
        settempProjectList(newItems);
        dispatch(setProjectFilterListDetails(newItems));

    }

    const opeSPOUrl = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Project name',
            fieldName: 'title',
            minWidth: 450,
            isResizable: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            styles: {
                cellTitle: {
                    marginTop: 12, lineHeight: 16
                },
            },
            onRender: (item: IHistoryResponse) => {
                return (
                    <Stack horizontal tokens={{ childrenGap: 20 }} style={projectNumberStyle}>


                        {item?.title?.toLowerCase() !== 'project not available' && (
                            <Stack
                                onClick={(e) => openProjectURLUrl(e, item.number)}
                                className="column-clickable-cell"
                            ><TooltipHost content={item.title}>
                                    {item.title?.trim() ? (
                                        truncateText(item.title.trim(), 65, 65)
                                    ) : (
                                        item.number
                                    )}
                                </TooltipHost>
                            </Stack>
                        )}
                        {item?.title?.toLowerCase() === 'project not available' && (
                            <Stack
                                style={columnCell}
                            ><TooltipHost content={item.title}>
                                    {item.title?.trim() ? (
                                        truncateText(item.title.trim(), 65, 65)
                                    ) : (
                                        item.number
                                    )}
                                </TooltipHost>
                            </Stack>
                        )}


                    </Stack>
                );
            },
        },
        {
            key: 'column2',
            name: 'Start date',
            fieldName: 'startDate',
            minWidth: 170,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    marginLeft: 75,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: 'date',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item) => {
                return (
                    <>
                        <Stack className="column-clickable-cell" >
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                <View item={item} />
                                {item?.title?.toLowerCase() !== 'project not available' && item?.spoUrl && (
                                <TooltipHost content={'Open SPO url associated to this project'}>
                                <Icon
                                     tabIndex={0}
                                    onClick={() => opeSPOUrl(item.spoUrl)}
                                    data-testid="copy-icon"
                                    iconName="Link"
                                    style={clipBoardIconStyle}
                                    aria-label="click on this icon to copy the spo url to clipboard"
                                /></TooltipHost>)}
                            </Stack>
                        </Stack>
                        <Stack className="column-cell" style={{ marginLeft: 75, marginTop: -20 }}>
                            {item.startDate
                                ? getFormattedDate(item.startDate?.trim())
                                : item.startDate?.trim()}
                        </Stack>
                    </>
                );
            },
        },
        {
            key: 'column4',
            name: 'Status',
            fieldName: 'status',
            minWidth: 75,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            //onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                if (item.status?.toLowerCase() === 'open') {
                    return (
                        <Stack horizontal style={columnCell}>
                            <Stack>
                                <Icon
                                    iconName={'StatusCircleRing'}
                                    style={statusIconGreen}
                                />
                            </Stack>
                            <Stack>
                                <Icon
                                    iconName={'StatusCircleCheckmark'}
                                    style={statusIconGreen2}
                                />
                            </Stack>
                            <Stack style={statusTextOpen}>{item.status?.trim()}</Stack>
                        </Stack>
                    );
                } else {
                    return (
                        <>
                            {item.status?.trim().length > 0 && (
                                <Stack horizontal style={columnCell}>
                                    <Stack>
                                        <Icon
                                            iconName={'StatusCircleRing'}
                                            style={statusIcon}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Icon
                                            iconName={'StatusCircleBlock2'}
                                            style={statusIcon2}
                                        />
                                    </Stack>
                                    <Stack style={statusTextClose}>{item.status?.trim()}</Stack>
                                </Stack>
                            )}
                        </>
                    );
                }
            },
        },
        {
            key: 'column5',
            name: 'Team members',
            fieldName: 'members',
            minWidth: 250,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            //onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                const count = item?.members?.length;
                return (
                    <Stack>
                        {item?.members && count > 1 ? (
                            <Stack
                                horizontal
                                onClick={(e) => openProjectURLUrl(e, item.number)}
                                className="column-clickable-cell"
                            >
                                <Stack className="column-cell">
                                    <MemberView userAlias={item.members[0]} graphTokenParam={graphToken} />
                                </Stack>
                                <Stack style={columnCell2}>
                                    <Stack>+ {count - 1}</Stack>
                                </Stack>
                            </Stack>
                        ) : (

                            <Stack style={columnCell1}>
                                <MemberView userAlias={item?.members ? item?.members[0] : ''} graphTokenParam={graphToken} />
                            </Stack>
                        )}
                    </Stack>
                );
            },
        },
        {
            key: 'column6',
            name: 'Owner',
            fieldName: 'owner',
            minWidth: 250,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            //onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item) => {
                const count = item?.owners?.length;
                return (
                    <Stack horizontal>
                        {item?.owners && count > 1 ? (
                            <Stack
                                horizontal
                                onClick={(e) => openProjectURLUrl(e, item.number)}
                                className="column-clickable-cell"
                            >
                                <Stack style={columnCell1}>

                                    <MemberView userAlias={item.owners[0]} graphTokenParam={graphToken} />

                                </Stack>
                                <Stack style={columnCell2}>
                                    <Stack>+ {count - 1}</Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack style={columnCell1}>
                                <MemberView userAlias={item?.owners ? item.owners[0] : ''} graphTokenParam={graphToken} />
                            </Stack>
                        )}
                    </Stack>
                );
            },
        }
    ];

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    _selection = new Selection({
        onSelectionChanged: () => {
            let newResult = '';
            setShowAccessError(false);
            setOwner(true);
            (_selection.getSelection() as IProjectListResponse[]).forEach((item, index) => {
                if (!item.owners.map(owner => owner.toLowerCase()).includes(loginUserpersonaProps?.secondaryText.toLowerCase())) {
                    setOwner(false);
                }

                newResult += item.number;
                if (index < _selection.getSelection().length - 1) {
                    newResult += ',';
                }
            });
            setSelectedList(newResult);
            setselectedCount(_selection.getSelectedCount());

        },
    });

    function openProjectURLUrl(e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string): void {
        history.push(`/viewproject?componentName=view&projectNumber=${projectNumber}&backToProjectList=true`);
    }


    function getKey(item: any, index?: number): string {
        return item.key;
    }

    function clearVariables() {
        setOwner(true);
        setSelectedList("");
        setShowAccessError(false);
    }



    const [isDialogOpen, setIsDialogOpen] = React.useState(false);





    return (
        <>
            {showAccessError && selectedCount > 0 && (
                <>

                    <DisplayMessage
                        accessabilityMessage={"Please uncheck the projects that you do not own to proceed with the deletion"}
                        messageType={MessageBarType.error}
                        message={"Please uncheck the projects that you do not own to proceed with the deletion"}
                    />
                </>
            )}

           

            <Stack className="projects-list-container">
                {!isProjectGettingCreated && projectCreationSuccess && (
                    <>
                        <DisplayMessage
                            accessabilityMessage={projectCreationResponse.projectTitle + " has been created."}
                            messageType={MessageBarType.success}
                            message={`"${projectCreationResponse.projectTitle}"` + " has been created."}
                        />
                    </>
                )}                
                {!isProjectDeleting && projectDeleteSuccess && (
                    <>
                        <DisplayMessage
                            accessabilityMessage={ProjectEventNames.singleProjectDeleteSuccess}
                            messageType={MessageBarType.success}
                            message={ProjectEventNames.singleProjectDeleteSuccess}
                        />
                    </>
                )}
                {!loadingLoginUserProfile && !isloadingLoginUserProfileError && !isLoadingBannerInformation && (
                    <>
                        <Stack>
                            {(isDeleteGridLoading) && (
                                <>
                                    <span role="alert" aria-label={GenericMessages.deletingProject} aria-live="assertive" />

                                    <div style={spinnerDeleteCss}>
                                        <DisplaySpinner
                                            accessabilityMessage={GenericMessages.deletingProject}
                                            spinnerText={GenericMessages.deletingProject}
                                            spinnerPosition={"right"}
                                        />
                                    </div>

                                </>
                            )}
                            {isUserHistoryResultsLoading && (
                                <div style={spinnerCss}>
                                    <DisplaySpinner
                                        accessabilityMessage='Loading history...'
                                        spinnerText='Loading history...'
                                        spinnerPosition={"right"}
                                    />
                                </div>
                            )}
                            {!isUserHistoryResultsLoading && isUserHistoryResultsLoadingError && (
                                <>
                                    <ErrorDisplay source={projectListError?.errorMessage} />
                                </>
                            )}
                            {!isUserHistoryResultsLoading && !isUserHistoryResultsLoadingError &&  (
                                <>
                                    <Stack horizontal className="top-panel">
                                        <Stack horizontal className="recent-container" tokens={{ childrenGap: 5 }}>
                                            <Icon iconName="History" style={{ marginTop: 5 }} />
                                            <span className="recent-text">History</span>
                                        </Stack>
                                    </Stack>
                                    {historyResponses.historyResponses?.length === 0 && (!isUserHistoryResultsLoading) && (<div style={displayMessage}>No history records found</div>)}
                                    <div style={divGridCSS}>

                                        {historyResponses.historyResponses?.length > 0 && (
                                            <DetailsList
                                                items={projectListFilterDetails}
                                                columns={detailListColumns}
                                                selectionMode={SelectionMode.none}
                                                getKey={getKey}
                                                setKey="multiple"
                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                isHeaderVisible={true}
                                                selection={_selection}
                                                enterModalSelectionOnTouch={true}
                                                onShouldVirtualize={() => false}
                                                styles={{
                                                    root: {
                                                        selectors: {
                                                            '.ms-DetailsRow': {
                                                                borderBottom: '1px solid #D1D1D1', // Adjust border style as needed
                                                                //borderLeft:'1px solid #D1D1D1',
                                                                height: 48,
                                                                fontSize: 14,
                                                                color: '#242424',
                                                                width: '100%'
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>

                                </>
                            )}
                        </Stack>
                    </>
                )}
            </Stack>
        </>
    )



}
export default UserHistoryPage;
