import { Stack, TooltipHost, ITooltipHostStyles, IStackStyles, Icon } from "@fluentui/react";
import React, { useState } from "react";
import './ChatCopy.styles.css'
import { ISearchResponse } from "../../../../Models/Chat/ISearchResponse";
import { ISource } from "../../../../Models/Chat/ISource";
export interface IChatCopyProperties {  
  stackStyles?: any;
  chatResponse?:ISearchResponse
}

const ChatCopy = (chatCopyProperties: IChatCopyProperties) => {
  const { stackStyles, chatResponse } = chatCopyProperties;
  const [isCopied, setIsCopied] = useState(false);
  const calloutProps = { gapSpace: 0 };
  const toolTipStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block", float: "right" } };
  const copy = () => {

    let storagePath: string = "";
    const sources1 = chatResponse?.sources?.map(({ sourceUrl }) => sourceUrl);
		const filteredUrls = chatResponse?.sources?.filter((source: ISource, index: number) => !sources1.includes(source?.sourceUrl, index + 1));
    filteredUrls?.map((source:ISource)=>{
      storagePath = storagePath + source?.documentTitle + "\n"
    })
    let answer = chatResponse?.answer;
    answer = answer + "\n\n" + storagePath
    navigator.clipboard
      .writeText(answer)
      .then(() => {
        console.error("Copied the text to clip board");
        setIsCopied(true);
      })

      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handleKeyDownOnCopy = (event: any)=>{
    if (event.key === 'Enter' || event.keyCode === 13) {
			copy();
		}
  }
  return (
    <>
      {chatResponse && Object.keys(chatResponse).length>0 && (
        <Stack horizontal style={stackStyles}>
          <Stack
            onClick={copy}
            onKeyDown={handleKeyDownOnCopy}
            tabIndex={0}
            data-testid="copy-div"
            className='chat-copy-container'
          >            
            <TooltipHost content={isCopied ? 'Copied' : 'Copy'}>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-width=".5" fill="gray" stroke="gray" d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L17.3663 20.5045C17.0573 21.3782 16.224 22.0042 15.2444 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM17.75 2C18.9926 2 20 3.00736 20 4.25V17.25C20 18.4926 18.9926 19.5 17.75 19.5H8.75C7.50736 19.5 6.5 18.4926 6.5 17.25V4.25C6.5 3.00736 7.50736 2 8.75 2H17.75ZM17.75 3.5H8.75C8.33579 3.5 8 3.83579 8 4.25V17.25C8 17.6642 8.33579 18 8.75 18H17.75C18.1642 18 18.5 17.6642 18.5 17.25V4.25C18.5 3.83579 18.1642 3.5 17.75 3.5Z"></path>
              </svg>
            </TooltipHost>
            <div style={{fontSize:'small', marginTop: -10, marginLeft:-10, color:'gray'}}>{isCopied ? 'Copied' : ''}</div>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ChatCopy;
