import { Icon, Stack, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { Reducer } from "redux";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { IProjectAppState } from "../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import { dueDateText, container, detailsText, stackStyle, stackStyle1, dueDateTextWidth, nodueDateText, dateTextWidth } from "./Styles/History";
import { truncateText } from "../../../../Helpers/ProjectHelper";
import { requestUserHistory } from "../../../../Shared/Actions/Project.action";
import DisplaySpinner from "../../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../../Helpers/ProjectConstants";
import ErrorDisplay from "../../../../Common/Components/ErrorDisplay";
import { useHistory } from "react-router-dom";
import { IHistoryResponse } from "src/Models/IHistoryResponses";
import { projectTitle, titleStyle } from "../MyProjects/Styles/MyProject";
const MyHistory = () => {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        historyResponses,
        isUserHistoryResultsLoading,
        isUserHistoryResultsLoadingError,
        historySearchRequest,
        loginUserpersonaProps,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            historySearchRequest.userAlias = loginUserpersonaProps?.secondaryText;
            dispatch(requestUserHistory(historySearchRequest));
        }
    }, [loginUserpersonaProps]);




    function openProjectURL(e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string): void {

        history.push(`/viewproject?componentName=view&projectNumber=${projectNumber}`);

    }

    return (
        <Stack style={container}>
            <Stack horizontal style={titleStyle}>
                <Stack>
                    <Icon
                        iconName="History"
                        style={{
                            fill: '#000',
                            width: '13.333px',
                            height: '12px',
                            marginTop: 3
                        }}
                    />
                </Stack>
                <Stack>
                    <div style={projectTitle}>
                        History
                    </div>
                </Stack>

            </Stack>
            {isUserHistoryResultsLoading && (
                <Stack style={{ marginTop: -75 }}>
                    <span role="alert" aria-label={GenericMessages.dueDateProjectsLoading} aria-live="assertive" />

                    <DisplaySpinner
                        accessabilityMessage={"Loading history details"}
                        spinnerText={"Loading history details..."}
                        spinnerPosition={"right"}
                    />
                </Stack>
            )}

            {!isUserHistoryResultsLoading && isUserHistoryResultsLoadingError && (
                <>
                    <ErrorDisplay source={"Error in loading history details"} />
                </>
            )}

            {!isUserHistoryResultsLoading && !isUserHistoryResultsLoadingError && historyResponses && historyResponses?.historyResponses?.length > 0 && (
                <>
                    <Stack>
                        {historyResponses.historyResponses.slice(0, 5).map((response: IHistoryResponse) => {
                            return (

                                <Stack horizontal style={stackStyle}>
                                    <Stack style={dueDateTextWidth}>
                                        <div style={dueDateText}>
                                            <TooltipHost
                                                content={response?.title}
                                                aria-label={response?.title}
                                                tabIndex={0}
                                            >
                                                {truncateText(response?.title, 25, 25)}
                                            </TooltipHost>
                                        </div>
                                    </Stack>
                                    <Stack style={dateTextWidth}>
                                        <div style={dueDateText}>
                                            {response?.startDate}
                                        </div>
                                    </Stack>

                                    <Stack style={stackStyle1}>
                                        {response?.title?.toLowerCase() !== 'project not available' && (
                                            <div style={detailsText} onClick={(e) => openProjectURL(e, response.number)} tabIndex={0}>
                                                Details
                                            </div>
                                        )}
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>

                </>
            )}
            {!isUserHistoryResultsLoadingError && historyResponses?.historyResponses?.length === 0 && (
                <>
                    <Stack>
                        <div style={nodueDateText}>
                            {"No recent history of accessed projects found"}
                        </div>

                    </Stack>
                </>
            )}


        </Stack>
    )
}

export default MyHistory;