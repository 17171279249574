import { IUser } from "@micro-frontend-react/employee-experience/lib/IUser";
import { IChatState } from "../IChatState";
import { ChatActionTypes, ChatActions } from "../Actions/Chat.action-types";
import { IChatTopicResponse, IChatTopicResponses } from "../../Models/Chat/IChatTopicResponse";
import { IUserChatLogsResponseError } from "../../Models/Chat/IUserChatLogsResponseError";
import { IChatTopicDeleteResponse } from "../../Models/Chat/IChatTopicDeleteResponse";
import { IChatTopicDeleteResponseError } from "../../Models/Chat/IChatTopicDeleteResponseError";
import { IChatTopicEditResponse } from "../../Models/Chat/IChatTopicEditResponse";
import { IChatTopicEditResponseError } from "../../Models/Chat/IChatTopicEditResponseError";
export const ChatReducerName = "ChatReducerName";

export const chatInitialState: IChatState = {
  loginUserpersonaProps: {},
  isRequestingNewAnswer: false,
  newUserPrompt: '',
  topicIdInContext: '',
  isNewTopic: false,
  //chatTopicResponses: {},
  user: {} as IUser,
  topic: {},
  isResponseNewAnswerError: false,
  responseNewAnswerError: {},
  shouldRetryUserPrompt: false,
  isRequestingChatTopicError: false,
  isRequestingChatTopic: false,
  chatTopicResponseError: {},
  isFeedbackSubmissionSuccess: false,
  feedbackRequest: {},

  isLoadingSystemPrompts: false,
  isLoadingSystemPromptsError: false,
  systemPromptsList: {},
  systemPromptsError: {},
  shouldShowSystemPrompts: true,

  isProjectChatInContext: false,
  shouldShowChatView: false,
  chatTopicResponses: {} as IChatTopicResponses,
  shouldDiplayChatLogs: false,
  chatTopicNewResponse: {},

  isRequestingChatLogs: false,
  userChatLogsResponse: [],
  isUserChatLogsResponseError: false,
  userChatLogsResponseError: {} as IUserChatLogsResponseError,

  isEditingChatTopic: false,
  isEditingChatTopicError: false,
  chatTopicEditResponseError: {} as IChatTopicEditResponseError,
  chatTopicEditResponse: {} as IChatTopicEditResponse,

  isDeletingChatTopic: false,
  isDeletingChatTopicError: false,
  chatTopicDeleteResponse: {} as IChatTopicDeleteResponse,
  chatTopicDeleteResponseError: {} as IChatTopicDeleteResponseError,
  topicUser: ''
}

export function chatReducer(prev: IChatState = chatInitialState, action: ChatActions): IChatState {
  switch (action.type) {
    case ChatActionTypes.IS_NEW_TOPIC:
      return {
        ...prev,
        isNewTopic: true,
        newUserPrompt: '',
        chatTopicResponses: {},
        topic: {},
        isResponseNewAnswerError: false,
        //createRequestTopic: {},
        topicIdInContext: ''
      };
    case ChatActionTypes.NEW_USER_PROMPT:
      return {
        ...prev,
        newUserPrompt: action.newUserPrompt,
        topicIdInContext: '',
        //createRequestTopic: {},
        isNewTopic: false,
        topic: {},
      };
    //---------------------user Prompt start-----------------------
    case ChatActionTypes.NEW_QUESTION_REQUEST:
      return {
        ...prev,
        isRequestingNewAnswer: true,
        isResponseNewAnswerError: false,
        responseNewAnswerError: {},
        topic: {},
        chatTopicResponses: {},
        isNewTopic: false
      };
    case ChatActionTypes.NEW_ANSWER_RESPONSE:
      return {
        ...prev,
        isRequestingNewAnswer: false,
        isResponseNewAnswerError: false,
        responseNewAnswerError: {},
        topic: action.topic,

      };
    case ChatActionTypes.NEW_ANSWER_RESPONSE_ERROR:
      return {
        ...prev,
        isRequestingNewAnswer: false,
        isResponseNewAnswerError: true,
        topic: {},
        responseNewAnswerError: {
          ...prev.responseNewAnswerError,
          error: action.error,
          errorMessage: action.errorMessage,
          CorrelationId: action.CorrelationId,
        },
      }

      case ChatActionTypes.NEW_DEFAULT_QUESTION_REQUEST:
      return {
        ...prev,
        isLoadingDefaultPrompts: true,
        isLoadingDefaultPromptsError: false,
        defaultPromptsError: {},
        defaultPromptsList: {},
      };
    case ChatActionTypes.NEW_DEFAULT_ANSWER_RESPONSE:
      return {
        ...prev,
        isLoadingDefaultPrompts: false,
        isLoadingDefaultPromptsError: false,
        defaultPromptsError: {},
        defaultPromptsList: action.topic,

      };
    case ChatActionTypes.NEW_DEFAULT_ANSWER_RESPONSE_ERROR:
      return {
        ...prev,
        isLoadingDefaultPrompts: false,
        isLoadingDefaultPromptsError: true,
        systemPromptsError: {
          ...prev.systemPromptsError,
          error: action.error,
          errorMessage: action.errorMessage,
          CorrelationId: action.CorrelationId,
        },
        systemPromptsList: {},
      }
    //------------------user prompt end ---------------------------

    case ChatActionTypes.REQUEST_SYSTEM_PROMPTS:
      return {
        ...prev,
        isLoadingSystemPrompts: true,
        isLoadingSystemPromptsError: false,
        systemPromptsError: {},
        systemPromptsList: {},
      };
    case ChatActionTypes.RESPONE_SYSTEM_PROMPTS:
      return {
        ...prev,
        isLoadingSystemPrompts: false,
        isLoadingSystemPromptsError: false,
        systemPromptsError: {},
        systemPromptsList: action.systemPrompts,
      };
    case ChatActionTypes.RESPONSE_SYSTEM_PROMPTS_ERROR:
      return {
        ...prev,
        isLoadingSystemPrompts: false,
        isLoadingSystemPromptsError: true,
        systemPromptsError: {
          ...prev.systemPromptsError,
          error: action.error,
          errorMessage: action.errorMessage,
          CorrelationId: action.CorrelationId,
        },
        systemPromptsList: {},
      };
    case ChatActionTypes.TOPIC_IN_CONTEXT:
      return {
        ...prev,
        chatTopicResponses: {} as IChatTopicResponses,
        topicIdInContext: action.topicIdInContext,
        topicUser: action.topicUser,
				newUserPrompt: '',
				topic: {},
				isNewTopic: false,
      };
    case ChatActionTypes.SHOULD_CLEAR_CHAT_RESPONSE:
      return {
        ...prev,
        chatTopicResponses: {},
      };
    case ChatActionTypes.SHOULD_SHOW_CHAT_LOGS:
      return {
        ...prev,
        shouldDiplayChatLogs: action.showChatLogs,
      };
    case ChatActionTypes.SHOULD_DISPLAY_CHAT_VIEW:
      return {
        ...prev,
        shouldShowChatView: action.shouldShowChatView,
        shouldDiplayChatLogs: false,
        chatTopicNewResponse: {} as IChatTopicResponse,

        userChatLogsResponseError: {} as IUserChatLogsResponseError,
        userChatLogsResponse: [],
        isUserChatLogsResponseError: false,
        isRequestingChatLogs: false,

        isDeletingChatTopicError: false,
        isDeletingChatTopic: false,
        chatTopicDeleteResponseError: {} as IChatTopicDeleteResponseError,
        chatTopicDeleteResponse: {} as IChatTopicDeleteResponse,

        isEditingChatTopic: false,
        isEditingChatTopicError: false,
        chatTopicEditResponseError: {} as IChatTopicEditResponseError,
        chatTopicEditResponse: {} as IChatTopicEditResponse,

      };

    case ChatActionTypes.REQUEST_CHAT_LOGS:
      return {
        ...prev,
        isUserChatLogsResponseError: false,
        isRequestingChatLogs: true,
        userChatLogsResponseError: {} as IUserChatLogsResponseError,
        userChatLogsResponse: [],
      };
    case ChatActionTypes.RESPONSE_CHAT_LOGS:
      return {
        ...prev,
        isRequestingChatLogs: false,
        isUserChatLogsResponseError: false,
        userChatLogsResponseError: {} as IUserChatLogsResponseError,
        userChatLogsResponse: action.userChatLogsResponse,
        chatTopicNewResponse: {},
      };
    case ChatActionTypes.RESPONSE_ERROR_CHAT_LOGS:
      return {
        ...prev,
        isRequestingChatLogs: false,
        isUserChatLogsResponseError: true,
        userChatLogsResponse: [],
        userChatLogsResponseError: {
          ...prev.userChatLogsResponseError,
          error: action.error,
          errorMessage: action.errorMessage,
          CorrelationId: action.CorrelationId,
        },
      };

      case ChatActionTypes.REQUEST_DELETE_CHAT_TOPIC:
      return {
        ...prev,
        isDeletingChatTopicError: false,
        isDeletingChatTopic: true,
        chatTopicDeleteResponseError: {} as IChatTopicDeleteResponseError,
      };
    case ChatActionTypes.RESPONSE_DELETE_CHAT_TOPIC:
      return {
        ...prev,
        isDeletingChatTopicError: false,
        isDeletingChatTopic: false,
        chatTopicDeleteResponseError: {} as IChatTopicDeleteResponseError,
        chatTopicDeleteResponse: action.chatTopicDeleteResponse,
      };
    case ChatActionTypes.RESPONSE_ERROR_DELETE_CHAT_TOPIC:
      return {
        ...prev,
        isDeletingChatTopic: false,
        isDeletingChatTopicError: true,
        chatTopicDeleteResponse: {} as IChatTopicDeleteResponse,
        chatTopicDeleteResponseError: {
          ...prev.chatTopicDeleteResponseError,
          error: action.error,
          errorMessage: action.errorMessage,
          CorrelationId: action.CorrelationId,
        },
      };

    case ChatActionTypes.REQUEST_EDIT_CHAT_TOPIC:
      return {
        ...prev,
        isEditingChatTopicError: false,
        isEditingChatTopic: true,
        chatTopicEditResponseError: {} as IChatTopicEditResponseError,
        chatTopicEditResponse: {} as IChatTopicEditResponse,
      };
    case ChatActionTypes.RESPONSE_EDIT_CHAT_TOPIC:
      return {
        ...prev,
        isEditingChatTopicError: false,
        isEditingChatTopic: false,
        chatTopicEditResponseError: {} as IChatTopicEditResponseError,
        chatTopicEditResponse: action.chatTopicEditResponse,
      };
    case ChatActionTypes.RESPONSE_ERROR_EDIT_CHAT_TOPIC:
      return {
        ...prev,
        isEditingChatTopic: false,
        isEditingChatTopicError: true,
        chatTopicEditResponse: {} as IChatTopicEditResponse,
        chatTopicEditResponseError: {
          ...prev.chatTopicEditResponseError,
          error: action.error,
          errorMessage: action.errorMessage,
          CorrelationId: action.CorrelationId,
        },
      };

      case ChatActionTypes.REQUEST_GET_CHAT_TOPIC:
        return {
          ...prev,
          isRequestingChatTopicError: false,
          isRequestingChatTopic: true,
          chatTopicResponseError: {},
          chatTopicResponses: {},
          topic: {},
          isResponseNewAnswerError: false
          //createRequestTopic: {}
        };
  
      case ChatActionTypes.RESPONSE_GET_CHAT_TOPIC:
        return {
          ...prev,
          isRequestingChatTopicError: false,
          isRequestingChatTopic: false,
          chatTopicResponseError: {},
          chatTopicResponses: action.topicResponses
        };
      case ChatActionTypes.RESPONSE_ERROR_GET_CHAT_TOPIC:
        return {
          ...prev,
          isRequestingChatTopicError: true,
          isRequestingChatTopic: false,
          chatTopicResponses: {},
          chatTopicResponseError: {
            ...prev.chatTopicResponseError,
            error: action.error,
            errorMessage: action.errorMessage,
            CorrelationId: action.CorrelationId
          }
        };
    default:
      return {
        ...prev
      };
  }
}