import React, { useEffect, useState } from 'react'
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';

import { Stack, Icon } from '@fluentui/react';
import DisplayMessage from '../../../../Common/Components/DisplayMessage';
import './FeedbackSuccessResponse.styles.css'

const FeedbackSuccessResponse = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { isFeedbackSubmissionSuccess, feedbackRequest } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);
  const [closeFeedback, setCloseFeedback] = React.useState<boolean>(false)

  const handleClosefeedback = () => {
    setCloseFeedback(true)
  }

  const handleCloseFeedbackOnKeyDown = (event: any)=> {
    if (event.key === 'Enter' || event.keyCode === 13) {
      setCloseFeedback(true)
    }
  }
  return (
    <>
      {!closeFeedback && (

        <Stack className='feedback-response-container' horizontal>
          <Stack className='feedback-response-text-container'></Stack>
          <Stack className='feedback-response-text' horizontal>
            <Stack className='feedback-response-prompt-text'>
              {/* <DisplayMessage messageType={MessageBarType.warning} message={prompt.promptText}
        accessabilityMessage={prompt.promptText} customMessageClass='banner-message-info'
      /> */}
              <Stack horizontal style={{ backgroundColor: 'rgb(255, 244, 206)' }} tokens={{ childrenGap: 5 }}>
                <Stack style={{ padding: 5 }}>
                  <div role='region' aria-label={isFeedbackSubmissionSuccess?'Feedback has been submitted':'Error in submitting feedback'} dangerouslySetInnerHTML={{ __html: isFeedbackSubmissionSuccess?'Feedback has been submitted':'Error in submitting feedback' }}></div>
                </Stack>
                <Stack style={{ padding: 5 }}>
                  <Icon role='link' aria-label='click on this close icon to close the information bar' iconName='ChromeClose' onClick={handleClosefeedback} tabIndex={0} style={{ cursor: 'pointer' }} onKeyDown={handleCloseFeedbackOnKeyDown} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  )
}
export default FeedbackSuccessResponse