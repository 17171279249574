import * as React from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Icon, IDropdownOption, PrimaryButton, Stack } from "@fluentui/react";
import { Reducer } from "redux";
import useGetProjectMemberProfile from "../../Hooks/useGetProjectMemberProfile";
import { bold, deleteiconStyle, dropdownStyles, paddingLeft5, paddingTop4, width20, width65 } from "./Styles/TeamMembers";
import MemberView from "./MemberView";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import { requestDeleteMember, requestMemberStatusUpdate, requestUpdateMembersNames, requestUpdateOwnersNames, updatedeletedmembers, updateMembersStatusState } from "../../../Shared/Actions/Project.action";

export interface MemberProps {
    index?: number;
    member?: any;
    responsibleFor?: boolean;
    removeMemberFromState(member: any, responsible: boolean): void;
    updateMemberListForStateChange(member: any, responsible: boolean): void;
    updateOwnerListForStateChange(member: any, responsible: boolean): void;
    graphTokenParam?: string;
}

const MemberDetails = (memberProps: MemberProps) => {
    const { index, member, responsibleFor, removeMemberFromState, updateMemberListForStateChange, updateOwnerListForStateChange, graphTokenParam } = memberProps;
    const userAlias: string = member.secondaryText;
    const { projectMemberProfile, isProjectMemberProfileLoading } = useGetProjectMemberProfile({ userAlias, graphTokenParam })
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch, httpClient } = reduxContext;
    const {
        projectContext,
        projectMembers,
        projectOwner,
        isProjectMemberDeletingError,
        projectMemberDeleteSuccess,
        deletedProjectMembers,
        isProjectMemberStatusUpdatingError,
        projectMemberStatusUpdatingSuccess,
        memberToUpdateStatus,
        updateMemberStatus,
        projectProps, loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    //Member Dropdown Start
    const dropDownOptions: IDropdownOption[] = [
        { key: "member", text: 'Member', title: 'member' },
        { key: "owner", text: 'Owner', title: 'owner' },
    ];
    const [selectedMemberType, setSelectedMemberType] = React.useState<IDropdownOption>();
    const [nonStatus, setNonStatus] = React.useState("");
    const [hideDialog, setHideDialog] = React.useState(true);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [hideStatusDialog, setStatusHideDialog] = React.useState(true);
    const [hideMemberDeleteDialog, setMemberDeleteHideDialog] = React.useState(true);
    const onMemberTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedMemberType(item);
    };

    React.useEffect(() => {
        if (responsibleFor) {
            setSelectedMemberType(dropDownOptions[1]);
            setNonStatus("member");
        }
        else {
            setSelectedMemberType(dropDownOptions[0]);
            setNonStatus("owner");
        }
    }, [responsibleFor]);

    React.useEffect(() => {
        if (
            member.secondaryText.toLowerCase().trim() === "lca@microsoft.com" ||
            member.secondaryText.toLowerCase().trim() === "lca" ||
            projectContext?.isBillable
        ) {
            setIsDisabled(true);
        }
    }, [member]);
    //Member Dropdown End

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Delete members",
    };

    const statusdialogContentProps = {
        type: DialogType.largeHeader,
        title: "Change status",
    };

    const deleteOwnerdialogContentProps = {
        type: DialogType.largeHeader,
        title: "Cannot delete owner",
    };

    const toggleHideDialog = () => setHideDialog(value => !value);

    const toggleStatusHideDialog = () => setStatusHideDialog(value => !value);

    const toggleMemberDeleteHideDialog = () => setMemberDeleteHideDialog(value => !value);

    // Removes the team member from the project
    const removeMember = (): void => {
        console.log("Project Owners list", projectOwner, member, responsibleFor);
        if (responsibleFor && projectOwner && projectOwner?.membersPicked.length == 1) {
            toggleHideDialog();
            toggleMemberDeleteHideDialog();
        }
        else {
            dispatch(updatedeletedmembers(member));
            dispatch(requestDeleteMember(projectContext, member, responsibleFor, projectProps,loginUserpersonaProps?.secondaryText));
            toggleHideDialog();
        } 
    }

    // Changes the member status
    const changeStatus = (): void => {
        dispatch(updateMembersStatusState(member, !responsibleFor));
        dispatch(requestMemberStatusUpdate(projectContext, member, !responsibleFor, projectProps));
    }

    React.useEffect(() => {
        if (projectMemberProfile && projectMemberProfile?.text) {
            if (responsibleFor) {
                dispatch(requestUpdateOwnersNames(projectOwner, userAlias, projectMemberProfile.text))
            }
            else {
                dispatch(requestUpdateMembersNames(projectMembers, userAlias, projectMemberProfile.text))
            }
        }
    }, [projectMemberProfile]);

    React.useEffect(() => {
        if (!isProjectMemberDeletingError && projectMemberDeleteSuccess) {
            removeMemberFromState(deletedProjectMembers, responsibleFor);
        }
    }, [isProjectMemberDeletingError, projectMemberDeleteSuccess]);

    React.useEffect(() => {
        if (!isProjectMemberStatusUpdatingError && projectMemberStatusUpdatingSuccess) {
            updateMemberListForStateChange(memberToUpdateStatus, updateMemberStatus);
            updateOwnerListForStateChange(memberToUpdateStatus, updateMemberStatus)
        }
    }, [isProjectMemberStatusUpdatingError, projectMemberStatusUpdatingSuccess]);

    return (
        <Stack horizontal style={paddingTop4}>
            <Stack style={width65}>
                <MemberView key={member.secondaryText} userAlias={member.secondaryText} />
            </Stack>
            <Stack style={width20}>
                <div >
                    <Dropdown
                        selectedKey={selectedMemberType ? selectedMemberType.key : undefined}
                        onChange={toggleStatusHideDialog}
                        options={dropDownOptions}
                        styles={dropdownStyles}
                        disabled={isDisabled}
                    />
                    <Dialog
                        hidden={hideStatusDialog}
                        onDismiss={toggleStatusHideDialog}
                        dialogContentProps={statusdialogContentProps}
                    >
                        Are you sure you want to change the permission of <span style={bold}>{projectMemberProfile?.text}</span> from {selectedMemberType?.key} to {nonStatus}?
                        <DialogFooter>
                            <PrimaryButton onClick={changeStatus} text="Confirm" />
                            <DefaultButton onClick={toggleStatusHideDialog} text="Cancel" />
                        </DialogFooter>
                    </Dialog>
                </div>
            </Stack>
            {!projectContext.isBillable && (
                <>
                    <Stack>
                        <div style={paddingLeft5}>
                            <Icon
                                iconName={"Delete"}
                                tabIndex={0}
                                style={deleteiconStyle}
                                onClick={toggleHideDialog}
                            />
                            <Dialog
                                hidden={hideDialog}
                                onDismiss={toggleHideDialog}
                                dialogContentProps={dialogContentProps}
                            >
                                Are you sure you want to remove <span style={bold}>{projectMemberProfile?.text}</span> from project "<span style={bold}>{projectProps.title}</span>"?
                                <DialogFooter>
                                    <PrimaryButton onClick={removeMember} text="Confirm" />
                                    <DefaultButton onClick={toggleHideDialog} text="Cancel" />
                                </DialogFooter>
                            </Dialog>


                            <Dialog
                                hidden={hideMemberDeleteDialog}
                                onDismiss={toggleMemberDeleteHideDialog}
                                dialogContentProps={deleteOwnerdialogContentProps}
                            >
                                Atleast 1 owner is required, so we cannot remove <span style={bold}>{projectMemberProfile?.text}</span> from the project "<span style={bold}>{projectProps.title}</span>"
                                <DialogFooter>
                                    <DefaultButton onClick={toggleMemberDeleteHideDialog} text="Close" />
                                </DialogFooter>
                            </Dialog>
                        </div>
                    </Stack>
                </>
            )}
        </Stack>
    );
};
export default MemberDetails;
