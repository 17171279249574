import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {  trackProjectRetryAPIEvents } from "../Helpers/ProjectTelemtry";
import { telemetryClient } from "../ShellWithStore";
import {  ProjectTrackingInformationeEventId } from "../Helpers/ProjectEnum";

//custom axios instance to make https call
const customAxiosApi: AxiosInstance = axios.create({
  baseURL: `${__API_BASE_LMI_URL__}`,
  headers: {
    "Content-Type": "application/json",
  },
});

//custom config object of AxiosRequestConfig
interface RetryConfig extends AxiosRequestConfig {
  retry?: number;
  retryDelay?: number;
  headers?: any;
}

//Config object to the axios where we are specifying the rety attemps and the retry delay and the
//headers that needs to be sent as part of http headers
const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
  headers: { "Access-Control-Allow-Origin": "*", Authorization: "", "x-correlation-id": "" },
};

/*
This function will intecept all the https requests that are being made and it will create a meta
object which will have the information such as, when the request has been started
*/
customAxiosApi.interceptors.request.use((req: any) => {
  req.meta = req.meta || {};
  req.meta.requestStartedAt = new Date().getTime();
  return req;
});

/*This function will intercept all respone network api calls
    1. if there is any network error, it will retry for 3 times and if the network error is not resolved after 3 attempts
       it will throw error
    2. If there is no network error, if the error is due to anyother reason, theere wont be any retry and the error
       will be thrown immediately
    3. If the response is success, success respknse will  be retuned to the user along with duration it has taken to
       process the request
*/
customAxiosApi.interceptors.response.use(
  (response: any) => {
    response.durationInMs = new Date().getTime() - response.config.meta.requestStartedAt;
    return response;
  },
  async (error: any) => {
    const { config } = error;

    //If there any response from the api call or not network error, and if any is returned from API, return error
    if (!config || !config.retry || error?.response || error?.message.trim().toLowerCase() !== "network error") {
      return Promise.reject(error);
    }

    //If there is any network error, retry the api call
    config.retry -= 1;
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {        
        trackProjectRetryAPIEvents(
          telemetryClient,
          "LegalEaseApp",
          "ProjectsAPIRetry:",
          ProjectTrackingInformationeEventId.projectsAPIRetry,
          {
            url: config.url,
            baseUrl: config?.baseURL,
            method: config?.method,
            retry: config?.retry,
          }
        );
        resolve();
      }, config.retryDelay * 5 || 1000);
    });
    await delayRetryRequest;
    return await customAxiosApi(config);
  }
);
export { customAxiosApi, globalConfig };
