import * as React from 'react';

import { Stack, Spinner, ISpinnerStyles } from '@fluentui/react';
import { SpinnerModel } from '../../Models/SpinnerModel';

const spinnerStyles: ISpinnerStyles = {
    label:{ fontSize: '16px'}
}
export const DisplaySpinner = (props: SpinnerModel): JSX.Element => {
    const { spinnerPosition, spinnerText, accessabilityMessage } = props;
    return (
        <Stack.Item styles={{root:{marginTop: 200}}}>
            <Spinner styles={spinnerStyles} label={spinnerText} labelPosition={spinnerPosition} ariaLabel={accessabilityMessage} />
        </Stack.Item>
    );
};

export default DisplaySpinner;