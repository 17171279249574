import * as React from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    ITag,
    Icon,
    PrimaryButton,
    Stack,
    TooltipHost
} from '@fluentui/react';
import { Reducer } from 'redux';
import {
    bold,
    deleteiconStyle,
    fileiconStyle,
    fileNameStyle,
    fileNameStyle2,
    fileNameWidth,
    paddingTop4,
    width30,
    width65
} from './Styles/Attachments';
import {
    getCurrentDate,
    getFormattedDate,
    getIconByFileExtension,
    truncateText
} from '../../../Helpers/ProjectHelper';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IProjectAppState } from '../../../Shared/ProjectState';
import {
    ProjectReducerName,
    projectReducer,
    projectInitialState
} from '../../../Shared/Reducers/Project.reducer';
import { projectSagas } from '../../../Shared/Sagas/Project.sagas';
import {
    requestDeleteFile,
    requestFileTagsUpdate,
    updateDeletedFiles
} from '../../../Shared/Actions/Project.action';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import CustomTagPicker from '../../PickerComponents/CustomTagPicker';
import { CustomTag } from 'src/Models/CustomTags';

export interface FileProps {
    index?: number;
    file?: any;
    removeFileFromState(file: any): void;
    showDate: boolean;
}

const FileView = (fileProps: FileProps) => {
    const { index, file, removeFileFromState, showDate } = fileProps;
    const reduxContext = React.useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    var fileCreationdate = file?.file?.createdDateTime
        ? getFormattedDate(file?.file?.createdDateTime?.split(' ')[0])
        : getCurrentDate();
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [
        projectSagas
    ]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectContext,
        projectFiles,
        projectFileDeleteSuccess,
        isProjectFileDeletingError,
        projectFilesDeleted,
        projectProps,
        projectFileTags
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const [hideDialog, setHideDialog] = React.useState(true);
    const [createDate, setCreateDate] = React.useState('');
    const [hideTagDialog, setHideTagDialog] = React.useState(true);
    const [selectedFileTags, setSelectedFileTags] = React.useState<ITag[]>([]);
    const [fileTags, setFileTags] = React.useState("");
    const [tagCount, setTagCount] = React.useState<number>(0);
    const [customTags, setCustomTags] = React.useState<CustomTag[]>([]);
    const [projectTag, setProjectTag] = React.useState<number>(0);
    const [changeDetected, setChangeDetected] = React.useState(false);

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Delete Attachment'
    };

    const toggleHideDialog = () => setHideDialog((value) => !value);

    // Removes the attachment from the project
    const removeAttachment = () => {
        if (file.isNew) {
            removeFileFromState(file);
        } else {
            dispatch(updateDeletedFiles(file));
            dispatch(requestDeleteFile(projectContext, file?.file?.name));
        }
        toggleHideDialog();
    };

    React.useEffect(() => {

        if (projectFileTags?.length) {
            console.log("fileTags", projectFileTags);
            const filteredFileTags = projectFileTags
                .filter(tag => tag.fileName === file.file.name)
                .map(tag => tag.tags);
            const firstOrDefaultTag = filteredFileTags.shift() ?? ""; //
            // Assuming setFileTags is a function to update the state
            setFileTags(firstOrDefaultTag);
            const iTags: ITag[] = [];
            if (firstOrDefaultTag) {

                const tagArray = firstOrDefaultTag?.split(",");
                tagArray?.map((projectTag: string) => {
                    const iTag: ITag = {
                        key: projectTag,
                        name: projectTag,
                    };
                    iTags.push(iTag);
                });
                setSelectedFileTags(iTags);
            }
        }
        else {
            setFileTags("");

        }
    }, [projectFileTags]);

    React.useEffect(() => {
        const iTags: ITag[] = [];
        if (fileTags) {

            const tagArray = fileTags?.split(",");
            tagArray?.map((projectTag: string) => {
                const iTag: ITag = {
                    key: projectTag,
                    name: projectTag,
                };
                iTags.push(iTag);
            });
            setSelectedFileTags(iTags);
        }
    }, [fileTags]);

    function isURL(str) {
        // Regular expression to check if the string is a valid URL
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        );
        str = !!pattern.test(str); // fragment locator
        str = str + '?web=1';
        return str;
    }
    React.useEffect(() => {
        if (!isProjectFileDeletingError && projectFileDeleteSuccess) {
            removeFileFromState(projectFilesDeleted);
        }
    }, [isProjectFileDeletingError, projectFileDeleteSuccess]);

    const toggleHideTagDialog = (fileName, type) => {

        if (type === 'add') {
            const fileTagsObject = {
                fileName: fileName,
                tags: fileTags, // Assuming fileTags is the object you want to store
            };

            dispatch(requestFileTagsUpdate(fileTagsObject, projectContext));

        }
        setHideTagDialog(value => !value);
    };

    const dialogTagContentProps = {
        type: DialogType.largeHeader,
        title: 'Add Tags',
        subText: 'Type the tags below to associate with the uploaded files',
    };

    const modalPropsStyles = { main: { minWidth: 250, minHeight: 400 } };

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        styles: modalPropsStyles
    };

    const onAddTag = async (text: string) => {
        setTagCount((x) => x + 1);
        const currentTagidIndex = tagCount + customTags.length;
        const response = await Promise.resolve({
            id: currentTagidIndex,
            text,
        });
        return response;
    };

    const onCategorySelected = (id?: number) => {
        setProjectTag(id);
    };

    const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
        if (filter) {
            return customTags
                .filter((customTag: CustomTag) => customTag.text.toLowerCase().includes(filter.toLowerCase()))
                .map((customTag: CustomTag): ITag => {
                    return {
                        name: customTag.text,
                        key: customTag.id,
                    };
                });
        }
        return [];
    };

    const onChangeFileTags = (items?: ITag[] | undefined) => {
        if (items && items[0]) {
            onCategorySelected(Number(items[0].key));
        } else {
            onCategorySelected();
        }
        setSelectedFileTags(items);

        let tagStrings: string = "";
        items.map((item: ITag) => {
            tagStrings = tagStrings + item.name.trim() + ",";
        });
        tagStrings = tagStrings.slice(0, -1);

        setFileTags(tagStrings);
        setChangeDetected(true);
    };



    return (
        <Stack horizontal style={paddingTop4}>
            <Stack style={width65}>
                <div>

                    <Stack horizontal>
                        <Stack>
                            <Icon
                                {...getFileTypeIconProps({
                                    extension:
                                        getIconByFileExtension(file?.file?.name),
                                    size: 16,
                                    imageFileType: 'png',

                                })}
                                tabIndex={0}
                                style={fileiconStyle}
                            />
                        </Stack>
                        <Stack style={fileNameWidth}>
                            <a
                                href={
                                    isURL(file?.file?.path)
                                        ? file?.file?.path
                                        : file?.file?.fullPath
                                }
                                target="_blank"
                                rel="noopener"
                                style={fileNameStyle}
                            >
                                <TooltipHost content={file.file.name}>
                                    {truncateText(file.file.name, 60, 60)}{' '}
                                </TooltipHost>
                            </a>
                        </Stack>
                        <Stack>
                            <button onClick={() => toggleHideTagDialog(file.file.name, 'open')} style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <TooltipHost content="Add File Tags">
                                    <Icon
                                        iconName={"Tag"}
                                        tabIndex={0}
                                        style={{
                                            width: "14px",
                                            height: "14px",
                                            color: "#000000",
                                            border: "none",
                                            fontSize: "14px",
                                            paddingLeft: "0px",
                                            marginTop: 13
                                        }}
                                    />
                                </TooltipHost>
                                <Dialog
                                    hidden={hideTagDialog}
                                    onDismiss={() => toggleHideTagDialog('', '')}
                                    dialogContentProps={dialogTagContentProps}
                                    modalProps={modelProps}
                                >

                                    <div style={{ border: "1px solid black" }}>

                                        <CustomTagPicker
                                            onAddTag={onAddTag}
                                            onCategorySelected={onCategorySelected}
                                            onResolveSuggestions={onResolveSuggestions}
                                            onChange={onChangeFileTags}
                                            selectedItems={selectedFileTags}
                                            scenario="FileTags"
                                            isBillable={projectContext.isBillable}
                                        />


                                    </div>
                                    <div style={{ color: '#fff', height: 100 }}>
                                        This div is placed to occupy some space for tags suggestion container</div>
                                    <DialogFooter>
                                        <PrimaryButton onClick={() => toggleHideTagDialog(file.file.name, 'add')} text="Add" />
                                        {/* <DefaultButton onClick={() => toggleHideTagDialog(file.file.name, 'close')} text="Close" /> */}
                                    </DialogFooter>

                                </Dialog>
                            </button>
                        </Stack>
                    </Stack>

                </div>
            </Stack>

            <Stack style={width30}>
                <div style={fileNameStyle2}>
                    {showDate && <>{fileCreationdate}</>}
                    <Icon
                        iconName={'Delete'}
                        tabIndex={0}
                        style={deleteiconStyle}
                        onClick={toggleHideDialog}
                    />
                    <Dialog
                        hidden={hideDialog}
                        onDismiss={toggleHideDialog}
                        dialogContentProps={dialogContentProps}
                    >
                        Are you sure you want to remove{' '}
                        <span style={bold}>{file?.file?.name}</span> from
                        project "<span style={bold}>{projectProps.title}</span>
                        "?
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => removeAttachment()}
                                text="Confirm"
                            />
                            <DefaultButton
                                onClick={toggleHideDialog}
                                text="Cancel"
                            />
                        </DialogFooter>
                    </Dialog>
                </div>
            </Stack>
        </Stack>
    );
};
export default FileView;
