import { Icon, Stack, Tooltip, TooltipHost } from "@fluentui/react";
import React from "react";
import './Vote.styles.css'

export interface IUpVoteProps {
  upVoteClick(): void;
  selectedButton: string;
}

const UpVote = (upVoteProps: IUpVoteProps) => {
  const { upVoteClick, selectedButton } = upVoteProps;
  const showFeedbackUIHandler = () => {
    upVoteClick();
  };

  const handleKeyDownOnUpVote = (event: any)=>{
		if (event.key === 'Enter' || event.keyCode === 13) {
			upVoteClick();
		}
	}
  return (
    <>
      <Stack className='vote-container'>
        <TooltipHost content={"Provide positve feedback"} aria-label="Provide positve feedback">
          <Icon
            tabIndex={0}
            onClick={showFeedbackUIHandler}
            onKeyDown={handleKeyDownOnUpVote}
            iconName={selectedButton === "up" ? "LikeSolid" : "Like"}
            className='vote'
          />
        </TooltipHost>
      </Stack>
    </>
  );
};

export default UpVote;
