import * as React from 'react';

import { Stack, MessageBar } from '@fluentui/react';
import { displayMessageStyles } from '../Styles/ProjectCommonStyles';
import { IMessage } from '../../Models/IMessage';


export const DisplayMessage = (props: IMessage): JSX.Element => {
    const { messageType, accessabilityMessage, message } = props;
    const [showMessageBar, setShowMessageBar] = React.useState(true);
    const closeMessageBar = () => {
        setShowMessageBar(false)
    }
    return (
        <>
            {showMessageBar && (
                <div className="container" style={{ margin: "10px",maxWidth: "100vw" }}>
                    <Stack.Item styles={displayMessageStyles}>
                        <span role="alert" aria-label={message} aria-live="assertive" />
                        <MessageBar
                            messageBarType={messageType}
                            tabIndex={0}
                            onDismiss={() => closeMessageBar()}
                            dismissButtonAriaLabel="click on close icon to dismiss the message bar"
                            isMultiline={false}
                             
                        >
                            {message}
                        </MessageBar>
                    </Stack.Item>
                </div>
            )}
        </>
    );
};

export default DisplayMessage;