import React from "react";
import { createContext, useContext } from "react";
import { ILitigationCase, ILitigationCaseFiles } from "src/Models/ILitigationCase";

interface Filter {
    condition?: string;
    fieldKey: string;
    operator: string
    fieldValue: string | Date;
    groupName?: string;
    isChecked?: boolean;
    type?: string;
}
interface LitigationFilterContextType {
    advancefilters?: Filter[];
    searchQuery?: string;
    selectedFiles?: string[];
    searchBoxText?: string;
    selectedFolders?: string;
    selectedExportFolders?: string;
    selectView?: string,
    selectedCaseFiles?: ILitigationCaseFiles[];
    addAdvanceFilters: (filter: Filter[]) => void;
    addSearchQuery: (query: string) => void;
    addSelectedFiles: (files: string[]) => void;
    addSearchText: (searchText: string) => void;
    addSelectedFolders: (folders: string) => void;
    addSelectedExportFolders: (folders: string) => void;
    addSelectView:(view:string) => void;
    clearFilterContext: () => void;
    addSelectedCaseFiles: (files: ILitigationCaseFiles[]) => void;
}

// Create a context with an initial value
const LitigationFilterContext = createContext<LitigationFilterContextType>({
    advancefilters: [],
    searchQuery: '',
    selectedFiles: [],
    searchBoxText: '',
    selectedFolders: '',
    selectedExportFolders: '',
    selectView: '',
    selectedCaseFiles: [],
    addAdvanceFilters: ([]) => { },
    addSearchQuery: () => { },
    addSelectedFiles: ([]) => { },
    addSearchText: () => { },
    addSelectedFolders: () => { },
    addSelectedExportFolders: () => { },
    addSelectView:() => {},
    clearFilterContext: () => { },
    addSelectedCaseFiles: ([]) => { }
});

// Create a provider component
export const LitigationFilterProvider: React.FC = ({ children }) => {
    const [advancefilters, setAdvanceFilters] = React.useState<Filter[]>([{ condition: '', fieldKey: '', operator: '', fieldValue: '' }]);
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);
    const [selectedCaseFiles, setSelectedCaseFiles] = React.useState<ILitigationCaseFiles[]>([]);
    const [searchBoxText, setSearchText] = React.useState<string>('');
    const [selectedFolders, setSelectedFolders] = React.useState<string>('');
    const [selectedExportFolders, setSelectedExportFolders] = React.useState<string>('');
    const [selectView, setSelectView] = React.useState<string>('');

    const addAdvanceFilters = (filters: Filter[]) => {
        setAdvanceFilters(filters);
    };
    const addSearchQuery = (query: string) => {
        setSearchQuery(query);
    };
    const addSelectedFiles = (files: string[]) => {
        setSelectedFiles(files);
    };
    const addSelectedCaseFiles = (files: ILitigationCaseFiles[]) => {
        setSelectedCaseFiles(files);
    };
    const addSearchText = (searchText: string) => {
        setSearchText(searchText);
    };

    const addSelectView = (selectView: string) => {
        setSelectView(selectView);
    };
    const addSelectedFolders = (exportIDS: string) => {
        setSelectedFolders(exportIDS);
    };
    const addSelectedExportFolders = (exportIDS: string) => {
        setSelectedExportFolders(exportIDS);
    };
    const clearFilterContext = () => {
        setAdvanceFilters([]);
        setSearchQuery('');
        setSelectedFiles([]);
        setSearchText('');
        setSelectedFolders('');
        setSelectedExportFolders('');
        setSelectedCaseFiles([]);
    };

    // Provide the context value to its children
    return (
        <LitigationFilterContext.Provider value={{
            advancefilters, searchQuery, selectedFiles, searchBoxText, selectedFolders, selectedExportFolders,selectView, selectedCaseFiles,
            addAdvanceFilters, addSearchQuery, addSelectedFiles, addSearchText, addSelectedFolders,addSelectedExportFolders, addSelectView,clearFilterContext, addSelectedCaseFiles
        }}>
            {children}
        </LitigationFilterContext.Provider>
    );
};

// Custom hook to consume the context
export const useAppContext = () => useContext(LitigationFilterContext);