import { IStackStyles } from "@fluentui/react";

export const SystemPromptsContainerStyles: IStackStyles = {
  root: {
    display: "flex",
    
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "8px",
    marginLeft: 53,
    marginRight: 20,
    marginTop: 5,
    marginBottom: -8
  },
};
export const SystemPromptsErrorContainerStyles: IStackStyles = {
  root: {
    display: "flex",
    width: "288px",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "8px",
    marginLeft: "2px",
    marginRight: 0,
    marginTop: 5,
    marginBottom: 5
  },
};

export const SystemPromptStyles: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    flexWrap: "wrap",
    marginLeft: "auto",
  },
};

export const ViewPromptContainerStyles: IStackStyles = {
  root: { display: "flex", padding: "2px 8px", justifyContent: "center", alignItems: "center", gap: "4px" },
};
export const ViewPromptTextStyles: IStackStyles = {
  root: {
    color: "var(--neutral-foreground-2-rest, #424242)",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
};
export const SvgStyles: IStackStyles = {
  root: { width: 20, height: 20 },
};

export const IndividualPromptsContainerStyles: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    flexWrap: "wrap",
    marginTop: 5,
    marginBottom: 5
  },
};
