export const mainContainer: React.CSSProperties = {
  marginLeft: "12px",
  
  background: 'radial-gradient(92.52% 116.46% at 71.65% -79.80%, rgba(225, 105, 184, 0.20) 0%, rgba(101, 129, 223, 0.00) 100%), #F5F5F5',
  borderTop:"0.3px solid #00000021",
  height: "auto",
  minHeight: "100vh",
  paddingRight:25
}
export const tileContainer: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between", // Add this property to distribute tiles evenly
  
}
export const projectsDeck: React.CSSProperties = 
{
  flex: "0 0 calc(33.33% - 20px)", /* Adjust the width and spacing as needed */
  minWidth: "350px", /* Specify the minimum width for each tile */
  //maxWidth: "calc(33.33% - 20px)", /* Specify the maximum width for each tile */
  height: 154,
  borderRadius: 4,
  background: "#FFFFFF",
  marginTop:10,
  padding:10,
  boxShadow: "0px 5.2px 5.6px rgba(0, 0, 0, 0.13), 0px 10.4px 14.4px rgba(0, 0, 0, 0.07)",
  marginLeft: 10
  
}

export const greetingContainer: React.CSSProperties = 
{
  display:'flex',
  justifyContent: 'space-between',
  alignItems:'center',
  width: "100%",
  padding: '10px 0 10px 10px',
  
}
export const greetingText: React.CSSProperties = 
{
  fontSize: 20,
  fontWeight:600,
  marginRight: 20
}
export const createProjectBtn: React.CSSProperties = 
{
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  fontSize: 16,
  cursor: 'pointer',
  borderRadius: '5px',
  marginLeft: 'auto'
}
export const dueDateDeck: React.CSSProperties = 
{
  flex: "0 0 calc(33.33% - 20px)", /* Adjust the width and spacing as needed */
  minWidth: "350px", /* Specify the minimum width for each tile */
  //maxWidth: "calc(33.33% - 20px)", /* Specify the maximum width for each tile */
  height:283,
  borderRadius:4,
  background: "#FFFFFF",
  marginTop:10,
  padding:10,
  
  boxShadow: "0px 5.2px 5.6px rgba(0, 0, 0, 0.13), 0px 10.4px 14.4px rgba(0, 0, 0, 0.07)",
  marginRight:10
}

export const notificationDeck: React.CSSProperties = 
{
  flex: "0 0 calc(33.33% - 20px)", /* Adjust the width and spacing as needed */
  minWidth: "350px", /* Specify the minimum width for each tile */
  //maxWidth: "calc(33.33% - 20px)", /* Specify the maximum width for each tile */
  height:283,
  borderRadius:4,
  background: "#FFFFFF",
  marginTop:10,
  padding:10,
 
  boxShadow: "0px 5.2px 5.6px rgba(0, 0, 0, 0.13), 0px 10.4px 14.4px rgba(0, 0, 0, 0.07)",
}
