import * as React from "react";
import { useHistory } from "react-router-dom";
import VerticalMenu from "./Shared/VerticalMenu";
import { Stack, INavLinkGroup, IStackTokens, Icon } from "@fluentui/react";
import { collapsed, createButtonStyle, createButtonText, disabledButtonStyle, expanded, iconCollpased, iconExpanded } from "./Styles/BaseContainerStyles";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { Reducer } from 'redux'
import { IProjectContext } from "../../Models/IProjectContext";
import { createNewProject, requestProjectListDetailV2, setProjectContext, setProjectListContext } from "../../Shared/Actions/Project.action";
import { IProjectSearchRequest } from "../../Models/IProjectSearchRequest";
import { IProjectAppState } from "../../Shared/ProjectState";
import { ProjectListFilter } from "src/Helpers/ProjectEnum";
import { useCreateNavContext } from "./Project/CreateProject/CreateNavContext/CreateNavContext";
interface ISidePanelProps {
  selectedKey: string;
}
export type SidePanelProps = ISidePanelProps;

const SidePanel: React.FunctionComponent<SidePanelProps> = (props) => {
  const { mainSidePanel, updateMainSidePanel } = useCreateNavContext();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector, dispatch } = reduxContext;
  const {

    loginUserpersonaProps
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  let history = useHistory();
  const { selectedKey } = props;

  const [expandProjects, updateExpandProjects] = React.useState(false);

  // Expand Projects menu, only if any of its sub-menus are selected
  React.useEffect(() => {
    const projectsChildren = ["history", "bookmarks", "shared"];
    if (projectsChildren.includes(selectedKey)) {
      updateExpandProjects(true);
    } else {
      updateExpandProjects(false);
    }
  })

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: "Dashboard",
          key: "dashboard",
          ariaLabel: "Dashboard menu",
          icon: "Home",
          url: "/",
          onClick: (e) => {
            e.preventDefault();
            const prjContext: IProjectContext = {
              number: ''
            }
            dispatch(createNewProject())
            goTo("/");
          },
          iconProps: {
            iconName: 'Home',
            style: { marginLeft: '24px' }, // Adjust as needed for the icon's margin
          },
        },
        {
          name: "Projects",
          isExpanded: expandProjects,
          key: "projects",
          expandAriaLabel: "Projects menu expanded",
          collapseAriaLabel: "Projects menu collapsed",
          icon: "FabricFolder",
          url: "/",
          iconProps: {
            iconName: 'FabricFolder',
            style: { marginLeft: '24px' }, // Adjust as needed for the icon's margin
          },
          onClick: (e) => {
            e.preventDefault();
            const projectSearchRequest: IProjectSearchRequest = { showStatusCountList: false, owner: loginUserpersonaProps?.secondaryText };
            dispatch(setProjectListContext(projectSearchRequest));
            if (history.location.pathname === "/projects") {
              var searchFilter = { ...projectSearchRequest, owner: loginUserpersonaProps?.secondaryText, showStatusCountList: false };
              dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, searchFilter));
            }
            else {
              goTo("/projects")
            }

          },
          links: [
            {
              name: "History",
              key: "history",
              ariaLabel: "History menu",
              icon: "History",
              url: "/history",
              iconProps: {
                iconName: 'History',
                style: { marginLeft: '28px' }, // Adjust as needed for the icon's margin
              },
              onClick: (e) => {
                e.preventDefault();
                goTo("/history")
              }
            },
            {
              name: "Bookmarks",
              key: "bookmarks",
              ariaLabel: "Bookmarks menu",
              icon: "SingleBookmark",
              url: "/bookmarks",
              iconProps: {
                iconName: 'SingleBookmark',
                style: { marginLeft: '28px' }, // Adjust as needed for the icon's margin
              },
              onClick: (e) => {
                e.preventDefault();
                goTo("/bookmarks")
              }
            },
            // {
            //   disabled: true,
            //   name: "Shared",
            //   key: "shared",
            //   ariaLabel: "Shared menu",
            //   icon: "Share",
            //   url: "/",
            //   onClick: (e) => {} // Invoke Shared page
            // }
          ]
        }
        // {
        //   disabled: true,
        //   name: "CELA Ask",
        //   key: "celaask",
        //   ariaLabel: "Celaask menu",
        //   icon: "Help",
        //   url: "/",
        //   onClick: (e) => {} // TBD
        // }

      ]
    }
  ]

  const sidePanelTokens: IStackTokens = { childrenGap: 24 };

  const goTo = (relativePath: string) => {
    history.push(relativePath);
  }


  const [isCollapsed, setCollapsed] = React.useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(!isCollapsed);
    updateMainSidePanel(!isCollapsed);
  };

  const isMicrosoftUser = loginUserpersonaProps?.secondaryText?.includes("microsoft.com");
  const createButtonComponent = (
    <Stack>
      <button
        id="side-panel-create-btn"
        onClick={(e) => {
          e.preventDefault();
          const prjContext: IProjectContext = {
            number: ''
          }
          dispatch(createNewProject())
          goTo("/createproject")
        }}
        style={isMicrosoftUser ? createButtonStyle :disabledButtonStyle}
        disabled={!isMicrosoftUser}
      >
        <div style={createButtonText} aria-label="Create a Project">Create a project</div>
      </button>
    </Stack>
  );

  const handleHomeClick = (e) => {
    e.preventDefault(); // Prevent the default action of the event
    const prjContext: IProjectContext = {
      number: '',
    };
    dispatch(createNewProject()); // Dispatch the action to create a new project
    history.push('/'); // Navigate to the root path
  };

  const handleProjectClick = (e) => {
    e.preventDefault();
    const projectSearchRequest: IProjectSearchRequest = { showStatusCountList: false, owner: loginUserpersonaProps?.secondaryText };
    dispatch(setProjectListContext(projectSearchRequest));
    if (history.location.pathname === "/projects") {
      var searchFilter = { ...projectSearchRequest, owner: loginUserpersonaProps?.secondaryText, showStatusCountList: false };
      dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, searchFilter));
    }
    else {
      goTo("/projects")
    }
  }





  return (
    <Stack id="sidePanel" tokens={sidePanelTokens} style={isCollapsed ? collapsed : expanded}>

      <Stack horizontal>
        <Stack>{createButtonComponent}</Stack>
        <Stack onClick={handleToggleCollapse} style={isCollapsed ? iconCollpased : iconExpanded}>
          {/* Insert your hamburger menu icon here */}
          <Icon
            onClick={handleToggleCollapse}
            iconName="CollapseMenu"
            style={{
              color: "rgb(79, 82, 178)",
              fontSize: 18,
              cursor: "pointer",
            }}
          />
        </Stack>



      </Stack>

      <Icon

        onClick={(e) => {
          e.preventDefault();
          const prjContext: IProjectContext = {
            number: ''
          }
          dispatch(createNewProject())
          goTo("/createproject")
        }}
        title="Create project"
        iconName="FabricNewFolder"
        style={{
          color: "rgb(79, 82, 178)",
          fontSize: 18,
          cursor: "pointer",
          display: isCollapsed ? "block" : "none",
          marginLeft: 170
        }}

      />

      <Icon

        onClick={handleHomeClick}
        title="Dashboard"
        iconName="Home"
        style={{
          color: "rgb(79, 82, 178)",
          fontSize: 18,
          cursor: "pointer",
          display: isCollapsed ? "block" : "none",
          marginLeft: 170
        }}

      />
      <Icon
        onClick={handleProjectClick}
        iconName="FabricFolder"
        title="Projects"
        style={{
          color: "rgb(79, 82, 178)",
          fontSize: 18,
          cursor: "pointer",
          display: isCollapsed ? "block" : "none",
          marginLeft: 170
        }}
      />

      <VerticalMenu
        navGroups={navLinkGroups}
        selectedKey={selectedKey}
      />
    </Stack>
  )
}

export default SidePanel;