import React from 'react'
import { Checkbox, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IBasePickerSuggestionsProps, IChoiceGroupOption, Icon, IPersonaProps, ITag, MessageBarType, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import Button from "../Helpers/Button/Button";
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import { useHistory } from "react-router-dom";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../../src/Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../../src/Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../../src/Shared/ProjectState";
import { assingPadding, colorred, formText, textFieldStyles } from '../../../../../../src/Components/V2/Styles/CreateProject';

export default function OutsideCounsel() {
  let history = useHistory();

  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);

  const { useSelector, dispatch } = reduxContext;

  const {
    projectCreate,
    isProjectGettingCreated,
    projectCreationSuccess,
    projectCreateError,
    projectCreationFailure,
    projectCreateRedirect,
    projectContext,
    projectFiles,
    projectFileTags,
    projectProps,
    projectOwner,
    projectMembers,
    deletedProjectMembers,
    projectTags,
    isProjectGettingEdited,
    projectEditSuccess,
    projectEditRedirect,
    projectEditFailure,
    projectEditError,
    loginUserpersonaProps,
    isGettingProjectWorkArea,
    isProjectWorkAreaSuccess,
    isProjectWorkAreaError,
    projectWorkAreaCollection,
    lawFirmAdaptiveDetails,
    isLoading,
    hasError

  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  const { updateNavItem } = useCreateNavContext();
  const [counselList, setCounselList] = React.useState([]);
  const [counselFirmList, setCounselFirmList] = React.useState([]);
  const [showCustomFields, setShowCustomFields] = React.useState(false);
  const [customCounselName, setCustomCounselName] = React.useState('');
  const [customCounselEmail, setCustomCounselEmail] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState(0);
  
  const handleContinueClick = () => {
    updateNavItem(['fileAttachment','firm', 'outside', 'finance'], 'finance')
    history.push('/createproject?componentName=finance')
  }
  const handleCancelClick = () => {
    //clear the state
    history.push('/')
  }

  const counselInfoOptions: IChoiceGroupOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' }
  ];

  React.useEffect(() => {
    const defaultCounsel = [
      { name: 'John Doe', email: 'john.doe@example.com' },
      { name: 'Jane Smith', email: 'jane.smith@example.com' },
      { name: 'Bob Johnson', email: 'bob.johnson@example.com' },
    ];
    setCounselList(defaultCounsel);
  }, []);

  const firms = [
    {
      initials: '1SS',
      name: '10 Square Solutions',
      ratePerHour: '$50 / hr',
      rating: Array.from({ length: 5 }, (_, i) => (
        <span key={i} className={i < 0 ? 'filled-star' : 'empty-star'}>&#9733;</span>
      )),
    },
    {
      initials: '1C',
      name: '110 Consulting',
      ratePerHour: '$50 / hr',
      rating: Array.from({ length: 5 }, (_, i) => (
        <span key={i} className={i < 0 ? 'filled-star' : 'empty-star'}>&#9733;</span>
      )),
    },
    // Add more firms as needed
  ];

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleAddCustomCounsel = () => {
    // Add the custom counsel to the list
    const newCounselList = [...counselList, { name: customCounselName, email: customCounselEmail }];
    setCounselList(newCounselList);

    // Close the custom fields
    setShowCustomFields(false);

    // Clear the input fields
    setCustomCounselName('');
    setCustomCounselEmail('');
  };

  const handleCancelCustomCounsel = () => {
    // Close the custom fields
    setShowCustomFields(false);

    // Clear the input fields
    setCustomCounselName('');
    setCustomCounselEmail('');
  };


  return (
    <div style={{ marginLeft: 20 }} className="pageContainer">

      <div className="contentContainer">
        <Stack tokens={{ childrenGap: 10 }}>
          <div>
            <h2 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#242424' }}>
              Outside counsel detail
            </h2>
            <p style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644', marginTop: 15 }}>
              Fill out additional info.
            </p>
          </div>

        </Stack>

        <Stack horizontal style={{ marginTop: 20 }}>
          <Stack style={{ width: 289, borderRight: '1px solid #E0E0E0', height: '100vh' }}>
           
            {firms.map((firm, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  paddingLeft: 10,
                  borderLeft: activeIndex === index ? '1px solid #5B5FC7' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleItemClick(index)}
              >
                <div className="initials-circle">{firm.initials}</div>
                <div className="firm-details">
                  <div className="firm-name">
                    <TooltipHost content={firm.name}>
                      {firm.name}
                    </TooltipHost>
                  </div>
                  <div className="rate-per-hour">{firm.ratePerHour}</div>
                  <div className="firm-rating">
                    Rating: {firm.rating}
                  </div>
                </div>
              </div>
            ))}
          </Stack>
          <Stack>
            <Stack style={assingPadding}>
              <div style={formText}>
                Budget Amount (USD) <span style={colorred}>*</span>
              </div>
              <div>
                <TextField
                  name="budget"
                  placeholder="Enter budget"
                  // value={title}
                  // onChange={onChangeTitleValue}
                  styles={textFieldStyles}

                />
              </div>
            </Stack>
            <Stack style={assingPadding}>
              <div style={formText}>
                Safe approver <span style={colorred}>*</span>
              </div>
              <div>
                <TextField
                  name="approver"

                  // value={title}
                  // onChange={onChangeTitleValue}
                  styles={textFieldStyles}

                />
              </div>
            </Stack>
            <Stack style={assingPadding}>
              <div style={formText}>Will this firm handle personal data while providing services on this?</div>
              <Stack>
                <ChoiceGroup options={counselInfoOptions} />
              </Stack>
            </Stack>
            <Stack style={assingPadding}>
              <div style={{ ...formText, fontWeight: '600' }}>Choose outside counsel</div>
              <Stack>
                {counselList.map((counsel, index) => (
                  <Stack key={index} horizontal verticalAlign="center" style={{ marginTop: 15 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        styles={{
                          root: {
                            width: 16,
                            height: 16
                          },
                          checkbox: {
                            width: 16,
                            height: 16
                          }
                        }}
                      />
                      <div style={{ width: 32, height: 32, borderRadius: '50%', background: '#0078d4', color: 'white', textAlign: 'center', lineHeight: '32px', marginLeft: 8 }}>
                        {counsel.name
                          .split(' ')
                          .map((word) => word.charAt(0).toUpperCase())
                          .join('')}
                      </div>
                    </div>
                    <span style={{ marginLeft: 8 }}>{counsel.name}</span>
                  </Stack>


                ))}

                <Stack>
                  <div style={{ cursor: 'pointer', fontWeight: '600', marginTop: 15 }} onClick={() => setShowCustomFields(true)}>
                    + Add custom outside counsel
                  </div>
                  {showCustomFields && (
                    <Stack>
                      <Stack style={assingPadding}>
                        <div style={formText}>
                          Name <span style={colorred}>*</span>
                        </div>
                        <div>
                          <TextField

                            value={customCounselName}
                            onChange={(event, newValue) => setCustomCounselName(newValue)}
                            styles={textFieldStyles}

                          />
                        </div>
                      </Stack>


                      <Stack style={assingPadding}>
                        <div style={formText}>
                          Email <span style={colorred}>*</span>
                        </div>
                        <div>
                          <TextField

                            value={customCounselEmail}
                            onChange={(event, newValue) => setCustomCounselEmail(newValue)}
                            styles={textFieldStyles}

                          />
                        </div>
                      </Stack>


                      <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 15 }}>
                        <PrimaryButton text="Add" onClick={handleAddCustomCounsel} />
                        <DefaultButton text="Cancel" onClick={handleCancelCustomCounsel} />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

        </Stack>
      </div>
      {/* Buttons at the bottom */}
      <div className="buttonContainer">
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 'auto' }}>
          <Button type='primary' onClick={handleContinueClick}>
            Continue
          </Button>
          <Button type='cancel' onClick={handleCancelClick}>
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
  )
}
