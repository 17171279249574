import { Stack, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { Reducer } from "redux";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import { dueDateTitle, dueDateText, container, detailsText, dateText, seeDueDates, seeDueDatesText, stackStyle, stackStyle1, dueDateTextWidth, dateTextWidth, nodueDateText } from "./Styles/DueDate";
import { IProjectProps } from "../../../Models/IProjectProps";
import { getFormattedDate, getFutureDate, truncateText } from "../../../Helpers/ProjectHelper";
import { requestProjectDueDate, setProjectListContext } from "../../../Shared/Actions/Project.action";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { IProjectSearchRequest } from "../../../Models/IProjectSearchRequest";
import { useHistory } from "react-router-dom";
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
import { pages } from "@microsoft/teams-js"; 
const DueDate = () => {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectDueDateResponse,
        isDueDateResultsLoading,
        isDueDateResultsLoadingError,
        projectDueDateError,
        loginUserpersonaProps,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    //State for Start and End Date
    const [endDate, setEndDate] = React.useState("");

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            setEndDate(getFutureDate(7));
            dispatch(requestProjectDueDate(loginUserpersonaProps?.optionalText, getFutureDate(7)));
        }
    }, [loginUserpersonaProps]);

    function openProjectList(statusType: string): void {
        const projectSearchRequest: IProjectSearchRequest = { status: "Open", startDate: "", endDate: endDate, owner: "" };
        
        if(pages.currentApp.isSupported) {
            const tabNavigation:ITabNavigation= {
                isNewProjectCreation: false, 
                isFromDashboard: true, 
                projectSearchRequest: projectSearchRequest, 
                componentName:'/projectlist'}
            const navPromise = pages.navigateToApp({appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectslist', subPageId:JSON.stringify(tabNavigation)});
            navPromise.then(()=>{
                console.log('Navigated to project list page')
            }).catch((error)=>{
                console.log('Error occurred when navigating to project list page. So defaulting to history api')
                dispatch(setProjectListContext(projectSearchRequest));
                history.push("/projectlist");
            })
        }else{
            dispatch(setProjectListContext(projectSearchRequest));
            history.push("/projectlist");
        }
        
    }

    function openProjectURL(e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string): void {
        if(pages.currentApp.isSupported) {
            const tabNavigation: ITabNavigation = { 
                isNewProjectCreation: false, 
                isFromDashboard: true, 
                componentName:'/viewproject', 
                projectNumber:projectNumber, 
                projectSearchRequest:{} };
            const navPromise = pages.navigateToApp({appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectslist', subPageId:JSON.stringify(tabNavigation)});
            navPromise.then(()=>{
                console.log('Navigated to project list page')
            }).catch((error)=>{                
                history.push(`/viewproject?projectNumber=${projectNumber}&backToProjectList=false`);
            })
        }else{            
            history.push(`/viewproject?projectNumber=${projectNumber}&backToProjectList=false`);
        } 
    }

    return (
        <Stack style={container}>
            <Stack>
                <div style={dueDateTitle}>
                    Due dates
                </div>
            </Stack>
            {isDueDateResultsLoading && (
                <Stack style={{marginTop: 50}}>
                    <span role="alert" aria-label={GenericMessages.dueDateProjectsLoading} aria-live="assertive" />
                    
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.dueDateProjectsLoading}
                        spinnerText={GenericMessages.dueDateProjectsLoading}
                        spinnerPosition={"right"}
                    />
                   </Stack>
            )}
            {!isDueDateResultsLoading && isDueDateResultsLoadingError && (
                <>
                    <ErrorDisplay source={projectDueDateError?.errorMessage} />
                </>
            )}
            
            {!isDueDateResultsLoading && !isDueDateResultsLoadingError && projectDueDateResponse && projectDueDateResponse?.responses?.length > 0 && (
                <>
                    <Stack style={{height:170}}>
                        {projectDueDateResponse.responses.map((response: IProjectProps, index: number) => {
                            return (

                                <Stack horizontal style={stackStyle}>
                                    <Stack style={dueDateTextWidth}>
                                        <div style={dueDateText}>
                                            <TooltipHost 
                                            content={response?.title} 
                                            aria-label={response?.title}
                                            tabIndex={0}
                                            >
                                                {truncateText(response?.title, 40, 40)}
                                            </TooltipHost>
                                        </div>
                                    </Stack>

                                    <Stack style={dateTextWidth}>
                                        {response?.endDate && (
                                            <div style={dateText}>
                                                    {getFormattedDate(response.endDate)}
                                            </div>
                                        )}
                                    </Stack>

                                    <Stack style={stackStyle1}>
                                        <div style={detailsText} onClick={(e) => openProjectURL(e, response.number)} tabIndex={0}>
                                            Details
                                        </div>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Stack style={seeDueDates}>
                        <div style={seeDueDatesText} tabIndex={0}>
                            <Stack onClick={(e) => openProjectList("Open")} >See all due dates</Stack>
                        </div>
                    </Stack>
                </>
            )}
            {!isDueDateResultsLoadingError && projectDueDateResponse?.responses?.length === 0 && (
                <>
                    <Stack>
                        <div style={nodueDateText}>
                            {"No projects are due"}
                        </div>

                    </Stack>
                </>
            )}


        </Stack>
    )
}

export default DueDate;