export const chatActions = [
  "RequestSystemPromptsAction",
  "RequestUserBookmarksAction",
  "RequestChatLogsAction",
  "DeleteChatTopicAction",
  "EditChatTopicAction",
  "RequestCreateBookmarkAction",
  "RequestDeleteBookmarkAction",
  "RequestNewAnswerAction",
  "RequestChatLogAction",
  "SubmitFeebackRequestAction",
  "RequestAttorneyDetailsAction",
  "RequestBookmarkTopicByAnswerIdAction"
] as const;

type ChatActionIds = typeof chatActions[number];


export const ChatActions: Record<ChatActionIds, string> = {
  RequestSystemPromptsAction: "RequestSystemPrompts_Action",
  RequestUserBookmarksAction:"RequestUserBookmarks_Action",
  RequestChatLogsAction:"RequestChatLogs_Action",
  DeleteChatTopicAction:"DeleteChatTopic_Action",
  EditChatTopicAction:"EditChatTopic_Action",
  RequestCreateBookmarkAction:"RequestCreateBookmark_Action",
  RequestDeleteBookmarkAction:"RequestDeleteBookmark_Action",
  RequestNewAnswerAction : "RequestNewAnswer_Action",
  RequestChatLogAction: "RequestChatLog_Action",
  SubmitFeebackRequestAction:"SubmitFeebackRequest_Action",
  RequestAttorneyDetailsAction : "RequestAttorneyDetails_Action",
  RequestBookmarkTopicByAnswerIdAction:"RequestBookmarkTopicByAnswerId_Action"
};