import * as React from "react";
import SidePanel from "./SidePanel";
import { Icon, Panel, Stack } from "@fluentui/react";
import { baseContainerStyle, mainComponentStyle } from "./Styles/BaseContainerStyles";
import NotificationPanel from "./Notification/NotificationPanel";
import { useBoolean } from '@fluentui/react-hooks';
import { useState } from "react";
import { Reducer } from "redux";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { IProjectAppState } from "../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { IUserNotificationResponse } from "../../Models/UserNotification/IUserNotificationResponse";
import { CreateNavProvider } from "./Project/CreateProject/CreateNavContext/CreateNavContext";
interface IBaseContainerProps {
  sidePanelSelection?: string
  mainComponent?: JSX.Element;
  shouldShowSidePanel?: boolean
}
export type BaseContainerProps = IBaseContainerProps;
export const ProjectContext = React.createContext(null);
const BaseContainer: React.FunctionComponent<BaseContainerProps> = (props) => {

  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector, dispatch } = reduxContext;
  const {
    userNotifications
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

  const { sidePanelSelection, mainComponent, shouldShowSidePanel } = props;
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [notificationCount, setNotificationCount] = useState<number>(0)
  const [isUserHasNotifications, setIsUserHasNotifications] = useState<boolean>(false)
  React.useEffect(() => {
    if (userNotifications?.totalRecords > 0) {
      const notificationsUnread = userNotifications?.userNotificationResponseList?.reduce((count, curr: IUserNotificationResponse) => { return !curr.isRead ? count + 1 : count }, 0)
      setNotificationCount(notificationsUnread)
    }
    else {
      setNotificationCount(0)
    }
    setIsUserHasNotifications(true)
  }, [userNotifications])
  return (
    <CreateNavProvider>
      <Stack style={baseContainerStyle} >
        <Stack>
          <div style={{ marginLeft: 'auto', marginRight: "30px", marginTop: 16 }}>
            <Stack style={{ position: 'relative', display: 'inline-block' }}>
              <Icon iconName="Ringer" onClick={openPanel} style={{ cursor: 'pointer', fontSize: 20 }} tabIndex={0} />
              {isUserHasNotifications && (
                <Stack style={{
                  position: 'absolute',
                  top: -16,
                  right: -11,
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '50%',
                  //padding: '4px 8px',
                  fontSize: '12px',
                  width: 20,
                  height: 20,
                  textAlign: 'center'
                }}>{notificationCount}</Stack>)}
            </Stack>
            <ProjectContext.Provider value={{ dismissPanel }}>
              <Panel
                headerText="Notifications"
                isOpen={isOpen}
                onDismiss={dismissPanel}
                closeButtonAriaLabel="Close"
              >
                <NotificationPanel />
              </Panel>
            </ProjectContext.Provider>
          </div>
        </Stack>
        <Stack horizontal>
          {sidePanelSelection && (
            <Stack>
              <SidePanel selectedKey={sidePanelSelection} />
            </Stack>
          )}

          <Stack style={mainComponentStyle}>
            <Stack>
              {mainComponent}
            </Stack>
          </Stack>
          <div></div>
        </Stack>
      </Stack>
    </CreateNavProvider>
  )
}

export default BaseContainer;