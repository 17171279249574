import React, { useState, useEffect, useRef } from 'react'
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { isNewTopicRequested,  requestGetChatTopic,  requestNewAnswer, setTopicInContext } from '../../../../Shared/Actions/Chat.action';
import { Stack } from '@fluentui/react';
import ProgressBar from '../../../../Common/Components/ProgressBar/ProgressBar';
import './ChatConversations.styles.css'
import { IChatTopicRequest } from '../../../../Models/Chat/IChatTopicRequest';
import { v4 as uuidv4 } from 'uuid';
import { IChatTopicResponse } from '../../../../Models/Chat/IChatTopicResponse';
import ChatConversation from './ChatConversation';
import { ITopic, ITopicResponses } from '../../../../Models/Chat/ITopic';
import { IQuestionRequest, IQuestion } from '../../../../Models/Chat/IQuestion';
import ErrorDisplay from '../../../../Helpers/ErrorDisplay/ErrorDisplay';
import { useLocation } from 'react-router-dom';
import useChatAIToken from '../../../Hooks/useChatAIToken';
import { LitigationContext } from "../ChatHome";
import { ILitigationCase } from 'src/Models/ILitigationCase';

export const ChatProviderContext = React.createContext(null);
const ChatConversations = () => {
  const { litCases, filter, projectNumber, user } = React.useContext(LitigationContext);
  const { isChatAITokenLoading, chatAIToken } = useChatAIToken();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const {topicUser, newUserPrompt, topicIdInContext, isRequestingNewAnswer, loginUserpersonaProps, isNewTopic, chatTopicResponses, topic, isResponseNewAnswerError, responseNewAnswerError, shouldRetryUserPrompt, isRequestingChatTopic, isRequestingChatTopicError, chatTopicResponseError } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);

  const [topicGuid, setTopicGuid] = useState<string>('');
  const [topicResponses, setTopicResponses] = useState<ITopicResponses>({ chatTopicResponses: [] });
  const mainRefContainer = useRef(null);
  const myRequestingMessage = useRef(null)

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const locationPath: string = queryParams.get('currentLocation')
  
  

  useEffect(() => {
    if (newUserPrompt && newUserPrompt.length > 0) {      
      submitQuestion();
    }
  }, [newUserPrompt]);

  useEffect(() => {
    if (shouldRetryUserPrompt) {
      submitQuestion();
      //dispatch(retryUserPrompt(false));
    }
  }, [shouldRetryUserPrompt]);

  //Existing btopic in context
  useEffect(() => {
    if (topicIdInContext?.length > 0 && topicIdInContext !== 'reset') {
      const userChatLogRequest: IChatTopicRequest = {
        topicId: topicIdInContext,
        userAlias: topicUser,
        projectNumber: projectNumber
      };
      setTopicResponses({})
      dispatch(requestGetChatTopic(userChatLogRequest));
    }
  }, [topicIdInContext]);

  // useEffect(()=>{
  //   if(topicResponses?.topic){
  //     setCurrentTopicUser(topicResponses?.topic?.user)
  //   }
  // },[topicResponses])

  //For creating new topic
  useEffect(() => {
    if (isNewTopic) {
      const mesageGuid = uuidv4();
      setTopicGuid(mesageGuid);
      setTopicResponses({ chatTopicResponses: [] });
      dispatch(isNewTopicRequested(false));
    }
  }, [isNewTopic]);

  useEffect(() => {
    if (chatTopicResponses && Object.keys(chatTopicResponses).length > 0 && topicIdInContext) {
      setTopicResponses(chatTopicResponses);      
      dispatch(setTopicInContext('reset', ''))
    }
    else  if (chatTopicResponses && Object.keys(chatTopicResponses).length > 0) {
      setTopicResponses(chatTopicResponses);
    }

  }, [chatTopicResponses, topicIdInContext]);

 

  useEffect(() => {
    if ((topic && Object.keys(topic).length > 0) || isResponseNewAnswerError) {
      let newChatAnswer: ITopic = {
        user: 'gpt',
        isNewQuestion: false,
        questions: topic.questions,
        isNewAnswer: true,
        topicId: topic?.topicId,
        topicName: topic?.topicName,
        
      };
      let newChatTopicResponses: ITopicResponses = Object.assign({}, topicResponses);
      newChatTopicResponses.chatTopicResponses = newChatTopicResponses?.chatTopicResponses?.concat(newChatAnswer);
      setTopicResponses(newChatTopicResponses);
    }

  }, [topic, isResponseNewAnswerError]);

  useEffect(() => {
    if (isRequestingNewAnswer) {
      if (myRequestingMessage && myRequestingMessage?.current) myRequestingMessage.current.scrollIntoView(false, { behavior: "smooth" });
    }
  }, [isRequestingNewAnswer]);
  const submitQuestion = () => {
    //reset isNewAnswer prop o false
    const existingQNAs: ITopicResponses = Object.assign({}, topicResponses);
    existingQNAs?.chatTopicResponses?.map((chatTopicResponse: IChatTopicResponse) => {
      chatTopicResponse.isNewAnswer = false;
    });
    setTopicResponses(existingQNAs);
    const qandAId: string = uuidv4();
    let tpGuid: string = '';
    if (topicIdInContext?.length === 0) {
      if (topicResponses && topicResponses?.chatTopicResponses?.length > 0 && topicResponses?.chatTopicResponses[0]?.topicId) {
        tpGuid = topicResponses?.chatTopicResponses[0]?.topicId;
      } else if (topicGuid) {
        tpGuid = topicGuid;
      } else {
        tpGuid = uuidv4();
      }
    } else {
      tpGuid = topicIdInContext;
    }   
    const newQuestionRequest: IQuestionRequest = {};
    let toipcLogs: string = '';
    chatTopicResponses?.chatTopicResponses?.map((topicResponse: IChatTopicResponse) => {
      if (topicResponse.user === 'me') {
        toipcLogs = toipcLogs + `Q:${topicResponse?.question}`;
      }
      if (topicResponse.user === 'gpt') {
        toipcLogs = toipcLogs + `A:${topicResponse?.answer}`;
      }
      toipcLogs = toipcLogs + '\n';
    });
    toipcLogs = toipcLogs + 'Q:' + newUserPrompt + '\n';
    newQuestionRequest.topicId = tpGuid;
    newQuestionRequest.newQuestionId = qandAId;
    newQuestionRequest.newQuestion = newUserPrompt;
    newQuestionRequest.userAlias = loginUserpersonaProps?.secondaryText;
    newQuestionRequest.locationPath = locationPath ?? '';
    newQuestionRequest.cases = litCases;
    newQuestionRequest.projectNumber = projectNumber
    var question: IQuestion = {
      qnaId: newQuestionRequest.newQuestionId,
      userQuestion: newQuestionRequest.newQuestion
    };

    

    
    let newChatQuestion: ITopic = {
      user: 'me',
      isNewQuestion: true,
      isNewAnswer: false,
      questions: [],
      topicId: tpGuid,
      topicName: newQuestionRequest.newQuestion      
    };
    newChatQuestion.questions.push(question);
    let newChatTopicResponses: ITopicResponses = Object.assign({}, topicResponses);
    newChatTopicResponses.chatTopicResponses = newChatTopicResponses?.chatTopicResponses?.concat(newChatQuestion);
    newChatTopicResponses.chatTopicResponses[0].topicId = tpGuid;
    newQuestionRequest.userAlias = user?.secondaryText
    setTopicResponses(newChatTopicResponses);
    dispatch(requestNewAnswer(newQuestionRequest));
  };



  return (
    <Stack className='chat-conversations-container'>
      <Stack className=''>
        <div ref={mainRefContainer}>
          <Stack className='q-a-container'>


            <Stack style={{ textAlign: 'left' }}>
              {topicResponses && topicResponses.chatTopicResponses && topicResponses.chatTopicResponses?.length > 0 && (
                <>
                  {topicResponses &&
                    topicResponses?.chatTopicResponses?.map((chatTopicLog: IChatTopicResponse, index: number) => {
                      return (
                        <>
                          <Stack key={index}>
                            <ChatProviderContext.Provider value={{ chatAIToken }}>
                              <ChatConversation key={index}
                                topicId={topicResponses?.topic?.topicId}
                                chatTopicResponse={chatTopicLog}
                                loginUserPersonaProps={loginUserpersonaProps}
                                rowNumber={index}                                
                                user={user}                                
                                isLastResponse={index === topicResponses?.chatTopicResponses.length - 1}
                                isResponseNewAnswerError={isResponseNewAnswerError}
                                responseNewAnswerError={responseNewAnswerError}
                                chatTopic={topicResponses?.topic}  
                                topicUser={topicUser?.length===0?topicResponses?.topic?.user:topicUser}                                                             
                              />
                            </ChatProviderContext.Provider>
                            {index === topicResponses?.chatTopicResponses.length - 1 && isResponseNewAnswerError && (
                              <Stack style={{ marginLeft: 20, marginTop: -55 }}>
                                <ErrorDisplay error={responseNewAnswerError} />
                              </Stack>
                            )}
                          </Stack>
                        </>
                      );
                    })}
                </>
              )}
            </Stack>

            {topicIdInContext && !isRequestingChatTopic && isRequestingChatTopicError && (
              <>
                <ErrorDisplay error={chatTopicResponseError} />
              </>
            )}
            {topicIdInContext && isRequestingChatTopic && !isRequestingChatTopicError && (
              <>
                <ProgressBar progressbarText='Loading previous chat response...' progressbarStyle='progress-container-main' />
              </>
            )}
            {/* <Stack>
                <ProgressBar progressbarText='Generating response...' progressbarStyle='progress-container-main' />
              </Stack> */}
            {isRequestingNewAnswer && (
              <Stack>
                <ProgressBar progressbarText='Generating response...' progressbarStyle='progress-container-main' />
              </Stack>
            )}
            <div ref={myRequestingMessage} className='ref-request-message'></div>
          </Stack>
        </div>
      </Stack>
    </Stack>
  )
}


export default ChatConversations