export const container: React.CSSProperties =
{
}

export const notificationTitle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "#000000",
    marginTop: "15px",
    marginLeft: "10px",
}

export const notificationTitleMsg: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    marginTop: "15px",
    marginLeft: "10px",
}

export const notificationText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#616161",
    marginTop: "10px"    
}

export const notificationTextBold: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#252525",
    marginTop: "10px"    
}

export const detailsText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px"
}
//
export const allNotificationsDetails: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",        
    cursor:'pointer'
}
export const seeNotifications: React.CSSProperties =
{
    marginLeft: "10px",
}

export const seeNotificationsText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    marginTop: "5%",
    marginBottom: "-2%",
    cursor:'pointer'
}

export const iconStyle: React.CSSProperties =
{
    width: "16px",
    height: "16px",
    marginTop: "20px",
    color: "#000000",
}

export const stackStyle: React.CSSProperties =
{
    marginLeft: 12,
    marginTop: 5,
  
}

export const notificationTextWidth: React.CSSProperties =
{
    width: "80%" ,
    marginLeft:10
}
export const allNotificationsContainerStyle: React.CSSProperties =
{
  marginLeft: 30
}
export const PaginationContainer = styled.div`
  margin-top: 20;
  margin-left: 500;
  width: 300px;
  box-sizing: border-box;
  color: #ffffff;
  border-radius: 2px;
`;

export const notificationColumnClickableCell: React.CSSProperties =
{
  color: "#242424",
  fontSize: "14px",
  fontFamily: "Segoe UI",
  fontWeight: 400,
  cursor: "pointer",
  marginLeft: 10
}