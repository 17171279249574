import { IDropdownStyles } from "@fluentui/react"
import * as React from "react"

export const paddingTop4: React.CSSProperties = {
    paddingTop: "4px"
}

export const projectInsightsWidth: React.CSSProperties =
{
    // width: "300px",
    paddingLeft: "0px"
}

export const insightsTitleStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",
    color: "#000000",
    marginTop: "2%",
    marginLeft: "4%",
}

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%", },
    dropdownItem: {
        fontFamily: "'Segoe UI'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#171717",
        marginLeft: "2%",
    }
};

export const dropdownPadding: React.CSSProperties = {
    paddingTop: "12px",
    marginLeft: "4%"
}