import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { ProjectReducerName, projectReducer } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { ProjectAPI } from "../../Services/ProjectAPI";
import { IProjectGetDetailsError } from "../../Models/IProjectGetDetailsError";
import { AxiosError } from "axios";
import useLMIToken from "./useLMIToken";

import {v4 as uuidv4} from 'uuid';

export interface IProjectMemberProps {    
    userAlias? :string;
    number?: string;
}

const useGetProjectDetails = (projectmemberProps: IProjectMemberProps) => {
    const { userAlias, number } = projectmemberProps;
    const [isProjectDetailsLoading, setIsProjectDetailsLoading] = React.useState(false);
    const [isProjectDetailsLoadingError, setIsProjectDetailsLoadingError] = React.useState(false);
    const [projectGetDetailsError, setProjectGetDetailsError] = React.useState<IProjectGetDetailsError>({});
    const [projectDetails, setProjectDetails] = React.useState<any>();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { httpClient } = reduxContext;
    const { lmiToken } = useLMIToken();    

    const getProjectDetails = async (): Promise<any> => {
        try {
            const projectdetailResponse = await ProjectAPI.getProjectDetailsById(number, userAlias, httpClient, uuidv4(), lmiToken);  
            return projectdetailResponse;
        } catch (error: any | AxiosError) {
            throw error;
        }
    };

    React.useEffect(() => {
        if (number && lmiToken) {
            setIsProjectDetailsLoading(true);
            const fetchProjectDetailsById = async () => {
                try {
                    const projectDetails = await getProjectDetails();
                    setIsProjectDetailsLoading(false);
                    setProjectDetails(projectDetails?.data);
                } catch (error: any) {
                    console.log(error);
                    setProjectDetails("");
                    const projectGetDetailsErrorOb:IProjectGetDetailsError =  {                        
                        error: error,
                        errorMessage: error?.errorMessage                        
                      }
                    setIsProjectDetailsLoading(false);
                    setProjectGetDetailsError(projectGetDetailsErrorOb)
                    setIsProjectDetailsLoadingError(true)
                }
            };
            fetchProjectDetailsById();
        }
    }, [number, lmiToken]);

    return { projectDetails, isProjectDetailsLoading, isProjectDetailsLoadingError, projectGetDetailsError };
};
export default useGetProjectDetails;
