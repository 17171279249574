import * as React from "react";
import { Persona, PersonaSize, Stack } from "@fluentui/react";
import { DisplaySpinner } from "../../../Common/Components/DisplaySpinner";
import useGetProjectMemberProfile from "../../Hooks/useGetProjectMemberProfile";

type IPersonaView = {
  userAlias: string;
  graphTokenParam? :string;
};

const MemberView = (personaView: IPersonaView) => { 
  const { userAlias, graphTokenParam} = personaView;
  const {projectMemberProfile, isProjectMemberProfileLoading} = useGetProjectMemberProfile({userAlias, graphTokenParam})
  
  return (
    <>
      {isProjectMemberProfileLoading && (
        <Stack style={{ marginTop: 30 }}>
          <span role="alert" aria-label="loading member details" aria-live="assertive" />
          <DisplaySpinner accessabilityMessage={"Loading ..."} spinnerPosition={"right"} spinnerText={"Loading..."} />
        </Stack>
      )}
      {!isProjectMemberProfileLoading && (projectMemberProfile || userAlias) ? (
        <span>
           <Persona
            role="icon"
            aria-label={projectMemberProfile?.text || userAlias}
            size={PersonaSize.size24}
            text={projectMemberProfile?.text || userAlias}
            imageUrl={projectMemberProfile?.imageUrl || ""}
            secondaryText={projectMemberProfile?.secondaryText || userAlias}
            optionalText={projectMemberProfile?.optionalText || userAlias}
            hidePersonaDetails={false}
          />
        </span>
      ): null}
    </>
  );
};
export default MemberView;
