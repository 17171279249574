import { Checkbox, ChoiceGroup, ComboBox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IBasePickerSuggestionsProps, IChoiceGroupOption, Icon, IconButton, IPersonaProps, ITag, MessageBarType, Modal, PrimaryButton, Stack, TextField, TooltipHost, IComboBoxStyles } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Reducer } from "redux";
import DisplayMessage from "../../../Common/Components/DisplayMessage";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import { checkIfFileExistsInSPO, formatDate, getDateFromString } from "../../../Helpers/ProjectHelper";
import CustomTags, { CustomTag } from "../../../Models/CustomTags";
import { IProjectFile, IProjectFiles } from "../../../Models/IProjectFile";
import { IProjectFileTags } from "../../../Models/IProjectFileTags";
import { IProjectMembers } from "../../../Models/IProjectMembers";
import { IProjectProps } from "../../../Models/IProjectProps";
import { IProjectTags } from "../../../Models/IProjectTags";
import { requestProjectCreation, requestProjectWorkArea, requestProjectEdit, updateProjectStateForFileUpload, requestLitigationCases, requestLitigationFeatureFlag, requestIsUserPartofLitigationSgGroup } from "../../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import CustomTagPicker from "../../PickerComponents/CustomTagPicker";
import PeoplePicker from "../../PickerComponents/PeoplePicker";
import FormAttachmentView from "../Components/FormAttachmentView";
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import ProjectWorkAreaView from "./ProjectWorkAreaView";
import { IProjectWorkArea } from "../../../Models/IProjectWorkArea";
import {
    assingPadding,
    cancelButtonStyle,
    cancelButtonText,
    colorred,
    createButtonStyle,
    createButtonText,
    createProjectTitle,
    dropdownStyles,
    formText,
    iconStyle,
    tagPickerStyling,
    textFieldStyles,
    controlWidth,
    controlPeoplePickerStyles,
    addAttachment,
    addFileControl,
    controlWidthAttachment,
    controlWidthFiles,
    controlWidthAttachementLabel,
    attachmentLabel,
    customDatePickerStyles,
    goBackIconStyle,
    goBack,
    textFieldDescStyles,
    chkStyle
} from "../Styles/CreateProject";
import { spinnerCss } from "../Styles/ProjectList";
import { app, pages } from "@microsoft/teams-js";
import { IProjectSearchRequest } from "../../../Models/IProjectSearchRequest";
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
import { IProjectCases } from "../../../Models/IProjectFileTags";
//
import { useLocation } from 'react-router-dom';
import { useCreateNavContext } from "./CreateProject/CreateNavContext/CreateNavContext";
import './CreateProjectPage.module.css';
import { AISearchChat } from "../AIChat/AIChat";
import { ILitigationCase } from "src/Models/ILitigationCase";
import { ILitigationCaseRequest } from "src/Models/ILitigationCaseRequest";
import CasePicker from "../../../Components/PickerComponents/CasePicker";
import ChatHome from "../LitigationChat/ChatHome";
import { setNewUserPrompt } from "../../../Shared/Actions/Chat.action";
//import FileDetailsList, { IDocument } from "../Litigation/FileExplorer";
//import FileExplorer from "../Litigation/FileExplorer";
export const ProjectContext = React.createContext(null);
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;
const CreateProjectPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let history = useHistory();
    const { updateNavItem } = useCreateNavContext();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectCreate,
        isProjectGettingCreated,
        projectCreationSuccess,
        projectCreateError,
        projectCreationFailure,
        projectCreateRedirect,
        projectContext,
        projectFiles,
        projectFileTags,
        projectProps,
        projectOwner,
        projectMembers,
        deletedProjectMembers,
        projectTags,
        isProjectGettingEdited,
        projectEditSuccess,
        projectEditRedirect,
        projectEditFailure,
        projectEditError,
        loginUserpersonaProps,
        isGettingProjectWorkArea,
        isProjectWorkAreaSuccess,
        isProjectWorkAreaError,
        projectWorkAreaCollection,
        litigationCaseResponses,
        isLitigationCaseResultsLoading,
        litigationFeatureFlagResponse,
        isLoadingLitigationFeatureFlag,
        canAddLitigationCases,
        isLoadedInBrowser
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const [dragActive, setDragActive] = React.useState(false);
    const [isRPSelected, setIsRPSelected] = React.useState(false);
    const [files, setFiles] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [workArea, setWorkArea] = React.useState("");
    const [pageTitle, setPageTitle] = React.useState('');
    const [buttonName, setButtonName] = React.useState('');
    const [projectTag, setProjectTag] = React.useState<number>(0);
    const [hideDialog, setHideDialog] = React.useState(true);
    const [customTags, setCustomTags] = React.useState<CustomTag[]>([]);
    const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);
    const [tagCount, setTagCount] = React.useState<number>(0);
    const [localProjectTags, setProjectTags] = React.useState<IProjectTags>();
    const [projectMember, setProjectMember] = React.useState<IProjectMembers>();
    const [projectOwners, setProjectOwners] = React.useState<IProjectMembers>();
    const [startDate, setStartDate] = React.useState<Date | null | undefined>(null);
    const [endDate, setEndDate] = React.useState<Date | null | undefined>(null);
    const [tagCharacrtersRemaining, setTagCharacrtersRemaining] = React.useState<number>(255);
    const [validationMessage, setValidationMessage] = React.useState("");
    const [selectedFileTags, setSelectedFileTags] = React.useState<ITag[]>([]);
    const [fileTags, setFileTags] = React.useState<IProjectFileTags>();
    const [selectedFile, setSelectedFile] = React.useState(null);
    const inputRef = React.useRef(null);
    const [fileList, setFileList] = React.useState([]);
    const [changeDetected, setChangeDetected] = React.useState(false);
    const [isCELALegalGroupChecked, setIsCELALegalGroupChecked] = React.useState(false);
    const [tabNavigation, setTabNavigation] = React.useState<ITabNavigation>({});
    const [isNavigationFromDashboard, setIsNavigationFromDashboard] = React.useState(false);
    const [backToProjectList, setBackToProjectList] = React.useState(false);
    const [dropdownWorkAreaOptions, setDropdownWorkAreaOptions] = React.useState<any>([]);
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
    const [fileTagsArray, setFileTagsArray] = React.useState<IProjectFileTags[]>([]);
    const [isLitigation, setIsLitigation] = React.useState(false);
    const [selectedCaseItem, setSelectedCaseItem] = React.useState<IDropdownOption>();
    const [CaseList, setCaseList] = React.useState<any>([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [showChat, setshowChat] = React.useState(false);
    const [createProjectCaseDispatch, setCreateProjectCaseDispatch] = React.useState(false);
    const [canUserAddLitigationCases, setCanUserAddLitigationCases] = React.useState(false);
    const [uploadFileSizeErrorMessage, setUploadFileSizeErrorMessage] = React.useState("");
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };
    const defaultWorkAreaKey = "Other//Other//";
    const defaultWorkAreaValue = "Other";

    const onChangeWorkArea = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setWorkArea(item.key as string);
        setSelectedItem(item);
        if (item?.key?.toString().toLowerCase().includes('litigation')) {
            if (CaseList?.length == 0 && canUserAddLitigationCases) {
                dispatchCaseListcall();
            }
        }
    };

    const dispatchCaseListcall = () => {
        const requetLitigationCases: ILitigationCaseRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            cases: []
        }
        setCreateProjectCaseDispatch(true);
        dispatch(requestLitigationCases(requetLitigationCases));
    }

    React.useEffect(() => {
        if (createProjectCaseDispatch) {
            if (litigationCaseResponses) {
                if (CaseList?.length == 0) {
                    litigationCaseResponses.litigationCaseResponses?.map((item, counter) => {
                        var dropDownHeaderItem = {
                            key: item.caseId,
                            text: item.caseName,
                            itemType: DropdownMenuItemType.Normal
                        }
                        CaseList.push(dropDownHeaderItem);
                    });
                    setCaseList(CaseList);
                }
            }
        }
    }, [CaseList, litigationCaseResponses]);

    // Title Change
    const onChangeTitleValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setTitle(newValue || '');
            setChangeDetected(true);
        },
        [],
    );

    // Description Change
    const onChangeDescriptionValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDescription(newValue || '');
            setChangeDetected(true);
        },
        [],
    );

    //Status Start
    const dropDownOptions: IDropdownOption[] = [
        { key: "Open", text: 'Open', title: 'Open' },
        { key: "Closed", text: 'Closed', title: 'Closed' },
    ];

    const outsideCounselOptions: IChoiceGroupOption[] = [
        { key: 'true', text: 'Yes' },
        { key: 'false', text: 'No' }
    ];

    const handleoutsideCounselSelection = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption): void => {
        const isKeyTrue = option.key.toLowerCase() === 'true';
        setIsRPSelected(isKeyTrue);
    }
    const [selectedStatus, setSelectedStatusItem] = React.useState<IDropdownOption>();
    const onStatusChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedStatusItem(item);
        setChangeDetected(true);
    };
    //Status End

    //Start and End Date Start
    const onSelectStartDate = (date: Date | null | undefined): void => {
        setStartDate(date);
        setChangeDetected(true);
    };
    const onSelectEndDate = (date: Date | null | undefined): void => {
        setEndDate(date);
        setChangeDetected(true);
    };
    //Start and End Date End

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsClassName: ''
    }

    const errorMessageRef = React.useRef(null);

    React.useEffect(() => {
        app.getContext().then((context) => {
            if (context?.page?.subPageId !== '') {
                setTabNavigation(JSON.parse(context.page.subPageId));
            }
        });
        const isBackToProjectList: string = queryParams.get('backToProjectList')
        setBackToProjectList(isBackToProjectList === 'true' ? true : false);
        setSelectedStatusItem(dropDownOptions[0]);
        setPageTitle("Create a project")
        setButtonName("Create");
        if (projectProps?.workArea) {
            setWorkArea(projectProps?.workArea);

            if (projectProps?.workArea?.toLowerCase().includes('litigation')) {
                if (CaseList?.length == 0) {
                    dispatchCaseListcall();
                }
            }

            if (!projectContext.number) {
                if (projectWorkAreaCollection.projectWorkAreas?.length > 0) {
                    var workAreaValue = projectProps?.workArea?.slice(-2) === '//' ? projectProps?.workArea : projectProps?.workArea + "//";
                    var workAreaFromCollection = projectWorkAreaCollection.projectWorkAreas.find(x => x.key === workAreaValue);
                    setSelectedItem({ key: workAreaFromCollection?.key, text: workAreaFromCollection?.value } as IDropdownOption)
                }
            }
        } else {
            setWorkArea(defaultWorkAreaKey);
            setSelectedItem({ key: defaultWorkAreaKey, text: defaultWorkAreaValue } as IDropdownOption)
        }
    }, []);

    React.useEffect(() => {
        if(loginUserpersonaProps?.secondaryText){
            dispatch(requestIsUserPartofLitigationSgGroup(loginUserpersonaProps?.secondaryText))
        }        
    }, []);

    React.useEffect(() => {
        if (Object.keys(canAddLitigationCases).length > 0 && canAddLitigationCases?.canAddCases) {
            setCanUserAddLitigationCases(canAddLitigationCases?.canAddCases)
        }
    }, [canAddLitigationCases]);

    React.useEffect(() => {
        if (Object.keys(tabNavigation).length > 0) {
            setIsNavigationFromDashboard(tabNavigation?.isFromDashboard)
        }
    }, [tabNavigation]);

    React.useEffect(() => {
        if (selectedItem && selectedItem?.key?.toString()?.toLowerCase()?.includes('litigation')) {
            setIsLitigation(true);
        } else {
            setIsLitigation(false);
        }
    }, [selectedItem]);

    React.useEffect(() => {
        if (projectContext?.number && projectContext?.number.indexOf('KMTeamAPP') === -1) {
            if (isLitigation && (isRPSelected || selectedCaseItem)) {
                setButtonName("Continue");
            } else {
                setButtonName("Save");
            }
        }
        else {
            if (isLitigation && (isRPSelected || selectedCaseItem))
                setButtonName("Continue");
            else
                setButtonName("Create");

        }
    }, [isRPSelected, isLitigation, selectedCaseItem]);


    React.useEffect(() => {
        if (projectContext?.number && projectContext?.number.indexOf('KMTeamAPP') === -1) {

            setPageTitle("Edit Project");
            if (isRPSelected)
                setButtonName("Continue");
            else
                setButtonName("Save");

            setTitle(projectProps.title);
            setDescription(projectProps.description);
            if (projectProps?.caseIds?.length > 0) {
                const caseTag: ITag[] = [];
                projectProps?.caseIds.map((item) => {
                    caseTag.push({ key: item, name: item });
                });
                setSelectedCases(caseTag);
            }
            if (projectFiles) {
                if (projectFiles?.projectFile?.length > 0) {
                    const files: any = [];
                    projectFiles.projectFile.map((file: any) => {
                        files.push(file?.file);
                    })
                    setFiles(files);
                }
                setFileList(projectFiles.projectFile);
            }
            if (projectWorkAreaCollection.projectWorkAreas?.length > 0) {
                var workAreaValue = projectProps?.workArea?.slice(-2) === '//' ? projectProps?.workArea : projectProps?.workArea + "//";
                var workAreaFromCollection = projectWorkAreaCollection?.projectWorkAreas?.find(x => x.key === workAreaValue);
                setSelectedItem({ key: workAreaFromCollection?.key, text: workAreaFromCollection?.value } as IDropdownOption)
            }
            if (projectProps?.startDate) { setStartDate(getDateFromString(projectProps.startDate)); }
            if (projectProps?.endDate) { setEndDate(getDateFromString(projectProps.endDate)); }
            if (projectProps?.status?.toLowerCase() === "closed") { setSelectedStatusItem(dropDownOptions[1]); }
            else { setSelectedStatusItem(dropDownOptions[0]); }
            if (projectOwner && projectOwner?.membersPicked?.length > 0) {
                setProjectOwners(projectOwner)
            }
            if (projectMembers && projectMembers?.membersPicked?.length > 0) {
                const tempprojectMembersList: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };

                projectMembers?.membersPicked?.map((member: IPersonaProps) => {
                    if (
                        member.secondaryText.toLowerCase().trim() === "lca@microsoft.com" ||
                        member.secondaryText.toLowerCase().trim() === "lca"
                    ) {
                        setIsCELALegalGroupChecked(true);
                    }
                    const teamMember: IPersonaProps = {
                        text: member?.text,
                        secondaryText: member?.secondaryText,
                        optionalText: member?.optionalText,
                        tertiaryText: member?.tertiaryText,
                        showSecondaryText:true
                    };
                    tempprojectMembersList.membersPicked.push(teamMember);
                    tempprojectMembersList.alias.push(member?.text);
                });
                setProjectMember(tempprojectMembersList)
            }
            if (projectTags?.tags) {
                setProjectTags(projectTags);
            }
            if (projectFileTags) {
                setFileTagsArray(projectFileTags)
            }

        } else {
            setPageTitle("Create Project");
            setButtonName("Create");
            setTitle('');
            setDescription('');
            setStartDate(null)
            setEndDate(null);
            setProjectTags({})
            setFileTags({})
            setSelectedItem({ key: defaultWorkAreaKey, text: defaultWorkAreaValue } as IDropdownOption)
            setFileList([])
            setProjectMember({})
            setProjectOwners({})
            setIsCELALegalGroupChecked(false)
            setSelectedCases([]);
        }
    }, [projectContext, dropdownWorkAreaOptions]);

    React.useEffect(() => {
        if (projectOwners && projectOwners?.membersPicked?.length > 0) {
            setProjectOwners(projectOwners)
        }
        if (projectMember && projectMember?.membersPicked?.length > 0) {
            setProjectMember(projectMember)
        }
    }, [projectCreationFailure]);


    // Project Tags Start
    React.useEffect(() => {
        const customProjectTags = CustomTags;
        setCustomTags(customProjectTags);
    }, []);

    React.useEffect(() => {
        const iTags: ITag[] = [];
        localProjectTags?.tags?.map((projectTag: string) => {
            const iTag: ITag = {
                key: projectTag,
                name: projectTag,
            };
            iTags.push(iTag);
        });
        setSelectedTags(iTags);
    }, [localProjectTags]);

    React.useEffect(() => {
        if (Object.keys(projectWorkAreaCollection).length === 0) {
            dispatch(requestProjectWorkArea());
        }
    }, []);

    React.useEffect(() => {
        if (projectWorkAreaCollection?.projectWorkAreas?.length > 0 && dropdownWorkAreaOptions.length == 0) {
            var currentHeader = '';
            var workAreaCollection = [];
            var childItemCount = 0;
            projectWorkAreaCollection?.projectWorkAreas.map((projectWorkArea, counter) => {
                if (projectWorkArea.headerRow) {
                    if (currentHeader != '') {
                        if (childItemCount === 0) {
                            var dropDownHeaderItem = {
                                key: currentHeader + "//" + currentHeader + "//",
                                text: currentHeader,
                                itemType: DropdownMenuItemType.Normal
                            }
                            workAreaCollection.push(dropDownHeaderItem);
                            projectWorkAreaCollection?.projectWorkAreas.push(
                                {
                                    key: currentHeader + "//" + currentHeader + "//",
                                    value: currentHeader,
                                    headerRow: false,
                                } as IProjectWorkArea);
                        }
                        var dropDownHeaderItem = {
                            key: 'divider_' + projectWorkArea.workAreaId,
                            text: '-',
                            itemType: DropdownMenuItemType.Divider
                        }
                        workAreaCollection.push(dropDownHeaderItem)
                        childItemCount = 0;
                    }
                    currentHeader = projectWorkArea.value;
                    var dropDownHeaderItem = {
                        key: projectWorkArea.key,
                        text: projectWorkArea.value,
                        itemType: DropdownMenuItemType.Header
                    }
                    workAreaCollection.push(dropDownHeaderItem)
                    if (counter + 1 == projectWorkAreaCollection?.projectWorkAreas?.length) {
                        var dropDownHeaderItem = {
                            key: currentHeader + "//" + currentHeader + "//",
                            text: currentHeader,
                            itemType: DropdownMenuItemType.Normal
                        }
                        workAreaCollection.push(dropDownHeaderItem)
                        projectWorkAreaCollection?.projectWorkAreas.push(
                            {
                                key: currentHeader + "//" + currentHeader + "//",
                                value: currentHeader,
                                headerRow: false,
                            } as IProjectWorkArea);
                    }
                } else {
                    childItemCount++;
                    var dropDownHeaderItem = {
                        key: projectWorkArea.key,
                        text: projectWorkArea.value,
                        itemType: DropdownMenuItemType.Normal
                    }
                    if (workAreaCollection.findIndex(x => x.key === projectWorkArea.key) == -1) {
                        workAreaCollection.push(dropDownHeaderItem)
                    }
                    setDropdownWorkAreaOptions(workAreaCollection);
                }
            });
        }
    }, [projectWorkAreaCollection]);

    const onAddTag = async (text: string) => {
        setTagCount((x) => x + 1);
        const currentTagidIndex = tagCount + customTags.length;
        const response = await Promise.resolve({
            id: currentTagidIndex,
            text,
        });
        return response;
    };

    const onCategorySelected = (id?: number) => {
        setProjectTag(id);
    };

    const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
        if (filter) {
            return customTags
                .filter((customTag: CustomTag) => customTag.text.toLowerCase().includes(filter.toLowerCase()))
                .map((customTag: CustomTag): ITag => {
                    return {
                        name: customTag.text,
                        key: customTag.id,
                    };
                });
        }
        return [];
    };

    const onChange = (items?: ITag[] | undefined) => {
        if (items && items[0]) {
            onCategorySelected(Number(items[0].key));
        } else {
            onCategorySelected();
        }
        setSelectedTags(items);
        let tagStrings: string[] = [];
        const localProjectTag: IProjectTags = {
            tags: [],
        };
        items.map((item: ITag) => {
            tagStrings.push(item.name.toString());
        });
        localProjectTag.tags = tagStrings;
        setProjectTags(localProjectTag);
        setChangeDetected(true);
    };
    // Project Tags End

    // File Tags Start
    React.useEffect(() => {
        const iTags: ITag[] = [];
        if (fileTags && fileTags?.tags) {
            if (fileTags?.tags[fileTags?.tags?.length - 1] === ',') {
                fileTags.tags = fileTags?.tags.slice(0, -1);
            }
            const tagArray = fileTags?.tags.split(",");
            tagArray?.map((projectTag: string) => {
                const iTag: ITag = {
                    key: projectTag,
                    name: projectTag,
                };
                iTags.push(iTag);
            });
            setSelectedFileTags(iTags);
        }
    }, [fileTags]);

    const modalPropsStyles = { main: { minWidth: 250, minHeight: 400 } };

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        styles: modalPropsStyles
    };

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Add Tags',
        subText: 'Type the tags below to associate with the uploaded files',
    };

    //Upload Files Start 
    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };
    const doFileValidation = (): boolean => {
        let totalFileSize: number = 0;
        Object.keys(files).map((fileName, index) => {
          totalFileSize = totalFileSize + +files[fileName].size;
        });
        if (totalFileSize > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
          //setDisableUploadButton(true);
          setUploadFileSizeErrorMessage("Total file size should not be greater than 50 MB");
          return false;
        } else {
          setUploadFileSizeErrorMessage("");
          //setDisableUploadButton(false);
        }
        return true;
      };
    const handleChange = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
        e.target.value = ''
    };
    const convertNestedObjectToArray = (nestedObj: any) => Object.keys(nestedObj).map((key) => nestedObj[key]);
    const callUpdateFilesCb = (files: any) => {
        const filesAsArray = convertNestedObjectToArray(files);
    };

    const addNewFiles = (newFiles: any) => {
        for (let file of newFiles) {
            files[file.name] = file;
        }
        setFiles(files);
        return { ...files };
    };


    const handleFiles = (newFiles: any) => {
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            const newProjectFiles: IProjectFiles = {
                projectFile: [],
            };
            Object.keys(updatedFiles).map((fileName) => {
                let projectFile: IProjectFile = {};
                let file = files[fileName];
                projectFile.file = file;
                projectFile.isNew = checkIfFileExistsInSPO(file.name, projectFiles);
                newProjectFiles.projectFile.push(projectFile);
            });
            setFileList(newProjectFiles?.projectFile);
            callUpdateFilesCb(updatedFiles);
            setChangeDetected(true);
            doFileValidation();
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();

    };

    // Updates the file project state if the file is successfully deleted from SPO
    // If the file is a new upload, then update the list

    const sendFileTags = (dailogFileTags: any) => {
        const index = fileTagsArray?.length > 0
            ? fileTagsArray.findIndex(item => item.fileName === dailogFileTags.fileName)
            : -1;
        if (index !== -1) {
            // Filename exists, update file tags
            setFileTagsArray((prevArray) => {
                const newArray = [...prevArray];
                newArray[index].tags = dailogFileTags?.tags;
                return newArray;
            });
        } else {
            // Filename doesn't exist, insert new entry in the array
            setFileTagsArray((prevArray) => [...prevArray, dailogFileTags]);
        }
        console.log(fileTagsArray);

    }

    const removeFileFromState = (file: any) => {
        if (projectContext && !file.isNew) {
            const localprojectFiles: IProjectFiles = {
                projectFile: [],
            };
            projectFiles?.projectFile.map((fileList: IProjectFile) => {
                if (fileList.file.name.toLowerCase() !== file.file.name.toLowerCase()) {
                    const projectFile: IProjectFile = {};
                    projectFile.file = fileList.file;
                    projectFile.isNew = fileList.isNew;
                    localprojectFiles.projectFile.push(projectFile);
                }
            });
            dispatch(updateProjectStateForFileUpload(localprojectFiles));
            //Set Change detected for the files which are uploaded to SPO and not the local ones they are deleting after uploading
            setChangeDetected(true);
        }
        if (typeof files === "object") {
            for (const [key, value] of Object.entries(files)) {
                if (value.name === file?.file?.name) {
                    console.log(files[key]);
                    delete files[key];
                }
            }
        } else {
            delete files[file?.file?.name];
        }
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
        doFileValidation();
        var tempFileList = fileList.filter(files => files.file.name.toLowerCase() !== file.file.name.toLowerCase());
        setFileList(tempFileList);
    }

    const doValidation = () => {
        // Do all the required Validations
        // If validation fails, set the Validation Message and display proper error message
        let message: string = "";
        var numberOfFields = 0;
        if (title?.trim().length == 0) {
            //setValidationMessage("Title is a mandatory field");
            message += "Title";
            numberOfFields++;
        }
        if (description?.trim().length == 0) {
            //setValidationMessage("Description is a mandatory field");
            message += message.length == 0 ? "Description" : ", Description";
            numberOfFields++;
        }
        if (workArea?.trim().length == 0) {
            message += message.length == 0 ? "Work Area" : ", Work Area";
            numberOfFields++;
        }
        if (message.length > 0) {
            message += numberOfFields > 1 ? " are mandatory fields. " : " is mandatory field. ";
        }
        if (endDate && startDate && endDate <= startDate) {
            //setValidationMessage("End Date should be after the Start Date");
            message += numberOfFields >=1 ? " and End Date should be greater than Start Date" : "End Date should be greater than Start Date";
        }

        if (uploadFileSizeErrorMessage?.trim().length > 0) {            
            message += uploadFileSizeErrorMessage;
            numberOfFields++;
        }

        if (message?.length == 0) {
            //setValidationMessage("");
            message = "";
        }
        setValidationMessage(message);
        return message
    }

    const createFileObject = (): IProjectFiles => {
        const newProjectFiles: IProjectFiles = {
            projectFile: [],
        };
        fileList?.map((files: any) => {
            let projectFile: IProjectFile = {};
            let file = files.file;
            projectFile.file = file;
            projectFile.isNew = checkIfFileExistsInSPO(file.name, projectFiles);;
            newProjectFiles.projectFile.push(projectFile);
        })
        return newProjectFiles;
    }

    function togglePermissionsToCelaGroup(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setChangeDetected(true);
        setIsCELALegalGroupChecked(isChecked);
    }

    // Check validation for last owner in project
    const deleteOwnerdialogContentProps = {
        type: DialogType.largeHeader,
        title: "No Owner found for project",
    };
    const toggleMemberDeleteHideDialog = () => setMemberDeleteHideDialog(value => !value);
    const [hideMemberDeleteDialog, setMemberDeleteHideDialog] = React.useState(true);

    const createProject = () => {
        const message: string = doValidation();
        // if (errorMessageRef.current) {
        //     errorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
        console.log(projectMember);
        if (message.trim().length === 0) {
            const caseIds: string[] = selectedCases.map((item) => item.name) as string[];
            const projectProps: IProjectProps = {};
            projectCreate.projectFiles = createFileObject();
            projectProps.title = title;
            projectProps.description = description;
            projectProps.workArea = workArea;
            projectProps.caseIds = Array.from(new Set(caseIds));//Remove duplicates
            projectProps.status = selectedStatus.text.toString();
            projectProps.startDate = formatDate(startDate);
            projectProps.endDate = formatDate(endDate);
            projectCreate.projectProps = projectProps;
            projectCreate.projectTags = localProjectTags;
            projectCreate.projectFileTags = fileTagsArray;
            projectCreate.projectMembers = projectMember;
            

            if (projectOwners && Object.keys(projectOwners).length > 0) {
                projectCreate.projectOwners = projectOwners;
            }
            else {
                const personaProps: IPersonaProps = {
                    secondaryText: loginUserpersonaProps?.secondaryText,
                    optionalText: loginUserpersonaProps?.optionalText
                }
                const alias: string = loginUserpersonaProps?.secondaryText
                const projOwners: IProjectMembers = {
                    alias: [],
                    isResponsible: true,
                    membersPicked: []
                }
                projOwners.alias.push(alias)
                projOwners.membersPicked.push(personaProps)
                projectCreate.projectOwners = projOwners;

            }

            projectCreate.userMailbox = loginUserpersonaProps?.secondaryText
            projectCreate.isCELAPeopleCheckedLocally = isCELALegalGroupChecked;

            console.log("Create Project", projectCreate, changeDetected);
            if (isLitigation && selectedCaseItem) {
                if (message.trim().length === 0) {
                    updateNavItem(['fileAttachment'], 'fileAttachment')
                    history.push(`/createproject?componentName=fileAttachment&isRPSelected=${isRPSelected}`);

                }
            }
            else if (isLitigation && isRPSelected) {
                if (message.trim().length === 0) {
                    updateNavItem(['fileAttachment', 'firm'], 'firm')
                    history.push(`/createproject?componentName=firm`);

                }

            }
            else if (projectContext?.number && projectContext?.number.indexOf('KMTeamAPP') === -1) {
                if (projectOwners && projectOwners?.membersPicked?.length > 0) {
                    dispatch(requestProjectEdit(projectCreate, projectContext, deletedProjectMembers));
                }
                else {
                    toggleMemberDeleteHideDialog();
                }
            }
            else {
                dispatch(requestProjectCreation(projectCreate));
            }
        }
        else {
            console.log("Validation", message)
        }
    }

    React.useEffect(() => {
        if (projectCreationSuccess && projectCreateRedirect) {
            if (isNavigationFromDashboard) {
                if (pages.currentApp.isSupported) {
                    const tabNavigation: ITabNavigation = {
                        isNewProjectCreation: true,
                        projectCreationSuccess: true,
                        title
                    };
                    const navPromise = pages.navigateToApp({ appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectdashboard', subPageId: JSON.stringify(tabNavigation) });
                    navPromise.then(() => {
                        console.log('Navigated to project list page')
                    }).catch((error) => {

                    })
                }
            } else {
                history.push("/projects");
            }
        }
    }, [projectCreationSuccess, projectCreateRedirect]);

    React.useEffect(() => {
        if (projectEditSuccess && projectEditRedirect && projectContext?.number) {
            history.push(`/viewproject?componentName=view&projectNumber=${projectContext?.number}&backToProjectList=true`);
        }
    }, [projectEditSuccess, projectEditRedirect, projectContext?.number]);

    React.useEffect(() => {
        if (projectContext?.number && projectContext?.number.indexOf('KMTeamAPP') === -1) {
            const requetLitigationCases: ILitigationCaseRequest = {
                userAlias: loginUserpersonaProps?.secondaryText,
                cases: []
            }
            dispatch(requestLitigationCases(requetLitigationCases));
        }
    }, [projectContext?.number]);

    const handleCancelClick = () => {
        if (projectContext?.number && projectContext?.number.indexOf('KMTeamAPP') === -1) {
            history.push(`/viewproject?componentName=view&projectNumber=${projectContext?.number}`);
        }
        else {
            history.push("/");
        }
    }

    // Confirmation Popup Start
    const [hideConfirmationDialog, setHideConfirmationDialog] = React.useState(true);
    const [createDate, setCreateDate] = React.useState("");
    const dialogConfirmationContentProps = {
        type: DialogType.largeHeader,
        title: 'Unsaved Changes',
    };

    const toggleHideConfirmationDialog = () => setHideConfirmationDialog(value => !value);

    const goBackAfterConfirmationRedirect = () => {
        history.goBack();
        toggleHideConfirmationDialog();
    }

    // Confirmation Popup End

    const goBackRedirect = () => {
        if (changeDetected) {
            toggleHideConfirmationDialog();
        }
        else if (backToProjectList) {
            history.push(`/viewproject?componentName=view&projectNumber=${projectContext?.number}`);
        }
        else {
            if (isNavigationFromDashboard) {
                if (pages.currentApp.isSupported) {
                    const navPromise = pages.navigateToApp({ appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectdashboard' });
                    navPromise.then(() => {
                        console.log('Navigated to project list page')
                    }).catch((error) => {

                    })
                }
            } else {
                history.goBack();
            }
        }
    }

    const handleIconClick = () => {
        setshowChat(true);
    };

    function onDismissAiSearchPanel(): void {
        setshowChat(false);
    }

    const [selectedCases, setSelectedCases] = React.useState<ITag[]>([]);
    const [projectCase, setProjectCase] = React.useState<number>(0);
    const [caseCount, setCaseCount] = React.useState<number>(0);
    const onCaseSelected = (id?: number) => {
        setProjectCase(id);
    };

    const onAddCase = async (text: string) => {
        setCaseCount((x) => x + 1);
        const currentCaseIndex = caseCount + customTags.length;
        const response = await Promise.resolve({
            id: currentCaseIndex,
            text,
        });
        return response;
    };

    const onResolveCaseSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
        if (filter) {
            return CaseList
                .filter((customTag: CustomTag) => customTag.text.toLowerCase().includes(filter.toLowerCase()))
                .map((customTag: CustomTag): ITag => {
                    return {
                        name: customTag.text,
                        key: customTag.id,
                    };
                });
        }
        return [];
    };

    const onChangeCase = (items?: ITag[] | undefined) => {
        if (items && items[0]) {
            onCaseSelected(Number(items[0].key));
        } else {
            onCaseSelected();
        }
        setSelectedCases(items);
        const projectCases: IProjectCases = {
            cases: "",
        };
        let tagStrings: string = "";
        items.map((item: ITag) => {
            tagStrings = tagStrings + item.name.trim() + ",";
        });
        tagStrings = tagStrings.slice(0, -1);
        projectCases.cases = tagStrings;
        setFileTags(fileTags);
        setChangeDetected(true);
    };
    const [showLitigationChatModal, setShowLitigationChatModal] = React.useState(false);
    const handleOpenLitigationChatModal = () => {
        dispatch(setNewUserPrompt(''))
        setShowLitigationChatModal(p => !p)
    }

    return (
        <Stack style={{ marginLeft: '12px', width: '100vw' }}>
            <div ref={errorMessageRef}></div>
            {validationMessage && validationMessage.length > 0 && (
                <>

                    <DisplayMessage
                        accessabilityMessage={validationMessage}
                        messageType={MessageBarType.error}
                        message={validationMessage}
                    />

                </>
            )}
            {isProjectGettingCreated && (
                <>
                    <span role="alert" aria-label={GenericMessages.projectsCreating} aria-live="assertive" />
                    <div style={spinnerCss}>
                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.projectsCreating}
                            spinnerPosition={"right"}
                            spinnerText={GenericMessages.projectsCreating}
                        />
                    </div>
                </>
            )}
            {isProjectGettingEdited && (
                <>
                    <span role="alert" aria-label={GenericMessages.projectsEditing} aria-live="assertive" />
                    <div style={spinnerCss}>
                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.projectsEditing}
                            spinnerPosition={"right"}
                            spinnerText={GenericMessages.projectsEditing}
                        />
                    </div>
                </>
            )}
            {!isProjectGettingEdited && projectEditSuccess && (
                <>
                    <DisplayMessage
                        accessabilityMessage={projectProps.title + " has been edited successfully"}
                        messageType={MessageBarType.success}
                        message={projectProps.title + " has been edited successfully"}
                    />
                </>
            )}
            {!isProjectGettingCreated && projectCreationFailure && (
                <>
                    <ErrorDisplay source={projectCreateError?.errorMessage} />
                </>
            )}
            {!isProjectGettingEdited && projectEditFailure && (
                <>
                    <ErrorDisplay source={projectEditError?.errorMessage} />
                </>
            )}
            {!isProjectGettingCreated && !isProjectGettingEdited && (
                <>

                    <div style={{ marginLeft: 20, width: '100%' }} className="pageContainer">
                        <Stack horizontal>

                            <Stack>
                                <div className="contentContainer">
                                    <Stack horizontal>
                                        <Stack>
                                            <div style={createProjectTitle}>
                                                {pageTitle}
                                            </div>
                                        </Stack>
                                    </Stack>
                                    {/* Title */}
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Title <span style={colorred}>*</span>
                                        </div>
                                        <div>
                                            <TextField
                                                name="title"
                                                placeholder="Enter title"
                                                value={title}
                                                onChange={onChangeTitleValue}
                                                styles={textFieldStyles}
                                                disabled={projectContext?.isBillable}
                                            />
                                        </div>
                                    </Stack>

                                    {/* Description */}
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Description <span style={colorred}>*</span>
                                        </div>
                                        <div>
                                            <TextField
                                                name="description"
                                                placeholder="Enter description"
                                                value={description}
                                                onChange={onChangeDescriptionValue}
                                                styles={textFieldDescStyles}
                                                disabled={projectContext?.isBillable}
                                                multiline
                                                 />
                                        </div>
                                    </Stack>

                                    {/* Owner */}
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Owner
                                        </div>
                                        {buttonName === "Create" && (
                                            <div style={controlPeoplePickerStyles}>
                                                <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                                    <PeoplePicker
                                                        peopleType="owner"
                                                        scenario={buttonName}
                                                    />
                                                </ProjectContext.Provider>
                                            </div>
                                        )}
                                        {buttonName != "Create" && (
                                            <div style={controlPeoplePickerStyles}>
                                                <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                                    <PeoplePicker
                                                        peopleType="owner"
                                                        scenario={buttonName}
                                                    />
                                                </ProjectContext.Provider>
                                            </div>
                                        )}
                                        <Dialog
                                            hidden={hideMemberDeleteDialog}
                                            onDismiss={toggleMemberDeleteHideDialog}
                                            dialogContentProps={deleteOwnerdialogContentProps}
                                        >
                                            There should be atleast 1 owner present for the project. Please add a owner for this project.
                                            <DialogFooter>
                                                <DefaultButton onClick={toggleMemberDeleteHideDialog} text="Close" />
                                            </DialogFooter>
                                        </Dialog>
                                    </Stack>

                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Work Area
                                        </div>
                                        {isGettingProjectWorkArea && (
                                            <>
                                                <div style={{ marginTop: -200, left: '20%' }}>
                                                    <span role="alert" aria-label={GenericMessages.gettingWorkArea} aria-live="assertive" />
                                                    <DisplaySpinner
                                                        accessabilityMessage={GenericMessages.gettingWorkArea}
                                                        spinnerPosition={"right"}
                                                        spinnerText={GenericMessages.gettingWorkArea}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {!isGettingProjectWorkArea && (<div style={controlWidth}>
                                            <Dropdown
                                                //label="Work Area"
                                                selectedKey={selectedItem ? selectedItem.key : undefined}
                                                // eslint-disable-next-line react/jsx-no-bind
                                                onChange={onChangeWorkArea}
                                                placeholder="Select Work Area"
                                                options={dropdownWorkAreaOptions}
                                                style={{ fontSize: 12 }}
                                                disabled={projectContext?.number !== "" && projectContext?.number?.indexOf('KMTeamAPP') === -1}
                                                hidden={!isProjectGettingCreated && projectContext?.workArea === ''}
                                                defaultValue={defaultWorkAreaValue}
                                                defaultSelectedKey={defaultWorkAreaKey}
                                            />
                                        </div>
                                        )}
                                    </Stack>
                                    {isLitigationCaseResultsLoading && (canUserAddLitigationCases || projectMember?.alias?.includes(loginUserpersonaProps?.secondaryText) || projectOwners?.alias?.includes(loginUserpersonaProps?.secondaryText)) && (
                                        <>
                                            <div style={{ marginTop: -190, left: '20%' }}>
                                                <span role="alert" aria-label={GenericMessages.getLitigationCases} aria-live="assertive" />
                                                <DisplaySpinner
                                                    accessabilityMessage={GenericMessages.getLitigationCases}
                                                    spinnerPosition={"right"}
                                                    spinnerText={GenericMessages.getLitigationCases}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {isLitigation && (canUserAddLitigationCases || projectMember?.alias?.includes(loginUserpersonaProps?.secondaryText) || projectOwners?.alias?.includes(loginUserpersonaProps?.secondaryText)) && !isGettingProjectWorkArea && !isLitigationCaseResultsLoading && (
                                        <>
                                            <Stack style={{ ...assingPadding, marginBottom: 30 }}>
                                                <div style={formText}>
                                                    Litigation Case
                                                </div>
                                                <div style={controlWidth}>
                                                    <CasePicker
                                                        onAddTag={onAddCase}
                                                        onCategorySelected={onCaseSelected}
                                                        onResolveSuggestions={onResolveCaseSuggestions}
                                                        onChange={onChangeCase}
                                                        selectedItems={selectedCases}
                                                        casesList={CaseList}
                                                    />
                                                </div>
                                            </Stack>
                                        </>
                                    )}

                                    {/* Upload Document */}
                                    <Stack style={assingPadding}>
                                        <Stack style={addAttachment}>
                                            <label style={attachmentLabel} title="Click here to upload your documents">
                                                {isLitigation && (canUserAddLitigationCases || projectMember?.alias?.includes(loginUserpersonaProps?.secondaryText) || projectOwners?.alias?.includes(loginUserpersonaProps?.secondaryText)) ? 'Documents To Review' : 'Upload your file'}
                                            </label>
                                        </Stack>
                                        <Stack horizontal style={controlWidthAttachment}>
                                            <Stack style={controlWidthFiles}>
                                                <div>
                                                    {fileList?.map((file: any, index: number) => {
                                                        return (
                                                            <FormAttachmentView index={index} file={file} removeFileFromState={removeFileFromState} showDate={false} sendFileTags={sendFileTags} />
                                                        )
                                                    })}
                                                </div>
                                            </Stack>
                                        </Stack>
                                        <div style={formText}>
                                            <Stack horizontal style={controlWidthAttachementLabel}>
                                                <Stack style={addAttachment}>
                                                    <button
                                                        style={{
                                                            border: '1px solid lightgray', fontWeight: "600", backgroundColor: 'white', padding: '8px', cursor: 'pointer', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // Box shadow
                                                        }}
                                                        title="Click here to upload your documents"
                                                        onClick={() => document.getElementById('attachment').click()}
                                                    >
                                                        Upload files
                                                    </button>
                                                </Stack>

                                                <input
                                                    style={addFileControl}
                                                    type="file"
                                                    name="file[]"
                                                    id="attachment"
                                                    onChange={handleChange}
                                                    multiple />
                                            </Stack>
                                        </div>
                                    </Stack>

                                    {/* Team Member */}
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Team Member 
                                        </div>
                                        <Stack horizontal style={controlPeoplePickerStyles}>
                                            <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                                <PeoplePicker
                                                    peopleType="member"
                                                />
                                            </ProjectContext.Provider>
                                        </Stack>
                                    </Stack>

                                    {/* CELA People */}
                                    <Stack style={assingPadding}>
                                        <Stack style={controlWidth} horizontal>


                                            <Stack>
                                                <div>
                                                    <div style={formText}>People in CELA</div>
                                                </div>
                                            </Stack>
                                            <Stack>
                                                <div style={chkStyle}>
                                                    <Checkbox
                                                        onChange={togglePermissionsToCelaGroup}
                                                        ariaLabel="Select checkbox to include all peopel in CELA"
                                                        checked={isCELALegalGroupChecked}
                                                        disabled={projectContext?.isBillable}
                                                    />
                                                </div>
                                            </Stack>

                                        </Stack>
                                    </Stack>

                                    {/* Start and End Date */}

                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Start Date
                                        </div>
                                        <div>
                                            <DatePicker
                                                isRequired={false}
                                                allowTextInput={true}
                                                showMonthPickerAsOverlay={true}
                                                onSelectDate={onSelectStartDate}
                                                formatDate={formatDate}
                                                value={startDate}
                                                showGoToToday={false}
                                                styles={customDatePickerStyles}
                                                disabled={projectContext?.isBillable}
                                            />
                                        </div>
                                    </Stack>
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Closing Date
                                        </div>
                                        <div>
                                            <DatePicker
                                                isRequired={false}
                                                allowTextInput={true}
                                                showMonthPickerAsOverlay={true}
                                                onSelectDate={onSelectEndDate}
                                                formatDate={formatDate}
                                                value={endDate}
                                                showGoToToday={false}
                                                styles={customDatePickerStyles}
                                                disabled={projectContext?.isBillable}
                                            />
                                        </div>
                                    </Stack>


                                    {/* Status */}
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Status
                                        </div>
                                        <div style={controlWidth}>
                                            <Dropdown
                                                selectedKey={selectedStatus ? selectedStatus.key : undefined}
                                                onChange={onStatusChange}
                                                options={dropDownOptions}
                                                styles={dropdownStyles}
                                                disabled={projectContext?.isBillable}
                                            />
                                        </div>
                                    </Stack>

                                    {/* Project Tags */}
                                    <Stack style={assingPadding}>
                                        <div style={formText}>
                                            Project Tags
                                        </div>
                                        <div style={tagPickerStyling}>
                                            <CustomTagPicker
                                                onAddTag={onAddTag}
                                                onCategorySelected={onCategorySelected}
                                                onResolveSuggestions={onResolveSuggestions}
                                                onChange={onChange}
                                                selectedItems={selectedTags}
                                                scenario="ProjectTags"
                                                isBillable={projectContext.isBillable}
                                            />
                                        </div>
                                    </Stack>

                                    {/* {isLitigation &&
                                        <>
                                            <Stack style={assingPadding}>
                                                <div style={formText}>Does this require a outside counsel?</div>
                                                <Stack>
                                                    <ChoiceGroup options={outsideCounselOptions} onChange={handleoutsideCounselSelection} />
                                                </Stack>
                                            </Stack></>
                                    }
                                    {isRPSelected && isLitigation &&
                                        <>
                                            <Stack style={assingPadding}>
                                                <Stack horizontal>
                                                    <Stack><div style={formText}>Responsible professional </div></Stack>
                                                    <Stack><span style={{ marginTop: 5, color: 'red' }}>*</span></Stack>
                                                </Stack>
                                                <Stack >
                                                    <Stack horizontal style={controlPeoplePickerStyles}>
                                                        <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                                            <PeoplePicker
                                                                peopleType="member"
                                                            />
                                                        </ProjectContext.Provider>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </>
                                    } */}
                                    {/* Buttons */}
                                    <Dialog
                                        hidden={hideConfirmationDialog}
                                        onDismiss={toggleHideConfirmationDialog}
                                        dialogContentProps={dialogConfirmationContentProps}
                                    >
                                        You have some unsaved changes which can be lost if you navigate back. Do you want to navigate back?
                                        <DialogFooter>
                                            <PrimaryButton onClick={() => goBackAfterConfirmationRedirect()} text="Go Back" />
                                            <DefaultButton onClick={toggleHideConfirmationDialog} text="Stay on Page" />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                                <div className="buttonContainer">
                                    <Stack horizontal>
                                        <Stack>
                                            <button onClick={() => createProject()} style={createButtonStyle}>
                                                <div style={createButtonText}>{buttonName}</div>
                                            </button>
                                        </Stack>
                                        <Stack>
                                            <button style={cancelButtonStyle} onClick={() => handleCancelClick()}>
                                                <div style={cancelButtonText} > Cancel </div>
                                            </button>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Stack>
                            {projectContext && projectContext?.number?.indexOf('KMTeamAPP') === -1 && (
                                <div className='edit-copilot-container' style={{top: isLoadedInBrowser?'100px':'50px'}}>
                                    <Stack>
                                        <div style={{ marginLeft: 'auto' }}>
                                            {/* Copilot Icon */}
                                            {!showLitigationChatModal && (
                                                <img
                                                    src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg"
                                                    alt="CoPilot Icon"
                                                    onClick={handleOpenLitigationChatModal}
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: 24,
                                                        height: 24,

                                                    }}
                                                />
                                            )}
                                        </div>
                                        {/* Chat Window */}
                                        {showLitigationChatModal && (
                                            <Stack>
                                                <Stack horizontal>
                                                    <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { marginLeft: 10 } }}>
                                                        <img
                                                            src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg"
                                                            alt="CoPilot Icon"
                                                            onClick={handleOpenLitigationChatModal}
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: 24,
                                                                height: 24,

                                                            }}
                                                        />
                                                        <Stack className="editcopilot-chat-label">Legal Ease AI Assistant</Stack>
                                                    </Stack>

                                                    <Stack className="edit-copilot-chat-close"><span tabIndex={0} style={{ cursor: 'pointer' }} onClick={handleOpenLitigationChatModal}>X</span></Stack>
                                                </Stack>
                                                <div>
                                                    <ChatHome user={loginUserpersonaProps} projectNumber={projectContext?.number} />
                                                </div>
                                            </Stack>
                                        )}
                                    </Stack>
                                </div>
                            )}
                        </Stack>
                    </div>
                </>
            )
            }
        </Stack >
    )
}

export default CreateProjectPage;