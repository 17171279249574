import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { useLoginOnStartup } from '@micro-frontend-react/employee-experience/lib/useLoginOnStartup';
import { SideNav } from './Components/Shared/Components/SideNav';
import { Main } from './Components/Shared/Components/Main';
import { ShellWithStore } from './ShellWithStore';
import './App.css';
import { navConfig } from './navConfig';
import { Routes } from './Routes';
import { Reducer } from "redux";
import * as microsoftTeams from '@microsoft/teams-js';
import { TopHeader } from './Components/Shared/Components/SecondaryHeader/TopHeader';
import { Link, Stack, initializeIcons } from '@fluentui/react';
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IProjectAppState } from './Shared/ProjectState';
import { ProjectReducerName, projectReducer, projectInitialState } from './Shared/Reducers/Project.reducer';
import { projectSagas } from './Shared/Sagas/Project.sagas';
import { isProjectTeamAppLoadedInBrowser, requestBannerInformation, requestFeatureFlagInformation, requestLoginuserProfile, requestUserNotification } from './Shared/Actions/Project.action';
import { IUserNotificationRequest } from './Models/UserNotification/IUserNotificationRequest';
import ErrorDisplay from './Common/Components/ErrorDisplay';
import { useUser } from '@micro-frontend-react/employee-experience/lib/useUser';
import { useGraphPhoto } from '@micro-frontend-react/employee-experience/lib/useGraphPhoto';

function App(): React.ReactElement {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        loginUserpersonaProps, featureFlagInformation, bannerResponseError, isLoadingErrorBannerInformation
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const [isLoadedInTeams, setIsLoadedInTeams] = React.useState<boolean>(true);
    const [isLoadedInBrowser, setIsLoadedInBrowser] = React.useState<boolean>(false);
    const user = useUser();
    const userPhoto = useGraphPhoto();
    React.useEffect(() => {
        if(microsoftTeams && Object.keys(microsoftTeams).length>0){
            microsoftTeams.initialize();
            microsoftTeams.appInitialization.notifySuccess();
            microsoftTeams.getContext((context) => {
                setIsLoadedInTeams(true);                               
                dispatch(requestLoginuserProfile(context?.loginHint));                
            });
            setIsLoadedInTeams(false);
            setIsLoadedInBrowser(true)
        }
        
    }, []);

    React.useEffect(() => {
        if (isLoadedInBrowser) {
            dispatch(isProjectTeamAppLoadedInBrowser(isLoadedInBrowser));
        }
    }, [isLoadedInBrowser]);

    React.useEffect(() => {
        if (isLoadedInTeams) {
            dispatch(isProjectTeamAppLoadedInBrowser(false));
        }
    }, [isLoadedInTeams]);

    React.useEffect(() => {
        if (user?.email) {
            dispatch(requestLoginuserProfile(user?.email));
            document.title = __APP_NAME__
        }
    }, [user]);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            dispatch(requestFeatureFlagInformation(loginUserpersonaProps.secondaryText));
        }
    }, [loginUserpersonaProps]);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText && featureFlagInformation && featureFlagInformation?.isFeatureEnabled) {
            dispatch(requestBannerInformation(loginUserpersonaProps.secondaryText));
            const userNotificationRequest: IUserNotificationRequest = {
                userAlias: loginUserpersonaProps?.secondaryText,
                currentPage: 1,
                recordsPerPage: 5,
                sortColumn: 'NotificationDate',
                sortOrder: 'desc'
            }
            dispatch(requestUserNotification(userNotificationRequest));
        }
    }, [featureFlagInformation, loginUserpersonaProps]);

    useLoginOnStartup();
    initializeIcons();
    initializeFileTypeIcons(undefined);

    return (
        <BrowserRouter>
            {!isLoadedInTeams && (
                <>
                    <TopHeader upn={''} displayName={''} />                    
                </>
            )}
            {(isLoadedInTeams || isLoadedInBrowser) && !isLoadingErrorBannerInformation && loginUserpersonaProps?.secondaryText && (
                <Main>
                    <Routes />
                </Main>
            )}
            {isLoadingErrorBannerInformation && (
                <>
                    <ErrorDisplay />
                </>
            )}

        </BrowserRouter>
    );
}

render(

    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);
