import * as React from "react";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { IBasePickerSuggestionsProps, NormalPeoplePicker } from "@fluentui/react/lib/Pickers";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";

import { Reducer } from "redux";

import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { projectInitialState, projectReducer, ProjectReducerName } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { ProjectAPI } from "../../Services/ProjectAPI";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { PaginationType } from "../../Helpers/ProjectConstants";
import { FC } from "react";
import useGraphToken from "../Hooks/useGraphToken";
import DisplaySpinner from "../../Common/Components/DisplaySpinner";
import { container, peoplePickerDescStyles, peoplePickerStyle, reset } from "./Styles/PeoplePicker";
import { ProjectContext } from "../V2/Project/CreateProjectPage";
import { updatedeletedmembers } from "../../Shared/Actions/Project.action";
import { useSelector } from "react-redux";
import { IProjectAppState } from "../../Shared/ProjectState";
import { Icon, TooltipHost } from "@fluentui/react";


interface loginUserProps {
  peopleType: string,
  scenario?: string,
}

const PeoplePicker: FC<loginUserProps> = ({ peopleType, scenario }) => {
  const { projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected } = React.useContext(ProjectContext);
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { dispatch, httpClient } = reduxContext;
  const {
    loginUserpersonaProps,
    projectContext
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  const [selectedTeamMembers, setSelectedTeamMembers] = React.useState<IPersonaProps[]>([]);
  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Suggested People",
    mostRecentlyUsedHeaderText: "Suggested Contacts",
    noResultsFoundText: "No results found",
    loadingText: "Loading",
    showRemoveButtons: true,
    suggestionsAvailableAlertText: "People Picker Suggestions available",
    suggestionsContainerAriaLabel: "Suggested contacts",
  };
  const picker = React.useRef(null);

  React.useEffect(() => {
    if (peopleType === "member" && projectMember && projectMember.membersPicked) {
      setSelectedTeamMembers(projectMember?.membersPicked);
    }
  }, [projectMember])

  React.useEffect(() => {
    if (peopleType === "owner" && projectOwners && projectOwners?.membersPicked) {
      setSelectedTeamMembers(projectOwners?.membersPicked);
    }
  }, [projectOwners])

  React.useEffect(() => {
    if (loginUserpersonaProps && Object.keys(loginUserpersonaProps).length > 0 && peopleType === "owner" && scenario === "Create") {
      setSelectedTeamMembers(selectedTeamMembers => [...selectedTeamMembers, loginUserpersonaProps]);
      const projectOwners: IProjectMembers = { membersPicked: [loginUserpersonaProps], isResponsible: true };
      setProjectOwners(projectOwners);
    }
  }, [loginUserpersonaProps])

  const { isGraphTokenLoading, graphToken } = useGraphToken();

  // Resets the team member state variable
  const resetMembers = () => {
    const emptyProjectMembers: IProjectMembers = {
      isResponsible: false
    };
    setProjectMember(emptyProjectMembers);
  }

  //This function will get people profile from the Graph for the filteredText
  const onFilterChanged = async (usersToFilter: string, currentPersonas: IPersonaProps[]): Promise<IPersonaProps[]> => {
    const limitUsersFromGraph: number = +PaginationType.limitUsersFromGraph;
    if (usersToFilter) {
      try {
        var usersFromGraph = await ProjectAPI.getFilteredUsers(usersToFilter, httpClient, limitUsersFromGraph, graphToken);
        const filteredPeoplesFromGraph = async () => {
          return Promise.all(
            usersFromGraph.map(async (userFromGraph: any) => {
              let userProfileImage: string = "";
              try {
                userProfileImage = URL.createObjectURL(await ProjectAPI.getUserPhoto(userFromGraph.mail, httpClient, graphToken));
              } catch {
                userProfileImage = "";
              }
              let personaObjet: IPersonaProps = {
                text: userFromGraph?.displayName,
                imageUrl: userProfileImage,
                secondaryText: userFromGraph?.onPremisesImmutableId ? userFromGraph.userPrincipalName : userFromGraph?.mail,
                showSecondaryText: true,
                primaryText: userFromGraph?.jobTitle ?? userFromGraph?.displayName,
                hidePersonaDetails: false,
                tertiaryText: "true",
                optionalText: userFromGraph?.onPremisesImmutableId ? userFromGraph?.onPremisesImmutableId : userFromGraph?.id,
                onRenderPrimaryText: (props) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{userFromGraph?.displayName}</span>
                    {userFromGraph?.externalUserState === "PendingAcceptance" && (
                      <TooltipHost
                        content="This user is part of the Microsoft tenant but has not yet accepted the invitation"
                        id="tooltipHost"
                        calloutProps={{ gapSpace: 0 }}
                        closeDelay={300}
                      >
                        <Icon iconName="AwayStatus" style={{ marginLeft: 5, color: 'orange' }} aria-describedby="tooltipHost" />
                      </TooltipHost>
                    )}
                  </div>
                ),
              };
              return personaObjet;
            })
          );
        };

        const filteredUsers = await filteredPeoplesFromGraph();
        if (Object.keys(filteredUsers)?.length === 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(usersToFilter)) {
          const username = usersToFilter.split('@')[0]; // Extract text before '@'
          let personaObjet: IPersonaProps = {
            text: username,
            secondaryText: usersToFilter,
            showSecondaryText: true,
            primaryText: username,
            hidePersonaDetails: false,
            tertiaryText: "true",
            optionalText: usersToFilter,
            onRenderPrimaryText: (props) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>{username}</span>
                <TooltipHost
                  content="This user is not currently part of the Microsoft tenant. An invitation link will be sent to them as part of the project notifications"
                  id="tooltipHost"
                  calloutProps={{ gapSpace: 0 }}
                  closeDelay={300}
                >
                  <Icon iconName="ErrorBadge" style={{ marginLeft: 5, color: 'red' }} aria-describedby="tooltipHost" />
                </TooltipHost>
              </div>
            ),
          };

          filteredUsers.push(personaObjet);
        }
        return filteredUsers;
      } catch (error: any) {
        return [];
      }
    } else {
      return [];
    }
  };

  function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
    return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
  }

  function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.secondaryText === persona.secondaryText).length > 0;
  }

  function getTextFromItem(persona: IPersonaProps): string {
    return persona.text as string;
  }

  // The functions checks if any member is deleted from ProjectMember/Project Owner list and update the deleted member state
  function checkdeleted(projectMembersSelected: IPersonaProps[]) {
    const projectMembersDeleted: IProjectMembers = { membersPicked: [] };
    deletedProjectMembers?.membersPicked?.map((member: IPersonaProps) => {
      if (!listContainsPersona(member, projectMembersSelected)) {
        const teamMember: IPersonaProps = {
          text: member?.text,
          secondaryText: member?.secondaryText,
          optionalText: member?.optionalText,
          tertiaryText: member?.tertiaryText
        }
        if (member?.tertiaryText === 'false') {
          projectMembersDeleted.membersPicked.push(teamMember)
        }
      }
    });
    if (peopleType === "member") {
      projectMember?.membersPicked?.map((member: IPersonaProps) => {
        if (!listContainsPersona(member, projectMembersSelected)) {
          const teamMember: IPersonaProps = {
            text: member?.text,
            secondaryText: member?.secondaryText,
            optionalText: member?.optionalText,
            tertiaryText: member?.tertiaryText
          }
          if (member?.tertiaryText === 'false') {
            projectMembersDeleted.membersPicked.push(teamMember)
          }
        }
      });
    }
    if (peopleType === "owner") {
      projectOwners?.membersPicked?.map((member: IPersonaProps) => {
        if (!listContainsPersona(member, projectMembersSelected)) {
          const teamMember: IPersonaProps = {
            text: member?.text,
            secondaryText: member?.secondaryText,
            optionalText: member?.optionalText,
            tertiaryText: member?.tertiaryText
          }
          if (member?.tertiaryText === 'false') {
            projectMembersDeleted.membersPicked.push(teamMember)
          }
        }
      });
    }
    console.log("Deleted Member", projectMembersDeleted)
    dispatch(updatedeletedmembers(projectMembersDeleted));
  }

  function onChangeSelection(projectMembersSelected: IPersonaProps[]): void {
    if (projectMembersSelected.length > 0) {
      setSelectedTeamMembers(projectMembersSelected);
      checkdeleted(projectMembersSelected);
      if (peopleType === "owner") {
        const projectOwners: IProjectMembers = { membersPicked: projectMembersSelected, isResponsible: true };
        setProjectOwners(projectOwners);
      }
      else {
        const projectMembers: IProjectMembers = { membersPicked: projectMembersSelected, isResponsible: false };
        setProjectMember(projectMembers)
      }
    }
    else {
      const projectMembers: IProjectMembers = {};
      setSelectedTeamMembers([]);
      checkdeleted(projectMembersSelected);
      if (peopleType === "owner") {
        const projectOwners: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: true };
        setProjectOwners(projectOwners);
      }
      else {
        const projectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };
        setProjectMember(projectMembers)
      }
    }
    setChangeDetected(true);
  }

  return (
    <div style={container}>
      <div style={peoplePickerStyle}>
        <NormalPeoplePicker
          onResolveSuggestions={onFilterChanged}
          onChange={onChangeSelection}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={suggestionProps}
          className={"ms-PeoplePicker"}
          key={"normal"}
          selectedItems={selectedTeamMembers}
          removeButtonAriaLabel={"Remove"}
          inputProps={{
            "aria-label": "Enter the approver's alias",
            "aria-required": true,
            required: true,
            placeholder: peopleType === "member" ? "Enter user alias/name" : "Enter Owner's alias/name",
          }}
          componentRef={picker}
          resolveDelay={1500}
          styles={peoplePickerDescStyles}
          itemLimit={10}
          disabled={projectContext?.isBillable}
        />
      </div>
      {/* <div style={reset} onClick={resetMembers}><div>Reset </div></div> */}
    </div>
  );
};

export default PeoplePicker;
