import { createRef, FC } from "react";
import {
  IIconProps,
  IBasePicker,
  ITag,
  TagPicker,
  IBasePickerSuggestionsProps,
  ValidationState,
  Stack,
} from "@fluentui/react";
import { CSSProperties } from "styled-components";
import { CustomTag } from "../../Models/CustomTags";
import * as React from "react";
import { inputProps, toggleStyles } from "./Styles/CustomTagPicker";

type CustomTagPickerProps = {
  onAddTag: (tagText: string) => Promise<CustomTag>;
  onCategorySelected: (tagId?: number) => void;
  onChange: (items: ITag[] | undefined, index?:number) => void;
  onResolveSuggestions: (filter: string, selectedItems: ITag[] | undefined) => PromiseLike<ITag[]>;
  pickerStyles?: CSSProperties;
  selectedItems?: ITag[];
  scenario?: string;
  isBillable?: boolean;
};

const addIcon: IIconProps = { iconName: "Add" };
const tagPickerRef = createRef<IBasePicker<ITag>>();

const CustomTagPicker: FC<CustomTagPickerProps> = ({ onAddTag, onChange, onResolveSuggestions, pickerStyles, selectedItems, scenario, isBillable }) => {
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Recommended Tags",
    noResultsFoundText: "No results found!",
  };
  
  const getTextFromItem = (item: ITag) => item.name;

  return (
    <Stack grow={2}
      style={pickerStyles}
    >
      <TagPicker
        removeButtonAriaLabel="Remove"
        pickerCalloutProps={{ doNotLayer: true }}
        componentRef={tagPickerRef}        
        styles={toggleStyles}
        onResolveSuggestions={onResolveSuggestions}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={inputProps}
        onChange={onChange}
        selectedItems={selectedItems}
        onValidateInput={(input: string) => {
          return input ? ValidationState.valid : ValidationState.invalid;
        }}
        createGenericItem={(input: string) => {
          return { key: "", name: input } as ITag;
        }}
        onItemSelected={(item?: ITag | undefined) => {
          if (!item?.key) {
            return onAddTag(item?.name || "").then((customTag: CustomTag) => ({
              key: customTag.id,
              name: customTag.text,
            }));
          }
          return item;
        }}
        disabled={isBillable && scenario==="ProjectTags"}
      />
    </Stack>
  );
};

export default CustomTagPicker;
