import { IPersonaProps } from "@fluentui/react";
import { IBannerInformation } from "../../Models/Banner/IBannerInformation";
import { IProjectContext } from "../../Models/IProjectContext";
import { IProjectCreate } from "../../Models/IProjectCreate";
import { IProjectCreationResponse } from "../../Models/IProjectCreationResponse";
import { IProjectFiles } from "../../Models/IProjectFile";
import { IProjectFileTags } from "../../Models/IProjectFileTags";
import { IProjectListDetails } from "../../Models/IProjectListDetails";
import { IProjectListResponse } from "../../Models/IProjectListResponse";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { IProjectProps } from "../../Models/IProjectProps";
import { IProjectSearchRequest } from "../../Models/IProjectSearchRequest";
import { IProjectSearchResponse } from "../../Models/IProjectSearchResponse";
import { IProjectTags } from "../../Models/IProjectTags";
import { ISimilarProjectResponse } from "../../Models/ISimilarProjectResponse";
import { IFeatureFlagInfo } from "../../Models/IFeatureFlagInfo";
import { IUserNotificationRequest } from "../../Models/UserNotification/IUserNotificationRequest";
import { IUserNotificationResponse, IUserNotificationResponses } from "../../Models/UserNotification/IUserNotificationResponse";
import { IProjectWorkArea, IProjectWorkAreas } from "../../Models/IProjectWorkArea";
import { ProjectListFilter, ProjectListType } from "src/Helpers/ProjectEnum";
import { IHistorySearchRequest } from "src/Models/IHistorySearchRequest";
import { IHistoryResponses } from "src/Models/IHistoryResponses";
import { IBookmarkSearchRequest } from "src/Models/IBookmarkSearchRequest";
import { IBookmarkResponses } from "src/Models/IBookmarkResponses";
import { IRecentDocumentsResponses } from "../../Models/IRecentDocumentsResponse";
import { IRequestRecentDocuments } from "../../Models/IRequestRecentDocuments";
import { ILawFirmAdaptiveDetails } from "src/Models/ILawFirmAdaptiveDetails";
import { ILawFirmSearchRequestParams } from "src/Models/ILawFirmSearchParams";
import { ILawFirmInfo } from "src/Models/ILawFirmInfo";
import { ILitigationCase, ILitigationCaseDataResponses, ILitigationCaseFiles, ILitigationCaseFilesResponses, ILitigationCaseResponses, ILitigationFileContentResponses,ILitigationSaveUserTagsResponses } from "src/Models/ILitigationCase";
import { ILitigationCaseDataRequest, ILitigationCaseFilesRequest, ILitigationCaseRequest, ILitigationFileContentRequest, ILitigationSaveUserTagsRequest } from "src/Models/ILitigationCaseRequest";
import { ISaveQueryRequest } from "src/Models/ISaveUserQuery";
import { ILitigationClassifyFileRequest } from "../../Models/ILitigationClassifyFileRequest";
import { ILitigationClassifyFileResponse } from "../../Models/ILitigationClassifyFileResponse";
import { IRequestFilesClassifySummaryByUser } from "../../Models/IRequestFilesClassifySummaryByUser";
import {IResponseFilesClassifySummaryByUser} from '../../Models/IResponseFilesClassifySummaryByUser'
import { IFileHistoryResponse } from "src/Models/FileHistory/IFileHistoryResponse";
import { IFileHistoryRequest } from "src/Models/FileHistory/IFileHistoryRequest";
export type ProjectActions =
    | IRequestProjectCreate
    | IResponseProjectDueDateFailure
    | IResponseProjectCreationSuccess
    | IResponseProjectCreationFailure
    | IRequestProjectEdit
    | IResponseProjectEditSuccess
    | IResponseProjectEditFailure
    | IRequestProjectSearch
    | IResponseProjectSearch
    | IResponseProjectSearchFailure
    | IRequestProjectDueDate
    | IResponseProjectDueDate
    | IRequestProjectDetails
    | IResponseProjectDetails
    | IResponseProjectDetailsFailure
    | ISetProjectSearchRequest
    | IRequestProjectListDetails
    | IRequestProjectListDetailsV2
    | IResponseProjectListDetails
    | IResponseProjectListDetailsFailure
    | ISetProjectContext
    | ISetProjectFilterListDetails
    | IUpdateProjectStateForFileUpload
    | IUpdateDeletedFiles
    | IUpdateTagsForFile
    | IUpdateTagsForProject
    | IUpdateProjectMembers
    | IUpdateProjectOwners
    | IUpdateProjectMembersSharepoint
    | IUpdateProjectMembersDeleted
    | IUpdateProjectMembersStatus
    | IUpdateProjectProps
    | IRequestUpdateProjectMembersNames
    | IRequestUpdateProjectOwnersNames
    | IRequestDeleteFile
    | IResponseDeleteFile
    | IResponseDeleteFileFailure
    | IRequestDeleteMember
    | IResponseDeleteMember
    | IResponseDeleteMemberFailure
    | IRequestMemberStatusUpdate
    | IResponseMemberStatusUpdate
    | IResponseMemberStatusUpdateFailure
    | IRequestLoginuserProfile
    | IResponseLoginuserProfile
    | IResponseLoginUserProfileError
    | IRequestProjectStatusUpdate
    | IResponseProjectStatusUpdate
    | IResponseProjectStatusUpdateFailure
    | IRequestUploadFilesToProject
    | IResponseUploadFilesToProject
    | IResponseUploadFilesToProjectFailure
    | IRequestAddMembersToProject
    | IResponseAddMembersToProject
    | IResponseAddMembersToProjectFailure
    | IRequestGetSimilarProject
    | IResponseGetSimilarProject
    | IResponseGetSimilarProjectFailure
    | IRequestDeleteProject
    | IResponseDeleteProject
    | IResponseDeleteProjectFailure
    | IRequestBannerInformation
    | IResponseBannerInformation
    | IResponseErrorBannerInformation
    | IRequestFeatureFlagInformation
    | IResponseFeatureFlagInformation
    | IRequestUserNotification
    | IResponseUserNotification
    | IResponseErrorUserNotification
    | IUpdateUserNotification
    | IClearMessageState
    | IUpdateProjectCELAPeople
    | IRequestProjectWorkArea
    | IResponseProjectWorkArea
    | IResponseErrorProjectWorkArea
    | IRequestUserHistory
    | IResponseUserHistory
    | IResponseUserHistoryError
    | IRequestToggleBookmark
    | IResponseToggleBookmark
    | IResponseErrorToggleBookmark
    | IRequestBookmark
    | IResponseBookmark
    | IResponseBookmarkError
    | IRequestAddProjectToUserHistory
    | ICreateNewProject
    | ISetIsEditingSuccess
    | IUpdateBannerStatusInState
    | IRequestProjectFileTagsUpdate
    | IResponseProjectFileTagsUpdate
    | IResponseErrorProjectFileTagsUpdate
    | IRequestRecentDocumentsList
    | IResponseRecentDocumentsList
    | IResponseRecentDocumentsListError
    | IResponseLawFirmSearchAction
    | IRequestLawFirmSearch
    | IFailedLawFirmSearchAction
    | ISetLawFirmInfo
    | IRequestLitiagtionCases
    | IResponseLitiagtionCases
    | IResponseLitiagtionCasesError
    | IRequestLitiagtionCaseData
    | IResponseLitiagtionCaseData
    | IResponseLitiagtionCaseDataError
    | IRequestLitiagtionCaseFiles
    | IResponseLitiagtionCaseFiles
    | IResponseLitiagtionCaseFilesError
    | IRequestLitiagtionFileContent
    | IResponseLitiagtionFileContent
    | IResponseLitiagtionFileContentError
    | IRequestLitiagtionFileURL
    | IResponseLitiagtionFileURL
    | IResponseLitiagtionFileURLError
    | IRequestDownloadLitiagtionCaseFiles
    | IResponseDownloadLitiagtionCaseFiles
    | IResponseDownloadLitiagtionCaseFilesError
    | IRequestLitigationSaveUserTags
    | IResponseLitigationSaveUserTags
    | IResetLitigationSaveUserTagsSuccess
    | IResponseLitigationSaveUserTagsError
    | IRequestLitigationFeatureFlag
    | IResponseLitigationFeatureFlag
    | IRequestIsUserPartofLitigationSgGroup
    | IResponseIsUserPartofLitigationSgGroup
    | IIsUserPartofLitigationSgGroupResponseError
    | IsProjectTeamAppLoadedInBrowser
    | IRequestLitigationUserQuery
    | IResponseLitigationUserQuery
    | IResponseLitigationUserQueryError
    | IRequestLitigationSaveUserQuery
    | IResponseLitigationSaveUserQuery
    | IResponseLitigationSaveUserQueryError
    | IRequestLitigationUpdateUserQuery
    | IResponseLitigationUpdateUserQuery
    | IResponseLitigationUpdateUserQueryError
    | IRequestLitigationDeleteQuery
    | IResponseLitigationDeleteQuery
    | IResponseLitigationDeleteQueryError
    | IClassifyFilesRequest
    | IClassifyFilesResponse
    | IClassifyFilesResponseError
    | IFilesClassifySummaryByUserRequest
    |IFilesClassifySummaryByUserResponse
    |IFilesClassifySummaryByUserResponseError
    |IRequestFileHistory
    |IResponseFileHistory
    |IResponseErrorFileHistory
export enum ProjectActionTypes {
    REQUEST_PROJECT_CREATE = "REQUEST_PROJECT_CREATE",
    RESPONSE_PROJECT_CREATION_SUCCESS = "RESPONSE_PROJECT_CREATION_SUCCESS",
    RESPONSE_PROJECT_CREATION_FAILURE = "RESPONSE_PROJECT_CREATION_FAILURE",
    REQUEST_PROJECT_EDIT = "REQUEST_PROJECT_EDIT",
    RESPONSE_PROJECT_EDIT_SUCCESS = "RESPONSE_PROJECT_EDIT_SUCCESS",
    RESPONSE_PROJECT_EDIT_FAILURE = "RESPONSE_PROJECT_EDIT_FAILURE",
    REQUEST_PROJECT_SEARCH = "REQUEST_PROJECT_SEARCH",
    RESPONSE_PROJECT_SEARCH = "RESPONSE_PROJECT_SEARCH",
    RESPONSE_PROJECT_SEARCH_FAILURE = "RESPONSE_PROJECT_SEARCH_FAILURE",
    REQUEST_PROJECT_DUE_DATE = "REQUEST_PROJECT_DUE_DATE",
    RESPONSE_PROJECT_DUE_DATE = "RESPONSE_PROJECT_DUE_DATE",
    RESPONSE_PROJECT_DUE_DATE_FAILURE = "RESPONSE_PROJECT_DUE_DATE_FAILURE",
    REQUEST_PROJECT_DETAILS_BY_ID = "REQUEST_PROJECT_DETAILS_BY_ID",
    RESPONSE_PROJECT_DETAILS_BY_ID = "RESPONSE_PROJECT_DETAILS_BY_ID",
    RESPONSE_PROJECT_DETAILS_BY_ID_FAILURE = "RESPONSE_PROJECT_DETAILS_BY_ID_FAILURE",
    SET_PROJECT_CONTEXT = "SET_PROJECT_CONTEXT",
    SET_PROJECTLIST_CONTEXT = "SET_PROJECTLIST_CONTEXT",
    SET_PROJECTFILTERLIST_DETAILS = "SET_PROJECTFILTERLIST_DETAILS",
    UPDATE_PROJECT_STATE_FOR_FILE_UPLOAD = "UPDATE_PROJECT_STATE_FOR_FILE_UPLOAD",
    UPDATE_DELETED_FILES = "UPDATE_DELETED_FILES",
    UPDATE_TAGS_STATE_FOR_FILE = "UPDATE_TAGS_STATE_FOR_FILE",
    UPDATE_PROJECT_MEMBERS = "UPDATE_PROJECT_MEMBERS",
    UPDATE_PROJECT_OWNERS = "UPDATE_PROJECT_OWNERS",
    UPDATE_PROJECT_MEMBERS_SHAREPOINT = "UPDATE_PROJECT_MEMBERS_SHAREPOINT",
    UPDATE_PROJECT_PROPS = "UPDATE_PROJECT_PROPS",
    UPDATE_PROJECT_MEMBERS_DELETED = "UPDATE_PROJECT_MEMBERS_DELETED",
    UPDATE_PROJECT_MEMBERS_STATUS = "UPDATE_PROJECT_MEMBERS_STATUS",
    UPDATE_TAGS_STATE_FOR_PROJECT = "UPDATE_TAGS_STATE_FOR_PROJECT",
    REQUEST_PROJECT_Detail_List = "REQUEST_PROJECT_Detail_List",
    REQUEST_PROJECT_DETAIL_LIST_V2 = "REQUEST_PROJECT_DETAIL_LIST_V2",
    RESPONSE_PROJECT_Detail_List = "RESPONSE_PROJECT_Detail_List",
    RESPONSE_PROJECT_Detail_List_FAILURE = "RESPONSE_PROJECT_Detail_List_FAILURE",
    REQUEST_UPDATE_PROJECT_MEMBERS_NAMES = "REQUEST_UPDATE_PROJECT_MEMBERS_NAMES",
    REQUEST_UPDATE_PROJECT_OWNERS_NAMES = "REQUEST_UPDATE_PROJECT_OWNERS_NAMES",
    REQUEST_DELETE_FILE = "REQUEST_DELETE_FILE",
    RESPONSE_DELETE_FILE = "RESPONSE_DELETE_FILE",
    RESPONSE_DELETE_FILE_FAILURE = "RESPONSE_DELETE_FILE_FAILURE",
    REQUEST_DELETE_MEMBER = "REQUEST_DELETE_MEMBER",
    RESPONSE_DELETE_MEMBER = "RESPONSE_DELETE_MEMBER",
    RESPONSE_DELETE_MEMBER_FAILURE = "RESPONSE_DELETE_MEMBER_FAILURE",
    REQUEST_MEMBER_STATUS_UPDATE = "REQUEST_MEMBER_STATUS_UPDATE",
    RESPONSE_MEMBER_STATUS_UPDATE = "RESPONSE_MEMBER_STATUS_UPDATE",
    RESPONSE_MEMBER_STATUS_UPDATE_FAILURE = "RESPONSE_MEMBER_STATUS_UPDATE_FAILURE",
    REQUEST_LOGIN_USER_PROFILE = "REQUEST_LOGIN_USER_PROFILE",
    RESPONSE_LOGIN_USER_PROFILE = "RESPONSE_LOGIN_USER_PROFILE",
    RESPONSE_LOGIN_USER_PROFILE_ERROR = "RESPONSE_LOGIN_USER_PROFILE_ERROR",
    REQUEST_PROJECT_STATUS_UPDATE = "REQUEST_PROJECT_STATUS_UPDATE",
    RESPONSE_PROJECT_STATUS_UPDATE = "RESPONSE_PROJECT_STATUS_UPDATE",
    RESPONSE_PROJECT_STATUS_UPDATE_FAILURE = "RESPONSE_PROJECT_STATUS_UPDATE_FAILURE",
    REQUEST_UPLOAD_FILES_TO_PROJECT = "REQUEST_UPLOAD_FILES_TO_PROJECT",
    RESPONSE_UPLOAD_FILES_TO_PROJECT = "RESPONSE_UPLOAD_FILES_TO_PROJECT",
    RESPONSE_UPLOAD_FILES_TO_PROJECT_FAILURE = "RESPONSE_UPLOAD_FILES_TO_PROJECT_FAILURE",
    REQUEST_ADD_MEMBERS_TO_PROJECT = "REQUEST_ADD_MEMBERS_TO_PROJECT",
    RESPONSE_ADD_MEMBERS_TO_PROJECT = "RESPONSE_ADD_MEMBERS_TO_PROJECT",
    RESPONSE_ADD_MEMBERS_TO_PROJECT_FAILURE = "RESPONSE_ADD_MEMBERS_TO_PROJECT_FAILURE",
    REQUEST_GET_SIMILAR_PROJECT = "REQUEST_GET_SIMILAR_PROJECT",
    RESPONSE_GET_SIMILAR_PROJECT = "RESPONSE_GET_SIMILAR_PROJECT",
    RESPONSE_GET_SIMILAR_PROJECT_FAILURE = "RESPONSE_GET_SIMILAR_PROJECT_FAILURE",
    REQUEST_DELETE_PROJECT = "REQUEST_DELETE_PROJECT",
    RESPONSE_DELETE_PROJECT = "RESPONSE_DELETE_PROJECT",
    RESPONSE_DELETE_PROJECT_FAILURE = "RESPONSE_DELETE_PROJECT_FAILURE",
    REQUEST_BANNER_INFORMATION = "REQUEST_BANNER_INFORMATION",
    RESPONSE_BANNER_INFORMATION = "RESPONSE_BANNER_INFORMATION",
    RESPONSE_ERROR_BANNER_INFORMATION = "RESPONSE_ERROR_BANNER_INFORMATION",
    REQUEST_FEATURE_FLAG_INFORMATION = "REQUEST_FEATURE_FLAG_INFORMATION",
    RESPONSE_FEATURE_FLAG_INFORMATION = "RESPONSE_FEATURE_FLAG_INFORMATION",
    REQUEST_USER_NOTIFICATION = "REQUEST_USER_NOTIFICATION",
    RESPONSE_ERROR_USER_NOTIFICATION = "RESPONSE_ERROR_USER_NOTIFICATION",
    RESPONSE_USER_NOTIFICATION = "RESPONSE_USER_NOTIFICATION",
    REQUEST_UPDATE_USER_NOTIFICATION = "REQUEST_UPDATE_USER_NOTIFICATION",
    CLEAR_MESSAGE_STATE = "CLEAR_MESSAGE_STATE",
    UPDATE_PROJECT_CELA_PEOPLE = "UPDATE_PROJECT_CELA_PEOPLE",
    REQUEST_PROJECT_WORKAREA = "REQUEST_PROJECT_WORKAREA",
    RESPONSE_PROJECT_WORKAREA = "RESPONSE_PROJECT_WORKAREA",
    RESPONSE_ERROR_PROJECT_WORKAREA = "RESPONSE_ERROR_PROJECT_WORKAREA",

    REQUEST_USER_HISTORY = "REQUEST_USER_HISTORY",
    RESPONSE_USER_HISTORY = "RESPONSE_USER_HISTORY",
    RESPONSE_USER_HISTORY_ERROR = "RESPONSE_USER_HISTORY_ERROR",

    REQUEST_TOGGLE_BOOKMARK = "REQUEST_TOGGLE_BOOKMARK",
    RESPONSE_TOGGLE_BOOKMARK = "RESPONSE_TOGGLE_BOOKMARK",
    RESPONSE_ERROR_TOGGLE_BOOKMARK = "RESPONSE_ERROR_TOGGLE_BOOKMARK",

    REQUEST_BOOKMARK = "REQUEST_BOOKMARK",
    RESPONSE_BOOKMARK = "RESPONSE_BOOKMARK",
    RESPONSE_BOOKMARK_ERROR = "RESPONSE_BOOKMARK_ERROR",
    REQUEST_ADD_PROJECT_TO_USER_HISTORY = "REQUEST_ADD_PROJECT_TO_USER_HISTORY",
    CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT',

    SET_IS_EDIT_SUCCESS = 'SET_IS_EDIT_SUCCESS',
    UPDATE_BANNER_STATUS_IN_STATE = "UPDATE_BANNER_STATUS_IN_STATE",
   

    REQUEST_RECENT_DOCUMENTS_LIST = "REQUEST_RECENT_DOCUMENTS_LIST",
    RESPONSE_RECENT_DOCUMENTS_LIST = "RESPONSE_RECENT_DOCUMENTS_LIST",
    RESPONSE_RECENT_DOCUMENTS_LIST_ERROR = "RESPONSE_RECENT_DOCUMENTS_LIST_ERROR",
    REQUEST_PROJECT_FILE_TAGS_UPDATE = "REQUEST_PROJECT_FILE_TAGS_UPDATE",
    RESPONSE_PROJECT_FILE_TAGS_UPDATE = "RESPONSE_PROJECT_FILE_TAGS_UPDATE",
    RESPONSE_ERROR_PROJECT_FILE_TAGS_UPDATE = "RESPONSE_ERROR_PROJECT_FILE_TAGS_UPDATE",

    RESPONSE_LAWFIRM_SEARCH = "RESPONSE_LAWFIRM_SEARCH",
    REQUEST_LAWFIRM_SEARCH = "REQUEST_LAWFIRM_SEARCH",
    FAILED_LAWFIRM_SEARCH = "FAILED_LAWFIRM_SEARCH",
    SET_LAW_FIRM_INFO = "SET_LAW_FIRM_INFO",

    REQUEST_LITIGATION_CASES = "REQUEST_LITIGATION_CASES",
    RESPONSE_LITIGATION_CASES = "RESPONSE_LITIGATION_CASES",
    RESPONSE_LITIGATION_CASES_ERROR = "RESPONSE_LITIGATION_CASES_ERROR",
    REQUEST_LITIGATION_CASEDATA = "REQUEST_LITIGATION_CASEDATA",
    RESPONSE_LITIGATION_CASEDATA = "RESPONSE_LITIGATION_CASEDATA",
    RESPONSE_LITIGATION_CASEDATA_ERROR = "RESPONSE_LITIGATION_CASEDATA_ERROR",
    REQUEST_LITIGATION_CASEFILES = "REQUEST_LITIGATION_CASEFILES",
    SET_CASEFILESFILTERLIST_DETAILS = "SET_CASEFILESFILTERLIST_DETAILS",
    RESPONSE_LITIGATION_CASEFILES = "RESPONSE_LITIGATION_CASEFILES",
    RESPONSE_LITIGATION_CASEFILES_ERROR = "RESPONSE_LITIGATION_CASES_CASEFILES",
    REQUEST_LITIGATION_FILECONTENT = "REQUEST_LITIGATION_FILECONTENT",
    RESPONSE_LITIGATION_FILECONTENT = "RESPONSE_LITIGATION_FILECONTENT",
    RESPONSE_LITIGATION_FILECONTENT_ERROR = "RESPONSE_LITIGATION_FILECONTENT_ERROR",
    REQUEST_DOWNLOAD_LITIGATION_CASEFILES = "REQUEST_DOWNLOAD_LITIGATION_CASEFILES",
    RESPONSE_DOWNLOAD_LITIGATION_CASEFILES = "RESPONSE_DOWNLOAD_LITIGATION_CASEFILES",
    RESPONSE_DOWNLOAD_LITIGATION_CASEFILES_ERROR = "RESPONSE_DOWNLOAD_LITIGATION_CASEFILES_ERROR",
    REQUEST_LITIGATION_FILEURL = "REQUEST_LITIGATION_FILEURL",
    RESPONSE_LITIGATION_FILEURL = "RESPONSE_LITIGATION_FILEURL",
    RESPONSE_LITIGATION_FILEURL_ERROR = "RESPONSE_LITIGATION_FILEURL_ERROR",

    REQUEST_LITIGATION_SAVEUSERTAGS = "REQUEST_LITIGATION_SAVEUSERTAGS",
    RESPONSE_LITIGATION_SAVEUSERTAGS = "RESPONSE_LITIGATION_SAVEUSERTAGS",
    RESPONSE_LITIGATION_SAVEUSERTAGS_ERROR = "RESPONSE_LITIGATION_SAVEUSERTAGS_ERROR",
    RESET_LITIGATION_SAVEUSERTAGS_SUCCESS = "RESET_LITIGATION_SAVEUSERTAGS_SUCCESS",

    REQUEST_LITIGATION_FEATURE_FLAG = "REQUEST_LITIGATION_FEATURE_FLAG",
    RESPONSE_LITIGATION_FEATURE_FLAG = "RESPONSE_LITIGATION_FEATURE_FLAG",

    REQUEST_IS_USER_PART_OF_LITIGATION_SG_GROUP = "REQUEST_IS_USER_PART_OF_LITIGATION_SG_GROUP",
    RESPONSE_IS_USER_PART_OF_LITIGATION_SG_GROUP = "RESPONSE_IS_USER_PART_OF_LITIGATION_SG_GROUP",
    IS_USER_PART_OF_LITIGATION_SG_GROUP_RESPONSE_ERROR = "IS_USER_PART_OF_LITIGATION_SG_GROUP_RESPONSE_ERROR",
    IS_PROJECT_TEAM_APP_LOADED_IN_BROWSER = "IS_PROJECT_TEAM_APP_LOADED_IN_BROWSER",

    
    REQUEST_LITIGATION_USER_QUERY = "REQUEST_LITIGATION_USER_QUERY",
    RESPONSE_LITIGATION_USER_QUERY = "RESPONSE_LITIGATION_USER_QUERY",
    RESPONSE_LITIGATION_USER_QUERY_ERROR = "RESPONSE_LITIGATION_USER_QUERY_ERROR",

    REQUEST_SAVE_USER_QUERY = "REQUEST_SAVE_USER_QUERY",
    RESPONSE_SAVE_USER_QUERY = "RESPONSE_SAVE_USER_QUERY",
    RESPONSE_SAVE_USER_QUERY_ERROR = "RESPONSE_SAVE_USER_QUERY_ERROR",

    REQUEST_UPDATE_USER_QUERY = "REQUEST_UPDATE_USER_QUERY",
    RESPONSE_UPDATE_USER_QUERY = "RESPONSE_UPDATE_USER_QUERY",
    RESPONSE_UPDATE_USER_QUERY_ERROR = "RESPONSE_UPDATE_USER_QUERY_ERROR",

    REQUEST_DELETE_USER_QUERY = "REQUEST_DELETE_USER_QUERY",
    RESPONSE_DELETE_USER_QUERY = "RESPONSE_DELETE_USER_QUERY",
    RESPONSE_DELETE_USER_QUERY_ERROR = "RESPONSE_DELETE_USER_QUERY_ERROR",

    CLASSIFY_FILES_REQUEST = "CLASSIFY_FILES_REQUEST",
    CLASSIFY_FILES_RESPONSE = "CLASSIFY_FILES_RESPONSE",
    CLASSIFY_FILES_RESPONSE_ERROR = "CLASSIFY_FILES_RESPONSE_ERROR",
    FILES_CLASSIFY_SUMMARY_BY_USER_REQUEST = "FILES_CLASSIFY_SUMMARY_BY_USER_REQUEST",
    FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE = "FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE",
    FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE_ERROR = 'FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE_ERROR',

    REQUEST_FILE_HISTORY = "REQUEST_FILE_HISTORY",
    RESPONSE_FILE_HISTORY = "RESPONSE_FILE_HISTORY",
    RESPONSE_ERROR_FILE_HISTORY = "RESPONSE_ERROR_FILE_HISTORY",
}

//-----------------------Recent document start------------------
export interface IRequestRecentDocumentsList {
    type: ProjectActionTypes.REQUEST_RECENT_DOCUMENTS_LIST;
    requestRecentDocuments: IRequestRecentDocuments
}
export interface IResponseRecentDocumentsList {
    type: ProjectActionTypes.RESPONSE_RECENT_DOCUMENTS_LIST;
    recentDocumentsResponses: IRecentDocumentsResponses
}

export interface IResponseRecentDocumentsListError {
    type: ProjectActionTypes.RESPONSE_RECENT_DOCUMENTS_LIST_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}


//-----------------------Recent document end------------------

export interface ISetIsEditingSuccess {
    type: ProjectActionTypes.SET_IS_EDIT_SUCCESS;
    resetEditSuccessFlag: boolean
}

export interface ICreateNewProject {
    type: ProjectActionTypes.CREATE_NEW_PROJECT;
}
export interface IRequestAddProjectToUserHistory {
    type: ProjectActionTypes.REQUEST_ADD_PROJECT_TO_USER_HISTORY;
    projectNumber: string;
    userAlias: string;
}

//------------------------User notification start------------
export interface IUpdateUserNotification {
    type: ProjectActionTypes.REQUEST_UPDATE_USER_NOTIFICATION,
    userNotification: IUserNotificationResponse
}

export interface IRequestUserNotification {
    type: ProjectActionTypes.REQUEST_USER_NOTIFICATION;
    userNotificationRequest: IUserNotificationRequest;
}

export interface IResponseUserNotification {
    type: ProjectActionTypes.RESPONSE_USER_NOTIFICATION;
    userNotificationResponses: IUserNotificationResponses;
}

export interface IResponseErrorUserNotification {
    type: ProjectActionTypes.RESPONSE_ERROR_USER_NOTIFICATION;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}

//------------------------User notification end----------


//------------------------ Banner Information Start ------------------------
export interface IRequestBannerInformation {
    type: ProjectActionTypes.REQUEST_BANNER_INFORMATION;
    loginUserAlias: string;
}
export interface IResponseBannerInformation {
    type: ProjectActionTypes.RESPONSE_BANNER_INFORMATION;
    bannerInformation?: IBannerInformation;
}
export interface IResponseErrorBannerInformation {
    type: ProjectActionTypes.RESPONSE_ERROR_BANNER_INFORMATION;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}
export interface IUpdateBannerStatusInState {
    type: ProjectActionTypes.UPDATE_BANNER_STATUS_IN_STATE;
}
//------------------------ Banner Information End ------------------------

//------------------------ Feature Flag Information Start ------------------------
export interface IRequestFeatureFlagInformation {
    type: ProjectActionTypes.REQUEST_FEATURE_FLAG_INFORMATION;
    loginUserAlias: string;
}
export interface IResponseFeatureFlagInformation {
    type: ProjectActionTypes.RESPONSE_FEATURE_FLAG_INFORMATION;
    featureFlagInformation?: IFeatureFlagInfo;
}
//------------------------ Feature Flag Information End ------------------------

//------------------------ Project Create Start ------------------------
export interface IRequestProjectCreate {
    type: ProjectActionTypes.REQUEST_PROJECT_CREATE;
    projectCreate: IProjectCreate;
}

export interface IResponseProjectCreationSuccess {
    type: ProjectActionTypes.RESPONSE_PROJECT_CREATION_SUCCESS;
    projectCreationResponse: IProjectCreationResponse;
}

export interface IResponseProjectCreationFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_CREATION_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}
//------------------------ Project Create End ------------------------

//------------------------ Project Edit Start ------------------------
export interface IRequestProjectEdit {
    type: ProjectActionTypes.REQUEST_PROJECT_EDIT;
    projectEdit: IProjectCreate;
    projectContext: IProjectContext;
    deletedProjectMembers: IProjectMembers;
}

export interface IResponseProjectEditSuccess {
    type: ProjectActionTypes.RESPONSE_PROJECT_EDIT_SUCCESS;
}

export interface IResponseProjectEditFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_EDIT_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}
//------------------------ Project Edit End ------------------------

//----------------- Project Search Start -----------------
export interface IRequestProjectSearch {
    type: ProjectActionTypes.REQUEST_PROJECT_SEARCH;
    personal_number: string;
    startDate: string;
}

export interface IResponseProjectSearch {
    type: ProjectActionTypes.RESPONSE_PROJECT_SEARCH;
    projectSearchResponse: IProjectSearchResponse;
}

export interface IResponseProjectSearchFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_SEARCH_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}
//----------------- Project Search End -----------------

//----------------- Project Due Date Start -----------------
export interface IRequestProjectDueDate {
    type: ProjectActionTypes.REQUEST_PROJECT_DUE_DATE;
    personal_number: string;
    endDate: string;
}

export interface IResponseProjectDueDate {
    type: ProjectActionTypes.RESPONSE_PROJECT_DUE_DATE;
    projectDueDateResponse: IProjectSearchResponse;
}

export interface IResponseProjectDueDateFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_DUE_DATE_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}
//----------------- Project Due Date End -----------------

//----------------- Project Details Start -----------------
export interface IRequestProjectDetails {
    type: ProjectActionTypes.REQUEST_PROJECT_DETAILS_BY_ID;
    projectContext: IProjectContext;
    userAlias: string;
}

export interface IResponseProjectDetails {
    type: ProjectActionTypes.RESPONSE_PROJECT_DETAILS_BY_ID;
    projectCreate: IProjectCreate;
}

export interface IResponseProjectDetailsFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_DETAILS_BY_ID_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}
//----------------- Project Details End -----------------


//----------------- Project Details List Start -----------------

// DEPRECATED - Use IRequestProjectListDetailsV2 for new development
export interface IRequestProjectListDetails {
    type: ProjectActionTypes.REQUEST_PROJECT_Detail_List;
    personal_number: string;
    projectSearchRequest: IProjectSearchRequest;
}

export interface IRequestProjectListDetailsV2 {
    type: ProjectActionTypes.REQUEST_PROJECT_DETAIL_LIST_V2;
    personal_number: string;
    projectSearchRequest: IProjectSearchRequest;
    projectListType: ProjectListType;
    filter: ProjectListFilter;
    currentPage:number;
    recordsPerPage:number;
}

export interface IResponseProjectListDetails {
    type: ProjectActionTypes.RESPONSE_PROJECT_Detail_List;
    projectListDetails: IProjectListDetails;
    currentPage:number;
}

export interface IResponseProjectListDetailsFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_Detail_List_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}

export interface ISetProjectSearchRequest {
    type: ProjectActionTypes.SET_PROJECTLIST_CONTEXT;
    projectSearchRequest: IProjectSearchRequest;
}

export interface ISetProjectFilterListDetails {
    type: ProjectActionTypes.SET_PROJECTFILTERLIST_DETAILS;
    projectFilterListDetails: IProjectListResponse[];
}


//----------------- Project Details List End -----------------

//  ------------------------ Components Update Start ------------------------
export interface ISetProjectContext {
    type: ProjectActionTypes.SET_PROJECT_CONTEXT;
    projectContext: IProjectContext;
}

export interface IUpdateProjectStateForFileUpload {
    type: ProjectActionTypes.UPDATE_PROJECT_STATE_FOR_FILE_UPLOAD;
    projectFiles: IProjectFiles;
}

export interface IUpdateDeletedFiles {
    type: ProjectActionTypes.UPDATE_DELETED_FILES;
    projectFiles: any;
}

export interface IUpdateTagsForFile {
    type: ProjectActionTypes.UPDATE_TAGS_STATE_FOR_FILE;
    projectFileTags: IProjectFileTags[];
}

export interface IUpdateTagsForProject {
    type: ProjectActionTypes.UPDATE_TAGS_STATE_FOR_PROJECT;
    projectTags: IProjectTags;
}

export interface IUpdateProjectMembers {
    type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS;
    projectMembers: IProjectMembers;
}

export interface IUpdateProjectOwners {
    type: ProjectActionTypes.UPDATE_PROJECT_OWNERS;
    projectOwners: IProjectMembers;
}

export interface IUpdateProjectCELAPeople {
    type: ProjectActionTypes.UPDATE_PROJECT_CELA_PEOPLE;
    projectCELAPeople: IProjectMembers;
}

export interface IUpdateProjectMembersSharepoint {
    type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_SHAREPOINT;
    projectMembersSharepoint: IProjectMembers;
}

export interface IUpdateProjectMembersDeleted {
    type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_DELETED;
    projectMembersDeleted: any;
}

export interface IUpdateProjectMembersStatus {
    type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_STATUS;
    projectMembersToUpdate: any;
    responsibleFor: boolean
}

export interface IUpdateProjectProps {
    type: ProjectActionTypes.UPDATE_PROJECT_PROPS;
    projectProps: IProjectProps;
}

export interface IRequestUpdateProjectMembersNames {
    type: ProjectActionTypes.REQUEST_UPDATE_PROJECT_MEMBERS_NAMES;
    projectMembers: IProjectMembers;
    userAlias: string;
    userName: string
}

export interface IRequestUpdateProjectOwnersNames {
    type: ProjectActionTypes.REQUEST_UPDATE_PROJECT_OWNERS_NAMES;
    projectOwners: IProjectMembers;
    userAlias: string;
    userName: string
}

//  ------------------------ Components Update End ------------------------

// ------------------------ Delete File Start ------------------------
export interface IRequestDeleteFile {
    type: ProjectActionTypes.REQUEST_DELETE_FILE;
    projectContext: IProjectContext;
    fileName: string;
}

export interface IResponseDeleteFile {
    type: ProjectActionTypes.RESPONSE_DELETE_FILE;
}

export interface IResponseDeleteFileFailure {
    type: ProjectActionTypes.RESPONSE_DELETE_FILE_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Delete File End ------------------------

// ------------------------ Delete Member Start ------------------------
export interface IRequestDeleteMember {
    type: ProjectActionTypes.REQUEST_DELETE_MEMBER;
    projectContext: IProjectContext;
    member: any;
    isResponsible: boolean;
    projectProps: IProjectProps;
    lastModifiedBy:string;
}

export interface IResponseDeleteMember {
    type: ProjectActionTypes.RESPONSE_DELETE_MEMBER;
}

export interface IResponseDeleteMemberFailure {
    type: ProjectActionTypes.RESPONSE_DELETE_MEMBER_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Delete Member End ------------------------

// ------------------------ Update Member Status Start ------------------------
export interface IRequestMemberStatusUpdate {
    type: ProjectActionTypes.REQUEST_MEMBER_STATUS_UPDATE;
    projectContext: IProjectContext;
    member: any;
    isResponsible: boolean;
    projectProps: IProjectProps,
}

export interface IResponseMemberStatusUpdate {
    type: ProjectActionTypes.RESPONSE_MEMBER_STATUS_UPDATE;
}

export interface IResponseMemberStatusUpdateFailure {
    type: ProjectActionTypes.RESPONSE_MEMBER_STATUS_UPDATE_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Update Member Status End ------------------------

// ------------------------ Setup LoggedIn Profile Start ------------------------
export interface IRequestLoginuserProfile {
    type: ProjectActionTypes.REQUEST_LOGIN_USER_PROFILE;
    loginUserAlias: string;
}

export interface IResponseLoginuserProfile {
    type: ProjectActionTypes.RESPONSE_LOGIN_USER_PROFILE;
    loginUserpersonaProps: IPersonaProps;
}

export interface IResponseLoginUserProfileError {
    type: ProjectActionTypes.RESPONSE_LOGIN_USER_PROFILE_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Setup LoggedIn Profile End ------------------------

// ------------------------ Update Project Status Start ------------------------
export interface IRequestProjectStatusUpdate {
    type: ProjectActionTypes.REQUEST_PROJECT_STATUS_UPDATE;
    projectContext: IProjectContext;
    projectProps: IProjectProps;
    newStatus: string;
}

export interface IResponseProjectStatusUpdate {
    type: ProjectActionTypes.RESPONSE_PROJECT_STATUS_UPDATE;
    newStatus: string;
}

export interface IResponseProjectStatusUpdateFailure {
    type: ProjectActionTypes.RESPONSE_PROJECT_STATUS_UPDATE_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Update Project Status End ------------------------

//Edit Scenario for File Tags Start
export interface IRequestProjectFileTagsUpdate {
    type: ProjectActionTypes.REQUEST_PROJECT_FILE_TAGS_UPDATE;
    projectFileTags: IProjectFileTags;
    projectContext: IProjectContext;
}
export interface IResponseProjectFileTagsUpdate {
    type: ProjectActionTypes.RESPONSE_PROJECT_FILE_TAGS_UPDATE;
    fileTags: IProjectFileTags
}
export interface IResponseErrorProjectFileTagsUpdate {
    type: ProjectActionTypes.RESPONSE_ERROR_PROJECT_FILE_TAGS_UPDATE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
//Edit Scenario for File Tags End



// ------------------------ Upload Files to existing project Start ------------------------
export interface IRequestUploadFilesToProject {
    type: ProjectActionTypes.REQUEST_UPLOAD_FILES_TO_PROJECT;
    projectContext: IProjectContext;
    projectProps: IProjectProps;
    newFiles: IProjectFiles;
}

export interface IResponseUploadFilesToProject {
    type: ProjectActionTypes.RESPONSE_UPLOAD_FILES_TO_PROJECT;
    newFiles: IProjectFiles;
}

export interface IResponseUploadFilesToProjectFailure {
    type: ProjectActionTypes.RESPONSE_UPLOAD_FILES_TO_PROJECT_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Upload Files to existing project End ------------------------

// ------------------------ Add Members to existing project Start ------------------------
export interface IRequestAddMembersToProject {
    type: ProjectActionTypes.REQUEST_ADD_MEMBERS_TO_PROJECT;
    projectContext: IProjectContext;
    projectProps: IProjectProps;
    newMember: any,
    lastModifiedBy: string
}

export interface IResponseAddMembersToProject {
    type: ProjectActionTypes.RESPONSE_ADD_MEMBERS_TO_PROJECT;
    newMember: any
}

export interface IResponseAddMembersToProjectFailure {
    type: ProjectActionTypes.RESPONSE_ADD_MEMBERS_TO_PROJECT_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Add Members to existing project End ------------------------

// ------------------------ Get Similar Project Start ------------------------
export interface IRequestGetSimilarProject {
    type: ProjectActionTypes.REQUEST_GET_SIMILAR_PROJECT;
    projectProps: IProjectProps;
    userAlias: string
}

export interface IResponseGetSimilarProject {
    type: ProjectActionTypes.RESPONSE_GET_SIMILAR_PROJECT;
    projectSearchResponse: ISimilarProjectResponse
}

export interface IResponseGetSimilarProjectFailure {
    type: ProjectActionTypes.RESPONSE_GET_SIMILAR_PROJECT_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Get Similar Project End ------------------------

// ------------------------ Delete Project Start ------------------------
export interface IRequestDeleteProject {
    type: ProjectActionTypes.REQUEST_DELETE_PROJECT;
    projectNumber: string
}

export interface IResponseDeleteProject {
    type: ProjectActionTypes.RESPONSE_DELETE_PROJECT;
}

export interface IResponseDeleteProjectFailure {
    type: ProjectActionTypes.RESPONSE_DELETE_PROJECT_FAILURE;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
// ------------------------ Delete Project End ------------------------

export interface IClearMessageState {
    type: ProjectActionTypes.CLEAR_MESSAGE_STATE;
}

//Project Work Area
export interface IRequestProjectWorkArea {
    type: ProjectActionTypes.REQUEST_PROJECT_WORKAREA;
}

export interface IResponseProjectWorkArea {
    type: ProjectActionTypes.RESPONSE_PROJECT_WORKAREA;
    projectworkAreaResponses: IProjectWorkAreas;
}

export interface IResponseErrorProjectWorkArea {
    type: ProjectActionTypes.RESPONSE_ERROR_PROJECT_WORKAREA;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

//Project Work Area

// User history
export interface IRequestUserHistory {
    type: ProjectActionTypes.REQUEST_USER_HISTORY;
    historySearchRequest: IHistorySearchRequest;
}

export interface IResponseUserHistory {
    type: ProjectActionTypes.RESPONSE_USER_HISTORY;
    historyResponses: IHistoryResponses;
}

export interface IResponseUserHistoryError {
    type: ProjectActionTypes.RESPONSE_USER_HISTORY_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

//------------User history end--------


// Toggle bookmark for user history page
export interface IRequestToggleBookmark {
    type: ProjectActionTypes.REQUEST_TOGGLE_BOOKMARK;
    isBookmarked: boolean;
    projectNumber: string;
    userAlias: string;
}

export interface IResponseToggleBookmark {
    type: ProjectActionTypes.RESPONSE_TOGGLE_BOOKMARK;
    bookMarkStatus: boolean;
}

export interface IResponseErrorToggleBookmark {
    type: ProjectActionTypes.RESPONSE_ERROR_TOGGLE_BOOKMARK;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

//-----------End toggle bookmark user history


// Bookmark
export interface IRequestBookmark {
    type: ProjectActionTypes.REQUEST_BOOKMARK;
    bookmarkSearchRequest: IBookmarkSearchRequest;
}

export interface IResponseBookmark {
    type: ProjectActionTypes.RESPONSE_BOOKMARK;
    bookmarkResponses: IBookmarkResponses;
}

export interface IResponseBookmarkError {
    type: ProjectActionTypes.RESPONSE_BOOKMARK_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

//------------Bookmark end--------

//Request for law firm search
export interface IResponseLawFirmSearchAction {
    type: ProjectActionTypes.RESPONSE_LAWFIRM_SEARCH;
    lawFirmAdaptiveDetails: ILawFirmAdaptiveDetails;
}

export interface IRequestLawFirmSearch {
    type: ProjectActionTypes.REQUEST_LAWFIRM_SEARCH;
    searchCriteria: ILawFirmSearchRequestParams
}

export interface IFailedLawFirmSearchAction {
    type: ProjectActionTypes.FAILED_LAWFIRM_SEARCH;
    error?: Error;
    errorMessage?: string;
}

export interface ISetLawFirmInfo {
    type: ProjectActionTypes.SET_LAW_FIRM_INFO;
    lawFirmInfo: ILawFirmInfo;
}

//-----------------Litigation Case Start-----------------

export interface IRequestLitiagtionCases {
    type: ProjectActionTypes.REQUEST_LITIGATION_CASES;
    litigationCaseRequest: ILitigationCaseRequest;
}

export interface IResponseLitiagtionCases {
    type: ProjectActionTypes.RESPONSE_LITIGATION_CASES;
    litigationCaseResponses: ILitigationCaseResponses;
}

export interface IResponseLitiagtionCasesError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_CASES_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IRequestLitiagtionCaseData {
    type: ProjectActionTypes.REQUEST_LITIGATION_CASEDATA;
    litigationCaseDataRequest: ILitigationCaseDataRequest;
}

export interface IResponseLitiagtionCaseData {
    type: ProjectActionTypes.RESPONSE_LITIGATION_CASEDATA;
    litigationCaseDataResponses: ILitigationCaseDataResponses;
}

export interface IResponseLitiagtionCaseDataError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_CASEDATA_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IRequestLitiagtionCaseFiles {
    type: ProjectActionTypes.REQUEST_LITIGATION_CASEFILES;
    litigationCaseFilesRequest: ILitigationCaseFilesRequest;
}

export interface ISetCaseFilesFilterListDetails {
    type: ProjectActionTypes.SET_CASEFILESFILTERLIST_DETAILS;
    caseFilesFilterListDetails: ILitigationCaseFiles[];
}

export interface IResponseLitiagtionCaseFiles {
    type: ProjectActionTypes.RESPONSE_LITIGATION_CASEFILES;
    litigationCaseFilesResponses: ILitigationCaseFilesResponses;
}

export interface IResponseLitiagtionCaseFilesError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_CASEFILES_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IRequestDownloadLitiagtionCaseFiles {
    type: ProjectActionTypes.REQUEST_DOWNLOAD_LITIGATION_CASEFILES;
    litigationCaseFilesRequest: ILitigationCaseFilesRequest;
}



export interface IResponseDownloadLitiagtionCaseFiles {
    type: ProjectActionTypes.RESPONSE_DOWNLOAD_LITIGATION_CASEFILES;

}

export interface IResponseDownloadLitiagtionCaseFilesError {
    type: ProjectActionTypes.RESPONSE_DOWNLOAD_LITIGATION_CASEFILES_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IRequestLitiagtionFileContent {
    type: ProjectActionTypes.REQUEST_LITIGATION_FILECONTENT;
    litigationFileContentRequest: ILitigationFileContentRequest;
}

export interface IResponseLitiagtionFileContent {
    type: ProjectActionTypes.RESPONSE_LITIGATION_FILECONTENT;
    litigationFileContentResponses: ILitigationFileContentResponses;
}

export interface IResponseLitiagtionFileContentError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_FILECONTENT_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IRequestLitiagtionFileURL {
    type?: ProjectActionTypes.REQUEST_LITIGATION_FILEURL;
    litigationFileURLRequest: ILitigationFileContentRequest;
}

export interface IResponseLitiagtionFileURL {
    type: ProjectActionTypes.RESPONSE_LITIGATION_FILEURL;
    litigationFileURLResponses: ILitigationFileContentResponses;
}

export interface IResponseLitiagtionFileURLError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_FILEURL_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IRequestLitigationFeatureFlag {
    type: ProjectActionTypes.REQUEST_LITIGATION_FEATURE_FLAG;
    loginUserAlias: string;
    featureName: string;
}
export interface IResponseLitigationFeatureFlag {
    type: ProjectActionTypes.RESPONSE_LITIGATION_FEATURE_FLAG;
    litigationFeatureFlagResponse?: IFeatureFlagInfo;
}

export interface IRequestLitigationSaveUserTags {
    type: ProjectActionTypes.REQUEST_LITIGATION_SAVEUSERTAGS;
    litigationSaveUserTagsRequest: ILitigationSaveUserTagsRequest;
}

export interface IResponseLitigationSaveUserTags {
    type: ProjectActionTypes.RESPONSE_LITIGATION_SAVEUSERTAGS;
    litigationSaveUserTagsResponses: ILitigationSaveUserTagsResponses;
}

export interface IResponseLitigationSaveUserTagsError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_SAVEUSERTAGS_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}

export interface IResetLitigationSaveUserTagsSuccess {
    type: ProjectActionTypes.RESET_LITIGATION_SAVEUSERTAGS_SUCCESS;
    resetSaveUsertagsSuccess: boolean;
}

// ----------------Litigation Case End ------------------------

//-------------------Litigation user Permission Check Start --------------
export interface IRequestIsUserPartofLitigationSgGroup {
    type: ProjectActionTypes.REQUEST_IS_USER_PART_OF_LITIGATION_SG_GROUP;
    userAlias?: string;
}

export interface IResponseIsUserPartofLitigationSgGroup {
    type: ProjectActionTypes.RESPONSE_IS_USER_PART_OF_LITIGATION_SG_GROUP;
    isuserPartOfLitigationSGGroup?: boolean;
}

export interface IIsUserPartofLitigationSgGroupResponseError {
    type: ProjectActionTypes.IS_USER_PART_OF_LITIGATION_SG_GROUP_RESPONSE_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId?: string;
}
//-------------------Litigation user Permission Check End --------------
export interface IsProjectTeamAppLoadedInBrowser {
    type: ProjectActionTypes.IS_PROJECT_TEAM_APP_LOADED_IN_BROWSER;
    isLoadedInBrowser: boolean
}
//----------------Is app loading in browser--------------

export interface IRequestLitigationUserQuery {
    type: ProjectActionTypes.REQUEST_LITIGATION_USER_QUERY;
    projectNumber: string;
    userAlias: string;
}
export interface IResponseLitigationUserQuery {
    type: ProjectActionTypes.RESPONSE_LITIGATION_USER_QUERY;
    queries: any[];
}

export interface IResponseLitigationUserQueryError {
    type: ProjectActionTypes.RESPONSE_LITIGATION_USER_QUERY_ERROR;
    error: Error;
    errorMessage: string;
    CorrelationId: string;
}

export interface IRequestLitigationSaveUserQuery {
    type: ProjectActionTypes.REQUEST_SAVE_USER_QUERY;
    projectNumber: string;
    userAlias: string;
    payload: ISaveQueryRequest;
}
export interface IResponseLitigationSaveUserQuery {
    type: ProjectActionTypes.RESPONSE_SAVE_USER_QUERY;
    queries: any[];
}

export interface IResponseLitigationSaveUserQueryError {
    type: ProjectActionTypes.RESPONSE_SAVE_USER_QUERY_ERROR;
    error: Error;
    errorMessage: string;
    correlationId: string;
}

export interface IRequestLitigationUpdateUserQuery {
    type: ProjectActionTypes.REQUEST_UPDATE_USER_QUERY;
    projectNumber: string;
    userAlias: string;
    payload: ISaveQueryRequest;
    queryID: string;
}
export interface IResponseLitigationUpdateUserQuery {
    type: ProjectActionTypes.RESPONSE_UPDATE_USER_QUERY;
    queries: any[];
}

export interface IResponseLitigationUpdateUserQueryError {
    type: ProjectActionTypes.RESPONSE_UPDATE_USER_QUERY_ERROR;
    error: Error;
    errorMessage: string;
    correlationId: string;
}

export interface IRequestLitigationDeleteQuery {
    type: ProjectActionTypes.REQUEST_DELETE_USER_QUERY;
    queryID: string;
    projectNumber: string;
    userAlias: string;
    
}
export interface IResponseLitigationDeleteQuery {
    type: ProjectActionTypes.RESPONSE_DELETE_USER_QUERY;
    queries: any[];
}

export interface IResponseLitigationDeleteQueryError {
    type: ProjectActionTypes.RESPONSE_DELETE_USER_QUERY_ERROR;
    error: Error;
    errorMessage: string;
    correlationId: string;
}

export interface IClassifyFilesRequest {
    type: ProjectActionTypes.CLASSIFY_FILES_REQUEST;
    litigationClassifyFileRequest: ILitigationClassifyFileRequest;
}

export interface IClassifyFilesResponse {
    type: ProjectActionTypes.CLASSIFY_FILES_RESPONSE;
    litigationClassifyFileResponse: ILitigationClassifyFileResponse
}

export interface IClassifyFilesResponseError {
    type: ProjectActionTypes.CLASSIFY_FILES_RESPONSE_ERROR;
    error: Error;
    errorMessage: string;
    correlationId: string;
}

export interface IFilesClassifySummaryByUserRequest {
    type: ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_REQUEST;
    requestFilesClassifySummaryByUser: IRequestFilesClassifySummaryByUser
}

export interface IFilesClassifySummaryByUserResponse {
    type: ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE;
    responseFilesClassifySummaryByUser: IResponseFilesClassifySummaryByUser
}

export interface IFilesClassifySummaryByUserResponseError {
    type: ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE_ERROR;
    error: Error;
    errorMessage: string;
    correlationId: string;
}

export interface IRequestFileHistory {
    type: ProjectActionTypes.REQUEST_FILE_HISTORY;
    fileHistoryRequest: IFileHistoryRequest
}
 
export interface IResponseFileHistory {
    type: ProjectActionTypes.RESPONSE_FILE_HISTORY;
    fileHistoryResponses: IFileHistoryResponse[]
}
 
export interface IResponseErrorFileHistory {
    type: ProjectActionTypes.RESPONSE_ERROR_FILE_HISTORY;
    error: Error;
    errorMessage: string;
    correlationId: string;
}
