import { IPersonaProps } from "@fluentui/react";
import { IBannerInformation } from "../../Models/Banner/IBannerInformation";
import { IProjectContext } from "../../Models/IProjectContext";
import { IProjectCreate } from "../../Models/IProjectCreate";
import { IProjectCreateError } from "../../Models/IProjectCreateError";
import { IProjectCreationResponse } from "../../Models/IProjectCreationResponse";
import { IProjectErrorDetails } from "../../Models/IProjectErrorDetails";
import { IProjectFiles } from "../../Models/IProjectFile";
import { IProjectFileTags } from "../../Models/IProjectFileTags";
import { IProjectGetDetailsError } from "../../Models/IProjectGetDetailsError";
import { IProjectListDetails } from "../../Models/IProjectListDetails";
import { IProjectListError } from "../../Models/IProjectListError";
import { IProjectListResponse } from "../../Models/IProjectListResponse";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { IProjectProps } from "../../Models/IProjectProps";
import { IProjectSearchRequest } from "../../Models/IProjectSearchRequest";
import { IProjectSearchResponse } from "../../Models/IProjectSearchResponse";
import { IProjectTags } from "../../Models/IProjectTags";
import { ISimilarProjectResponse } from "../../Models/ISimilarProjectResponse";
import { IResponseErrorToggleBookmark, IResponseProjectListDetailsFailure, IResponseToggleBookmark, ProjectActions, ProjectActionTypes } from "../Actions/Project.action-types";
import { IProjectState } from "../ProjectState";
import { IFeatureFlagInfo } from "../../Models/IFeatureFlagInfo";
import { IBannerResponsesError } from "../../Models/Banner/IBannerResponsesError";
import { IUserNotificationError } from "../../Models/UserNotification/IUserNotificationError";
import { IUserNotificationResponse, IUserNotificationResponses } from "../../Models/UserNotification/IUserNotificationResponse";
import { IUserNotificationRequest } from "../../Models/UserNotification/IUserNotificationRequest";
import { IProjectWorkAreas } from "../../Models/IProjectWorkArea";
import { IHistorySearchRequest } from "src/Models/IHistorySearchRequest";
import { IHistoryResponses } from "src/Models/IHistoryResponses";
import { IHistoryResponsesError } from "src/Models/IHistoryResponsesError";
import { IBookmarkSearchRequest } from "src/Models/IBookmarkSearchRequest";
import { IBookmarkResponses } from "src/Models/IBookmarkResponses";
import { IBookmarkResponsesError } from "src/Models/IBookmarkResponsesError";
import { IBookmarkRequest } from "src/Models/IBookmarkRequest";
import { IEditFileTagsResponseError } from "src/Models/IEditFileTagsResponseError";

import { IRecentDocumentsResponses } from "../../Models/IRecentDocumentsResponse";
import { IRequestRecentDocuments } from "../../Models/IRequestRecentDocuments";
import { IRecentDocumentsResponsesError } from "../../Models/IRecentDocumentsResponsesError";
import { ILitigationCaseDataRequest, ILitigationCaseFilesRequest, ILitigationCaseRequest, ILitigationFileContentRequest } from "src/Models/ILitigationCaseRequest";
import { ILitigationCaseDataResponses, ILitigationCaseFilesResponses, ILitigationCaseResponses, ILitigationFileContentResponses, ILitigationSaveUserTagsRequest, ILitigationSaveUserTagsResponses } from "src/Models/ILitigationCase";
import { ILitigationCaseDataResponsesError, ILitigationCaseFilesResponsesError, ILitigationCaseResponsesError, ILitigationFileContentResponsesError, ILitigationSaveUserTagsResponsesError } from "src/Models/ILitigationCaseResponsesError";
export const ProjectReducerName = "ProjectReducerName";
export const projectInitialState: IProjectState = {
    // Initial Project Components Declarations Start
    projectFiles: {} as IProjectFiles,
    projectFilesDeleted: {} as IProjectFiles,
    projectProps: {} as IProjectProps,
    projectTags: {} as IProjectTags,
    projectFileTags: {} as IProjectFileTags[],
    projectMembers: {} as IProjectMembers,
    projectMembersSharepoint: {} as IProjectMembers,
    projectOwner: {} as IProjectMembers,
    projectCELAPeople: {} as IProjectMembers,
    loginUserProfile: {} as IPersonaProps,
    // Initial Project Components Declarations End
    projectContext: {} as IProjectContext,
    projectErrorDetails: {} as IProjectErrorDetails,
    // Initial Project Components Declarations Start


    // Project Create Start
    projectCreate: {} as IProjectCreate,
    isProjectGettingCreated: false,
    projectCreationSuccess: false,
    projectCreateRedirect: false,
    projectCreationResponse: {} as IProjectCreationResponse,
    projectCreationFailure: false,
    projectCreateError: {} as IProjectCreateError,
    // Project Create End
    // Project Edit Start
    projectEdit: {} as IProjectCreate,
    isProjectGettingEdited: false,
    projectEditSuccess: false,
    projectEditRedirect: false,
    projectEditFailure: false,
    projectEditError: {} as IProjectErrorDetails,
    // Project Edit End
    //Project Search Start
    projectSearchResponse: {} as IProjectSearchResponse,
    isSearchResultsLoading: false,
    isSearchResultsLoadingError: false,
    projectSearchError: {} as IProjectCreateError,
    //Project Start End
    //Project Due Date Start
    projectDueDateResponse: {} as IProjectSearchResponse,
    isDueDateResultsLoading: false,
    isDueDateResultsLoadingError: false,
    projectDueDateError: {} as IProjectCreateError,
    //Project Due Date End
    //Get Project Details Start 
    isProjectinContext: false,
    isProjectDetailsLoading: false,
    isProjectDetailsLoadingError: false,
    projectGetDetailsError: {} as IProjectGetDetailsError,
    //Get Project Details end
    //Get Project List start
    projectListDetails: {} as IProjectListDetails,
    isProjectListLoading: false,
    isProjectListLoadingError: false,
    projectListError: {} as IProjectListError,
    projectSearchRequest: {} as IProjectSearchRequest,
    projectListFilterDetails: {} as IProjectListResponse[],
    //Get Project List end
    //Delete File Start
    isProjectFileDeleting: false,
    isProjectFileDeletingError: false,
    projectFileDeleteSuccess: false,
    projectDeleteFileError: {} as IProjectErrorDetails,
    //Delete File End
    //Delete Member Start  
    deletedProjectMembers: {} as IProjectMembers,
    isProjectMemberDeleting: false,
    isProjectMemberDeletingError: false,
    projectMemberDeleteSuccess: false,
    projectDeleteMemberError: {} as IProjectErrorDetails,
    //Delete Member End
    //Update Member Status Start
    updateMemberStatus: false,
    memberToUpdateStatus: {} as IProjectMembers,
    isProjectMemberStatusUpdating: false,
    isProjectMemberStatusUpdatingError: false,
    projectMemberStatusUpdatingSuccess: false,
    projectMemberStatusUpdateError: {} as IProjectErrorDetails,
    //Update Member Status End
    //LoggedIn Member Profile Start
    loginUserpersonaProps: {} as IPersonaProps,
    loadingLoginUserProfile: false,
    isloadingLoginUserProfileError: false,
    loginUserProfileError: {} as IProjectErrorDetails,
    //LoggedIn Member Profile End
    //Update Project Status Start
    newStatus: "",
    isProjectStatusUpdating: false,
    isProjectStatusUpdatingError: false,
    projectStatusUpdatingSuccess: false,
    projectStatusUpdateError: {} as IProjectErrorDetails,
    //Update Project Status End
    //Upload Files to existing project Start
    newFiles: {} as IProjectFiles,
    isFileUploadingToProject: false,
    isFileUploadingToProjectError: false,
    FileUploadingToProjectSuccess: false,
    FileUploadingToProjectError: {} as IProjectErrorDetails,
    //Upload Files to existing project End
    //Add Members to existing project Start
    newMembers: {} as any,
    isMemberAddingToProject: false,
    isMemberAddingToProjectError: false,
    MemberAddingToProjectSuccess: false,
    MemberAddingToProjectError: {} as IProjectErrorDetails,
    //Add Members to existing project End
    //Get Similar Project Start
    isGettingSimilarProject: false,
    isGettingSimilarProjectError: false,
    GettingSimilarProjectSuccess: false,
    projectSimilarResponse: {} as ISimilarProjectResponse,
    GettingSimilarProjectError: {} as IProjectErrorDetails,
    //Get Similar Project End
    //Delete Project Start
    isProjectDeleting: false,
    isProjectDeletingError: false,
    projectDeleteSuccess: false,
    projectDeleteError: {} as IProjectErrorDetails,
    //Delete Project End
    //Banner info start
    shouldHideBanner: true,
    bannerInformation: {} as IBannerInformation,
    isLoadingBannerInformation: false,
    bannerResponseError: {} as IBannerResponsesError,
    isLoadingErrorBannerInformation: false,
    isBannerAPICallComplete: false,
    //Banner Info End
    //Feature Flag start
    featureFlagInformation: {} as IFeatureFlagInfo,
    isLoadingFeatureFlagInformation: false,
    //Feature Flag end
    //User notifications start
    loadingUserNotifications: false,
    loadingUserNotificationsError: false,
    userNotifications: {} as IUserNotificationResponses,
    userNotificationError: {} as IUserNotificationError,
    userNotificationRequest: {} as IUserNotificationRequest,
    //User notifications end
    //Project Work Area start
    isGettingProjectWorkArea: false,
    isProjectWorkAreaSuccess: false,
    isProjectWorkAreaError: false,
    projectWorkAreaError: {} as IProjectErrorDetails,
    projectWorkAreaCollection: {} as IProjectWorkAreas,
    //Project Work Area end
    //User history start
    historySearchRequest: {} as IHistorySearchRequest,
    historyResponses: {} as IHistoryResponses,
    isUserHistoryResultsLoading: false,
    isUserHistoryResultsLoadingError: false,
    historyResponsesError: {} as IHistoryResponsesError,
    //User history end
    //Toggle BookMark
    toggleBookmarkRequest: {} as IBookmarkRequest,
    //
    //Bookmark start
    bookmarkSearchRequest: {} as IBookmarkSearchRequest,
    bookmarkResponses: {} as IBookmarkResponses,
    isBookmarkResultsLoading: false,
    isBookmarkResultsLoadingError: false,
    bookmarkResponsesError: {} as IBookmarkResponsesError,
    //Edit Scenarios for File Tags Start
    isEditingFileTags: false,
    isEditingFileTagsSuccess: false,
    isEditingFileTagsError: false,
    editingFileTagsResponseError: {} as IEditFileTagsResponseError,
    projectFileTagsViewScreen: {} as IProjectFileTags,
    //Edit Scenario for File Tags End
    //----------------Recent documents----------
    requestRecentDocuments: {} as IRequestRecentDocuments,
    isLoadingRecentDocuments: false,
    isLoadingRecentDocumentsError: false,
    recentDocumentsResponses: {} as IRecentDocumentsResponses,
    recentDocumentsResponsesError: {} as IRecentDocumentsResponsesError,
    //-----------------Recent documents end------------

    //-----------------Law firms--------------------

    lawFirmAdaptiveDetails: {} as null,
    lawFirmInfo: null,
    isLoading: false,
    hasError: false,
    searchParams: null,

    //----------------Litigation Case Start----------------
    litigationCaseRequest: {} as ILitigationCaseRequest,
    litigationCaseResponses: {} as ILitigationCaseResponses,
    isLitigationCaseResultsLoading: false,
    isLitigationCaseResultsLoadingError: false,
    litigationCaseResponsesError: {} as ILitigationCaseResponsesError,

    litigationCaseDataRequest: {} as ILitigationCaseDataRequest,
    litigationCaseDataResponses: {} as ILitigationCaseDataResponses,
    isLitigationCaseDataResultsLoading: false,
    isLitigationCaseDataResultsLoadingError: false,
    litigationCaseDataResponsesError: {} as ILitigationCaseDataResponsesError,

    litigationCaseFilesRequest: {} as ILitigationCaseFilesRequest,
    litigationCaseFilesResponses: {} as ILitigationCaseFilesResponses,
    isLitigationCaseFilesResultsLoading: false,
    isLitigationCaseFilesResultsLoadingError: false,
    litigationCaseFilesResponsesError: {} as ILitigationCaseFilesResponsesError,
    isLitigationFilesDownloading: false,
    isLitigationFilesDownloadError: false,

    litigationFileContentRequest: {} as ILitigationFileContentRequest,
    litigationFileContentResponses: {} as ILitigationFileContentResponses,
    isLitigationFileContentResultsLoading: false,
    isLitigationFileContentResultsLoadingError: false,
    litigationFileContentResponsesError: {} as ILitigationFileContentResponsesError,

    litigationFileURLRequest: {} as ILitigationFileContentRequest,
    litigationFileURLResponses: {} as ILitigationFileContentResponses,
    isLitigationFileURLResultsLoading: false,
    isLitigationFileURLResultsLoadingError: false,
    litigationFileURLResponsesError: {} as ILitigationFileContentResponsesError,

    litigationSaveUserTagsRequest: {} as ILitigationSaveUserTagsRequest,
    litigationSaveUserTagsResponses: { isSuccess: false } as ILitigationSaveUserTagsResponses,
    resetSaveUserTagsSuccess: false,
    isLitigationSaveUsertagsLoading: false,
    isLitigationSaveUserTagsLoadingError: false,
    litigationSaveUserTagsResponsesError: {} as ILitigationSaveUserTagsResponsesError,

    litigationFeatureFlagResponse: {} as IFeatureFlagInfo,
    isLoadingLitigationFeatureFlag: false,

    //----------------Litigation Case End----------------

    canAddLitigationCases: {},
    isLoadingCanAddLitigationCases: false,
    isLoadingCanAddLitigationCasesError: false,
    userCanAddLitigationCasesError: {},
    isLoadedInBrowser: false,

    isLoadingLitigationUserQuery: false,
    userQueries: [],
    error: null,
    errorMessage: null,
    correlationId: null,

    isLoadingSaveUserQuery: false,
    isSaveUserQueryError: false,

    isLoadingUpdateUserQuery: false,
    isUpdateUserQueryError: false,

    isLoadingDeleteUserQuery: false,
    isDeleteUserQueryError: false,

    isSaveUserQuerySuccess: false,
    isUpdateUserQuerySuccess: false,
    isDeleteUserQuerySuccess: false,


    isFilesClassificationLoading: false,
    isFilesClassificationError: false,
    litigationClassifyFileResponseError: {},
    litigationClassifyFileResponse: {},


    responseFilesClassifySummaryByUser: {},
    requestFilesClassifySummaryByUser: {},
    responseErrorFilesClassifySummaryByUser: {},
    isLoadingFileSummaryByUser: false,
    isLoadingFileSummaryByUserError: false,

    //Case file history
    isLoadingFileHistory: false,
    isLoadingFileHistoryError: false,

    fileHistoryRequest: {},
    fileHistoryResponses: [],
    fileHistoryResponseError: {}
}

export function projectReducer(prev: IProjectState = projectInitialState, action: ProjectActions): IProjectState {
    switch (action.type) {

        case ProjectActionTypes.REQUEST_FILE_HISTORY:
            return{
                ...prev,
                isLoadingFileHistory: true,
                isLoadingFileHistoryError: false,
                fileHistoryResponses: [],
                fileHistoryResponseError: {}
            }
            case ProjectActionTypes.RESPONSE_FILE_HISTORY:
               
                return{
                    ...prev,
                    isLoadingFileHistory: false,
                    isLoadingFileHistoryError: false,
                    fileHistoryResponses: action.fileHistoryResponses,
                    fileHistoryResponseError: {}
                }
                case ProjectActionTypes.RESPONSE_ERROR_FILE_HISTORY:
                    return {
                        ...prev,
                        isLoadingFileHistory: false,
                        isLoadingFileHistoryError: true,    
                        fileHistoryResponses: [],          
                        fileHistoryResponseError: {
                            ...prev.fileHistoryResponseError,
                            error: action.error,
                            errorMessage: action.errorMessage,
                            CorrelationId: action.correlationId,
                        }
                    }

        //-----------Files classification summary start----------
        case ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_REQUEST:
            return {
                ...prev,
                isLoadingFileSummaryByUser: true,
                isLoadingFileSummaryByUserError: false,
                responseErrorFilesClassifySummaryByUser: {},
                responseFilesClassifySummaryByUser: {}
            }
        case ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE:
            return {
                ...prev,
                responseFilesClassifySummaryByUser: action.responseFilesClassifySummaryByUser,
                isLoadingFileSummaryByUser: false,
                isLoadingFileSummaryByUserError: false,
                responseErrorFilesClassifySummaryByUser: {}
            }
        case ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_RESPONSE_ERROR:
            return {
                ...prev,
                isLoadingFileSummaryByUser: false,
                isLoadingFileSummaryByUserError: true,      
                responseFilesClassifySummaryByUser: {},          
                responseErrorFilesClassifySummaryByUser: {
                    ...prev.responseErrorFilesClassifySummaryByUser,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.correlationId,
                }
            }
        //-----------Files classification summary end----------

        //-----------Files classification start----------
        case ProjectActionTypes.CLASSIFY_FILES_REQUEST:
            return {
                ...prev,
                isFilesClassificationLoading: true,
                isFilesClassificationError: false,
                litigationClassifyFileResponseError: {},
                litigationClassifyFileResponse: {},

                isLoadingFileSummaryByUser: false,
                isLoadingFileSummaryByUserError: false,
                responseErrorFilesClassifySummaryByUser: {},
                responseFilesClassifySummaryByUser: {}
            }
        case ProjectActionTypes.CLASSIFY_FILES_RESPONSE:
            return {
                ...prev,
                isFilesClassificationLoading: false,
                isFilesClassificationError: false,
                litigationClassifyFileResponseError: {},
                litigationClassifyFileResponse: action.litigationClassifyFileResponse
            }
        case ProjectActionTypes.CLASSIFY_FILES_RESPONSE_ERROR:
            return {
                ...prev,
                isFilesClassificationLoading: false,
                isFilesClassificationError: true,
                litigationClassifyFileResponseError: {
                    ...prev.litigationClassifyFileResponseError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.correlationId,
                }
            }
        //-----------Files classification end ----------
        //----------------Recent documents----------
        case ProjectActionTypes.REQUEST_RECENT_DOCUMENTS_LIST:
            return {
                ...prev,
                isLoadingRecentDocuments: true,
                isLoadingRecentDocumentsError: false,
                recentDocumentsResponses: {},
                recentDocumentsResponsesError: {}
            }
        case ProjectActionTypes.RESPONSE_RECENT_DOCUMENTS_LIST:
            return {
                ...prev,
                isLoadingRecentDocuments: false,
                isLoadingRecentDocumentsError: false,
                recentDocumentsResponses: action.recentDocumentsResponses,
                recentDocumentsResponsesError: {}
            }
        case ProjectActionTypes.RESPONSE_RECENT_DOCUMENTS_LIST_ERROR:
            return {
                ...prev,
                isLoadingRecentDocuments: false,
                isLoadingRecentDocumentsError: true,
                recentDocumentsResponses: {},
                recentDocumentsResponsesError: {
                    ...prev.recentDocumentsResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                }
            }
        //-----------------Recent documents end------------
        case ProjectActionTypes.SET_IS_EDIT_SUCCESS:
            return {
                ...prev,
                projectEditSuccess: action.resetEditSuccessFlag
            }
        case ProjectActionTypes.CREATE_NEW_PROJECT:
            return {
                ...prev,
                projectContext: {
                    ...prev.projectContext,
                    number: new Date().getTime().toString() + 'KMTeamAPP',
                    isBillable: false
                },
                projectEditSuccess: false,
                projectProps: {
                    ...prev.projectProps,
                    title: '',
                    description: '',
                    startDate: '',
                    endDate: '',
                    status: '',
                    workArea: '',
                    caseIds: [],
                },
                projectOwner: {
                    ...prev.projectOwner,
                    alias: [],
                    membersPicked: []
                },
                projectFiles: {
                    ...prev.projectFiles,
                    projectFile: [],
                    folderPath: ''
                },
                projectTags: {
                    ...prev.projectTags,
                    tags: []
                },
                projectFileTags: {
                    ...prev.projectFileTags,

                },
                projectMembers: {
                    ...prev.projectMembers,
                    alias: [],
                    membersPicked: []
                }
            }
        //User notifications start
        case ProjectActionTypes.REQUEST_USER_NOTIFICATION:
            return {
                ...prev,
                loadingUserNotifications: true,
                loadingUserNotificationsError: false,
                userNotifications: {
                    totalRecords: 0,
                    userNotificationResponseList: []
                },
                userNotificationError: {},
                userNotificationRequest: action.userNotificationRequest
            };
        case ProjectActionTypes.RESPONSE_USER_NOTIFICATION:
            return {
                ...prev,
                loadingUserNotifications: false,
                loadingUserNotificationsError: false,
                userNotifications: action.userNotificationResponses,
                userNotificationError: {}
            };
        case ProjectActionTypes.RESPONSE_ERROR_USER_NOTIFICATION:
            return {
                ...prev,
                loadingUserNotifications: false,
                loadingUserNotificationsError: true,
                userNotifications: {},
                userNotificationError: {
                    ...prev.userNotificationError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        //User notifications end

        case ProjectActionTypes.UPDATE_BANNER_STATUS_IN_STATE:
            return {
                ...prev,
                bannerInformation: {
                    ...prev.bannerInformation,
                    showBanner: false
                }
            };

        case ProjectActionTypes.REQUEST_BANNER_INFORMATION:
            return {
                ...prev,
                isLoadingBannerInformation: true,
                isLoadingErrorBannerInformation: false,
                isBannerAPICallComplete: false,
            };
        case ProjectActionTypes.RESPONSE_BANNER_INFORMATION:
            return {
                ...prev,
                isLoadingBannerInformation: false,
                isLoadingErrorBannerInformation: false,
                isBannerAPICallComplete: true,
                bannerInformation: action?.bannerInformation,
            };
        case ProjectActionTypes.RESPONSE_ERROR_BANNER_INFORMATION:
            return {
                ...prev,
                isLoadingBannerInformation: false,
                isLoadingErrorBannerInformation: true,
                isBannerAPICallComplete: true,
                bannerInformation: {} as IBannerInformation,
                bannerResponseError: {
                    ...prev.bannerResponseError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_FEATURE_FLAG_INFORMATION:
            return {
                ...prev,
                isLoadingFeatureFlagInformation: true,
            };
        case ProjectActionTypes.RESPONSE_FEATURE_FLAG_INFORMATION:
            return {
                ...prev,
                isLoadingFeatureFlagInformation: false,
                featureFlagInformation: action?.featureFlagInformation,
            };
        case ProjectActionTypes.REQUEST_PROJECT_CREATE:
            return {
                ...prev,
                projectCreate: action.projectCreate,
                isProjectGettingCreated: true,
                projectCreationSuccess: false,
                projectCreateRedirect: false,
                projectCreationFailure: false,
                projectProps: {
                    ...prev.projectProps,
                },
            };
        case ProjectActionTypes.RESPONSE_PROJECT_CREATION_SUCCESS:
            return {
                ...prev,
                isProjectGettingCreated: false,
                projectCreationSuccess: true,
                projectCreateRedirect: true,
                projectCreationFailure: false,
                projectCreationResponse: action.projectCreationResponse,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_CREATION_FAILURE:
            return {
                ...prev,
                isProjectGettingCreated: false,
                projectCreationSuccess: false,
                projectCreationFailure: true,
                projectCreateRedirect: false,
                projectCreateError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_PROJECT_EDIT:
            return {
                ...prev,
                projectEdit: action.projectEdit,
                isProjectGettingEdited: true,
                projectEditSuccess: false,
                projectEditRedirect: false,
                projectEditFailure: false,
                projectProps: {
                    ...prev.projectProps,
                },
            };
        case ProjectActionTypes.RESPONSE_PROJECT_EDIT_SUCCESS:
            return {
                ...prev,
                isProjectGettingEdited: false,
                projectEditSuccess: true,
                projectEditRedirect: true,
                projectEditFailure: false,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_EDIT_FAILURE:
            return {
                ...prev,
                isProjectGettingEdited: false,
                projectEditSuccess: false,
                projectEditRedirect: false,
                projectEditFailure: true,
                projectEditError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_PROJECT_SEARCH:
            return {
                ...prev,
                isSearchResultsLoading: true,
                isSearchResultsLoadingError: false,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_SEARCH:
            return {
                ...prev,
                isSearchResultsLoading: false,
                isSearchResultsLoadingError: false,
                projectSearchResponse: action.projectSearchResponse,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_SEARCH_FAILURE:
            return {
                ...prev,
                isSearchResultsLoading: false,
                isSearchResultsLoadingError: true,
                projectSearchError: {
                    ...prev.projectSearchError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_PROJECT_DUE_DATE:
            return {
                ...prev,
                isDueDateResultsLoading: true,
                isDueDateResultsLoadingError: false,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_DUE_DATE:
            return {
                ...prev,
                isDueDateResultsLoading: false,
                isDueDateResultsLoadingError: false,
                projectDueDateResponse: action.projectDueDateResponse,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_DUE_DATE_FAILURE:
            return {
                ...prev,
                isDueDateResultsLoading: false,
                isDueDateResultsLoadingError: true,
                projectDueDateError: {
                    ...prev.projectDueDateError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_PROJECT_DETAILS_BY_ID:
            return {
                ...prev,
                projectContext: action.projectContext,
                isProjectinContext: true,
                isProjectDetailsLoading: true,
                projectEditRedirect: false,
                isProjectDetailsLoadingError: false,
                FileUploadingToProjectSuccess: false,
                projectFileDeleteSuccess: false,
                projectCreationSuccess: false,
                projectCreate: {
                    ...prev.projectCreate,
                    projectMembers: {},
                    projectFiles: {},
                    projectCELAPeople: {},
                    projectFileTags: [],
                    projectOwners: {},
                    projectProps: {},
                    projectTags: {}
                }
            };
        case ProjectActionTypes.RESPONSE_PROJECT_DETAILS_BY_ID:
            return {
                ...prev,
                projectCreate: action.projectCreate,
                isProjectDetailsLoading: false,
                isProjectDetailsLoadingError: false,
                projectStatusUpdatingSuccess: false,
                isProjectFileDeletingError: false
            };
        case ProjectActionTypes.RESPONSE_PROJECT_DETAILS_BY_ID_FAILURE:
            return {
                ...prev,
                isProjectDetailsLoading: false,
                isProjectDetailsLoadingError: true,
                projectGetDetailsError: {
                    ...prev.projectGetDetailsError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.CLEAR_MESSAGE_STATE:
            return {
                ...prev,
                projectStatusUpdatingSuccess: false,
                projectEditSuccess: false,
                projectCreationSuccess: false,
                projectCreationFailure: false,
                projectEditFailure: false,
                isProjectStatusUpdatingError: false,
                projectDeleteSuccess: false,
                deletedProjectMembers: {} as IProjectMembers,
            };
        case ProjectActionTypes.SET_PROJECT_CONTEXT:
            return {
                ...prev,
                projectContext: {
                    ...prev.projectContext,
                    number: action.projectContext?.number,
                    isBillable: (action.projectContext?.number?.toLowerCase().startsWith("nb") || action.projectContext?.number === '') ? false : true,
                },
            };
        case ProjectActionTypes.SET_PROJECTLIST_CONTEXT:
            return {
                ...prev,
                projectSearchRequest: action.projectSearchRequest,
            };
        case ProjectActionTypes.SET_PROJECTFILTERLIST_DETAILS:
            return {
                ...prev,
                projectListFilterDetails: action.projectFilterListDetails,
            };
        case ProjectActionTypes.REQUEST_PROJECT_Detail_List:
            return {
                ...prev,
                projectSearchRequest: action.projectSearchRequest,
                isProjectListLoading: true,
                isProjectListLoadingError: false,
                projectCreateRedirect: false,
                projectListDetails: {},
                projectListFilterDetails: []
            };
        case ProjectActionTypes.REQUEST_PROJECT_DETAIL_LIST_V2:
            return {
                ...prev,
                projectSearchRequest: action.projectSearchRequest,
                isProjectListLoading: true,
                isProjectListLoadingError: false,
                projectCreateRedirect: false,
                projectListDetails: action.currentPage === 1 ? {} : prev.projectListDetails,
                projectListFilterDetails: action.currentPage === 1 ? [] : prev.projectListFilterDetails
            };
        case ProjectActionTypes.RESPONSE_PROJECT_Detail_List:
            return {
                ...prev,
                projectListDetails: action.currentPage === 1 ? action.projectListDetails : {
                    ...prev.projectListDetails,
                    items: [...prev.projectListDetails.items, ...action.projectListDetails.items]
                },
                isProjectListLoading: false,
                isProjectListLoadingError: false,
                projectListFilterDetails: action.currentPage === 1 ? action.projectListDetails.items : [...prev.projectListFilterDetails, ...action.projectListDetails.items],
            };
        case ProjectActionTypes.RESPONSE_PROJECT_Detail_List_FAILURE:
            return {
                ...prev,
                isProjectListLoading: false,
                isProjectDetailsLoadingError: true,
                projectListError: {
                    ...prev.projectListError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.UPDATE_PROJECT_STATE_FOR_FILE_UPLOAD:
            return {
                ...prev,
                projectFiles: action.projectFiles,
                isProjectFileDeletingError: false
            };
        case ProjectActionTypes.UPDATE_DELETED_FILES:
            return {
                ...prev,
                projectFilesDeleted: action.projectFiles,
            };
        case ProjectActionTypes.UPDATE_TAGS_STATE_FOR_FILE:
            return {
                ...prev,
                projectFileTags: action.projectFileTags,
            };
        case ProjectActionTypes.UPDATE_TAGS_STATE_FOR_PROJECT:
            return {
                ...prev,
                projectTags: action.projectTags,
            };
        case ProjectActionTypes.UPDATE_PROJECT_MEMBERS:
            return {
                ...prev,
                projectMembers: action.projectMembers,
            };
        case ProjectActionTypes.UPDATE_PROJECT_OWNERS:
            return {
                ...prev,
                projectOwner: action.projectOwners,
            };
        case ProjectActionTypes.UPDATE_PROJECT_CELA_PEOPLE:
            return {
                ...prev,
                projectCELAPeople: action.projectCELAPeople,
            };
        case ProjectActionTypes.UPDATE_PROJECT_MEMBERS_SHAREPOINT:
            return {
                ...prev,
                projectMembersSharepoint: action.projectMembersSharepoint,
            };
        case ProjectActionTypes.UPDATE_PROJECT_MEMBERS_DELETED:
            return {
                ...prev,
                deletedProjectMembers: action.projectMembersDeleted,
            };
        case ProjectActionTypes.UPDATE_PROJECT_MEMBERS_STATUS:
            return {
                ...prev,
                memberToUpdateStatus: action.projectMembersToUpdate,
                updateMemberStatus: action.responsibleFor,
            };
        case ProjectActionTypes.UPDATE_PROJECT_PROPS:
            return {
                ...prev,
                projectProps: action.projectProps,
            };
        case ProjectActionTypes.REQUEST_UPDATE_PROJECT_MEMBERS_NAMES:
            return {
                ...prev,
                projectMembers: action.projectMembers,
            };
        case ProjectActionTypes.REQUEST_UPDATE_PROJECT_OWNERS_NAMES:
            return {
                ...prev,
                projectOwner: action.projectOwners,
            };
        case ProjectActionTypes.REQUEST_DELETE_FILE:
            return {
                ...prev,
                projectContext: action.projectContext,
                isProjectFileDeleting: true,
                isProjectFileDeletingError: false,
                projectFileDeleteSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_DELETE_FILE:
            return {
                ...prev,
                isProjectFileDeleting: false,
                isProjectFileDeletingError: false,
                projectFileDeleteSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_DELETE_FILE_FAILURE:
            return {
                ...prev,
                isProjectFileDeleting: false,
                isProjectFileDeletingError: true,
                projectFileDeleteSuccess: false,
                projectDeleteFileError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_DELETE_MEMBER:
            return {
                ...prev,
                projectContext: action.projectContext,
                isProjectMemberDeleting: true,
                isProjectMemberDeletingError: false,
                projectMemberDeleteSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_DELETE_MEMBER:
            return {
                ...prev,
                isProjectMemberDeleting: false,
                isProjectMemberDeletingError: false,
                projectMemberDeleteSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_DELETE_MEMBER_FAILURE:
            return {
                ...prev,
                isProjectMemberDeleting: false,
                isProjectMemberDeletingError: true,
                projectMemberDeleteSuccess: false,
                projectDeleteMemberError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_MEMBER_STATUS_UPDATE:
            return {
                ...prev,
                projectContext: action.projectContext,
                isProjectMemberStatusUpdating: true,
                isProjectMemberStatusUpdatingError: false,
                projectMemberStatusUpdatingSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_MEMBER_STATUS_UPDATE:
            return {
                ...prev,
                isProjectMemberStatusUpdating: false,
                isProjectMemberStatusUpdatingError: false,
                projectMemberStatusUpdatingSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_MEMBER_STATUS_UPDATE_FAILURE:
            return {
                ...prev,
                isProjectMemberStatusUpdating: false,
                isProjectMemberStatusUpdatingError: true,
                projectMemberStatusUpdatingSuccess: false,
                projectMemberStatusUpdateError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.RESPONSE_LOGIN_USER_PROFILE:
            return {
                ...prev,
                loginUserpersonaProps: action.loginUserpersonaProps,
                loadingLoginUserProfile: false,
                isloadingLoginUserProfileError: false,
            };

        case ProjectActionTypes.REQUEST_LOGIN_USER_PROFILE:
            return {
                ...prev,
                loadingLoginUserProfile: true,
                isloadingLoginUserProfileError: false,
            };
        case ProjectActionTypes.RESPONSE_LOGIN_USER_PROFILE_ERROR:
            return {
                ...prev,
                loadingLoginUserProfile: false,
                isloadingLoginUserProfileError: true,
                loginUserProfileError: {
                    ...prev.loginUserProfileError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_PROJECT_STATUS_UPDATE:
            return {
                ...prev,
                projectContext: action.projectContext,
                isProjectStatusUpdating: true,
                isProjectStatusUpdatingError: false,
                projectStatusUpdatingSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_STATUS_UPDATE:
            return {
                ...prev,
                newStatus: action.newStatus,
                isProjectStatusUpdating: false,
                isProjectStatusUpdatingError: false,
                projectStatusUpdatingSuccess: true,
            };

        // Edit Scenario file tags start
        case ProjectActionTypes.REQUEST_PROJECT_FILE_TAGS_UPDATE:
            return {
                ...prev,
                isEditingFileTags: true,
                projectFileTagsViewScreen: action.projectFileTags,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_FILE_TAGS_UPDATE:
            const updatedFileTags = prev.projectFileTags.map(fileTag => {
                if (fileTag.fileName === action.fileTags.fileName) {
                    // If the fileName matches, update the tags
                    return {
                        ...fileTag,
                        tags: action.fileTags.tags,
                    };
                } else {
                    // Otherwise, keep the existing values
                    return fileTag;
                }
            });

            return {
                ...prev,
                projectFileTags: updatedFileTags,
                isEditingFileTags: false,
                isEditingFileTagsError: false,
                isEditingFileTagsSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_ERROR_PROJECT_FILE_TAGS_UPDATE:
            return {
                ...prev,
                isEditingFileTags: false,
                isEditingFileTagsSuccess: false,
                isEditingFileTagsError: true,
                editingFileTagsResponseError: {
                    ...prev.editingFileTagsResponseError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        // Edit Scenario file tags end


        case ProjectActionTypes.RESPONSE_PROJECT_STATUS_UPDATE_FAILURE:
            return {
                ...prev,
                isProjectStatusUpdating: false,
                isProjectStatusUpdatingError: true,
                projectStatusUpdatingSuccess: false,
                projectStatusUpdateError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_UPLOAD_FILES_TO_PROJECT:
            return {
                ...prev,
                isFileUploadingToProject: true,
                isFileUploadingToProjectError: false,
                FileUploadingToProjectSuccess: false,
                projectFileDeleteSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_UPLOAD_FILES_TO_PROJECT:
            return {
                ...prev,
                newFiles: action.newFiles,
                isFileUploadingToProject: false,
                isFileUploadingToProjectError: false,
                FileUploadingToProjectSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_UPLOAD_FILES_TO_PROJECT_FAILURE:
            return {
                ...prev,
                isFileUploadingToProject: false,
                isFileUploadingToProjectError: true,
                FileUploadingToProjectSuccess: false,
                FileUploadingToProjectError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_ADD_MEMBERS_TO_PROJECT:
            return {
                ...prev,
                isMemberAddingToProject: true,
                isMemberAddingToProjectError: false,
                MemberAddingToProjectSuccess: false,
                projectMemberDeleteSuccess: false,
                projectMemberStatusUpdatingSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_ADD_MEMBERS_TO_PROJECT:
            return {
                ...prev,
                newMembers: action.newMember,
                isMemberAddingToProject: false,
                isMemberAddingToProjectError: false,
                MemberAddingToProjectSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_ADD_MEMBERS_TO_PROJECT_FAILURE:
            return {
                ...prev,
                isMemberAddingToProject: false,
                isMemberAddingToProjectError: true,
                MemberAddingToProjectSuccess: false,
                MemberAddingToProjectError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_GET_SIMILAR_PROJECT:
            return {
                ...prev,
                isGettingSimilarProject: true,
                isGettingSimilarProjectError: false,
                GettingSimilarProjectSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_GET_SIMILAR_PROJECT:
            return {
                ...prev,
                isGettingSimilarProject: false,
                isGettingSimilarProjectError: false,
                GettingSimilarProjectSuccess: true,
                projectSimilarResponse: action.projectSearchResponse,
            };
        case ProjectActionTypes.RESPONSE_GET_SIMILAR_PROJECT_FAILURE:
            return {
                ...prev,
                isGettingSimilarProject: false,
                isGettingSimilarProjectError: true,
                GettingSimilarProjectSuccess: false,
                GettingSimilarProjectError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        case ProjectActionTypes.REQUEST_DELETE_PROJECT:
            return {
                ...prev,
                isProjectDeleting: true,
                isProjectDeletingError: false,
                projectDeleteSuccess: false,
            };
        case ProjectActionTypes.RESPONSE_DELETE_PROJECT:
            return {
                ...prev,
                isProjectDeleting: false,
                isProjectDeletingError: false,
                projectDeleteSuccess: true,
            };
        case ProjectActionTypes.RESPONSE_DELETE_PROJECT_FAILURE:
            return {
                ...prev,
                isProjectDeleting: false,
                isProjectDeletingError: true,
                projectDeleteSuccess: false,
                projectDeleteError: {
                    ...prev.projectErrorDetails,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        //work area
        case ProjectActionTypes.REQUEST_PROJECT_WORKAREA:
            return {
                ...prev,
                isGettingProjectWorkArea: true,
            };
        case ProjectActionTypes.RESPONSE_PROJECT_WORKAREA:
            return {
                ...prev,
                isGettingProjectWorkArea: false,
                isProjectWorkAreaSuccess: true,
                isProjectWorkAreaError: false,
                projectWorkAreaCollection: action.projectworkAreaResponses,
            };
        case ProjectActionTypes.RESPONSE_ERROR_PROJECT_WORKAREA:
            return {
                ...prev,
                isGettingProjectWorkArea: false,
                isProjectWorkAreaSuccess: false,
                isProjectWorkAreaError: true,
                projectWorkAreaError: {
                    ...prev.loginUserProfileError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId,
                },
            };
        //work area

        // User history start
        case ProjectActionTypes.REQUEST_USER_HISTORY:
            return {
                ...prev,
                isUserHistoryResultsLoading: true
            };

        case ProjectActionTypes.RESPONSE_USER_HISTORY:
            return {
                ...prev,
                isUserHistoryResultsLoading: false,
                historyResponses: action.historyResponses,
            };

        case ProjectActionTypes.RESPONSE_USER_HISTORY_ERROR:
            return {
                ...prev,
                isUserHistoryResultsLoading: false,
                isUserHistoryResultsLoadingError: true,
                historyResponsesError: {
                    ...prev.historyResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };
        // User history end

        // Toogle BookMark
        case ProjectActionTypes.REQUEST_TOGGLE_BOOKMARK:
            return {
                ...prev
            };
        // Toogle BookMark end



        // Bookmark start
        case ProjectActionTypes.REQUEST_BOOKMARK:
            return {
                ...prev,
                isBookmarkResultsLoading: true
            };

        case ProjectActionTypes.RESPONSE_BOOKMARK:
            return {
                ...prev,
                isBookmarkResultsLoading: false,
                bookmarkResponses: action.bookmarkResponses,
            };

        case ProjectActionTypes.RESPONSE_BOOKMARK_ERROR:
            return {
                ...prev,
                isBookmarkResultsLoading: false,
                isBookmarkResultsLoadingError: true,
                bookmarkResponsesError: {
                    ...prev.bookmarkResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };
        // Bookmark end

        //Law firm start

        case ProjectActionTypes.REQUEST_LAWFIRM_SEARCH:
            return {
                ...prev,
                isLoading: true,
                hasError: false,
                lawFirmAdaptiveDetails: {
                    template: null,
                    payload: null,
                    totalRecords: 0
                },
                lawFirmInfo: null,

            };

        case ProjectActionTypes.RESPONSE_LAWFIRM_SEARCH:
            return {
                ...prev,
                isLoading: false,
                hasError: false,
                lawFirmAdaptiveDetails: action.lawFirmAdaptiveDetails

            };
            // case ProjectActionTypes.FAILED_LAWFIRM_SEARCH:
            //   return {
            //     ...prev,

            //   };
            // case ProjectActionTypes.SET_LAW_FIRM_INFO:
            return {
                ...prev,
            };

        // Litigation Case start
        case ProjectActionTypes.REQUEST_LITIGATION_CASES:
            return {
                ...prev,
                responseErrorFilesClassifySummaryByUser: {},
                responseFilesClassifySummaryByUser: {},
                isLitigationCaseResultsLoadingError: false,
                isLitigationCaseResultsLoading: true
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_CASES:
            return {
                ...prev,
                isLoadingFileSummaryByUser: false,
                isLoadingFileSummaryByUserError: false,
                isLitigationCaseResultsLoading: false,
                litigationCaseResponses: action.litigationCaseResponses,
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_CASES_ERROR:
            return {
                ...prev,
                isLitigationCaseResultsLoading: false,
                isLitigationCaseResultsLoadingError: true,
                litigationCaseResponsesError: {
                    ...prev.litigationCaseResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };

        case ProjectActionTypes.REQUEST_LITIGATION_CASEDATA:
            return {
                ...prev,
                isLitigationCaseDataResultsLoadingError: false,
                isLitigationCaseDataResultsLoading: true,
                isFilesClassificationLoading: false,
                isFilesClassificationError: false,
                litigationClassifyFileResponseError: {},
                litigationClassifyFileResponse: {}
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_CASEDATA:
            return {
                ...prev,
                isLitigationCaseDataResultsLoading: false,
                litigationCaseDataResponses: action.litigationCaseDataResponses,
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_CASEDATA_ERROR:
            return {
                ...prev,
                isLitigationCaseDataResultsLoading: false,
                isLitigationCaseDataResultsLoadingError: true,
                litigationCaseDataResponsesError: {
                    ...prev.litigationCaseDataResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };

        case ProjectActionTypes.REQUEST_LITIGATION_CASEFILES:
            return {
                ...prev,
                isFilesClassificationLoading: false,
                isFilesClassificationError: false,
                litigationClassifyFileResponseError: {},
                litigationClassifyFileResponse: {},
                isLitigationCaseFilesResultsLoadingError: false,
                isLitigationCaseFilesResultsLoading: true
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_CASEFILES:
            return {
                ...prev,
                isLitigationCaseFilesResultsLoading: false,
                litigationCaseFilesResponses: {
                    litigationCaseFiles: action?.litigationCaseFilesResponses?.litigationCaseFiles,
                    totalRecords: action?.litigationCaseFilesResponses?.totalRecords
                }
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_CASEFILES_ERROR:
            return {
                ...prev,
                isLitigationCaseFilesResultsLoading: false,
                isLitigationCaseFilesResultsLoadingError: true,
                litigationCaseFilesResponsesError: {
                    ...prev.litigationCaseFilesResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };

        case ProjectActionTypes.REQUEST_DOWNLOAD_LITIGATION_CASEFILES:
            return {
                ...prev,
                isLitigationFilesDownloading: true,
                isLitigationFilesDownloadError: false
            };

        case ProjectActionTypes.RESPONSE_DOWNLOAD_LITIGATION_CASEFILES:
            return {
                ...prev,
                isLitigationFilesDownloading: false,
                isLitigationFilesDownloadError: false

            };

        case ProjectActionTypes.RESPONSE_DOWNLOAD_LITIGATION_CASEFILES_ERROR:
            return {
                ...prev,
                isLitigationFilesDownloading: false,
                isLitigationFilesDownloadError: true,

            };


        case ProjectActionTypes.REQUEST_LITIGATION_FILECONTENT:
            return {
                ...prev,
                litigationFileContentResponses: null,
                isLitigationFileContentResultsLoadingError: false,
                isLitigationFileContentResultsLoading: true
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_FILECONTENT:
            return {
                ...prev,
                isLitigationFileContentResultsLoadingError: false,
                isLitigationFileContentResultsLoading: false,
                litigationFileContentResponses: action.litigationFileContentResponses,
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_FILECONTENT_ERROR:
            return {
                ...prev,
                isLitigationFileContentResultsLoading: false,
                isLitigationFileContentResultsLoadingError: true,
                litigationFileContentResponsesError: {
                    ...prev.litigationFileContentResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };

        case ProjectActionTypes.REQUEST_LITIGATION_FILEURL:
            return {
                ...prev,
                litigationFileURLResponses: null,
                isLitigationFileURLResultsLoadingError: false,
                isLitigationFileURLResultsLoading: true
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_FILEURL:
            return {
                ...prev,
                isLitigationFileURLResultsLoadingError: false,
                isLitigationFileURLResultsLoading: false,
                litigationFileURLResponses: action.litigationFileURLResponses,
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_FILEURL_ERROR:
            return {
                ...prev,
                isLitigationFileURLResultsLoading: false,
                isLitigationFileURLResultsLoadingError: true,
                litigationFileURLResponsesError: {
                    ...prev.litigationFileContentResponsesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };

        case ProjectActionTypes.REQUEST_LITIGATION_SAVEUSERTAGS:
            return {
                ...prev,
                litigationSaveUserTagsResponses: null,
                isLitigationSaveUserTagsLoadingError: false,
                isLitigationSaveUsertagsLoading: true
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_SAVEUSERTAGS:
            return {
                ...prev,
                isLitigationSaveUsertagsLoading: false,
                isLitigationSaveUserTagsLoadingError: false,
                litigationSaveUserTagsResponses: action.litigationSaveUserTagsResponses,
            };
        case ProjectActionTypes.RESET_LITIGATION_SAVEUSERTAGS_SUCCESS:
            return {
                ...prev,
                litigationSaveUserTagsResponses: { isSuccess: action.resetSaveUsertagsSuccess }
            };
        case ProjectActionTypes.RESPONSE_LITIGATION_SAVEUSERTAGS_ERROR:
            return {
                ...prev,
                isLitigationSaveUsertagsLoading: false,
                isLitigationSaveUserTagsLoadingError: true,
                litigationSaveUserTagsResponsesError: {
                    ...prev.litigationSaveUserTagsResponsesError,
                    CorrelationId: action.CorrelationId
                }
            };

        case ProjectActionTypes.REQUEST_LITIGATION_FEATURE_FLAG:
            return {
                ...prev,
                isLoadingLitigationFeatureFlag: true,
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_FEATURE_FLAG:
            return {
                ...prev,
                isLoadingLitigationFeatureFlag: false,
                litigationFeatureFlagResponse: action?.litigationFeatureFlagResponse,
            };

        // Litigation Case end
        case ProjectActionTypes.REQUEST_IS_USER_PART_OF_LITIGATION_SG_GROUP:
            return {
                ...prev,
                isLoadingCanAddLitigationCases: true,
                isLoadingCanAddLitigationCasesError: false,
                canAddLitigationCases: {}
            };
        case ProjectActionTypes.RESPONSE_IS_USER_PART_OF_LITIGATION_SG_GROUP:
            return {
                ...prev,
                isLoadingCanAddLitigationCases: false,
                isLoadingCanAddLitigationCasesError: false,
                canAddLitigationCases: {
                    canAddCases: action.isuserPartOfLitigationSGGroup
                },
            };
        case ProjectActionTypes.IS_USER_PART_OF_LITIGATION_SG_GROUP_RESPONSE_ERROR:
            return {
                ...prev,
                isLoadingCanAddLitigationCases: false,
                isLoadingCanAddLitigationCasesError: true,
                userCanAddLitigationCasesError: {
                    ...prev.userCanAddLitigationCasesError,
                    error: action.error,
                    errorMessage: action.errorMessage,
                    CorrelationId: action.CorrelationId
                }
            };
        case ProjectActionTypes.IS_PROJECT_TEAM_APP_LOADED_IN_BROWSER:
            return {
                ...prev,
                isLoadedInBrowser: action.isLoadedInBrowser

            }

        case ProjectActionTypes.REQUEST_LITIGATION_USER_QUERY:
            return {
                ...prev,
                isLoadingLitigationUserQuery: true,
                isSaveUserQueryError: false,
                isDeleteUserQueryError: false,
                isUpdateUserQueryError: false,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                error: null,
                errorMessage: null,
                correlationId: null
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_USER_QUERY:
            return {
                ...prev,
                isLoadingLitigationUserQuery: false,
                userQueries: action.queries,
                error: null,
                errorMessage: null,
                correlationId: null
            };

        case ProjectActionTypes.RESPONSE_LITIGATION_USER_QUERY_ERROR:
            return {
                ...prev,
                isLoadingLitigationUserQuery: false,
                error: action.error,
                errorMessage: action.errorMessage,
                correlationId: action.CorrelationId
            };
        case ProjectActionTypes.REQUEST_SAVE_USER_QUERY:
            return {
                ...prev,
                isLoadingSaveUserQuery: true,
                isSaveUserQueryError: false,
                isDeleteUserQueryError: false,
                isUpdateUserQueryError: false,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_SAVE_USER_QUERY:
            return {
                ...prev,
                isLoadingSaveUserQuery: false,
                isSaveUserQuerySuccess: true,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                userQueries: action.queries,
                error: null,
                isSaveUserQueryError: false,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_SAVE_USER_QUERY_ERROR:
            return {
                ...prev,
                isLoadingSaveUserQuery: false,
                isSaveUserQueryError: true,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                error: action.error,
                errorMessage: action.errorMessage,
                correlationId: action.correlationId,
            };
        case ProjectActionTypes.REQUEST_UPDATE_USER_QUERY:
            return {
                ...prev,
                isLoadingUpdateUserQuery: true,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                isSaveUserQueryError: false,
                isDeleteUserQueryError: false,
                isUpdateUserQueryError: false,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_UPDATE_USER_QUERY:
            return {
                ...prev,
                isLoadingUpdateUserQuery: false,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: true,
                isDeleteUserQuerySuccess: false,
                isUpdateUserQueryError: false,
                userQueries: action.queries,
                error: null,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_UPDATE_USER_QUERY_ERROR:
            return {
                ...prev,
                isLoadingUpdateUserQuery: false,
                isUpdateUserQueryError: true,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                error: action.error,
                errorMessage: action.errorMessage,
                correlationId: action.correlationId,
            };
        case ProjectActionTypes.REQUEST_DELETE_USER_QUERY:
            return {
                ...prev,
                isLoadingDeleteUserQuery: true,
                isSaveUserQueryError: false,
                isDeleteUserQueryError: false,
                isUpdateUserQueryError: false,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_DELETE_USER_QUERY:
            return {
                ...prev,
                isLoadingDeleteUserQuery: false,
                isDeleteUserQueryError: false,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: true,
                userQueries: action.queries,
                error: null,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_DELETE_USER_QUERY_ERROR:
            return {
                ...prev,
                isLoadingDeleteUserQuery: false,
                isDeleteUserQueryError: true,
                isSaveUserQuerySuccess: false,
                isUpdateUserQuerySuccess: false,
                isDeleteUserQuerySuccess: false,
                error: action.error,
                errorMessage: action.errorMessage,
                correlationId: action.correlationId,
            };
        case ProjectActionTypes.REQUEST_DELETE_USER_QUERY:
            return {
                ...prev,
                isLoadingDeleteUserQuery: true,
                isDeleteUserQueryError: false,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_DELETE_USER_QUERY:
            return {
                ...prev,
                isLoadingDeleteUserQuery: false,
                isDeleteUserQueryError: false,
                userQueries: action.queries,
                error: null,
                errorMessage: null,
                correlationId: null,
            };

        case ProjectActionTypes.RESPONSE_DELETE_USER_QUERY_ERROR:
            return {
                ...prev,
                isLoadingDeleteUserQuery: false,
                isDeleteUserQueryError: true,
                error: action.error,
                errorMessage: action.errorMessage,
                correlationId: action.correlationId,
            };
        default:
            return {
                ...prev,
            };
    }
}