import { ITag, IBasePicker, Stack, TagPicker, ValidationState, IBasePickerSuggestionsProps } from '@fluentui/react';
import React, { CSSProperties, createRef } from 'react'
import { CustomTag } from 'src/Models/CustomTags';
import { toggleStyles, inputProps } from './Styles/CustomTagPicker';
type ICasePickerProps = {
  onAddTag: (tagText: string) => Promise<CustomTag>;
  onCategorySelected: (tagId?: number) => void;
  onChange: (items: ITag[] | undefined, index?:number) => void;
  onResolveSuggestions: (filter: string, selectedItems: ITag[] | undefined) => PromiseLike<ITag[]>;
  pickerStyles?: CSSProperties;
  selectedItems?: ITag[];
  casesList?:any
};
const tagPickerRef = createRef<IBasePicker<ITag>>();
const CasePicker = (casePickerProps: ICasePickerProps) => {
  const { onAddTag, onChange, onResolveSuggestions, pickerStyles, selectedItems,   casesList } = casePickerProps
  const getTextFromItem = (item: ITag) => item.name;
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Recommended Cases",
    noResultsFoundText: "No results found!",
  };
  return (
    <Stack grow={2}
      style={pickerStyles}
    >
      <TagPicker
        removeButtonAriaLabel="Remove"
        pickerCalloutProps={{ doNotLayer: true }}
        componentRef={tagPickerRef}        
        styles={toggleStyles}
        onResolveSuggestions={onResolveSuggestions}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={{...inputProps, placeholder: "Search for litigation case name"}}
        onChange={onChange}
        selectedItems={selectedItems}
        onValidateInput={(input: string) => {
          //return input ? ValidationState.valid : ValidationState.invalid;
          const hasMatch = casesList.some(tag => tag.name.toLowerCase() === input.toLowerCase());
          return hasMatch ? ValidationState.valid : ValidationState.invalid;
        }}
        createGenericItem={(input: string) => {
          const isValid = casesList.some(tag => tag.key === input);
          if(!isValid){
            return { key: "", name: '' } as ITag;
          }
          return { key: "", name: input } as ITag;
        }}
        onItemSelected={(item?: ITag | undefined)  => {
          if (item === undefined) {
            return item;
          }
          if (!item?.key) {
            return onAddTag(item?.name || "").then((customTag: CustomTag) => ({
              key: customTag.id,
              name: customTag.text,
            }));
          }
          return item;
        }}        
      />
    </Stack>
  )
}


export default CasePicker