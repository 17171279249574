import {
    Context
} from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import * as React from 'react';
import { Reducer } from 'redux';
import { IProjectAppState } from '../../../../Shared/ProjectState';
import {
    ProjectReducerName,
    projectReducer,
    projectInitialState
} from '../../../../Shared/Reducers/Project.reducer';
import { projectSagas } from '../../../../Shared/Sagas/Project.sagas';
import { Stack } from '@fluentui/react';
import DisplaySpinner from '../../../../Common/Components/DisplaySpinner';
import ErrorDisplay from '../../../../Common/Components/ErrorDisplay';
import { requestRecentDocumentsList } from '../../../../Shared/Actions/Project.action';
import { IRequestRecentDocuments } from '../../../../Models/IRequestRecentDocuments';
import Carousel from './Carousel';
const RecentDocuments = () => {
    const reduxContext = React.useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [
        projectSagas
    ]);
    const { useSelector, dispatch } = reduxContext;
    const {
        isLoadingRecentDocuments,
        isLoadingRecentDocumentsError,
        recentDocumentsResponses,
        loginUserpersonaProps,
        recentDocumentsResponsesError
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    React.useEffect(() => {
        if(loginUserpersonaProps?.secondaryText) {
            const requestRecentDocuments: IRequestRecentDocuments = {
                userEmail:loginUserpersonaProps?.secondaryText,
                pageSize: 10
            };
            dispatch(requestRecentDocumentsList(requestRecentDocuments));
        }
        
    }, [loginUserpersonaProps]);

    return (
        <Stack>
            {isLoadingRecentDocuments && (
                <Stack style={{marginTop: -200}}>
                    <span
                        role="alert"
                        aria-label={'loading recent documents'}
                        aria-live="assertive"
                    />

                    <DisplaySpinner
                        accessabilityMessage={'Loading recent documents...'}
                        spinnerText={'Loading recent documents...'}
                        spinnerPosition={'right'}
                    />
                </Stack>
            )}

            {!isLoadingRecentDocuments && isLoadingRecentDocumentsError && (
                <>
                    {/* <ErrorDisplay source={recentDocumentsResponsesError} /> */}
                    No recent documents
                </>
            )}
            {!isLoadingRecentDocuments &&
                !isLoadingRecentDocumentsError &&
                recentDocumentsResponses?.recentDocumentsResponses?.length >
                    0 && (
                    <>
                        <Carousel
                            items={
                                recentDocumentsResponses?.recentDocumentsResponses
                            }
                        />
                    </>
                )}
                {!isLoadingRecentDocuments &&
                !isLoadingRecentDocumentsError &&
                recentDocumentsResponses?.recentDocumentsResponses?.length ===
                    0 && (
                    <>
                        No recent documents
                    </>
                )}
        </Stack>
    );
};

export default RecentDocuments;
