import React from "react"

const Disabled = ({disabled, children})=>{

  
  if(disabled) {
    return(
      <div style={{opacity:0.5, pointerEvents:'none'}}>
        {children}
      </div>

    )
  }
  return <React.Fragment>{children}</React.Fragment>
}

export default Disabled