import * as React from "react";
import { Nav, INavLinkGroup, IconButton  } from "@fluentui/react";
import { menuNavStyles } from "../Styles/VerticalMenuStyles";
import "../Styles/VerticalMenuStyles.css";

interface IVerticalMenuProps {
  navGroups: INavLinkGroup[];
  selectedKey: string;
}
export type VerticalMenuProps = IVerticalMenuProps

const VerticalMenu: React.FunctionComponent<VerticalMenuProps> = (props) => {

  return (
    <div id="vertical-menu-navigation">
      <Nav
        groups={props.navGroups}
        selectedKey={props.selectedKey}
        styles={menuNavStyles}
      />
    </div>
  )
}

export default VerticalMenu;