import React, { useState, useEffect, useRef } from 'react';
import { Reducer } from "redux";
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, DialogType, Stack, DialogContent, Button, Icon } from '@fluentui/react';
import { ILitigationFileContentRequest } from 'src/Models/ILitigationCaseRequest';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { requestLitigationCaseData, requestLitigationFileContent } from "../../../../../Shared/Actions/Project.action";
import DisplayMessage from "../../../../../Common/Components/DisplayMessage";
import DisplaySpinner from "../../../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../../../Helpers/ProjectConstants";
import './FileContentDialog.css';
import { ILitigationCase, ILitigationCaseFiles } from 'src/Models/ILitigationCase';
import MarkdownIt from 'markdown-it';
import FileHistory from './FileHistory/FileHistory';
export interface IFileContentProps {
    isOpen?: boolean;
    title?: string;
    onDismiss(): void;
    dialogWidth?: string;
    dialogHeight?: string;
    nativeFilePath?: string;
    item?: ILitigationCaseFiles;
}

const FileContentDialog = (fileProps: IFileContentProps) => {
    const { isOpen, title, onDismiss, dialogWidth, dialogHeight, nativeFilePath, item } = fileProps;
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectContext,
        litigationFileContentResponses,
        isLitigationFileContentResultsLoading,
        isLitigationFileContentResultsLoadingError,
        litigationFileURLResponses,
        isLitigationFileURLResultsLoading,
        isLitigationFileURLResultsLoadingError,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    //const [fileContent, setFileContent] = React.useState('');
    const [caseFile, setCaseFile] = React.useState<ILitigationCaseFiles>(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchPositions, setSearchPositions] = useState([]);
    const [searchVisible, setSearchVisible] = useState(false);
    const [justification, setJustification] = useState('');
    const searchRef = useRef(null);

    function getStringAfterLastSlash(nativeFilePath) {
        // Split the string by slashes and get the last part
        const parts = nativeFilePath.split('/');
        const lastPart = parts[parts.length - 1];
        return lastPart;
    }

    // Example usage:

    const fileName = getStringAfterLastSlash(nativeFilePath);

    const handleKeyPress = (e) => {
        // Check if Ctrl+F is pressed
        if (e.ctrlKey && e.key === 'f') {
            setSearchVisible(true);
            e.preventDefault(); // Prevent the browser's default "Find" dialog from showing up
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        if (item?.justification) {
            const renderJustification = new MarkdownIt().render(item?.justification)
            setJustification(renderJustification)
        }

    }, [item]);

    const handleCloseSearch = () => {
        setSearchVisible(false);
        setSearchKeyword('');
    };

    useEffect(() => {
        if (litigationFileContentResponses && litigationFileContentResponses?.litigationCaseFiles) {
            //setFileContent(litigationFileContentResponses?.litigationCaseFiles?.content);
            setCaseFile(litigationFileContentResponses?.litigationCaseFiles)
        }
    }, [litigationFileContentResponses]);

    let searchTimeout;

    const handleSearch = (value) => {
        setSearchKeyword(value); // Update search input state immediately

        if (value.trim() === '') {
            // If the search string is empty, reset search positions immediately
            setSearchPositions([]);
            return; // Exit early to prevent starting the debounce timeout
        }

        clearTimeout(searchTimeout); // Clear previous timeout
        searchTimeout = setTimeout(() => {
            const regex = new RegExp(value, 'gi');
            const matches = [];
            let match;
            while ((match = regex.exec(caseFile?.content)) !== null) {
                matches.push(match.index);
            }
            setSearchPositions(matches);
        }, 300); // Adjust the delay as needed (e.g., 300 milliseconds)
    };

    const convertPlainTextToHTML = (input) => {
        let html;

        try {
            // Ensure input is a string
            if (typeof input !== 'string') {
                input = JSON.stringify(input, null, 2);
            }

            // Check if input is JSON
            if (input.trim().startsWith('{')) {
                const jsonData = JSON.parse(input);

                // Function to recursively convert JSON to HTML
                const jsonToHTML = (json) => {
                    let html = '<ul>';

                    for (const key in json) {
                        if (json.hasOwnProperty(key)) {
                            const value = json[key];
                            html += `<li><strong>${key}:</strong> `;
                            if (typeof value === 'object' && value !== null) {
                                html += jsonToHTML(value);
                            } else {
                                html += String(value).replace(/&/g, '&amp;')
                                    .replace(/</g, '&lt;')
                                    .replace(/>/g, '&gt;')
                                    .replace(/"/g, '&quot;')
                                    .replace(/'/g, '&#039;');
                            }
                            html += '</li>';
                        }
                    }

                    html += '</ul>';
                    return html;
                };

                html = `<html><body>${jsonToHTML(jsonData)}</body></html>`;
            } else {
                // Treat input as regular text
                html = `<html><body><p>${input.replace(/\n/g, '<br>')}</p></body></html>`;
            }
        } catch (error) {
            console.error('Error parsing input:', error);
            // Treat input as regular text if JSON parsing fails
            html = `<html><body><p>${input.replace(/\n/g, '<br>')}</p></body></html>`;
        }

        return html;
    };


    const highlightMatches = (htmlContent) => {
        if (!searchKeyword || !htmlContent || typeof htmlContent !== 'string') {
            return { __html: htmlContent }; // Return the original content if conditions are not met
        }

        // Create a regular expression with the search keyword and apply global and case-insensitive flags
        const regex = new RegExp(`(${searchKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');

        // Apply highlighting to HTML content
        const highlightedHTML = htmlContent.replace(regex, '<mark>$1</mark>');

        return { __html: highlightedHTML }; // Return as an object with __html property
    };


    const openEmbedURL = () => {
        if (litigationFileURLResponses.embedURL) {
            let url = litigationFileURLResponses.embedURL;
            // Check if parameters already exist
            if (url.includes('?')) {
                // Parameters already exist, append &web=1
                url += '&web=1';
            } else {
                // No parameters exist, append ?web=1
                url += '?web=1';
            }
            // Open the modified URL in a new tab
            window.open(url, '_blank');
        };
    }

    return (
        <>
            <Dialog
                hidden={!isOpen}
                onDismiss={() => {
                    onDismiss();
                    setSearchKeyword('');
                    setSearchPositions([]);
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    showCloseButton: true,
                    styles: { content: { height: '80vh' } }
                }}
                modalProps={{
                    isBlocking: false,
                    styles: { main: { maxWidth: 'none', overflowY: 'hidden' } },
                }}
                styles={{
                    main: {
                        '@media(min-width: 768px)': {
                            minWidth: window.innerWidth - 50 || '600px',
                            height: '99vh'
                        },
                    }
                }}
            >

                <DialogContent styles={{
                    content: {
                        height: '80vh', // Set height
                        overflowY: 'auto', // Allow vertical scrolling
                    },
                }}>

                    {isLitigationFileContentResultsLoading && (
                        <Stack style={{ marginTop: 0 }}>
                            <span role="alert" aria-label="Loading file content" aria-live="assertive" />
                            <DisplaySpinner
                                accessabilityMessage="Loading file content"
                                spinnerText="Loading file content..."
                                spinnerPosition="right"
                            />
                        </Stack>
                    )}
                    {!isLitigationFileContentResultsLoading && caseFile && (
                        <div style={{ height: dialogHeight || '100vh' }}>
                            <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }} style={{ marginBottom: 10 }}>

                                <Stack style={{ marginLeft: 'auto', width: '40%', minWidth: '300px' }}>
                                    <input
                                        ref={searchRef}
                                        type="text"
                                        placeholder="Find"
                                        value={searchKeyword}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        style={{ height: '40px' }}
                                    />

                                </Stack>
                                <Stack>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {isLitigationFileURLResultsLoading ? (
                                            <button disabled style={{ cursor: 'not-allowed', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '125px', height: '40px' }}>
                                                <div className="spinner-container" style={{ marginRight: '8px' }}>
                                                    <div className="spinner"></div>
                                                </div>
                                                <span>Loading...</span>
                                            </button>
                                        ) : (
                                            <PrimaryButton onClick={openEmbedURL} style={{ width: '125px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Icon iconName="EdgeLogo" style={{ marginRight: '8px' }} />
                                                Native file
                                            </PrimaryButton>
                                        )}
                                    </div>
                                </Stack>
                            </Stack>


                            <div>
                                <table style={{ width: '100%', textAlign: 'left', marginTop: 30, borderCollapse: 'collapse', border: '1px solid #ddd' }}>
                                    <tbody>
                                        {caseFile?.metadata_storage_name && (
                                            <tr>
                                                <th className='columnName'>File Name</th>
                                                <td className='columnValue'>{caseFile?.metadata_storage_name}</td>
                                            </tr>
                                        )}
                                        {nativeFilePath && (
                                            <tr>
                                                <th className='columnName'>File Path</th>
                                                <td className='columnValue'>{nativeFilePath}</td>
                                            </tr>
                                        )}
                                        {caseFile?.file_class && (
                                            <tr>
                                                <th className='columnName'>File Class</th>
                                                <td className='columnValue'>{caseFile?.file_class}</td>
                                            </tr>
                                        )}
                                        {caseFile?.custodian && (
                                            <tr>
                                                <th className='columnName'>Custodian</th>
                                                <td className='columnValue'>{caseFile?.custodian}</td>
                                            </tr>
                                        )}
                                        {caseFile?.user_tags && (
                                            <tr>
                                                <th className='columnName'>User Tags</th>
                                                <td className='columnValue'>{caseFile?.user_tags}</td>
                                            </tr>
                                        )}
                                        {caseFile?.justification && (
                                            <tr>
                                                <th className='columnName'>Justification</th>
                                                <td className='columnValue'>
                                                    <div style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: caseFile?.justification }} />
                                                </td>
                                            </tr>
                                        )}
                                        {caseFile?.decision && (
                                            <tr>
                                                <th className='columnName'>Relevant to the Case</th>
                                                <td className='columnValue'>{caseFile?.decision}</td>
                                            </tr>
                                        )}
                                        {caseFile?.fileRelevanceScore && (
                                            <tr>
                                                <th className='columnName'>Relevance</th>
                                                <td className='columnValue'>{litigationFileContentResponses?.litigationCaseFiles?.fileRelevanceScore}</td>
                                            </tr>
                                        )}
                                        {caseFile?.documentTheme && (
                                            <tr>
                                                <th className='columnName'>Document theme</th>
                                                <td className='columnValue'>{caseFile?.documentTheme}</td>
                                            </tr>
                                        )}
                                        {caseFile?.documentSummary && (
                                            <tr>
                                                <th className='columnName'>Document summary</th>
                                                <td className='columnValue'>{caseFile?.documentSummary}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th className='columnName'>Classification History</th>
                                            <td className='columnValue'>
                                                <Stack style={{marginLeft:10}}>
                                                    <FileHistory id={item?.id} fileId={item?.file_ID}/>
                                                </Stack>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 30, paddingBottom: 40 }} dangerouslySetInnerHTML={highlightMatches(convertPlainTextToHTML(caseFile?.content))} />
                        </div>


                    )}
                </DialogContent>

            </Dialog>
        </>
    );
};

export default FileContentDialog;
