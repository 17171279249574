import { Icon, Image, Stack, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { Reducer } from "redux";
import { container, detailsText, iconStyle, notificationText, notificationTextBold, notificationTextWidth, notificationTitle, notificationTitleMsg, seeNotifications, seeNotificationsText, stackStyle } from "./Styles/Notification";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../Helpers/ProjectConstants";
import { isNotificationRead, requestUserNotification, setProjectContext } from "../../../Shared/Actions/Project.action";
import { IUserNotificationRequest } from "../../../Models/UserNotification/IUserNotificationRequest";
import { IUserNotificationResponse } from "../../../Models/UserNotification/IUserNotificationResponse";
import { IProjectContext } from "../../../Models/IProjectContext";
import { useHistory } from "react-router-dom";
import { projectTitle } from "../MyProjects/Styles/MyProject";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { formatDateString, truncateText } from "../../../Helpers/ProjectHelper";
import peopleBot from './Images/PeopleBot.png'
import nonPeopleBot from './Images/NonPeople.png'
import { IProjectSearchRequest } from "../../../Models/IProjectSearchRequest";
import { pages } from "@microsoft/teams-js";
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
const Notifications = () => {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        loadingUserNotifications,
        loadingUserNotificationsError,
        userNotifications,
        userNotificationError,
        projectContext,
        loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.secondaryText) {    
            const userNotificationRequest:IUserNotificationRequest = {
                userAlias: loginUserpersonaProps?.secondaryText,
                currentPage:1,
                recordsPerPage: 5,
                sortColumn:'NotificationDate',
                sortOrder:'desc'
            }        
            dispatch(requestUserNotification(userNotificationRequest));
        }
    }, [loginUserpersonaProps]);

    const openProject = (e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string, notificationId?: string): void => {
        const userNotification: IUserNotificationResponse = {
            isRead : true,
            notificationId: notificationId
        }                
        dispatch(isNotificationRead(userNotification));
        if(pages.currentApp.isSupported) {
            const tabNavigation: ITabNavigation = { 
                isNewProjectCreation: false, 
                isFromDashboard: true, 
                componentName:'/viewproject', 
                projectNumber:projectNumber, 
                projectSearchRequest:{} };
            const navPromise = pages.navigateToApp({appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectslist', subPageId:JSON.stringify(tabNavigation)});
            navPromise.then(()=>{
                console.log('Navigated to project list page')
            }).catch((error)=>{                
                history.push(`/viewproject?projectNumber=${projectNumber}&backToProjectList=false`);
            })
        }else{                       
            history.push(`/viewproject?projectNumber=${projectNumber}&backToProjectList=false`);
        }        
    }

    const allNotifications = (e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void => {        
        history.push("/allNotifications");
    }

    return (
        <>      
            <Stack style={container}>
                <Stack>
                    <div style={notificationTitle}>
                        Notifications
                    </div>
                </Stack>
                {loadingUserNotifications && (
                    <Stack style={{marginTop: 50}}>
                        <span role="alert" aria-label={GenericMessages.userNotificationsLoading} aria-live="assertive" />
                       
                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.userNotificationsLoading}
                            spinnerText={GenericMessages.userNotificationsLoading}
                            spinnerPosition={"right"}
                        />
                       
                    </Stack>
                )}
                {!loadingUserNotifications && loadingUserNotificationsError && (
                    <>
                        <ErrorDisplay source={userNotificationError?.errorMessage} customText=" to see notifications" />
                    </>
                )}
                {!loadingUserNotifications && !loadingUserNotificationsError && userNotifications?.totalRecords===0 && (
                    <>                            
                        <Stack>
                            <div style={notificationTitleMsg}>
                                No notifications
                            </div>
                        </Stack>                            
                    </>
                    )}   
                {!loadingUserNotifications && !loadingUserNotificationsError && userNotifications?.totalRecords>0 && (
                    <>
                    <Stack style={{height:170}}>
                        {userNotifications?.userNotificationResponseList?.map((userNotificationResponse: IUserNotificationResponse)=>{

                            return (<>
                                <Stack horizontal style={stackStyle}>
                                    <Stack>
                                        <Image src={(userNotificationResponse?.operationType==='ProjectCreated'|| userNotificationResponse?.operationType==='ProjectUpdated') ?peopleBot:nonPeopleBot} style={{marginTop: 5, left:16}}/>
                                    </Stack>
                                    <Stack style={notificationTextWidth}>
                                        <div style={!userNotificationResponse?.isRead?notificationTextBold:notificationText}>
                                            <TooltipHost 
                                            content={userNotificationResponse?.notificationText} 
                                            aria-label={userNotificationResponse?.notificationText}
                                            tabIndex={0}
                                            >
                                                {truncateText(userNotificationResponse?.notificationText, 30, 30)}
                                            </TooltipHost>
                                        </div>
                                    </Stack>
                                    <Stack>
                                        <span style={detailsText}>{formatDateString(userNotificationResponse.notificationDate)}</span>
                                    </Stack>
                                    <Stack>
                                        <div style={detailsText} tabIndex={0}>
                                            
                                                <>
                                                    {userNotificationResponse?.operationType!=='ProjectDeleted' && (
                                                        <Stack horizontal onClick={(e) => openProject(e, userNotificationResponse.projectNumber, userNotificationResponse?.notificationId)} style={{cursor:'pointer'}}>
                                                            Details
                                                        </Stack>   
                                                     )} 
                                                     {userNotificationResponse?.operationType==='ProjectDeleted' && (
                                                        <Stack horizontal  style={{cursor:'cursor'}}>
                                                            Details
                                                        </Stack>   
                                                     )}                                                         
                                                </>
                                                                                     
                                        </div>
                                    </Stack>
                                </Stack>                                
                            </>)
                        })} 
                        </Stack>
                        <Stack style={seeNotifications}>
                            <Stack style={seeNotificationsText} onClick={(e) => allNotifications(e)} >
                                <span tabIndex={0}>
                                    See all notifications
                                </span>                                
                            </Stack>
                        </Stack>                           
                    </>
                )}
            </Stack>            
        </>
    )
}

export default Notifications;