// CustomDialog.js
import React, { useEffect } from 'react';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, DialogType, Stack, DialogContent, Dropdown, TooltipHost, TagItemSuggestion, ITag } from '@fluentui/react';
import { ILitigationCase, ILitigationCaseFiles } from 'src/Models/ILitigationCase';
import { truncateText } from '../../../../../Helpers/ProjectHelper';
import CustomTagPicker from '../../../../../Components/PickerComponents/CustomTagPicker';
import CustomTags, { CustomTag } from "../../../../../Models/CustomTags";
import { IProjectTags } from '../../../../../Models/IProjectTags';
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import { useHistory } from 'react-router';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { ILitigationCaseFilesRequest, ILitigationFilesRequest, ILitigationSaveUserTagsRequest } from 'src/Models/ILitigationCaseRequest';
import { IProjectAppState } from 'src/Shared/ProjectState';
import { requestSaveUserTags, resetSaveUserTagsSuccess } from "../../../../../Shared/Actions/Project.action";
import ErrorDisplay from '../../../../../Common/Components/ErrorDisplay';
export type ITagContentDialogProps = {
  isOpen?: boolean;
  title?: string;
  onDismiss(files?: ILitigationCaseFiles[]): void;
  dialogWidth?: string;
  dialogHeight?: string;
  files?: ILitigationCaseFiles[];
  refreshCaseDetailsView(): void;
  cases?: ILitigationCase[];
}

const TagContentDialog = (tagContentDialogProps: ITagContentDialogProps) => {
  const { isOpen, title, onDismiss, dialogWidth, dialogHeight, files, refreshCaseDetailsView, cases } = tagContentDialogProps
  const [selectedFilesTags, setSelectedFilesTags] = React.useState<ILitigationCaseFiles[]>([]);
  const [tagCount, setTagCount] = React.useState<number>(0);
  const [customTags, setCustomTags] = React.useState<CustomTag[]>([]);
  const [projectTag, setProjectTag] = React.useState<number>(0);
  const [localProjectTags, setProjectTags] = React.useState<IProjectTags>();
  const [changeDetected, setChangeDetected] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);

  let history = useHistory();
  const { updateNavItem } = useCreateNavContext();
  const { mainSidePanel, subMenuSidePanel } = useCreateNavContext();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector, dispatch } = reduxContext;
  const {
    litigationSaveUserTagsRequest,
    litigationSaveUserTagsResponses,
    litigationSaveUserTagsResponsesError,
    isLitigationSaveUsertagsLoading,
    isLitigationSaveUserTagsLoadingError,
    isLoadedInBrowser,
    isSaveUserQueryError
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

  const { loginUserpersonaProps } = useSelector(
    (state: IProjectAppState) =>
      state.dynamic?.[ProjectReducerName] || projectInitialState
  );

  const addFileTags = () => {
    saveTagInfo();
  }

  const clearFileTags = () => {
    saveTagInfo(true);
  }

  const saveTagInfo = (isClear: boolean = false) => {
    const tags = selectedTags?.map(tag => tag?.name)?.join(', ');

    let caseFileRequest: ILitigationFilesRequest[] = [];

    cases?.map((caseItem: ILitigationCase) => {
      if (files.some(file => file?.caseId === caseItem?.caseId)) {
        const fileList = files.filter((fileItem) => fileItem.caseId === caseItem.caseId) as ILitigationCaseFiles[];
        fileList.map((fileItem) => {
          let selectedTagsArray = selectedTags?.length == 0 ? [] : selectedTags?.map(tag => tag?.name) as string[];
          let userTagsArray = fileItem?.user_tags?.length == 0 ? [] : fileItem?.user_tags?.split(',') as string[];

          let combinedTags = selectedTagsArray?.length == 0 ? [] :
            userTagsArray?.length > 0 ? Array.from(new Set([...selectedTagsArray, ...userTagsArray]))
              : selectedTagsArray;

          fileItem.user_tags = combinedTags?.length > 0 ? combinedTags?.join(',') : '';
        });
        const detail: ILitigationFilesRequest = {
          litigationCase: caseItem as ILitigationCase,
          files: fileList
        };
        caseFileRequest.push(detail);
      }
    });
    const litigationSaveUserTagrequest: ILitigationSaveUserTagsRequest = {
      userAlias: loginUserpersonaProps?.secondaryText,
      caseFileDetails: caseFileRequest
    };
    dispatch(requestSaveUserTags(litigationSaveUserTagrequest));
  }

  useEffect(() => {
    if (litigationSaveUserTagsResponses?.isSuccess) {
      dispatch(resetSaveUserTagsSuccess(false));
      // files.map((file: ILitigationCaseFiles) => {
      //   let newTags = selectedTags?.map(tag => tag?.name) || [];
      //   if (newTags?.length == 0) {
      //     file.user_tags = '';
      //   } else {
      //     let existingTags = file.user_tags ? file.user_tags.split(', ') : [];
      //     let combinedTags = Array.from(new Set([...existingTags, ...newTags]));
      //     file.user_tags = combinedTags.join(', ');
      //   }
      // });
      refreshCaseDetailsView();
      onDismiss(selectedFilesTags);
    }
  }, [litigationSaveUserTagsResponses])

  const handleRemoveFiles = (fileName?: string) => {
    const updatedObjectList = selectedFilesTags.filter(obj => obj?.metadata_storage_name !== fileName);
    setSelectedFilesTags([...updatedObjectList])
    console.log(updatedObjectList.length)
  }

  useEffect(() => {
    setSelectedFilesTags([...files])
  }, [files])



  React.useEffect(() => {
    const customProjectTags = CustomTags;
    setCustomTags(customProjectTags);
    const fileTags: ITag[] = [];
    if (files?.length == 1) {
      const tags: ITag[] = files[0]?.user_tags?.split(',').map((tagItem: string) => {
        return {
          key: tagItem?.trim(),
          name: tagItem?.trim(),
        } as ITag;
      });
      setSelectedTags(tags);
    }
  }, []);

  React.useEffect(() => {
    const iTags: ITag[] = [];
    localProjectTags?.tags?.map((projectTag: string) => {
      const iTag: ITag = {
        key: projectTag,
        name: projectTag,
      };
      iTags.push(iTag);
    });
  }, [localProjectTags]);

  const onAddTag = async (text: string) => {
    setTagCount((x) => x + 1);
    const currentTagidIndex = tagCount + customTags.length;
    const response = await Promise.resolve({
      id: currentTagidIndex,
      text,
    });
    return response;
  };
  const onCategorySelected = (id?: number) => {
    setProjectTag(id);
  };
  const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
    if (filter) {
      return customTags
        .filter((customTag: CustomTag) => customTag.text.toLowerCase().includes(filter.toLowerCase()))
        .map((customTag: CustomTag): ITag => {
          return {
            name: customTag.text,
            key: customTag.id,
          };
        });
    }
    return [];
  };

  const onChange = (items?: ITag[] | undefined) => {
    if (items && items[0]) {
      onCategorySelected(Number(items[0].key));
    } else {
      onCategorySelected();
    }
    setSelectedTags(items);
    let tagStrings: string[] = [];
    const localProjectTag: IProjectTags = {
      tags: [],
    };
    items.map((item: ITag) => {
      tagStrings.push(item.name.toString());
    });
    localProjectTag.tags = tagStrings;
    setProjectTags(localProjectTag);
    setChangeDetected(true);
  };

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={() => onDismiss(null)}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        showCloseButton: !isLitigationSaveUsertagsLoading,
        styles: { content: { minHeight: 550, height: 'auto', overflowY: 'none' } }
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 'none', overflowY: 'none' } },
      }}
      styles={{
        main: {
          '@media(min-width: 768px)': {
            minWidth: dialogWidth || '600px',
          },
        }
      }}
    >

      <DialogContent>
        <Stack style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
          <Stack horizontal>
            Selected files ({selectedFilesTags.length}) <span style={{ color: 'red' }}>*</span>
          </Stack>
          <Stack horizontal wrap tokens={{ childrenGap: 1 }} style={{ width: 650, height: 150, borderRadius: 3, background: 'var(--Light-theme-Rest-Background-Default-Background-3, #F0F0F0)' }}>
            {selectedFilesTags.map((file: ILitigationCaseFiles, index: number) => {
              return (
                <Stack>
                  {index < 15 && <TooltipHost content={file?.metadata_storage_name}>
                    <Stack style={{ width: 130, height: 22, margin: 5, padding: 5, flexShrink: 0, background: 'var(--surfaceColors-white, #FFF)', borderRadius: 12 }} horizontal>
                      <Stack style={{
                        color: 'var(--typography-colors-secondary-neutral-secondary, #605E5C)',
                        fontFamily: 'Segoe UI',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                      }}>
                        {truncateText(file?.metadata_storage_name, 15, 15)}
                      </Stack>
                      <Stack style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexShrink: 0,
                        marginLeft: 'auto'
                      }}>
                        <div style={{
                          color: '#363636',
                          fontSize: '10px',
                          cursor: 'pointer'
                        }} tabIndex={0} onClick={() => handleRemoveFiles(file?.metadata_storage_name)}>X</div>
                      </Stack>
                    </Stack>
                  </TooltipHost>}
                </Stack>)
            })}
            {selectedFilesTags.length > 15 && (
              <Stack style={{ marginTop: 5 }}>
                ...
              </Stack>
            )}
          </Stack>
          <Stack>
            <Stack style={{ marginTop: 19 }} horizontal>
              Tags <span style={{ fontSize: 'smaller', marginLeft: 2, marginTop: 2 }}> (select recommended tags or enter free text and press tab to add a custom tag) </span>
              <span style={{ color: 'red', marginLeft: 5 }}>*</span>
            </Stack>
            <Stack style={{ marginTop: 5 }}>
              <div style={{ border: "0.5px solid grey", position: "fixed", width: 575 }}>
                <CustomTagPicker
                  onAddTag={onAddTag}
                  onCategorySelected={onCategorySelected}
                  onResolveSuggestions={onResolveSuggestions}
                  onChange={onChange}
                  selectedItems={selectedTags}
                />
              </div>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <div style={{ marginTop: 100 }}>
          <DefaultButton onClick={() => onDismiss(null)} text="Cancel" disabled={isLitigationSaveUsertagsLoading} />
          {/* <PrimaryButton onClick={clearFileTags} hidden={true}>
          {isLitigationSaveUsertagsLoading ?
            <button disabled style={{ cursor: 'not-allowed', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80px', height: '30px' }}>
              <div className="spinner-container" style={{ marginRight: '8px' }}>
                <div className="spinner"></div>
              </div>
              <span>clearing..</span>
            </button> : "Clear"}
        </PrimaryButton> */}
          <PrimaryButton onClick={addFileTags} style={{ marginLeft: 10 }}>
            {isLitigationSaveUsertagsLoading ?
              <button disabled style={{ cursor: 'not-allowed', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80px', height: '30px' }}>
                <div className="spinner-container" style={{ marginRight: '8px' }}>
                  <div className="spinner"></div>
                </div>
                <span>saving..</span>
              </button> : "Add"}
          </PrimaryButton>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default TagContentDialog;

