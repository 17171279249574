// CustomDialog.js
import React, { useEffect } from 'react';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, DialogType, Stack, DialogContent, Dropdown, TooltipHost, TagItemSuggestion, TextField } from '@fluentui/react';
import { ILitigationCaseFiles } from 'src/Models/ILitigationCase';
import { truncateText } from '../../../../../Helpers/ProjectHelper';


export type IReviewerContentDialogProps = {
  isOpen?: boolean;
  title?: string;
  onDismiss(files?: ILitigationCaseFiles[]): void;
  dialogWidth?: string;
  dialogHeight?: string;
  files?: ILitigationCaseFiles[]
}

const ReviewerContentDialog = (reviewerContentDialogProps: IReviewerContentDialogProps) => {
  const { isOpen, title, onDismiss, dialogWidth, dialogHeight, files } = reviewerContentDialogProps
  //const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [selectedFilesReviewers, setSelectedFilesReviewers] = React.useState<ILitigationCaseFiles[]>([]);
  const [description, setDescription] = React.useState('');
  const [reviewers, setReviewers] = React.useState('');
  const handleReviewerToFiles = () => {
    const tags = reviewers
    selectedFilesReviewers.map((file: ILitigationCaseFiles, index: number) => {
      file.assignedTo = tags
    })
    onDismiss(selectedFilesReviewers);
  }

  const handleRemoveFiles = (native_File_Name?: string) => {
    const updatedObjectList = selectedFilesReviewers.filter(obj => obj.native_File_Name !== native_File_Name);
    setSelectedFilesReviewers([...updatedObjectList])
    console.log(updatedObjectList.length)
  }

  const handleDescriptionChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setDescription(newValue || '');
    },
    [],
  );

  const handleReviewersChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setReviewers(newValue || '');
    },
    [],
  );

  useEffect(() => {
    setSelectedFilesReviewers([...files])
  }, [files])

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={() => onDismiss(null)}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        showCloseButton: true,
        styles: { content: { height: dialogHeight || 'auto', overflowY: 'none' } }
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 'none', overflowY: 'none' } },
      }}
      styles={{
        main: {
          '@media(min-width: 768px)': {
            minWidth: dialogWidth || '600px',
          },
        }
      }}
    >

      <DialogContent>
        <Stack style={{ overflow: 'hidden', marginTop: -30 }}>
          {/* <Stack horizontal>
            Selected files ({selectedFilesReviewers.length}) <span style={{ color: 'red' }}>*</span>
          </Stack> */}
          {/* <Stack horizontal wrap tokens={{ childrenGap: 2 }} style={{ width: 564, height: 104, borderRadius: 3, background: 'var(--Light-theme-Rest-Background-Default-Background-3, #F0F0F0)' }}>
            {selectedFilesReviewers.map((file: ILitigationCaseFiles, index: number) => {
              return (
                <Stack>
                  {index < 6 && <TooltipHost content={file?.native_File_Name}>
                    <Stack style={{ width: 130, height: 22, margin: 10, padding: 5, flexShrink: 0, background: 'var(--surfaceColors-white, #FFF)', borderRadius: 12 }} horizontal>
                      <Stack style={{
                        color: 'var(--typography-colors-secondary-neutral-secondary, #605E5C)',
                        fontFamily: 'Segoe UI',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                      }}>
                        {truncateText(file?.native_File_Name, 10, 10)}
                      </Stack>
                      <Stack style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexShrink: 0,
                        marginLeft: 'auto'
                      }}>
                        <div style={{
                          color: '#363636',
                          fontSize: '10px',
                          cursor: 'pointer'
                        }} tabIndex={0} onClick={() => handleRemoveFiles(file?.native_File_Name)}>X</div>
                      </Stack>
                    </Stack>
                  </TooltipHost>}
                </Stack>)
            })}
            {selectedFilesReviewers.length > 6 && (
              <Stack style={{ marginTop: 5 }}>
                ...
              </Stack>
            )}
          </Stack> */}
          <Stack>
            <Stack style={{ marginTop: 19 }} horizontal>
              Reviewers <span style={{ color: 'red' }}>*</span>
            </Stack>
            <Stack style={{ marginTop: 5 }}>
            <TextField
                className='descriptionControl'
                name="reviewers"                
                value={reviewers}
                onChange={handleReviewersChange}
                multiline
                autoAdjustHeight />
            </Stack>
          </Stack>

          <Stack>
            <Stack style={{ marginTop: 19 }} horizontal>
              Note
            </Stack>
            <Stack style={{ marginTop: 5 }}>
              <TextField
                className='descriptionControl'
                name="description"
                placeholder="Enter description"
                value={description}
                onChange={handleDescriptionChange}
                multiline
                autoAdjustHeight />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <DefaultButton onClick={() => onDismiss(null)} text="Cancel" />
        <PrimaryButton onClick={handleReviewerToFiles} text="Assign" />
      </DialogFooter>
    </Dialog>
  );
};

export default ReviewerContentDialog;
