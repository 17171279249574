export const baseContainerStyle: React.CSSProperties = {
  height: '100vh',
  margin: 0,
  width: '100vw',
  overflowY: 'hidden',
};

export const mainComponentStyle: React.CSSProperties = {
  height: '100vh',
  overflowY: 'auto',
  width:'100vw'
};


export const createButtonStyle: React.CSSProperties =
{
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 12px",
  width: "125px",
  height: "35px",
  background: "#4F52B2",
  borderRadius: "6px",
  borderWidth: "0px",
  boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.32), 0px 1.6px 3.6px rgba(0, 0, 0, 0.28)",
  cursor: "pointer",
  margin: "26px 0px 0px 24px"
}

export const disabledButtonStyle: React.CSSProperties = {
  ...createButtonStyle,
  background: "#A9A9A9", // Disabled gray background
  cursor: "not-allowed", // Disabled cursor
  boxShadow: "none", // Remove shadow when disabled
  opacity: 0.6, // Slightly reduce opacity
};

export const createButtonText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  color: "#FFFFFF",
}

export const collapsed: React.CSSProperties =
{
  marginLeft: -150,
  
}
export const expanded: React.CSSProperties =
{
  transition: 'margin-left 0.5s',
  maxWidth:165
}

export const iconExpanded: React.CSSProperties =
{
  marginLeft:-125,marginTop:-20
}

export const iconCollpased: React.CSSProperties =
{
  
  marginLeft:23,marginTop:-20
}