import { Modal, IconButton, IIconProps, Stack, DefaultButton } from "@fluentui/react";
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { useSelector } from "react-redux";
import { Reducer } from "redux";
import DOMPurify from "dompurify";
import { buttonParentStackStyles, contentStyles, iconButtonStyles, primaryButtonStyles } from "./Styles/Banner.Styles";
import { v4 as uuidv4 } from "uuid";
import { IShowBanner } from "../../Models/Banner/IShowBanner";
import { ProjectAPI } from "../../Services/ProjectAPI";
import { IProjectAppState } from "../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { updateBannerStatusInState } from "../../Shared/Actions/Project.action";

const Banner = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { dispatch, httpClient } = reduxContext;
  const { loginUserpersonaProps, bannerInformation, isLoadingBannerInformation, isLoadingErrorBannerInformation } = useSelector(
    (state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState
  );
  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [showBanner, setShowBanner] = React.useState<boolean>(true);
  const titleId = useId("title");

  React.useEffect(() => {
    if (bannerInformation?.showBanner) {
      setIsModalOpen(true);
    }
  }, [bannerInformation?.showBanner]);

  const updateUerBannerStatusAsync = async (): Promise<any> => {
    const bannerInfo: IShowBanner = {
      showBanner: false,
      userAlias: loginUserpersonaProps?.secondaryText,
    };
    await ProjectAPI.requestHideBannerForUser(bannerInfo, httpClient, uuidv4());
    dispatch(updateBannerStatusInState())
  };

  React.useEffect(() => {
    if (showBanner === false) {
      const updateUerBannerStatus = async () => {
        try {
          await updateUerBannerStatusAsync();
        } catch (error: any) {
          console.log(error);
        }
      };
      updateUerBannerStatus();
    }
  }, [showBanner]);

  const hideModal = () => {
    setIsModalOpen(false);
  };
  const handleHideBanner = () => {
    setShowBanner(false);
    setIsModalOpen(false);
  };
  return (
    <>
      {bannerInformation?.showBanner && bannerInformation?.bannerContent && !isLoadingBannerInformation && ! isLoadingErrorBannerInformation && (
        <Modal
          titleAriaId={titleId}
          isOpen={isModalOpen}
          onDismiss={hideModal}
          isBlocking={true}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <h2 className={contentStyles.heading} id={titleId} tabIndex={0}>
              {bannerInformation?.title}
            </h2>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={hideModal}
            />
          </div>
          <div className={contentStyles.body}>
            <Stack>
              <Stack>
                <p className={contentStyles.bodyContent} tabIndex={0}>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bannerInformation?.bannerContent) }} />
                </p>
              </Stack>
              <Stack styles={buttonParentStackStyles}>
                <DefaultButton onClick={handleHideBanner} styles={primaryButtonStyles}>
                  Don't Show Again.
                </DefaultButton>
              </Stack>
            </Stack>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Banner;
