import React from "react";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { MessageBarType, Stack } from "@fluentui/react";
import DisplayMessage from "../../../../../Common/Components/DisplayMessage";

export interface IClassificationStatusProps{
    showFileClassificationSuccessMessage?: boolean
}

const ClassificationStatus = (classificationStatusProps: IClassificationStatusProps) => {
    const {showFileClassificationSuccessMessage} = classificationStatusProps
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector } = reduxContext;   

    const { litigationClassifyFileResponse,
        isLitigationCaseResultsLoading 
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );
    return (<>
        {!isLitigationCaseResultsLoading && showFileClassificationSuccessMessage && (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {litigationClassifyFileResponse?.successFilesClassificationCount > 0 && (
                    <DisplayMessage
                        accessabilityMessage={`${litigationClassifyFileResponse?.successFilesClassificationCount} files classified successfully.`}
                        messageType={MessageBarType.success}
                        message={`${litigationClassifyFileResponse?.successFilesClassificationCount} files classified successfully.`}
                    />
                )}
                {litigationClassifyFileResponse?.failureFilesClassificationCount > 0 && (
                    <DisplayMessage
                        accessabilityMessage={`${litigationClassifyFileResponse?.failureFilesClassificationCount} files not classified successfully`}
                        messageType={MessageBarType.error}
                        message={`${litigationClassifyFileResponse?.failureFilesClassificationCount} files not classified successfully`}
                    />
                )}
                {litigationClassifyFileResponse?.isProcessingByWebjob && (
                    <DisplayMessage
                        accessabilityMessage={`Files are classified through web job`}
                        messageType={MessageBarType.success}
                        message={`Files submitted for classification.`}
                    />
                )}
            </Stack>
        )}    
    </>);
}

export default ClassificationStatus;