import { Stack } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import React from "react";
import { IProjectAppState } from "../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { WelcomeContainerStyle } from "./Styles/WelcomeMessage.Styles";
const WelcomeMessage = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector } = reduxContext;
  const { loginUserpersonaProps } = useSelector(
    (state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState
  );
  return (
    <>
      <Stack styles={WelcomeContainerStyle}>
        <Stack>
          <Stack>Hi {loginUserpersonaProps?.primaryText ?? loginUserpersonaProps?.text} ,</Stack>
          
            {/* <Stack style={{ marginTop: 10 }}>
              Ready to explore? Select one of the suggestions below to get started...
            </Stack> */}
         
        </Stack>
      </Stack>
    </>
  );
};

export default WelcomeMessage;
