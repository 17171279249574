import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { retryUserPrompt, setNewUserPrompt } from '../../../../Shared/Actions/Chat.action';
import { IChatResponse } from '../../../../Models/Chat/IChatResponse';
import { IResponseNewAnswerError } from '../../../../Models/Chat/IResponseNewAnswerError';
import './ChatError.styles.css'
import Feedback from '../Feedback/Feedback';
export interface IChatErrorProps {
	chatResponse?: IChatResponse;
	isNewAnswer?: boolean;
	user?: string;
	userAlias?: string;
	qnaId?: string;
	topicId?: string;
	errorMessage?: string;
}

const ChatError = (chatErrorProps: IChatErrorProps) => {
	const [errorMessage, setErrorMessage] = React.useState('');
	const [correlationId, setCorrelationId] = React.useState('');
	const { userAlias, topicId, qnaId } = chatErrorProps;
	const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
	useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
	const { responseNewAnswerError } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);
	const [statusCode, setStatusCode] = React.useState<number>();
	const [errorDetails, setErrorDetails] = React.useState<IResponseNewAnswerError>();

	const tryAgainClickEvent = () => {
		dispatch(retryUserPrompt(true));
	};
	React.useEffect(() => {
		if (errorDetails?.CorrelationId) {
			setCorrelationId(errorDetails?.CorrelationId);
		}
	}, [errorDetails]);

	React.useEffect(() => {
		if (Object.keys(responseNewAnswerError).length > 0) {
			if (responseNewAnswerError?.errorMessage?.data?.Messages?.[0]) {
				setErrorMessage(responseNewAnswerError?.errorMessage?.data?.Messages?.[0]);
			} else if (responseNewAnswerError?.errorMessage?.data?.Message) {
				setErrorMessage(responseNewAnswerError?.errorMessage?.data?.Message);
			} else if (responseNewAnswerError?.errorMessage?.data?.message) {
				setErrorMessage(responseNewAnswerError?.errorMessage?.data?.message);
			} else if (responseNewAnswerError?.errorMessage?.statusText) {
				setErrorMessage(responseNewAnswerError?.errorMessage?.statusText);
			}
			setErrorDetails(responseNewAnswerError);
			if (responseNewAnswerError?.errorMessage?.data?.StatusCode) {
				setStatusCode(responseNewAnswerError?.errorMessage?.data?.StatusCode);
			}
			if (responseNewAnswerError?.errorMessage?.status) {
				setStatusCode(+responseNewAnswerError?.errorMessage?.status);
			}
		}
	}, [responseNewAnswerError]);

	return (
		<>
			<Stack className='chat-error-container'>
				<Stack className='chat-error-text'>
					Sorry, something went wrong. Please try again or share your feedback or report this error to
					<a
						href={`mailto:celalmengcrew@microsoft.com?subject=CELA Legal Ease Chat Error: ${errorMessage}.
                                      &body=An error occurred in  CELA Legal EASE Chat". Correlation-id: ${correlationId}. Error reason is ${errorMessage}`}
					>
						CELA Engineering Team.
					</a>
				</Stack>
				<Stack style={{ marginTop: 5 }}>
					<Feedback userAlias={userAlias} topicId={topicId} QNAId={qnaId} />
				</Stack>
			</Stack>
			<Stack className='retry-container'>
				<div tabIndex={0} onClick={tryAgainClickEvent} style={{ cursor: 'pointer' }}>
					<Stack horizontal className='retry-text'>
						<Stack className='retry-svg'>
							<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
								<path d="M15.5 10C15.5 7.23858 13.2614 5 10.5 5C8.86492 5 7.413 5.7844 6.5 6.99946L9 7C9.27614 7 9.5 7.22386 9.5 7.5C9.5 7.77614 9.27614 8 9 8H6C5.72386 8 5.5 7.77614 5.5 7.5V6.84295C5.49994 6.83864 5.49994 6.83434 5.5 6.83003V4.5C5.5 4.22386 5.72386 4 6 4C6.27614 4 6.5 4.22386 6.5 4.5V5.52786C7.56118 4.57815 8.96306 4 10.5 4C13.8137 4 16.5 6.68629 16.5 10C16.5 13.3137 13.8137 16 10.5 16C7.18629 16 4.5 13.3137 4.5 10C4.5 9.72386 4.72386 9.5 5 9.5C5.27614 9.5 5.5 9.72386 5.5 10C5.5 12.7614 7.73858 15 10.5 15C13.2614 15 15.5 12.7614 15.5 10Z" fill="#424242" />
							</svg>
						</Stack>
						<Stack className='retry-again-text'>Try again</Stack>
					</Stack>
				</div>
			</Stack>
		</>
	);
};
export default ChatError;
