import { ITopic, ITopicResponses } from '../../../../Models/Chat/ITopic';
import React, { useEffect, useRef, useState } from 'react'
import { IPersonaProps, Stack } from "@fluentui/react";
import ChatError from './ChatError';
import { ISearchResponse } from '../../../../Models/Chat/ISearchResponse';
import DisplayAnswer from './DisplayAnswer';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import FeedbackSuccessResponse from '../Feedback/FeedbackSuccessResponse';
type IChatAnswerProps = {
  chatTopicResponse?: ITopic;
  topicId?: string;
  question?: string;
  loginUserPersonaProps?: IPersonaProps;
  topicProjectNumber?: string;
}

const ChatAnswer = (chatAnswerProps: IChatAnswerProps) => {

  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { isFeedbackSubmissionSuccess, feedbackRequest } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);

  const myRefMessage = useRef(null);
  const [isQuestionResponseError, setIsQuestionResponseError] = useState<boolean>(false);
  const [hoveredClassName, setHoveredClassName] = useState('chat-copy-container_unhover');
  const { chatTopicResponse, topicId, question, loginUserPersonaProps, topicProjectNumber } = chatAnswerProps;
  useEffect(() => {
    if (chatTopicResponse && chatTopicResponse?.questions?.[0]?.searchResponsesList?.length > 0) {
      const responseString: boolean = chatTopicResponse?.questions[0].searchResponsesList.length > 0;
      if (responseString) {
        if (myRefMessage && myRefMessage?.current) myRefMessage.current.scrollIntoView(false, { behavior: "smooth" });
      }
    }
  }, [chatTopicResponse?.user]);


  //onMouseLeave={() => handleQuestionHovered(false)}
  return (
    <>
      <div ref={myRefMessage} style={{ marginTop: 10 }}></div>
      {chatTopicResponse && chatTopicResponse.user === "gpt" && (
        <>
          {isQuestionResponseError && (
            <>
              <Stack style={{ wordBreak: "break-word", marginLeft: 20, marginTop: -40, marginBottom: 25 }}>
                <Stack>
                  <ChatError
                    userAlias={chatTopicResponse.user}
                    topicId={topicId}
                    qnaId={chatTopicResponse.questions[0].qnaId}
                  />
                </Stack>
              </Stack>
            </>
          )}
          {!isQuestionResponseError && (
            <>
              {chatTopicResponse?.questions && chatTopicResponse?.questions.length > 0 && chatTopicResponse?.questions[0]?.searchResponsesList?.map((chatResponse: ISearchResponse) => {
                return (
                  <>
                    <Stack>
                      <Stack >
                        <DisplayAnswer
                          topicId={topicId}
                          sourceType={chatResponse?.sourceType}
                          user={chatTopicResponse.user}
                          chatResponse={chatResponse}
                          isNewAnswer={chatTopicResponse?.isNewAnswer}
                          qnaId={chatTopicResponse.questions[0].qnaId}
                          userAlias={loginUserPersonaProps?.secondaryText}
                          question={question}
                          sources={chatResponse?.sources}
                          topicProjectNumber={topicProjectNumber}
                        />
                      </Stack>
                      {isFeedbackSubmissionSuccess && chatTopicResponse.questions[0].qnaId === feedbackRequest.QNAId && (
                        <Stack>
                          <FeedbackSuccessResponse />
                        </Stack>
                      )}
                    </Stack>
                  </>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  )
}
export default ChatAnswer