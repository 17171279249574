import { IStackStyles } from "@fluentui/react";
export const WelcomeContainerStyle: IStackStyles = {
  root: {
    alignItems: "stretch",
    color: "var(--neutral-foreground-1-rest, #242424)",
    fontFamily: "Segoe UI",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
};
