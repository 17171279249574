import React, {useEffect} from 'react'

const ErrorBoundary = ({children})=> {
  const [hasError, setHasError] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errorInfo, setErrorInfo] = React.useState(null);

  const componentDidCatch = (error, errorInfo) => {
    setHasError(true);
    setError(error);
    setErrorInfo(errorInfo);    
  };

   useEffect(() => {
    const errorHandler = (error) => {
      console.error('Error caught by error boundary:', error);
      setHasError(true);
      // You could also send the error to a logging service
    };

    // Attach the error handler to the window's error event
    window.addEventListener('error', errorHandler);

    // Cleanup function to remove the error handler when the component unmounts
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  useEffect(() => {
    // Reset the error state when the children change
    setHasError(false);
  }, [children]);

  
  if (hasError) {
    // You can customize the error display here
    return (
      <div>
        <h2>Something went wrong.</h2>
        <p>{error && error.toString()}</p>
        <p>Component Stack Trace:</p>
        <pre>{errorInfo && errorInfo.componentStack}</pre>
      </div>
    );
  }

  return children;
}

export default ErrorBoundary;