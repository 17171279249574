import { Stack } from "@fluentui/react";
import { Context, withContext } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import { IProjectAppState } from "../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { featureMsgStyle } from "./Styles/FeatureFlagStyle";


function FeatureFlag(): React.ReactElement {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector } = reduxContext;
    const {
        featureFlagInformation,
        isLoadingFeatureFlagInformation
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    const [featureMsg, setFeatureMsg] = React.useState("");
    React.useEffect(() => {
        if (featureFlagInformation && !featureFlagInformation.isFeatureEnabled) {
            const msg = featureFlagInformation?.featureFlagMessage?.length>0 ? featureFlagInformation?.featureFlagMessage : "";
            setFeatureMsg(msg);
        }
    }, [featureFlagInformation]);


    return (
        <>
            {featureFlagInformation && !featureFlagInformation.isFeatureEnabled && !isLoadingFeatureFlagInformation &&(
                <Stack style={featureMsgStyle}>
                    {featureMsg}
                </Stack>
            )}
        </>
    );
}

const connected = withContext(FeatureFlag);
export { connected as FeatureFlag };