import { Icon, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  IndividualPromptsContainerStyles,
  SvgStyles,
  SystemPromptsContainerStyles,
  SystemPromptStyles,
  ViewPromptContainerStyles,
  ViewPromptTextStyles,
  SystemPromptsErrorContainerStyles,
} from "./Styles/SystemPrompts.Styles";
import { Reducer } from "redux";
import SystemPrompt from "./SystemPrompt";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";

import { ISystemPrompts } from "../../../../Models/Chat/ISystemPrompts";
import ProgressBar from "../../../../Common/Components/ProgressBar/ProgressBar";
import ChatError from "../ChatDisplay/ChatError";
import { v4 as uuidv4 } from 'uuid';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { requestDefaultNewAnswer, requestNewAnswer, requestSystemPrompts, setNewUserPrompt } from "../../../../Shared/Actions/Chat.action";
import { IQuestion, IQuestionRequest } from "src/Models/Chat/IQuestion";
import { LitigationContext } from "../ChatHome";


const SystemPrompts = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { isLoadingSystemPrompts, isLoadingSystemPromptsError, systemPromptsList, defaultPromptsList, isLoadingDefaultPrompts, systemPromptsError, loginUserpersonaProps } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);
  const [showPrompts, setShowPrompts] = useState<boolean>(false);
  const { litCases, files, projectNumber } = React.useContext(LitigationContext);
  const showSystemPrompts = () => {
    if (litCases?.length > 0) {
      //dispatch(requestSystemPrompts());
      let tpGuid: string = uuidv4();
      const qandAId: string = uuidv4();
      const newQuestionRequest: IQuestionRequest = {};
      newQuestionRequest.topicId = tpGuid;
      newQuestionRequest.newQuestionId = qandAId;
      newQuestionRequest.newQuestion = "generate the top 3 most relevant and insightful questions that would help gather critical information from the selected documents.";
      newQuestionRequest.userAlias = loginUserpersonaProps?.secondaryText;

      newQuestionRequest.cases = litCases;
      newQuestionRequest.projectNumber = projectNumber
      //dispatch(requestDefaultNewAnswer(newQuestionRequest));
    }
    setShowPrompts(!showPrompts);
   
  };

  const onSystemPromptClick = (prompt: string, callPromptAPI: boolean) => {
        
    if (callPromptAPI) {
      dispatch(setNewUserPrompt(prompt));
    }

    setShowPrompts(false);
  };

  const [systemPrompsArray, setSystemPrompsArray] = useState<ISystemPrompts[]>([]);
  useEffect(() => {
    if (litCases?.length > 0) {
      dispatch(requestSystemPrompts());
      let tpGuid: string = uuidv4();
      const qandAId: string = uuidv4();
      const newQuestionRequest: IQuestionRequest = {};
      newQuestionRequest.topicId = tpGuid;
      newQuestionRequest.newQuestionId = qandAId;
      newQuestionRequest.newQuestion = "generate the top 3 most relevant and insightful questions that would help gather critical information from the selected documents.";
      newQuestionRequest.userAlias = loginUserpersonaProps?.secondaryText;

      newQuestionRequest.cases = litCases;
      newQuestionRequest.projectNumber = projectNumber
      //dispatch(requestDefaultNewAnswer(newQuestionRequest));
    }
  }, [files]);

  useEffect(() => {
    if (litCases?.length > 0) {
      dispatch(requestSystemPrompts());
      }
  }, [litCases]);


  useEffect(() => {
    // Check if both systemPromptsList and defaultPromptsList have been loaded
    if ((systemPromptsList?.systemPrompts?.length > 0) || (defaultPromptsList?.systemPrompts?.length > 0)) {
      const sysPrompts: ISystemPrompts[] = [];

      // Add system prompts from systemPromptsList
      if (systemPromptsList?.systemPrompts) {
        systemPromptsList.systemPrompts.forEach((systemPrompt: ISystemPrompts) => {
          const sysPrompt: ISystemPrompts = {
            promptText: systemPrompt.promptText,
            promptAI: systemPrompt.promptAI,
            question: systemPrompt.question,
            prompt: systemPrompt.prompt,
          };
          sysPrompts.push(sysPrompt);
        });
      }

      // Add system prompts from defaultPromptsList
      if (defaultPromptsList?.systemPrompts) {
        defaultPromptsList.systemPrompts.forEach((defaultPrompt: ISystemPrompts) => {
          const defPrompt: ISystemPrompts = {
            promptText: defaultPrompt.promptText,
            promptAI: defaultPrompt.promptAI,
            question: defaultPrompt.question,
            prompt: defaultPrompt.prompt,
          };
          sysPrompts.push(defPrompt);
        });
      }

      // Set the combined system prompts array
      setSystemPrompsArray(sysPrompts);
    }
  }, [systemPromptsList, defaultPromptsList]);


  return (
    <>
      <Stack styles={isLoadingSystemPromptsError ? SystemPromptsErrorContainerStyles : SystemPromptsContainerStyles}>
        {!isLoadingSystemPrompts && !isLoadingDefaultPrompts && isLoadingSystemPromptsError && (
          <Stack style={{ marginLeft: "auto", marginRight: 10 }}>
            <ChatError errorMessage="Error occurred when getting system prompts" />
          </Stack>
        )}
        <Stack styles={SystemPromptStyles}>
          <Stack styles={ViewPromptContainerStyles}>
            {!showPrompts && (
              <div onClick={showSystemPrompts} tabIndex={0}>
                <Stack horizontal style={{ cursor: "pointer", marginTop: 5, marginBottom: 5 }}>
                  <Stack styles={SvgStyles}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M6 5C6 4.44772 6.44772 4 7 4H13C13.5523 4 14 4.44772 14 5V6C14 6.55228 13.5523 7 13 7H7C6.44772 7 6 6.55228 6 6V5ZM7 5V6H13V5H7ZM4 4V16C4 17.1046 4.89543 18 6 18H15.5C15.7761 18 16 17.7761 16 17.5C16 17.2239 15.7761 17 15.5 17H6C5.44772 17 5 16.5523 5 16H15C15.5523 16 16 15.5523 16 15V4C16 2.89543 15.1046 2 14 2H6C4.89543 2 4 2.89543 4 4ZM14 3C14.5523 3 15 3.44772 15 4V15H5V4C5 3.44772 5.44772 3 6 3H14Z"
                        fill="#242424"
                      />
                    </svg>
                  </Stack>
                  <Stack styles={ViewPromptTextStyles}>View prompts</Stack>
                </Stack>
              </div>
            )}
            {showPrompts && (
              <Stack styles={IndividualPromptsContainerStyles}>

                {(isLoadingSystemPrompts || isLoadingDefaultPrompts) && (
                  <Stack style={{ marginLeft: -75 }}>
                    <ProgressBar progressbarText="loading system prompts..." />
                  </Stack>
                )}
                <Stack style={{ marginRight: 18, background: 'white', marginLeft: -60, paddingTop: 10, paddingLeft: 10 }}>

                  <Stack style={{ position: 'relative' }}>
                    {!isLoadingSystemPrompts && !isLoadingDefaultPrompts && !isLoadingSystemPromptsError && (


                      <Stack
                        styles={{
                          root: {
                            position: 'absolute',
                            top: 0,
                            right: 0,
                           
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => setShowPrompts(false)} // Set this to false to close prompts
                      >
                       <Icon iconName="ChromeClose" style={{ fontSize: 12, color: '#323130',marginRight:5 }} />
                      </Stack>
                    )}
                  </Stack>
                  {!isLoadingSystemPrompts && !isLoadingDefaultPrompts &&
                    !isLoadingSystemPromptsError &&
                    systemPrompsArray?.map((prompt: ISystemPrompts, index: number) => {
                      return (
                        <Stack
                          horizontal
                          // style={{ marginLeft: index === systemPrompsArray.length - 1 ? "-60px" : "-62px", marginBottom: 5 }}
                          style={{ marginBottom: 5 }}
                        >
                          <SystemPrompt
                            systemPrompt={prompt.promptText}
                            prompt={prompt}
                            onSystemPromptClick={() => onSystemPromptClick(prompt.prompt, true)}
                          />
                          {index === systemPrompsArray.length - 1 && (
                            <Stack horizontal style={{ cursor: "pointer", marginTop: 5, marginBottom: 5 }} tabIndex={0}>
                              <Stack styles={SvgStyles}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  onClick={() => onSystemPromptClick(prompt.prompt, false)}
                                >
                                  <path
                                    d="M6 5C6 4.44772 6.44772 4 7 4H13C13.5523 4 14 4.44772 14 5V6C14 6.55228 13.5523 7 13 7H7C6.44772 7 6 6.55228 6 6V5ZM7 5V6H13V5H7ZM4 4V16C4 17.1046 4.89543 18 6 18H15.5C15.7761 18 16 17.7761 16 17.5C16 17.2239 15.7761 17 15.5 17H6C5.44772 17 5 16.5523 5 16H15C15.5523 16 16 15.5523 16 15V4C16 2.89543 15.1046 2 14 2H6C4.89543 2 4 2.89543 4 4ZM14 3C14.5523 3 15 3.44772 15 4V15H5V4C5 3.44772 5.44772 3 6 3H14Z"
                                    fill="#242424"
                                  />
                                </svg>
                              </Stack>
                            </Stack>
                          )}
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SystemPrompts;
