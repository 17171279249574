import { IButtonStyles, IStackStyles } from '@fluentui/react';

export const carousel: React.CSSProperties = {
    width: 'auto',
    // margin: '100px auto',
    // padding: '32px 48px 32px 0px',
    borderRadius: '8px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '86px',
    height: 100,
    marginTop: 15,
    marginLeft: 3
};



/* CONTROLS */
export const btnclass: React.CSSProperties = {
    backgroundColor: '#fff',
    border: 'none',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    position: 'absolute',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const btnLeft= (showLeftCarouselButton: boolean) :React.CSSProperties=> {
  return {
    backgroundColor: '#fff',
    border: 'none',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    position: 'absolute',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    display: !showLeftCarouselButton ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '-43px',
    //top: '50%',
    transform: 'translate(50%, -50%)'
  }
};

export const btnRight = (showRightCarouselButton: boolean):React.CSSProperties => {
    return {
        backgroundColor: '#fff',
        border: 'none',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        display: !showRightCarouselButton ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: '0',
        //top: '50%',
        transform: 'translate(50%, -50%)'
    };
};

export const btnIcon: React.CSSProperties = {
    height: '24px',
    width: '24px',
    stroke: '#087f5b'
};

