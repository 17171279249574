import React from 'react'
import { Checkbox, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IBasePickerSuggestionsProps, IChoiceGroupOption, Icon, IPersonaProps, ITag, MessageBarType, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import Button from "../Helpers/Button/Button";
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import { useHistory } from "react-router-dom";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../../src/Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../../src/Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../../src/Shared/ProjectState";
import { assingPadding, colorred, formText, textFieldStyles } from '../../../../../../src/Components/V2/Styles/CreateProject';

export default function FinancialInformation() {
  let history = useHistory();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);

  const { useSelector, dispatch } = reduxContext;

  const {
    projectCreate,
    isProjectGettingCreated,
    projectCreationSuccess,
    projectCreateError,
    projectCreationFailure,
    projectCreateRedirect,
    projectContext,
    projectFiles,
    projectFileTags,
    projectProps,
    projectOwner,
    projectMembers,
    deletedProjectMembers,
    projectTags,
    isProjectGettingEdited,
    projectEditSuccess,
    projectEditRedirect,
    projectEditFailure,
    projectEditError,
    loginUserpersonaProps,
    isGettingProjectWorkArea,
    isProjectWorkAreaSuccess,
    isProjectWorkAreaError,
    projectWorkAreaCollection,
    lawFirmAdaptiveDetails,
    isLoading,
    hasError

  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

  const { updateNavItem } = useCreateNavContext();
  const handleContinueClick = () => {
    updateNavItem(['fileAttachment','firm', 'outside', 'finance', 'additional'], 'additional')
    history.push('/createproject?componentName=additional')
  }
  const handleCancelClick = () => {
    //clear the state
    history.push('/')
  }

  const concealedOptions: IChoiceGroupOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' }
  ];

  const rfqOptions: IChoiceGroupOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' }
  ];

  return (
    <div style={{ marginLeft: 20 }} className="pageContainer">

      <div className="contentContainer">
        <Stack tokens={{ childrenGap: 10 }}>
          <div>
            <h2 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#242424' }}>
              Financial information
            </h2>
            <p style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644', marginTop: 15 }}>
              Fill out financial info.
            </p>
          </div>

        </Stack>

        <Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Budget Amount (USD) <span style={colorred}>*</span>
            </div>
            <div>
              <TextField
                name="budget"
                placeholder="Enter budget"
                // value={title}
                // onChange={onChangeTitleValue}
                styles={textFieldStyles}

              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              IO Number <span style={colorred}>*</span>
            </div>
            <div>
              <TextField
                name="budget"
                placeholder="Enter budget"
                // value={title}
                // onChange={onChangeTitleValue}
                styles={textFieldStyles}

              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>Is this a concealed project?</div>
            <Stack>
              <ChoiceGroup options={concealedOptions} />
            </Stack>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>Does this project need to go out to bid through an RFQ?</div>
            <Stack>
              <ChoiceGroup options={rfqOptions} />
            </Stack>
          </Stack>
        </Stack>
      </div>
      <div className="buttonContainer">
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 'auto' }}>
          <Button type='primary' onClick={handleContinueClick}>
            Continue
          </Button>
          <Button type='cancel' onClick={handleCancelClick}>
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
  )
}
