import { IStackStyles } from "@fluentui/react";

export const SystemPromptContainerStyles: IStackStyles = {
  root: {
    display: "flex",
    width: "288px",
    flexDirection: "column",
    alignItems: "flex-end",  
    cursor:'pointer'  
  },
};

export const SystemPromptTextContainerStyles: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    flexWrap: "wrap",
  },
};

export const SystemPromptTextStyles: IStackStyles = {
  root: {
    display: "flex",    
    padding: "4px 8px",
    alignItems: "flex-start",
    gap: "4px",
    borderRadius: "8px",
    border: "1px solid var(--brand-stroke-2-rest, #B0BEFF)",
  },
};

export const SystemTextStyles: IStackStyles = {
  root: {
    color: "var(--neutral-foreground-2-rest, #424242)",
    fontFamily: "Segoe UI",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px"
  },
};
