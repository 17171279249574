import * as React from 'react';
import {
    Icon,
    MessageBar,
    Stack,
    IIconProps,
    MessageBarType,
    TooltipHost
} from '@fluentui/react';
import { DisplaySpinner } from '../../../Common/Components/DisplaySpinner';
import useGetProjectDetails from '../../Hooks/useGetProjectDetail';
import {
    getIconByFileExtension,
    truncateText
} from '../../../Helpers/ProjectHelper';
import {
    fileiconStyle,
    fileitemStyle,
    paddingTop,
    titleText,
    descriptionText
} from './Styles/SearchResponse';
import { excludedGroups } from '../../../Helpers/ProjectConstants';

import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import ErrorDisplay from '../../../Common/Components/ErrorDisplay';
type ProjectDetailViewProps = {
    responseItem?: any;
    userAlias?: string;
    number?: string;
    onHandleProjectExists(isProjectExists: boolean): void;
};
const messageBarIconProps: IIconProps = {
    'aria-label': 'click on close icon to dismiss the message bar'
};
const ProjectDetailView = (projectDetailsView: ProjectDetailViewProps) => {
    const { responseItem, userAlias, number, onHandleProjectExists } =
        projectDetailsView;
    const {
        projectDetails,
        isProjectDetailsLoading,
        isProjectDetailsLoadingError,
        projectGetDetailsError
    } = useGetProjectDetails({
        number,
        userAlias
    });
    const [projectOwners, setProjectOwners] = React.useState([]);
    const [projectMembersCount, setProjectMembersCount] = React.useState('');
    const [fileList, setFileList] = React.useState([]);
    React.useEffect(() => {
        if (isProjectDetailsLoadingError) {
            onHandleProjectExists(false);
        } else {
            onHandleProjectExists(true);
        }
    }, [isProjectDetailsLoadingError]);
    React.useEffect(() => {
        if (projectDetails) {
            if (projectDetails.files) {
                setFileList(projectDetails.files);
            } else {
                setFileList([]);
            }
            var tempOwnerList = [];
            var count = -1;
            projectDetails?.members?.map((member: any) => {
                if (
                    member.alias.toLowerCase().trim() !==
                        excludedGroups.celaLegalEngineeringGroup &&
                    member.alias.toLowerCase().trim() !==
                        excludedGroups.KMSITOwners &&
                    member.alias.toLowerCase().trim() !==
                        excludedGroups.CELADMSOwners.toLowerCase().trim()
                ) {
                    if (member.isResponsible) {
                        tempOwnerList.push(member?.alias);
                        count += 1;
                    } else {
                        count += 1;
                    }
                }
            });
            if (count > 0) {
                var countText = '+' + count.toString();
                setProjectMembersCount(countText);
            } else {
                setProjectMembersCount('');
            }
            setProjectOwners(tempOwnerList);
        }
    }, [projectDetails]);

    React.useEffect(() => {
        setFileList([]);
        setProjectOwners([]);
        setProjectMembersCount('');
    }, []);

    return (
        <>
            {isProjectDetailsLoading && (
                <Stack>
                    <span
                        role="alert"
                        aria-label="loading member details"
                        aria-live="assertive"
                    />
                    <DisplaySpinner
                        accessabilityMessage={'Loading ...123'}
                        spinnerPosition={'right'}
                        spinnerText={'Loading...'}
                    />
                </Stack>
            )}
            {!isProjectDetailsLoading && isProjectDetailsLoadingError && (
                <>                    
                    {/* <ErrorDisplay source={projectGetDetailsError} customText={' to access this project.'}/> */}
                </>
            )}
            {!isProjectDetailsLoading && !isProjectDetailsLoadingError && (
                <>
                    <Stack style={descriptionText}>
                        <TooltipHost content={projectDetails?.description}>
                            {truncateText(projectDetails?.description, 60, 60)}
                        </TooltipHost>
                    </Stack>
                    {fileList.length > 0 && fileList[0]?.name && (
                        <span style={paddingTop}>
                            <a
                                href={fileList[0]?.path}
                                target="_blank"
                                rel="noopener"
                            >
                                <Stack horizontal>
                                    <Stack>
                                        <Icon
                                            {...getFileTypeIconProps({
                                                size: 16,
                                                imageFileType: 'png',
                                                type:
                                                    fileList[0]?.path !== 'file'
                                                        ? 2
                                                        : 3
                                            })}
                                            tabIndex={0}
                                            style={fileiconStyle}
                                        />
                                    </Stack>
                                    <Stack style={fileitemStyle}>
                                        {truncateText(
                                            fileList[0]?.name,
                                            25,
                                            25
                                        )}
                                    </Stack>
                                    <Stack style={fileitemStyle}>
                                        <TooltipHost
                                            content={`Total  files in the root folder in the project ${fileList.length}`}
                                        >
                                            {fileList.length > 1 && (
                                                <span
                                                    style={{
                                                        marginLeft: '4px'
                                                    }}
                                                >
                                                    +{fileList.length - 1}
                                                </span>
                                            )}
                                        </TooltipHost>
                                    </Stack>
                                </Stack>
                            </a>
                        </span>
                    )}
                </>
            )}
        </>
    );
};
export default ProjectDetailView;
