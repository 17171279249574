import { IUser } from '@micro-frontend-react/employee-experience/lib/IUser';
import { IChatQuestionRequest } from '../../Models/Chat/IChatQuestionRequest';
import { ChatActionTypes, IFeedbackSubmissionSuccess, IRequestDefaultNewAnswer, IRequestDeleteChatTopic, IRequestEditChatTopic, IRequestGetChatTopic, IRequestIsNewTopic, IRequestNewAnswer,  IRequestSystemPrompts,  IRequestUserChatLogs,  IResponseDefaultErrorNewAnswer,  IResponseDefaultNewAnswer,  IResponseDeleteChatTopic,  IResponseEditChatTopic,  IResponseErrorDeleteChatTopic,  IResponseErrorEditChatTopic,  IResponseErrorGetChatTopic,  IResponseErrorNewAnswer,  IResponseErrorUserChatLogs,  IResponseGetChatTopic,  IResponseNewAnswer, IResponseSystemPrompts, IResponseSystemPromptsError, IResponseUserChatLogs, IRetryUserPrompt, ISetTopicInContext, ISetnewUserPrompt, IShouldClearChatResponse, IShouldDisplayChatView, IShouldShowChatLogs } from './Chat.action-types';
import { ITopic, ITopicResponses } from '../../Models/Chat/ITopic';
import { IChatFeedbackRequest } from '../../Models/Chat/IChatFeedbackRequest';
import { ISystemPromptsList } from '../../Models/Chat/ISystemPrompts';
import { IUserChatLogsRequest } from '../../Models/Chat/IUserChatLogsRequest';
import { IUserChatLogsResponse } from '../../Models/Chat/IUserChatLogsResponse';
import { IChatTopicDeleteRequest } from '../../Models/Chat/IChatTopicDeleteRequest';
import { IChatTopicDeleteResponse } from '../../Models/Chat/IChatTopicDeleteResponse';
import { IChatTopicEditRequest } from '../../Models/Chat/IChatTopicEditRequest';
import { IChatTopicEditResponse } from '../../Models/Chat/IChatTopicEditResponse';
import { IChatTopicRequest } from '../../Models/Chat/IChatTopicRequest';
export function requestNewAnswer(chatQuestionRequest: IChatQuestionRequest): IRequestNewAnswer {
	return {
		type: ChatActionTypes.NEW_QUESTION_REQUEST,
		chatQuestionRequest
	};
}

export function responseNewAnswer(topic: ITopic): IResponseNewAnswer {
	return {
		type: ChatActionTypes.NEW_ANSWER_RESPONSE,
		topic
	};
}

export function responseErrorNewAnswer(error: Error, errorMessage: string, CorrelationId: string): IResponseErrorNewAnswer {
	return {
		type: ChatActionTypes.NEW_ANSWER_RESPONSE_ERROR,
		error,
		errorMessage,
		CorrelationId
	};
}

export function requestDefaultNewAnswer(chatQuestionRequest: IChatQuestionRequest): IRequestDefaultNewAnswer {
	return {
		type: ChatActionTypes.NEW_DEFAULT_QUESTION_REQUEST,
		chatQuestionRequest
	};
}

export function responseDefaultNewAnswer(topic: ISystemPromptsList): IResponseDefaultNewAnswer {
	return {
		type: ChatActionTypes.NEW_DEFAULT_ANSWER_RESPONSE,
		topic
	};
}

export function responseDefaultErrorNewAnswer(error: Error, errorMessage: string, CorrelationId: string): IResponseDefaultErrorNewAnswer {
	return {
		type: ChatActionTypes.NEW_DEFAULT_ANSWER_RESPONSE_ERROR,
		error,
		errorMessage,
		CorrelationId
	};
}

export function isNewTopicRequested(isNewTopic: boolean): IRequestIsNewTopic {
	return {
		type: ChatActionTypes.IS_NEW_TOPIC,
		isNewTopic
	};
}

export function setNewUserPrompt(newUserPrompt: string): ISetnewUserPrompt {
	return {
		type: ChatActionTypes.NEW_USER_PROMPT,
		newUserPrompt
	};
}

export function retryUserPrompt(shouldRetryUserPrompt: boolean): IRetryUserPrompt {
	return {
		type: ChatActionTypes.RETRY_USER_PROMPT,
		shouldRetryUserPrompt
	};
}

export function feedbackSubmissionSuccess(isFeedbackSubmissionSuccess: boolean, feedbackRequest: IChatFeedbackRequest): IFeedbackSubmissionSuccess{
	return {
		type: ChatActionTypes.IS_FEEDBACK_SUBMISSION_SUCCESS,
		isFeedbackSubmissionSuccess,
		feedbackRequest
	};
}

export function requestSystemPrompts(): IRequestSystemPrompts {
  return {
    type: ChatActionTypes.REQUEST_SYSTEM_PROMPTS,
  };
}

export function responseSystemPrompts(systemPrompts: ISystemPromptsList): IResponseSystemPrompts {
  return {
    type: ChatActionTypes.RESPONE_SYSTEM_PROMPTS,
    systemPrompts
  };
}

export function responseSystemPromptsError(error: Error,
  errorMessage: string,
  CorrelationId: string): IResponseSystemPromptsError {
  return {
    type: ChatActionTypes.RESPONSE_SYSTEM_PROMPTS_ERROR,
    error,
    errorMessage,
    CorrelationId,
  };
}

export function setTopicInContext(topicIdInContext: string, topicUser: string): ISetTopicInContext {
  return {
    type: ChatActionTypes.TOPIC_IN_CONTEXT,
    topicIdInContext,
    topicUser
  };
}

export function shouldClearChatResponse(): IShouldClearChatResponse {
  return {
    type: ChatActionTypes.SHOULD_CLEAR_CHAT_RESPONSE,
  };
}

export function shouldShowChatLogs(showChatLogs: boolean): IShouldShowChatLogs {
  return {
    type: ChatActionTypes.SHOULD_SHOW_CHAT_LOGS,
    showChatLogs,
  };
}

export function shouldDisplayChatView(shouldShowChatView: boolean): IShouldDisplayChatView {
  return {
    type: ChatActionTypes.SHOULD_DISPLAY_CHAT_VIEW,
    shouldShowChatView,
  };
}

export function requestUserChatLogs(userChatLogsRequest: IUserChatLogsRequest): IRequestUserChatLogs {
  return {
    type: ChatActionTypes.REQUEST_CHAT_LOGS,
    userChatLogsRequest,
  };
}
export function responseUserChatLogs(userChatLogsResponse: IUserChatLogsResponse[]): IResponseUserChatLogs {
  return {
    type: ChatActionTypes.RESPONSE_CHAT_LOGS,
    userChatLogsResponse,
  };
}
export function responseErrorUserChatLogs(
  error: Error,
  errorMessage: string,
  CorrelationId: string
): IResponseErrorUserChatLogs {
  return {
    type: ChatActionTypes.RESPONSE_ERROR_CHAT_LOGS,
    error,
    errorMessage,
    CorrelationId,
  };
}

export function requestDeleteChatTopic(chatTopicDeleteRequest: IChatTopicDeleteRequest): IRequestDeleteChatTopic {
  return {
    type: ChatActionTypes.REQUEST_DELETE_CHAT_TOPIC,
    chatTopicDeleteRequest,
  };
}
export function responseDeleteChatTopic(chatTopicDeleteResponse: IChatTopicDeleteResponse): IResponseDeleteChatTopic {
  return {
    type: ChatActionTypes.RESPONSE_DELETE_CHAT_TOPIC,
    chatTopicDeleteResponse,
  };
}
export function responseErrorDeleteChatTopic(
  error: Error,
  errorMessage: string,
  CorrelationId: string
): IResponseErrorDeleteChatTopic {
  return {
    type: ChatActionTypes.RESPONSE_ERROR_DELETE_CHAT_TOPIC,
    error,
    errorMessage,
    CorrelationId,
  };
}

export function requestEditChatTopic(chatTopicEditRequest: IChatTopicEditRequest): IRequestEditChatTopic {
  return {
    type: ChatActionTypes.REQUEST_EDIT_CHAT_TOPIC,
    chatTopicEditRequest,
  };
}
export function responseEditChatTopic(chatTopicEditResponse: IChatTopicEditResponse): IResponseEditChatTopic {
  return {
    type: ChatActionTypes.RESPONSE_EDIT_CHAT_TOPIC,
    chatTopicEditResponse,
  };
}
export function responseErrorEditChatTopic(
  error: Error,
  errorMessage: string,
  CorrelationId: string
): IResponseErrorEditChatTopic {
  return {
    type: ChatActionTypes.RESPONSE_ERROR_EDIT_CHAT_TOPIC,
    error,
    errorMessage,
    CorrelationId,
  };
}

export function requestGetChatTopic(chatTopicRequest: IChatTopicRequest): IRequestGetChatTopic {
	return {
		type: ChatActionTypes.REQUEST_GET_CHAT_TOPIC,
		chatTopicRequest
	};
}

export function responseGetChatTopic(topicResponses: ITopicResponses): IResponseGetChatTopic {
	return {
		type: ChatActionTypes.RESPONSE_GET_CHAT_TOPIC,
		topicResponses
	};
}
export function responseErrorGetChatTopic(error: Error, errorMessage: string, CorrelationId: string): IResponseErrorGetChatTopic {
	return {
		type: ChatActionTypes.RESPONSE_ERROR_GET_CHAT_TOPIC,
		error,
		errorMessage,
		CorrelationId
	};
}