export type CustomTag = {
  id: number;
  text: string;
};
const CustomTags = (): CustomTag[] => {
  const tags: CustomTag[] = [
    {
      id: 1,
      text: "product_or_feature",
    },
    {
      id: 2,
      text: "comm_trans_contract_nego",
    },
    {
      id: 3,
      text: "strategic_partnership",
    },
    {
      id: 4,
      text: "regulatory_inquiry",
    },
    {
      id: 5,
      text: "eu_facing_or_marketing",
    },
    {
      id: 6,
      text: "geo_us",
    },
    {
      id: 7,
      text: "geo_UK",
    },
    {
      id: 8,
      text: "geo_EU",
    },
    {
      id: 9,
      text: "geo_APAC",
    },
    {
      id: 10,
      text: "geo_LatAm",
    },
    {
      id: 11,
      text: "geo_Brazil",
    },
    {
      id: 12,
      text: "geo_MENA",
    },
    {
      id: 13,
      text: "geo_India",
    },
    {
      id: 14,
      text: "geo_Other",
    },
    {
      id: 15,
      text: "payment_acceptance",
    },
    {
      id: 16,
      text: "3rdP_financing",
    },
    {
      id: 17,
      text: "gift_cards",
    },
    {
      id: 18,
      text: "rewards",
    },
    {
      id: 19,
      text: "blockchain",
    },
    {
      id: 20,
      text: "1st_party",
    },
    {
      id: 21,
      text: "3rd_party",
    },
    {
      id: 22,
      text: "1st_and_3rd_party",
    },
    {
      id: 23,
      text: "data_sharing",
    },
    {
      id: 24,
      text: "MTL",
    },
    {
      id: 25,
      text: "BNPL",
    },
    {
      id: 26,
      text: "commercial",
    },
    {
      id: 27,
      text: "consumer",
    },

    {
      id: 28,
      text: "CSV",
    },
    {
      id: 29,
      text: "rewards_rebates",
    },
    {
      id: 30,
      text: "unclaimed_property",
    },
    {
      id: 31,
      text: "custodial",
    },
    {
      id: 32,
      text: "noncustodial",
    },
    {
      id: 33,
      text: "wallet_service",
    },
    {
      id: 34,
      text: "NFT",
    },
    {
      id: 35,
      text: "exchange_service",
    },
    {
      id: 36,
      text: "Emoney",
    },
    {
      id: 37,
      text: "Marketplaces",
    },
    {
      id: 38,
      text: "Prepaid_access",
    },
    {
      id: 39,
      text: "Subscriptions",
    },
    {
      id: 40,
      text: "SMD",
    },
    {
      id: 41,
      text: "SCA",
    },
    {
      id: 42,
      text: "PSD2",
    },
    {
      id: 43,
      text: "SEPA",
    },
    {
      id: 44,
      text: "Agent_of_payee",
    },
    {
      id: 45,
      text: "exception",
    },
    {
      id: 46,
      text: "exemption",
    },
    {
      id: 47,
      text: "utility_tokens",
    },
    {
      id: 48,
      text: "issuances",
    },
    {
      id: 49,
      text: "payment_services",
    },
    {
      id: 50,
      text: "cryptoplatform",
    },
    {
      id: 51,
      text: "resale",
    },
    {
      id: 52,
      text: "transmission",
    },
    {
      id: 53,
      text: "privacy",
    },
    {
      id: 54,
      text: "data_security",
    },
    {
      id: 55,
      text: "lending",
    },
    {
      id: 56,
      text: "In_Review",
    },
    {
      id: 57,
      text: "Review_Completed",
    },
    {
      id: 58,
      text: "In_Scope",
    },
    {
      id: 59,
      text: "Out_Of_Scope",
    },
  ];

  return tags;
};

export default CustomTags;
