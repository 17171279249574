import React from 'react'
import { PersonaSize, Stack } from "@fluentui/react";
import './ChatQuestion.styles.css'
import { IUser } from '@micro-frontend-react/employee-experience/lib/IUser';
import { Persona } from '../../../../Components/Shared/Components/Persona';
import { LitigationContext } from "../ChatHome";
import MemberView from '../../Components/MemberView';

export interface IChatQuestionProps {
  question?: string;
  isFirstQuestion?: boolean,
  userType?: string;
  user?: any;
  topicUser?: string;
  isNewPrompt?: boolean
}
const ChatQuestion = (chatQuestionProps: IChatQuestionProps) => {
  const { question, isFirstQuestion, userType, topicUser, isNewPrompt, user } = chatQuestionProps

  const dynamicClassName = isFirstQuestion ? 'question-first-container' : 'question-all-container';
  return (
    <Stack>
      {userType === "me" && (
        <>
          <Stack horizontal className={dynamicClassName}>
            <Stack >
              <Stack >
                <Stack horizontal>
                  <Stack className='question-text-container-main'>
                    <Stack className='question-text-container' horizontal tokens={{ childrenGap: 5 }}>
                      <Stack style={{ width: 30, marginLeft: 2 }}>
                        <MemberView key={isNewPrompt ? user?.email ?? user?.secondaryText : topicUser} userAlias={isNewPrompt ? user?.email ?? user?.secondaryText : topicUser} />
                      </Stack>
                      <Stack className='question-text'>{question}</Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  )
}
export default ChatQuestion