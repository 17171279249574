import { IHttpClient, IHttpClientRequest, IHttpHeader } from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import axios, { AxiosError } from 'axios';
import { IRequestDeleteChatTopic, IRequestEditChatTopic, IRequestGetChatTopic, IRequestNewAnswer, IRequestSystemPrompts, IRequestUserChatLogs } from '../Shared/Actions/Chat.action-types';
import { globalConfig, customAxiosApi } from './HttpInterceptor';
import { IChatFeedbackRequest } from '../Models/Chat/IChatFeedbackRequest';

export const ChatAPI = {
  async requestAnswer(action: IRequestNewAnswer, httpClient: IHttpClient, correlationId: string, userProfile?: any) {
		try {
			const accessToken = await getChatAPIToken();
			const chatAIAPI = `${__AI_API_BASE_URL__}/LitigationChat/Answer`;

			const data = {
				topicId: action?.chatQuestionRequest?.topicId,
				qNAId: action?.chatQuestionRequest?.newQuestionId,
				query: action?.chatQuestionRequest?.newQuestion,				
				upn: userProfile?.UserAlias,			
				openAIModel:'Gpt4Turbo',
				shouldApplySecurity: false,
				projectNumber:action?.chatQuestionRequest?.projectNumber,
				cases:action?.chatQuestionRequest?.cases
			};
			const request: IHttpClientRequest = {
				resource: `${__AI_API_BASE_RESOURCE_ID__}`,
				accessToken,
				data,
				headers: {
					'x-correlation-id': correlationId,
					userAlias: userProfile?.UserAlias
				}
			};
			globalConfig.headers.Authorization = `Bearer ${accessToken}`;
      globalConfig.headers["x-correlation-id"] = correlationId;
			globalConfig.headers["userAlias"] = userProfile?.UserAlias;
			const response: any = await customAxiosApi.post(chatAIAPI, data, globalConfig);
			//const response: any = await httpClient.post(chatAIAPI, request);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	async requestDefaultAnswer(action: IRequestNewAnswer, httpClient: IHttpClient, correlationId: string, userProfile?: any) {
		try {
			const accessToken = await getChatAPIToken();
			const chatAIAPI = `${__AI_API_BASE_URL__}/LitigationChat/Answer`;

			const data = {
				topicId: action?.chatQuestionRequest?.topicId,
				qNAId: action?.chatQuestionRequest?.newQuestionId,
				query: action?.chatQuestionRequest?.newQuestion,				
				upn: userProfile?.UserAlias,			
				openAIModel:'Gpt4Turbo',
				shouldApplySecurity: false,
				projectNumber:action?.chatQuestionRequest?.projectNumber,
				cases:action?.chatQuestionRequest?.cases
			};
			const request: IHttpClientRequest = {
				resource: `${__AI_API_BASE_RESOURCE_ID__}`,
				accessToken,
				data,
				headers: {
					'x-correlation-id': correlationId,
					userAlias: userProfile?.UserAlias
				}
			};
			globalConfig.headers.Authorization = `Bearer ${accessToken}`;
      globalConfig.headers["x-correlation-id"] = correlationId;
			globalConfig.headers["userAlias"] = userProfile?.UserAlias;
			const response: any = await customAxiosApi.post(chatAIAPI, data, globalConfig);
			//const response: any = await httpClient.post(chatAIAPI, request);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	async requestFeedBackSubmission(
		chatRequestFeedback: IChatFeedbackRequest,
		httpClient: IHttpClient,
		correlationId: string,
		chatAIToken?: string
	) {
		try {
			const userFeedback: string =
				encodeURIComponent(chatRequestFeedback?.feedbackText);
			const accessToken =
				chatAIToken ??
				(await getChatAPIToken());

			const data = {
				topicId:chatRequestFeedback?.topicId,
				qNAId:chatRequestFeedback?.QNAId,
				isPositiveFeedback:chatRequestFeedback?.isPositiveFeedback,
				error:chatRequestFeedback?.feedbackUserErrorTypes.join(','),
				comments: userFeedback,
				aiModel:chatRequestFeedback?.aiModel,
				appName:'Litigation'
			};

			const request: IHttpClientRequest =
				{
					resource: `${__AI_API_BASE_RESOURCE_ID__}`,
					accessToken,
					data,
					headers: {
						'x-correlation-id':
							correlationId,
						userAlias:
							chatRequestFeedback?.userAlias
					}
				};
			await httpClient.post(
				`${__AI_API_BASE_URL__}/Feedback`,
				request
			);
			return true;
		} catch (error) {
			throw error;
		}
	},
	async requestSystemPrompts(action: IRequestSystemPrompts, httpClient: IHttpClient, correlationId: string) {
    try {
      const accessToken = await getChatAPIToken();
      const lmiApi = `${__AI_API_BASE_URL__}/LitigationChat/GetDefaultPrompts`;
      const response: any = await httpClient.request({
        url: lmiApi,
        resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
        accessToken: accessToken,
        headers: {
          "x-correlation-id": correlationId,
        },
      });
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
	async requestChatLogs(action: IRequestUserChatLogs, httpClient: IHttpClient, correlationId: string) {
    try {
      const accessToken = await getChatAPIToken();
      const chatAIAPI = `${__AI_API_BASE_URL__}/litigationchat?count=0&projectNumber=${action?.userChatLogsRequest?.projectNumber}`;
      const response: any = await httpClient.request({
        url: chatAIAPI,
        resource: `${__AI_API_BASE_RESOURCE_ID__}`,
        accessToken: accessToken,
        headers: {
          "x-correlation-id": correlationId,
          userAlias: action?.userChatLogsRequest?.userAlias,
        },
      });
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

	async requestDeleteChatTopic(action: IRequestDeleteChatTopic, httpClient: IHttpClient, correlationId: string) {
    try {
      const accessToken = await getChatAPIToken();
      const lmiApi = `${__AI_API_BASE_URL__}/litigationchat?topicId=${action?.chatTopicDeleteRequest?.topicId}`;
      const request: IHttpClientRequest = {
        resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
        accessToken,
        headers: {
          "x-correlation-id": correlationId,
          userAlias: action?.chatTopicDeleteRequest?.userAlias,
        },
      };
      await httpClient.delete(`${lmiApi}`, request);
    } catch (error: any) {
      throw error;
    }
  },
  async requestEditChatTopic(action: IRequestEditChatTopic, httpClient: IHttpClient, correlationId: string) {
    try {
      const accessToken = await getChatAPIToken();
      const lmiApi = `${__AI_API_BASE_URL__}/litigationchat`;

      const updateTopic: any = {
        topicId: action?.chatTopicEditRequest?.topicId,
        topicName: action?.chatTopicEditRequest?.topicName,
      };
      const request: IHttpClientRequest = {
        resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
        data: updateTopic,
        accessToken,
        headers: {
          "x-correlation-id": correlationId,
          userAlias: action?.chatTopicEditRequest?.userAlias,
        },
      };
      await httpClient.patch(`${lmiApi}`, request);
    } catch (error: any) {
      throw error;
    }
  },

	async requestChatLog(
		action: IRequestGetChatTopic,
		httpClient: IHttpClient,
		correlationId: string
	) {
		try {
			const accessToken =
				await getChatAPIToken();
			const chatAIAPI = `${__AI_API_BASE_URL__}/litigationchat/${action?.chatTopicRequest?.topicId}?projectNumber=${action?.chatTopicRequest?.projectNumber}&topicUser=${action?.chatTopicRequest?.userAlias}`;
			const response: any =
				await httpClient.request({
					url: chatAIAPI,
					resource: `${__AI_API_BASE_RESOURCE_ID__}`,
					accessToken: accessToken,
					headers: {
						'x-correlation-id':
							correlationId,
						userAlias:
							action?.chatTopicRequest
								?.userAlias
					}
				});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
}

export const ChatAPITokens = {  
  
  async getChatAPIToken() {
    return await getChatAPIToken();
  },
};

export async function getChatAPIToken() {
	try {
		const aiApiTokenUrl: string = __AI_API_TOKEN_URL__;
		const funcCode: string = __ENV_NAME__==='Prod'?__AI_FUNCTION_CODE__:__AI_FUNCTION_CODE__DEV__;
		let serverURL = `${aiApiTokenUrl}?code=${funcCode}&resourceId=${__AI_API_BASE_RESOURCE_ID__}`;
		var response = await axios.get(serverURL);
		if (!response.data)
			throw {
				data: {
					StatusCode: 401,
					Message: 'UnAuthorized: Error in generating token for AI API'
				}
			};
		const aiBearerToken: string = await response.data;
		return aiBearerToken;
	} catch (error) {
		throw error;
	}
}