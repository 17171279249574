import { Dialog, DialogType, DialogContent, Stack, TextField, DialogFooter, DefaultButton, PrimaryButton, Dropdown, Toggle, ITag, IPersonaProps, Icon, MessageBarType, MessageBar, DatePicker, IDropdownOption, Spinner, SpinnerSize, ComboBox } from '@fluentui/react'
import React from 'react'
import { Reducer } from "redux";
import './AdvanceFilter.module.css'
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';;
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { useAppContext } from './LitigationFilterContext';
import { formatDate } from "../../../../../Helpers/ProjectHelper";
import CustomTagPicker from '../../../../../Components/PickerComponents/CustomTagPicker';
import { IProjectTags } from 'src/Models/IProjectTags';
import CustomTags, { CustomTag } from "../../../../../Models/CustomTags";
import { ILitigationCase, ILitigationCaseData } from 'src/Models/ILitigationCase';
import { ProjectAPI } from '../../../../../../src/Services/ProjectAPI';
import { Select } from '@fluentui/react-components';

export type IAdvanceFilterDialogProps = {
  isOpen?: boolean;
  title?: string;
  onDismiss(): void;
  dialogWidth?: string;
  dialogHeight?: string;
  ClearFilesOnFilter(searchFilter: string): void;
}
interface Filter {
  condition?: string;
  fieldKey: string;
  operator: string
  fieldValue: string | Date | string[];
  groupName?: string;
  isChecked?: boolean;
  type?: string;
}

const AdvanceFilter = (advanceFilterDialogProps: IAdvanceFilterDialogProps) => {
  const { isOpen, title, onDismiss, dialogWidth, dialogHeight, ClearFilesOnFilter } = advanceFilterDialogProps
  const { advancefilters, searchBoxText, selectedFiles, selectedCaseFiles, selectView, selectedFolders, selectedExportFolders, addSelectedFiles, clearFilterContext, addSearchText, addSelectView, addSelectedFolders, addAdvanceFilters } = useAppContext();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector, dispatch, httpClient } = reduxContext;
  const {
    projectContext,
    litigationCaseDataResponses,
    litigationCaseResponses
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

  type FilterField = { key: string, value: string, ariaLabel: string, type: string };
  const createFilterField = (key: string, value: string, ariaLabel: string, type: string = 'string'): FilterField => {
    return { key, value, ariaLabel, type };
  }
  const filterField: FilterField[] = [
    createFilterField("File_class", "File class", "File class"),
    createFilterField("Location_Name", "Location name", "Location name"),
    createFilterField("Custodian", "Custodian", "Custodian", "dropdownCustodian"),
    createFilterField("Compound_path", "Compound path", "Compound path"),
    createFilterField("Input_path", "Input path", "Input path"),
    createFilterField("Load_id", "Load id", "Load id"),
    createFilterField("Date", "Date", "Date", "date"),
    createFilterField("Message_kind", "Message kind", "Message kind"),
    createFilterField("Email_sender", "Email sender", "Email sender"),
    createFilterField("Email_recipients", "Email recipients", "Email recipients"),
    createFilterField("Email_Participants", "Email participants", "Email Participants"),
    createFilterField("Email_participant_domains", "Email participant domains", "Email participant domains"),
    createFilterField("Native_type", "Native type", "Native type"),
    createFilterField("Similarity_percent", "Similarity percent", "Similarity percent"),
    createFilterField("Pivot_id", "Pivot id", "Pivot id"),
    createFilterField("Set_id", "Set id", "Set id"),
    createFilterField("Tags", "Tags", "Tags"),
    createFilterField("Compliance_labels", "Compliance labels", "Compliance labels"),
    createFilterField("TeamName", "Team name", "Team Name"),
    createFilterField("Channel_Name", "Channel name", "Channel name"),
    createFilterField("ConversationName", "Conversation name", "Conversation name"),
    createFilterField("True_Family_ID", "True family id", "True family id"),
    createFilterField("ALL_CUSTODIANS", "ALL custodians", "ALL custodians", "dropdownAllCustodian"),
    createFilterField("Export_ID", "Export id", "Export id"),
    createFilterField("content", "content", "content"),
    createFilterField("metadata_storage_name", "Extracted text file name", "Extracted text file name"),
    createFilterField("User_tags", "User tags", "User tags", "dropdownUT"),
    createFilterField("Email_to", "Email to", "Email to"),
    createFilterField("Email_cc", "Email cc", "Email cc"),
    createFilterField("Email_bcc", "Email bcc", "Email bcc"),
    createFilterField("Location", "Location", "Location"),
    createFilterField("Native_extension", "Native extension", "Native extension"),
    createFilterField("Thread_participants", "Thread participants", "Thread participants"),
    createFilterField("Email_sender_domain", "Email sender domain", "Email sender domain"),
    createFilterField("Email_recipient_domains", "Email recipient domains", "Email recipient domains"),
    createFilterField("Email_subject", "Email subject", "Email subject"),
    createFilterField("Native_file_name", "Native file name", "Native file name"),
    createFilterField("Doc_authors", "Doc authors", "Doc authors"),
    createFilterField("Decision", "Relevance to the case", "Relevance to the case"),
    createFilterField("Justification", "Justification", "Justification"),
    createFilterField("FileRelevanceScore", "Relevance score", "Relevance score", "number"),
    createFilterField("DocumentTheme", "Document theme", "Document theme", "dropdownDocumentTheme"),
    createFilterField("DocumentSummary", "Document summary", "Document summary"),
    createFilterField("File_ID", "File id", "File id"),
    createFilterField("Original_File_ID", "Original file id", "Original file id"),
  ].sort((a, b) => a.value.localeCompare(b.value));

  type operatorType = { key: string, value: string, ariaLabel: string, supportedTypes: string[] };
  const operatorOptions: operatorType[] = [
    { key: "eq", value: "eq", ariaLabel: "equal", supportedTypes: ['string', 'boolean', 'number'] },
    { key: "ne", value: "ne", ariaLabel: "not equal", supportedTypes: ['string', 'boolean', 'number'] },
    { key: "contains", value: "contains", ariaLabel: "contains", supportedTypes: ['string', 'boolean', 'number'] },
    { key: "in", value: "in", ariaLabel: "in", supportedTypes: ['string', 'number'] },
    { key: "not in", value: "not in", ariaLabel: "not in", supportedTypes: ['string', 'number'] },
    { key: "gt", value: "gt", ariaLabel: "greater than", supportedTypes: ['date', 'number'] },
    { key: "ge", value: "ge", ariaLabel: "greater than or equal", supportedTypes: ['date', 'number'] },
    { key: "lt", value: "lt", ariaLabel: "less than", supportedTypes: ['date', 'number'] },
    { key: "le", value: "le", ariaLabel: "less than or equal", supportedTypes: ['date', 'number'] },
  ].sort((a, b) => a.key.localeCompare(b.key));
  const [operators, setOperators] = React.useState<operatorType[]>([]);
  const conditionOptions: { key: string, value: string, ariaLabel: string }[] = [
    { key: "and", value: "and", ariaLabel: "and" },
    { key: "or", value: "or", ariaLabel: "or" }];

  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [validationMessage, setValidationMessage] = React.useState("");
  const [isErrorMessageVisible, setIsErrorMessageVisible] = React.useState(true);
  const [CaseData, setCaseData] = React.useState<ILitigationCaseData[]>([]);
  const [uniqueValueCases, setUniqueValueCases] = React.useState<ILitigationCase[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dtLoading, setDTLoading] = React.useState<boolean>(false);
  const [allCusLoading, setAllCusLoading] = React.useState<boolean>(false);
  const [utLoading, setUTLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setCaseData([]);
    if (litigationCaseDataResponses?.litigationCaseDataResponses?.length > 0) {
      setCaseData(litigationCaseDataResponses?.litigationCaseDataResponses);
    }
  }, [litigationCaseDataResponses]);

  React.useEffect(() => {
    if (advancefilters && advancefilters.length > 0) {
      setFilters(advancefilters);
    }
  }, []);

  const RemoveErrorMessage = () => {
    setIsErrorMessageVisible(false);
    setValidationMessage("");
  }

  React.useEffect(() => {
    // If litigationCaseResponses is available, map and set cases
    if (litigationCaseResponses?.litigationCaseResponses) {
      const updatedCases = litigationCaseResponses.litigationCaseResponses.map((item: ILitigationCase) => {
        // Generate the search query using your function
        const searchQuery = GetSearchQuery(item.caseId, "", false);

        // Return a new object with the modified 'filter'
        return {
          ...item, // Copy other properties of the case
          filter: searchQuery, // Assign the search query to the filter
        };
      });

      // Update the 'Cases' state with the modified cases
      setUniqueValueCases(updatedCases);

    }
  }, [litigationCaseResponses]);
  const fetchUniqueFilterValues = async (filterName: string) => {
    try {
      let condition = filterName;
      if (filterName === 'Custodian')
        setLoading(true);
      else if (filterName === 'DocumentTheme')
        setDTLoading(true);
      else if (filterName === 'AllCustodian') {
        setAllCusLoading(true);
        condition = 'Custodian';
      }
      else if (filterName === 'User_tags') {
        setUTLoading(true);
      }
      const response = await ProjectAPI.getUniqueFilterValues(uniqueValueCases, condition, "stippala@microsoft.com", httpClient);
      const uniqueCustodianOptions: IDropdownOption[] = filterName === 'User_tags'
        ? response.flatMap((item: string) =>
          item.split(',').map((tag: string) => ({
            key: tag.trim(),
            text: tag.trim(),
            title: tag.trim(),
          }))
        )
        : response.map((item: string) => ({
          key: item,
          text: item,
          title: item,
        }));
      if (filterName === 'Custodian') {
        setUniqueCustodians(uniqueCustodianOptions);
        setUniqueAllCustodians(uniqueCustodianOptions);
        setHasFetched(true);
      }
      else if (filterName === 'DocumentTheme') {
        setUniqueDocumentTheme(uniqueCustodianOptions);
        setHasDTFetched(true);
      }
      else if (filterName === 'AllCustodian') {
        setUniqueCustodians(uniqueCustodianOptions);
        setUniqueAllCustodians(uniqueCustodianOptions);
        setHasAllCusFetched(true);
      }
      else if (filterName === 'User_tags') {
        setUniqueUserTags(uniqueCustodianOptions);
        setHasAllUserTagsFetched(true);
      }
    } catch (error) {
      console.error("Error fetching unique filter values: ", error);
    } finally {
      if (filterName === 'Custodian')
        setLoading(false);
      else if (filterName === 'DocumentTheme')
        setDTLoading(false);
      else if (filterName === 'AllCustodian') {
        setAllCusLoading(false);
      }
      else if (filterName === 'User_tags') {
        setUTLoading(false);
      }
    }
  };

  const handleDropdownOpen = async (filterName: string) => {
    if (!hasFetched && !loading && filterName === 'Custodian') {
      if (uniqueCustodians.length > 0) return;
      await fetchUniqueFilterValues(filterName); // Adjust as necessary
    }
    else if (!hasDTFetched && !dtLoading && filterName === 'DocumentTheme') {
      if (uniqueDocumentTheme.length > 0) return;
      await fetchUniqueFilterValues(filterName);
    }
    else if (!hasAllCusFetched && !allCusLoading && filterName === 'AllCustodian') {
      if (uniqueAllCustodians.length > 0) return;
      await fetchUniqueFilterValues(filterName); // Adjust as necessary
    }
    else if (!hasAllUserTagsFetched && !utLoading && filterName === 'User_tags') {
      if (uniqueUserTags.length > 0) return;
      await fetchUniqueFilterValues(filterName); // Adjust as necessary
    }

  };
  const GetSearchQuery = (caseId: string, advanceFilterText: string = "", IsSearchClear = false, isAdvanceFilterClear = false) => {
    // Filter the CaseData based on the caseId
    let searchQuery = "";
    // Filter items where the attribute is true
    if (selectedExportFolders && selectedExportFolders.trim()) {
      if (selectView === "Custodian") {
        searchQuery = "(";
        searchQuery += selectedExportFolders?.split(',').map(item => `Custodian eq '${item}'`).join(' or ');
        searchQuery += ")";
      }
      else {
        searchQuery = " search.in(Export_ID,'" + selectedExportFolders + "')";
      }
    }


    return searchQuery; // Ensure the search query is returned
  };

  const handleAddFilter = () => {
    const condition = filters.length > 0 ? 'or' : '';
    setFilters([...filters, { condition: condition, fieldKey: '', operator: '', fieldValue: '', groupName: '', isChecked: false, type: '' }]);
  };

  const handleFilterChange = (index: number, key: keyof Filter, value: string) => {

    const newFilters = [...filters];
    if (key === 'fieldKey') {
      const type = filterField.find((item) => item.key === value)?.type;
      if (type) {
        newFilters[index]["type"] = type;
        const supportedOperators = operatorOptions.filter((operator) => operator.supportedTypes.includes(type));
        newFilters[index]["operator"] = "";
        newFilters[index]["fieldValue"] = "";
        setOperators(supportedOperators);
      }
    }
    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  const handleFilterDateChange = (date: Date | null | undefined, index: number) => {
    const newFilters = [...filters];
    newFilters[index]["type"] = "date";
    newFilters[index]["fieldValue"] = date;
    setFilters(newFilters);
  }

  const handleRemoveFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
    if (filters?.length === 0) {
      setValidationMessage("");
      setIsErrorMessageVisible(false);
    }
  };

  const handleResetFilter = () => {
    setValidationMessage("");
    setIsErrorMessageVisible(false);
    setFilters([]);
    addAdvanceFilters([]);
    ClearFilesOnFilter("");
  };

  const groupFilter = () => {
    setValidationMessage("");
    const groupText = "g";
    const groupNumberPattern = /^(\d+)$/i;

    const groupNumbers = filters
      .filter((filter) => groupNumberPattern.test(filter.groupName))
      .map((filter) => parseInt(filter.groupName, 10))
      .sort((a, b) => b - a);

    if (filters.find((filter) => filter?.groupName?.length > 0 && filter?.isChecked)) {
      setIsErrorMessageVisible(true);
      setValidationMessage("one or more Filter row already assigned to a group");
      return;
    }
    let consecutive = true;
    let lastChecked = -1;

    filters.forEach((filter, index) => {
      if (filter.isChecked) {
        if (lastChecked !== -1 && index !== lastChecked + 1) {
          consecutive = false;
        }
        lastChecked = index;
      }
    });

    if (!consecutive) {
      setIsErrorMessageVisible(true);
      setValidationMessage("Please select consecutive filters for grouping");
      return;
    }
    const nextGroupNumber = groupNumbers.length > 0 ? groupNumbers[0] + 1 : 1;
    const newFilters = [...filters];
    filters.map((filter, index) => {
      if (filter.isChecked) {
        newFilters[index].groupName = `${nextGroupNumber}`;
      }
      filter.isChecked = false;
    });

    setFilters(newFilters);
  };

  const handleFilterCheck = (index: number, isChecked: boolean) => {
    const newFilters = [...filters];
    newFilters[index].isChecked = isChecked;
    setFilters(newFilters);
  };

  const handleUngroup = (groupName: string) => {
    const newFilters = [...filters];
    filters.map((filter, index) => {
      if (filter.groupName === groupName) {
        newFilters[index].groupName = "";
      }
    });
    setFilters(newFilters);
  }
  const doValidation = () => {
    let message = '';
    if (filters?.length === 0) {
      message = 'Please select atleast one filter to apply.';
    }
    filters.map((filter, index) => {
      if (
        (filter.condition === '' && filter.fieldKey === '') ||
        filter.operator === '' ||
        (typeof filter.fieldValue === 'string' && filter.fieldValue.trim() === '') ||
        (filter.fieldValue instanceof Date && isNaN(filter.fieldValue.getTime()))
      ) {
        message = 'One or more filters are empty. Please fill all the filters to apply.';
      }
    });
    if (message?.trim()?.length != 0) {
      setIsErrorMessageVisible(true);
      setValidationMessage(message);
    }
    return message;
  };

  const handleApplyFilter = () => {
    const message: string = doValidation();

    if (message?.trim()?.length != 0) {
      return;
    }
    addAdvanceFilters([]);
    let advanceFilterText: string = '';
    let groupedFilters = {};

    // Group filters by groupName
    filters.forEach((filter) => {
      if (filter.fieldKey && filter.operator && filter.fieldValue) {
        if (!groupedFilters[filter.groupName]) {
          groupedFilters[filter.groupName] = [];
        }
        groupedFilters[filter.groupName].push(filter);
      }
    });

    let isFirstGroup = true;

    // Iterate over each group and construct the filter text
    for (let groupName in groupedFilters) {
      let groupFilterText = groupedFilters[groupName][0]?.condition?.length > 0
        ? groupedFilters[groupName][0]?.condition + ' (' : ' (';

      groupedFilters[groupName].forEach((filter, index) => {
        if (filter.condition.length > 0 && index > 0) {
          groupFilterText += ' ' + filter.condition;
        }
        if (filter.operator.toLowerCase() === 'contains') {
          groupFilterText += ` search.ismatch('${filter.fieldValue}', '${filter.fieldKey}','full','all')`;
        } else if (filter.operator.toLowerCase() === 'in' || filter.operator.toLowerCase() === 'not in') {
          if (filter.fieldKey === 'ALL_CUSTODIANS') {
            // Use contains-like search with search.ismatch for ALL_CUSTODIANS
            const formattedFieldValue = filter.fieldValue
              .split(';') // Split by semicolon
              .map(value => value.trim()) // Trim extra spaces
              .map(value => `search.ismatch('${value}', 'ALL_CUSTODIANS','full','all')`) // Use contains-like search
              .join(' or '); // Join with 'or' for each custodian

            groupFilterText += filter.operator.toLowerCase() === 'in' ?
              `(${formattedFieldValue})` : `not (${formattedFieldValue})`;

          }
          else if (filter.fieldKey === 'User_tags')
          {
            const formattedFieldValue = filter.fieldValue
            .split(';') // Split by semicolon
            .map(value => value.trim()) // Trim extra spaces
            .map(value => `search.ismatch('${value}', '${filter.fieldKey}','full','all')`) // Use contains-like search
            .join(' or '); // Join with 'or' for each custodian

          groupFilterText += filter.operator.toLowerCase() === 'in' ?
            `(${formattedFieldValue})` : `not (${formattedFieldValue})`;
          }
           else if (filter.fieldKey === 'Custodian' || filter.fieldKey === 'DocumentTheme' ) {
            // Regular in and not in conditions for other field keys
            const formattedFieldValue = filter.fieldValue
              .split(';') // Split by semicolon
              .map(value => value.trim()) // Trim extra spaces
              .map(value => `${filter.fieldKey} eq '${value}'`) // Construct "fieldKey eq 'value'"
              .join(' or '); // Join with 'or'

            groupFilterText += filter.operator.toLowerCase() === 'in' ?
              `(${formattedFieldValue})` : `not (${formattedFieldValue})`;
          }
          else {
            groupFilterText += filter.operator.toLowerCase() === 'in' ?
              ` search.in(${filter.fieldKey}, '${filter.fieldValue}',',')` :
              ` not search.in(${filter.fieldKey}, '${filter.fieldValue}',',')`;

          }
        } else {
          if (filter.type === 'date') {
            groupFilterText += ` ${filter.fieldKey} ${filter.operator} ${filter.fieldValue.toISOString()}`;
          }
          else if (filter.type === 'number') {
            groupFilterText += ` ${filter.fieldKey} ${filter.operator} ${filter.fieldValue}`;
          }
          else {
            groupFilterText += ` ${filter.fieldKey} ${filter.operator} '${filter.fieldValue}'`;
          }
        }
        if (isFirstGroup && index === 0) {
          groupFilterText = groupFilterText.replace(filter.condition, '');
        }
      });
      groupFilterText += ') ';
      advanceFilterText += groupFilterText;

      isFirstGroup = false;
    }

    advanceFilterText.replace(/\band$/, '');
    advanceFilterText.replace(/\bor$/, '');
    const filledFilters = filters.filter((filter) => filter?.fieldKey?.length > 0
      && filter?.operator?.length > 0 && filter?.fieldValue);
    addAdvanceFilters(filledFilters);
    setUniqueCustodians([]);
    setUniqueDocumentTheme([]);
    onDismiss();
    ClearFilesOnFilter(advanceFilterText);
  };

  // tag picker
  const [tagCount, setTagCount] = React.useState<number>(0);
  const [customTags, setCustomTags] = React.useState<CustomTag[]>([]);
  const [customTagsCollection, setCustomTagsCollection] = React.useState<IDropdownOption[]>([]);
  const [uniqueCustodians, setUniqueCustodians] = React.useState<IDropdownOption[]>([]);
  const [uniqueDocumentTheme, setUniqueDocumentTheme] = React.useState<IDropdownOption[]>([]);
  const [uniqueAllCustodians, setUniqueAllCustodians] = React.useState<IDropdownOption[]>([]);
  const [uniqueUserTags, setUniqueUserTags] = React.useState<IDropdownOption[]>([]);



  const [hasFetched, setHasFetched] = React.useState<boolean>(false);
  const [hasDTFetched, setHasDTFetched] = React.useState<boolean>(false);
  const [hasAllCusFetched, setHasAllCusFetched] = React.useState<boolean>(false);
  const [hasAllUserTagsFetched, setHasAllUserTagsFetched] = React.useState<boolean>(false);
  const [selectedTags, setSelectedTags] = React.useState([]);

  React.useEffect(() => {
    const customProjectTags = CustomTags();
    setCustomTags(customProjectTags);
    const customTagsCollection = customProjectTags.map((tag: CustomTag) => {
      return {
        key: tag.text,
        text: tag.text,
      };
    });
    setCustomTagsCollection(customTagsCollection);
  }, []);

  const onChange = (event, item, index) => {
    const newFilters = [...filters];
    const newSelectedItems = newFilters[index]["fieldValue"] ? newFilters[index]["fieldValue"]?.split('; ') as string[] : [];
    if (item.selected) {
      // add the selection
      newSelectedItems.push(item.key);
    } else {
      // remove the selection
      const currItemIndex = newSelectedItems.indexOf(item.key);
      if (currItemIndex > -1) {
        newSelectedItems.splice(currItemIndex, 1);
      }
    }
    //setSelectedTags(newSelectedItems);


    newFilters[index]["type"] = "dropdownUT";
    newFilters[index]["fieldValue"] = Object.values(newSelectedItems)?.join('; ');
    setFilters(newFilters);
  };

  const onComboChange = (event, item, index, type) => {
    const newFilters = [...filters];
    const newSelectedItems = newFilters[index]["fieldValue"] ? newFilters[index]["fieldValue"]?.split('; ') as string[] : [];
    if (item.selected) {
      // add the selection
      newSelectedItems.push(item.key);
    } else {
      // remove the selection
      const currItemIndex = newSelectedItems.indexOf(item.key);
      if (currItemIndex > -1) {
        newSelectedItems.splice(currItemIndex, 1);
      }
    }
    //setSelectedTags(newSelectedItems);


    newFilters[index]["type"] = type;
    newFilters[index]["fieldValue"] = Object.values(newSelectedItems)?.join('; ');
    setFilters(newFilters);
  };


  const getFieldType = (filterField: FilterField[], fieldKey: string): string => {
    return filterField.find((item) => item.key === fieldKey)?.type
  }

  const hideField = (currentFieldType: string, typesToCheck: string[]): boolean => {
    const hasVisibleItems = typesToCheck.some(item => currentFieldType);
    return !typesToCheck.includes(currentFieldType)
  }



  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={() => onDismiss()}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        showCloseButton: true,
        styles: { content: { height: dialogHeight || 'auto', overflowY: 'none' } }
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 'none', overflowY: 'auto' } },
      }}
      styles={{
        main: {
          '@media(min-width: 768px)': {
            minWidth: dialogWidth || '600px',
          },
        }
      }}
    >
      <DialogContent className="scrollable-content">
        <Stack style={{ overflow: 'hidden', marginTop: -30 }}>
          <div className="query-container">
            <div style={{ marginBottom: 10 }}>
              {isErrorMessageVisible && validationMessage && validationMessage.length > 0 && (
                <div className="container" style={{ margin: "10px", maxWidth: "100vw" }}>
                  <Stack.Item styles={{ root: { width: "100%", } }}>
                    <span role="alert" aria-label={validationMessage} aria-live="assertive" />
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      tabIndex={0}
                      onDismiss={() => RemoveErrorMessage()}
                      dismissButtonAriaLabel="click on close icon to dismiss the message bar"
                      isMultiline={false}
                    >
                      {validationMessage}
                    </MessageBar>
                  </Stack.Item>
                </div>
              )}
            </div>
            <Stack horizontal tokens={{ childrenGap: 14, }} style={{ marginLeft: 25 }}>
              <Stack style={{ marginBottom: "15px" }}>
                {/* {filters && filters?.length > 0 && ( */}
                <button onClick={() => groupFilter()}
                  disabled={!filters.some(x => x.isChecked)}
                >
                  <div style={{ fontWeight: "600" }}>
                    <Icon iconName={'RowsGroup'} /> Group filters
                  </div>
                </button>
                {/* )} */}
              </Stack>
              <Stack style={{ marginBottom: "15px" }}>
                {/* {filters && filters?.length > 0 && ( */}
                <button onClick={() => handleResetFilter()} >
                  <div style={{ fontWeight: "600" }}>
                    <Icon iconName={'Refresh'} /> Clear filters
                  </div>
                </button>
                {/* )} */}
              </Stack>
            </Stack>
            <div style={{ marginLeft: 25 }}>
              {filters.map((filter, index) => (
                <div key={index} className={`filter-container ${filter.groupName ? 'grouped-filter' : ''}`}
                  style={{ marginLeft: filter.groupName ? '10px' : '0' }}  /* Optional inline style for margin */
                >
                  <div style={{ marginRight: 10 }}>
                    {filter?.groupName?.length == 0 ?
                      (<input className="checkbox-select" type="checkbox" checked={filter.isChecked} onChange={(e) => handleFilterCheck(index, e.target.checked)} />
                      )
                      : (<div style={{ width: '10px' }}></div>)
                    }
                  </div>
                  <div style={{ marginRight: 10 }}>
                    {filter?.groupName?.length > 0 ? (
                      // className={`checkbox-select filter-group-${filter.groupName}`} 

                      <button onClick={() => handleUngroup(filter.groupName)}>
                        <div>{filter.groupName}</div>
                      </button>)
                      : (<div style={{ width: '30px' }}></div>)

                    }
                  </div>
                  {index > 0 ? (
                    <select value={filter.condition} onChange={(e) => handleFilterChange(index, 'condition', e.target.value)} className="logic-select">
                      <option value=""></option>
                      {conditionOptions.map((item, index) => (
                        <option value={item.key} aria-label={item.ariaLabel}>{item.value}</option>
                      ))}
                    </select>
                  ) : (
                    <div style={{ width: '65px' }}></div>
                  )}
                  <select value={filter.fieldKey} onChange={(e) => handleFilterChange(index, 'fieldKey', e.target.value)} className="field-select">
                    <option value="">Select Field</option>
                    {filterField.map((item, index) => (
                      <option value={item.key} aria-label={item.ariaLabel}>{item.value}</option>
                    ))}
                  </select>

                  <select value={filter.operator} onChange={(e) => handleFilterChange(index, 'operator', e.target.value)} className="condition-select">
                    <option value="">Select operator</option>

                    {/* Equals operator */}
                    <option value="eq" hidden={getFieldType(filterField, filter?.fieldKey) === 'dropdownUT'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownCustodian'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownDocumentTheme'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownAllCustodian'
                      || filterField.find((item) => item.key === filter?.fieldKey)?.key === "content"}>
                      Equals (=)
                    </option>

                    {/* Not Equals operator */}
                    <option value="ne" hidden={getFieldType(filterField, filter?.fieldKey) === 'dropdownUT'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownCustodian'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownAllCustodian'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownDocumentTheme'
                      || filterField.find((item) => item.key === filter?.fieldKey)?.key === "content"}>
                      Not Equals (≠)
                    </option>

                    {/* Contains operator */}
                    <option value="contains" hidden={getFieldType(filterField, filter?.fieldKey) === 'date'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownUT'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownCustodian'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownAllCustodian'
                      || getFieldType(filterField, filter?.fieldKey) === 'dropdownDocumentTheme'}>
                      Contains
                    </option>

                    {/* In operator (shown for dropdowns) */}
                    <option value="in" hidden={getFieldType(filterField, filter?.fieldKey) === 'date'}>
                      In
                    </option>

                    {/* Not In operator (shown for dropdowns) */}
                    <option value="not in" hidden={getFieldType(filterField, filter?.fieldKey) === 'date'}>
                      Not In
                    </option>

                    {/* Greater Than operator */}
                    <option value="gt" hidden={hideField(getFieldType(filterField, filter?.fieldKey), ["date", "number"])}>
                      Greater Than ({'>'})
                    </option>

                    {/* Greater Than or Equal To operator */}
                    <option value="ge" hidden={hideField(getFieldType(filterField, filter?.fieldKey), ["date", "number"])}>
                      Greater Than or Equal To ({'>='})
                    </option>

                    {/* Less Than operator */}
                    <option value="lt" hidden={hideField(getFieldType(filterField, filter?.fieldKey), ["date", "number"])}>
                      Less Than ({'<'})
                    </option>

                    {/* Less Than or Equal To operator */}
                    <option value="le" hidden={hideField(getFieldType(filterField, filter?.fieldKey), ["date", "number"])}>
                      Less Than or Equal To ({'<='})
                    </option>
                  </select>


                  {filter.type === "date" ? (
                    <DatePicker
                      isRequired={false}
                      allowTextInput={true}
                      showMonthPickerAsOverlay={true}
                      onSelectDate={(date) => handleFilterDateChange(date, index)}
                      formatDate={formatDate}
                      value={filter.fieldValue as Date}
                      showGoToToday={false}
                      className='dateControl'
                      style={{ minWidth: 200, marginRight: 10 }}
                    />) : filter.type === "dropdownUT" ? (
                      // <Dropdown
                      //   placeholder="Select tags"
                      //   className='value-input-dropdown'
                      //   multiSelect
                      //   options={customTagsCollection}
                      //   selectedKeys={filter.fieldValue ? filter.fieldValue?.split('; ') as string[] : []}
                      //   onChange={(event, item) => onChange(event, item, index)}
                      // />
                      <div style={{ position: 'relative' }}>
                        <ComboBox
                          placeholder={utLoading ? "" : "Select tags"}
                          allowFreeform={true}
                          autoComplete="on"
                          options={uniqueUserTags}
                          className='value-input-dropdown'
                          multiSelect
                          selectedKey={filter.fieldValue ? filter.fieldValue.split('; ') : []}
                          onChange={(event, option) => onComboChange(event, option, index, 'dropdownUT')}
                          disabled={allCusLoading}
                          onClick={() => handleDropdownOpen("User_tags")}

                        />
                        {utLoading && (
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1
                          }}>
                            <Spinner size={SpinnerSize.medium} />
                          </div>
                        )}
                      </div>
                    )
                    : filter.type === "dropdownAllCustodian" ? (


                      <div style={{ position: 'relative' }}>
                        <ComboBox
                          placeholder={allCusLoading ? "" : "Select custodians"}
                          allowFreeform={true}
                          autoComplete="on"
                          options={uniqueAllCustodians}
                          className='value-input-dropdown'
                          multiSelect
                          selectedKey={filter.fieldValue ? filter.fieldValue.split('; ') : []}
                          onChange={(event, option) => onComboChange(event, option, index, 'dropdownAllCustodian')}
                          disabled={allCusLoading}
                          onClick={() => handleDropdownOpen("AllCustodian")}

                        />
                        {allCusLoading && (
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1
                          }}>
                            <Spinner size={SpinnerSize.medium} />
                          </div>
                        )}
                      </div>

                    )
                      : filter.type === "dropdownCustodian" ? (


                        <div style={{ position: 'relative' }}>
                          <ComboBox
                            placeholder={loading ? "" : "Select custodians"}
                            allowFreeform={true}
                            autoComplete="on"
                            options={uniqueCustodians}
                            className='value-input-dropdown'
                            multiSelect
                            selectedKey={filter.fieldValue ? filter.fieldValue.split('; ') : []}
                            onChange={(event, option) => onComboChange(event, option, index, 'dropdownCustodian')}
                            disabled={loading}
                            onClick={() => handleDropdownOpen("Custodian")}

                          />
                          {loading && (
                            <div style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1
                            }}>
                              <Spinner size={SpinnerSize.medium} />
                            </div>
                          )}
                        </div>

                      ) : filter.type === "dropdownDocumentTheme" ? (


                        <div style={{ position: 'relative' }}>
                          <ComboBox
                            placeholder={dtLoading ? "" : "Select document theme"}
                            allowFreeform={true}
                            autoComplete="on"
                            options={uniqueDocumentTheme}
                            className='value-input-dropdown'
                            multiSelect
                            selectedKey={filter.fieldValue ? filter.fieldValue.split('; ') : []}
                            onChange={(event, option) => onComboChange(event, option, index, 'dropdownDocumentTheme')}
                            disabled={dtLoading}
                            onClick={() => handleDropdownOpen("DocumentTheme")}

                          />
                          {dtLoading && (
                            <div style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1
                            }}>
                              <Spinner size={SpinnerSize.medium} />
                            </div>
                          )}
                        </div>
                      ) :
                        (
                          <input
                            type="text"
                            value={filter.fieldValue as string}
                            onChange={(e) => handleFilterChange(index, 'fieldValue', e.target.value)}
                            className="value-input"
                            placeholder="Enter value"
                          />
                        )}

                  <button onClick={() => handleRemoveFilter(index)} className="remove-button">
                    <Icon iconName={'Delete'} />
                  </button>
                </div>
              ))}
              <div style={{ marginBottom: "15px" }}>
                <button onClick={handleAddFilter} >
                  <div style={{ fontWeight: "600" }}>
                    <Icon iconName={'Add'} /> Add new filter
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Stack>
      </DialogContent>
      <DialogFooter className="fixed-footer">
        <DefaultButton onClick={() => onDismiss()} text="Cancel" />
        <PrimaryButton onClick={handleApplyFilter} text="Apply"
          styles={{
            root: { backgroundColor: '#6264A7', borderColor: '#6264A7' },
            rootHovered: { backgroundColor: '#8B8CC7', borderColor: '#8B8CC7' },
            rootPressed: { backgroundColor: '#5558A3', borderColor: '#5558A3' }
          }} />
      </DialogFooter>
    </Dialog>
  )
}


export default AdvanceFilter