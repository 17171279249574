import React from "react";
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles } from "@fluentui/react";

export interface IPromptSelectorProps {
  options: IComboBoxOption[];
  selectedKey?: string;
  onChange: (isAllPromptsSelected: boolean) => void;
}

const PromptSelector: React.FC<IPromptSelectorProps> = ({ options, selectedKey, onChange }) => {
  const comboBoxRef = React.useRef<IComboBox>(null);
  const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 400, marginBottom: 10, marginLeft: -25 } };

  const handleComboBoxChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (option) {
      onChange(option.key === "AllPrompts");
    }
  };

  return (
    <ComboBox
      componentRef={comboBoxRef}
      selectedKey={selectedKey}
      options={options}
      styles={comboBoxStyles}
      onChange={handleComboBoxChange}
    />
  );
};

export default PromptSelector;
