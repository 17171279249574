import { IDropdownStyles,  mergeStyleSets, getTheme, FontWeights, IButtonStyles, IStackStyles } from "@fluentui/react"
import * as React from "react"
const theme = getTheme();

export const container: React.CSSProperties =
{
  marginLeft: "12px",
  marginRight: "20px",
  background: "#F5F5F5",
  marginTop: "20px",
  paddingBottom: "20px",
  width: "80%"
  // boxShadow: "0px 5.2px 5.6px rgba(0, 0, 0, 0.13), 0px 10.4px 14.4px rgba(0, 0, 0, 0.07)",
  // height: window.innerHeight - 95,
}

export const width65: React.CSSProperties =
{
  width: "65%",
}
export const width50: React.CSSProperties =
{
  width: "50%",
}

export const width40: React.CSSProperties =
{
  width: "40%",
}

export const width30: React.CSSProperties =
{
  width: "30%",
}
export const width20: React.CSSProperties =
{
  width: "30%",
}

export const width15: React.CSSProperties =
{
  width: "15%",
}

export const goBack: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#242424",
  marginTop: "10px",
  marginLeft: "10px",
  float: "left",
  cursor: "pointer"
}

export const iconStyle: React.CSSProperties =
{
  // verticalAlign: "bottom", 
  height: "20px",
  width: "20px",
  marginTop: 3,
  color: "#242424 !important",
  fontSize:14
}

export const stackStyle: React.CSSProperties =
{
  marginLeft: "2%",
  marginTop: 5
}

export const viewProjectWidth: React.CSSProperties =
{
  width: "90%",
  marginTop: 5,
}

export const titleStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  color: "#242424",
  marginTop: "2%",
  marginLeft: "2%",
}

export const headerStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#171717",
  marginTop: "20px",
  marginLeft: "20px",
}

export const headerStatusStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  color: "#000000",
  marginTop: "4%",
  marginLeft: "auto",
  marginRight: "4%"
}

export const descriptionStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#171717",
  marginTop: "4%",
  marginLeft: "2%",
  paddingRight: "12px",
  textAlign: "justify"
}

export const readMore: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#3366cc",
  marginTop: "4%",
  marginLeft: "2%",
  paddingRight: "12px",
  textAlign: "justify",
  cursor: "pointer"
}

export const sectionheaderStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  color: "#242424",
  marginTop: "5%",
  marginLeft: "2%",
}

export const closingDateStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
 
  color: "#242424",
  marginTop: "1%",
  marginLeft: "2%",
}

export const memberNameStyle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
 
  color: "#171717",
  marginLeft: "2%",
}

export const paddingTop4: React.CSSProperties = {
  paddingTop: "4px"
}

export const paddingLeft5: React.CSSProperties =
{
  paddingLeft: "5px",
  fontSize: "16px",
 
}


export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "100%", marginTop:10,border:"none !important",backgroundColor:"transparent"},
  dropdownItem: {
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    color: "#171717",
    marginLeft: "5px",
  },
  title:{border:"none"}
};

export const dropdownOpenText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    marginLeft: "2px",
    color: "green"
}

export const dropdownClosedText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    marginLeft: "2px",
    color: "red"
}

export const renderOpeniconStyles = { marginRight: '2px', color: "green" };
export const renderClosediconStyles = { marginRight: '2px', color: "red" };

export const editiconStyle: React.CSSProperties =
{
  
  paddingLeft: "10px",
  cursor: "pointer",
  width:13,
  height:13,
  fontSize:"15px"
}

export const spinnerCss: React.CSSProperties =
{
  position: "fixed",
  top: "40%",
  left: "50%",
  /* bring your own prefixes */
  transform: "translate(-50%, -50%)"
}

export const separator: React.CSSProperties =
{
  borderTop: "1px solid #bbb",
  margin: 5
}

export const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    backgroundColor: "white",
    color: "#323130",
    width: "45%"
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    height: "22px",
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "22px",
    marginTop: "10px",
    marginBottom: "7px",
    color: "#323130",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    marginTop: "20px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  bodyContent: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#323130",
    marginLeft: 24,
    marginRight: 24,
  },
});



export const primaryDescriptionButtonStyles: IButtonStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 20px",
    width: "100px",
    height: "32px",
    background: "#FFFFFF",
    borderRadius: "2px",
    marginTop: "8px"
  },
};
export const descriptionButtonParentStackStyles: IStackStyles = {
  root: { marginTop: 17, marginLeft: "auto", marginRight: 24 },
};