import React from 'react'
import './Button.module.css'

export interface IButtonProps {
  text?: string;
  onClick(): void;
  type?: string;
  children?: any
}
const Button = (buttonProps: IButtonProps) => {
  const {text, onClick, type, children} = buttonProps
  return (
    <button type='button' onClick={onClick} className={`btn ${type}`}>
      {children}
    </button>
  )
}


export default Button