import * as React from "react"

export const container: React.CSSProperties =
{
  display: "flex",
  justifyContent: "center",
  minHeight: "210px",
  width: "300px",
  marginTop: "14px",
}

export const padding: React.CSSProperties =
{
  padding: "10px"
}

export const iconAlignProject: React.CSSProperties =
{
  padding: "10px",

}

export const iconAlignFiles: React.CSSProperties =
{
  padding: "10px",
  verticalAlign: "middle",
  marginTop: "10%",
}

export const paddingTop: React.CSSProperties =
{
  paddingTop: "5px"
}

export const paddingTop10: React.CSSProperties =
{
  paddingTop: "10px"
}

export const leftArrowStyle: React.CSSProperties =
{
  marginLeft: "3px",
  marginRight: "auto",
  cursor: "pointer",
  marginTop: "auto",
  marginBottom: "auto"
}

export const rightArrowStyle: React.CSSProperties =
{
  marginRight: "3px",
  marginLeft: "auto",
  cursor: "pointer",
  marginTop: "auto",
  marginBottom: "auto",
}

export const itemContainer: React.CSSProperties =
{
  display: "block",
  marginRight: "auto",
  marginLeft: "auto",
  background: "rgba(223, 224, 236, 0.24)",
  width: "287px",
  overflowWrap: "break-word",
  paddingLeft: "10px",
  paddingBottom: "15px",
}

export const separator: React.CSSProperties =
{
  borderTop: "1px solid #bbb",
  margin: 5
}

export const titleText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  color: "#000000",
  textAlign: "center",
  marginTop: "7px",
  cursor: "pointer",
}

export const spinnerMargin: React.CSSProperties =
{
  marginTop:"10px"
}

export const descriptionText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#000000",
  paddingTop: "10px",
  marginLeft: "10px",
  marginRight: "10px",
}

export const detailsText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#000000",
  paddingTop: "10px",
  marginLeft: "10px",
  marginRight: "10px",
  overflowX: "scroll",
}

export const fileiconStyle: React.CSSProperties =
{
  verticalAlign: "bottom",
  paddingRight: "3px",
  fontSize: "24px",
  marginRight: "10px",
  marginTop: "3px",
  color: "#171717"
}

export const fileitemStyle: React.CSSProperties =
{
  fontFamily: 'Segoe UI',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  color: "#171717",
  paddingTop: "5px"

}

export const countMsg: React.CSSProperties =
{
  textAlign: "center",
}
