import { Checkbox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IBasePickerSuggestionsProps, Icon, IPersonaProps, ITag, MessageBarType, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Reducer } from "redux";
import DisplayMessage from "../../Common/Components/DisplayMessage";
import DisplaySpinner from "../../Common/Components/DisplaySpinner";
import ErrorDisplay from "../../Common/Components/ErrorDisplay";
import { GenericMessages } from "../../Helpers/ProjectConstants";
import { checkIfFileExistsInSPO, formatDate, getDateFromString } from "../../Helpers/ProjectHelper";
import CustomTags, { CustomTag } from "../../Models/CustomTags";
import { IProjectFile, IProjectFiles } from "../../Models/IProjectFile";
import { IProjectFileTags } from "../../Models/IProjectFileTags";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { IProjectProps } from "../../Models/IProjectProps";
import { IProjectTags } from "../../Models/IProjectTags";
import { requestProjectCreation, requestProjectWorkArea, requestProjectEdit, updateProjectStateForFileUpload } from "../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import CustomTagPicker from "../PickerComponents/CustomTagPicker";
import PeoplePicker from "../PickerComponents/PeoplePicker";
import FormAttachmentView from "./Components/FormAttachmentView";
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import ProjectWorkAreaView from "./ProjectWorkAreaView";

import {
    assingPadding,
    cancelButtonStyle,
    cancelButtonText,
    colorred,
    createButtonStyle,
    createButtonText,
    createProjectTitle,
    dropdownStyles,
    formText,
    iconStyle,
    tagPickerStyling,
    textFieldStyles,
    controlWidth,
    controlPeoplePickerStyles,
    addAttachment,
    addFileControl,
    controlWidthAttachment,
    controlWidthFiles,
    controlWidthAttachementLabel,
    attachmentLabel,
    customDatePickerStyles,
    goBackIconStyle,
    goBack,
    textFieldDescStyles,
    chkStyle
} from "./Styles/CreateProject";
import { spinnerCss } from "./Styles/ProjectList";
import { app, pages } from "@microsoft/teams-js";
import { IProjectSearchRequest } from "../../Models/IProjectSearchRequest";
import { ITabNavigation } from "../../Models/TabNavigation/ITabNavigation";
import { useLocation } from 'react-router-dom';
export const ProjectContext = React.createContext(null);

const CreateProject = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectCreate,
        isProjectGettingCreated,
        projectCreationSuccess,
        projectCreateError,
        projectCreationFailure,
        projectCreateRedirect,
        projectContext,
        projectFiles,
        projectFileTags,
        projectProps,
        projectOwner,
        projectMembers,
        deletedProjectMembers,
        projectTags,
        isProjectGettingEdited,
        projectEditSuccess,
        projectEditRedirect,
        projectEditFailure,
        projectEditError,
        loginUserpersonaProps,
        isGettingProjectWorkArea,
        isProjectWorkAreaSuccess,
        isProjectWorkAreaError,
        projectWorkAreaCollection

    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const [dragActive, setDragActive] = React.useState(false);
    const [files, setFiles] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [workArea, setWorkArea] = React.useState("");
    const [pageTitle, setPageTitle] = React.useState('');
    const [buttonName, setButtonName] = React.useState('');
    const [projectTag, setProjectTag] = React.useState<number>(0);
    const [hideDialog, setHideDialog] = React.useState(true);
    const [customTags, setCustomTags] = React.useState<CustomTag[]>([]);
    const [selectedTags, setSelectedTags] = React.useState<ITag[]>([]);
    const [tagCount, setTagCount] = React.useState<number>(0);
    const [localProjectTags, setProjectTags] = React.useState<IProjectTags>();
    const [projectMember, setProjectMember] = React.useState<IProjectMembers>();
    const [projectOwners, setProjectOwners] = React.useState<IProjectMembers>();
    const [startDate, setStartDate] = React.useState<Date | null | undefined>(null);
    const [endDate, setEndDate] = React.useState<Date | null | undefined>(null);
    const [tagCharacrtersRemaining, setTagCharacrtersRemaining] = React.useState<number>(255);
    const [validationMessage, setValidationMessage] = React.useState("");
    const [selectedFileTags, setSelectedFileTags] = React.useState<ITag[]>([]);
    const [fileTags, setFileTags] = React.useState<IProjectFileTags>();
    const [selectedFile, setSelectedFile] = React.useState(null);
    const inputRef = React.useRef(null);
    const [fileList, setFileList] = React.useState([]);
    const [changeDetected, setChangeDetected] = React.useState(false);
    const [isCELALegalGroupChecked, setIsCELALegalGroupChecked] = React.useState(false);
    const [tabNavigation, setTabNavigation] = React.useState<ITabNavigation>({});
    const [isNavigationFromDashboard, setIsNavigationFromDashboard] = React.useState(false);
    const [backToProjectList, setBackToProjectList] = React.useState(false);
    const [dropdownWorkAreaOptions, setDropdownWorkAreaOptions] = React.useState<any>([]);
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();

    const onChangeWorkArea = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setWorkArea(item.key as string);
        setSelectedItem(item);
    };
    // Title Change
    const onChangeTitleValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setTitle(newValue || '');
            setChangeDetected(true);
        },
        [],
    );

    // Description Change
    const onChangeDescriptionValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDescription(newValue || '');
            setChangeDetected(true);
        },
        [],
    );

    //Status Start
    const dropDownOptions: IDropdownOption[] = [
        { key: "Open", text: 'Open', title: 'Open' },
        { key: "Closed", text: 'Closed', title: 'Closed' },
    ];
    const [selectedStatus, setSelectedStatusItem] = React.useState<IDropdownOption>();
    const onStatusChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedStatusItem(item);
        setChangeDetected(true);
    };
    //Status End

    //Start and End Date Start
    const onSelectStartDate = (date: Date | null | undefined): void => {
        setStartDate(date);
        setChangeDetected(true);
    };
    const onSelectEndDate = (date: Date | null | undefined): void => {
        setEndDate(date);
        setChangeDetected(true);
    };
    //Start and End Date End

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsClassName: ''
    }

    React.useEffect(() => {
        app.getContext().then((context) => {
            if (context?.page?.subPageId !== '') {
                setTabNavigation(JSON.parse(context.page.subPageId));
            }
        });
        const isBackToProjectList: string = queryParams.get('backToProjectList')
        setBackToProjectList(isBackToProjectList === 'true' ? true : false);
        setSelectedStatusItem(dropDownOptions[0]);
        setPageTitle("Create a project")
        setButtonName("Create");
    }, []);

    React.useEffect(() => {
        if (Object.keys(tabNavigation).length > 0) {
            setIsNavigationFromDashboard(tabNavigation?.isFromDashboard)
        }
    }, [tabNavigation]);


    React.useEffect(() => {
        if (projectContext?.number) {

            setPageTitle("Edit project");
            setButtonName("Save");
            setTitle(projectProps.title);
            setDescription(projectProps.description);
            if (projectFiles) {
                if (projectFiles?.projectFile?.length > 0) {
                    const files: any = [];
                    projectFiles.projectFile.map((file: any) => {
                        files.push(file?.file);
                    })
                    setFiles(files);
                }
                setFileList(projectFiles.projectFile);
            }
            if (projectProps?.startDate) { setStartDate(getDateFromString(projectProps.startDate)); }
            if (projectProps?.startDate) { setEndDate(getDateFromString(projectProps.endDate)); }
            if (projectProps?.status?.toLowerCase() === "closed") { setSelectedStatusItem(dropDownOptions[1]); }
            else { setSelectedStatusItem(dropDownOptions[0]); }
            if (projectOwner && projectOwner?.membersPicked.length > 0) {
                setProjectOwners(projectOwner)
            }
            if (projectMembers && projectMembers?.membersPicked.length > 0) {
                const tempprojectMembersList: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };

                projectMembers?.membersPicked?.map((member: IPersonaProps) => {
                    if (
                        member.secondaryText.toLowerCase().trim() === "lca@microsoft.com" ||
                        member.secondaryText.toLowerCase().trim() === "lca"
                    ) {
                        setIsCELALegalGroupChecked(true);
                    }
                    else {
                        const teamMember: IPersonaProps = {
                            text: member?.text,
                            secondaryText: member?.secondaryText,
                            optionalText: member?.optionalText,
                            tertiaryText: member?.tertiaryText,
                        };
                        tempprojectMembersList.membersPicked.push(teamMember);
                        tempprojectMembersList.alias.push(member?.text);
                    }
                });
                setProjectMember(tempprojectMembersList)
            }
            if (projectTags?.tags) {
                setProjectTags(projectTags);
            }
            if (projectFileTags?.tags) {
                console.log("fileTags", projectFileTags);
                setFileTags(projectFileTags);
            }
        }
    }, [projectContext]);

    React.useEffect(() => {
        if (projectOwners && projectOwners.membersPicked.length > 0) {
            setProjectOwners(projectOwners)
        }
        if (projectMember && projectMember.membersPicked.length > 0) {
            setProjectMember(projectMember)
        }
    }, [projectCreationFailure]);


    // Project Tags Start
    React.useEffect(() => {
        const customProjectTags = CustomTags;
        setCustomTags(customProjectTags);
    }, []);

    React.useEffect(() => {
        const iTags: ITag[] = [];
        localProjectTags?.tags?.map((projectTag: string) => {
            const iTag: ITag = {
                key: projectTag,
                name: projectTag,
            };
            iTags.push(iTag);
        });
        setSelectedTags(iTags);
    }, [localProjectTags]);

    React.useEffect(() => {
        if (Object.keys(projectWorkAreaCollection).length === 0) {
            dispatch(requestProjectWorkArea());
        }
    }, []);



    React.useEffect(() => {
        if (projectWorkAreaCollection?.projectWorkAreas?.length > 0 && dropdownWorkAreaOptions.length == 0) {
          var currentHeader = '';
          var workAreaCollection = [];
          var childItemCount = 0;
          projectWorkAreaCollection?.projectWorkAreas.map((projectWorkArea, counter) => {
            if (projectWorkArea.headerRow) {
              if (currentHeader != '') {
                if(childItemCount === 0){
                  var dropDownHeaderItem = {
                    key: currentHeader + "//" + currentHeader + "//",
                    text: currentHeader,
                    itemType: DropdownMenuItemType.Normal
                  }
                  workAreaCollection.push(dropDownHeaderItem)              
                }
                var dropDownHeaderItem = {
                  key: 'divider_' + projectWorkArea.workAreaId,
                  text: '-',
                  itemType: DropdownMenuItemType.Divider
                }
                workAreaCollection.push(dropDownHeaderItem)
                childItemCount = 0;
              }
              currentHeader = projectWorkArea.value;
              var dropDownHeaderItem = {
                key: projectWorkArea.key,
                text: projectWorkArea.value,
                itemType: DropdownMenuItemType.Header
              }
              workAreaCollection.push(dropDownHeaderItem)
              if(counter + 1 == projectWorkAreaCollection?.projectWorkAreas?.length){
                var dropDownHeaderItem = {
                  key: currentHeader + "//" + currentHeader + "//",
                  text: currentHeader,
                  itemType: DropdownMenuItemType.Normal
                }
                workAreaCollection.push(dropDownHeaderItem)  
              }
            } else {
              childItemCount++;
              var dropDownHeaderItem = {
                key: projectWorkArea.key,
                text: projectWorkArea.value,
                itemType: DropdownMenuItemType.Normal
              }
              if (workAreaCollection.findIndex(x => x.key === projectWorkArea.key) == -1) {
                workAreaCollection.push(dropDownHeaderItem)
              }
              setDropdownWorkAreaOptions(workAreaCollection);
            }
          });
        }
      }, [projectWorkAreaCollection]);


    const onAddTag = async (text: string) => {
        setTagCount((x) => x + 1);
        const currentTagidIndex = tagCount + customTags.length;
        const response = await Promise.resolve({
            id: currentTagidIndex,
            text,
        });
        return response;
    };

    const onCategorySelected = (id?: number) => {
        setProjectTag(id);
    };

    const onResolveSuggestions = async (filter: string, selectedItems: ITag[] | undefined): Promise<ITag[]> => {
        if (filter) {
            return customTags
                .filter((customTag: CustomTag) => customTag.text.toLowerCase().includes(filter.toLowerCase()))
                .map((customTag: CustomTag): ITag => {
                    return {
                        name: customTag.text,
                        key: customTag.id,
                    };
                });
        }
        return [];
    };

    const onChange = (items?: ITag[] | undefined) => {
        if (items && items[0]) {
            onCategorySelected(Number(items[0].key));
        } else {
            onCategorySelected();
        }
        setSelectedTags(items);
        let tagStrings: string[] = [];
        const localProjectTag: IProjectTags = {
            tags: [],
        };
        items.map((item: ITag) => {
            tagStrings.push(item.name.toString());
        });
        localProjectTag.tags = tagStrings;
        setProjectTags(localProjectTag);
        setChangeDetected(true);
    };
    // Project Tags End

    // File Tags Start
    React.useEffect(() => {
        const iTags: ITag[] = [];
        if (fileTags && fileTags?.tags) {
            if (fileTags?.tags[fileTags?.tags?.length - 1] === ',') {
                fileTags.tags = fileTags?.tags.slice(0, -1);
            }
            const tagArray = fileTags?.tags.split(",");
            tagArray?.map((projectTag: string) => {
                const iTag: ITag = {
                    key: projectTag,
                    name: projectTag,
                };
                iTags.push(iTag);
            });
            setSelectedFileTags(iTags);
        }
    }, [fileTags]);

    const modalPropsStyles = { main: { minWidth: 250, minHeight: 400 } };

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
        styles: modalPropsStyles
    };

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Add Tags',
        subText: 'Type the tags below to associate with the uploaded files',
    };

    const toggleHideDialog = () => setHideDialog(value => !value);

    const onChangeFileTags = (items?: ITag[] | undefined) => {
        if (items && items[0]) {
            onCategorySelected(Number(items[0].key));
        } else {
            onCategorySelected();
        }
        setSelectedFileTags(items);
        const fileTags: IProjectFileTags = {
            tags: "",
        };
        let tagStrings: string = "";
        items.map((item: ITag) => {
            tagStrings = tagStrings + item.name.trim() + ",";
        });
        tagStrings = tagStrings.slice(0, -1);
        fileTags.tags = tagStrings;
        setFileTags(fileTags);
        setChangeDetected(true);
    };
    // File Tags End  

    //Upload Files Start 
    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
        e.target.value = ''
    };
    const convertNestedObjectToArray = (nestedObj: any) => Object.keys(nestedObj).map((key) => nestedObj[key]);
    const callUpdateFilesCb = (files: any) => {
        const filesAsArray = convertNestedObjectToArray(files);
    };

    const addNewFiles = (newFiles: any) => {
        for (let file of newFiles) {
            files[file.name] = file;
        }
        setFiles(files);
        return { ...files };
    };

    const handleFiles = (newFiles: any) => {
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            const newProjectFiles: IProjectFiles = {
                projectFile: [],
            };
            Object.keys(updatedFiles).map((fileName) => {
                let projectFile: IProjectFile = {};
                let file = files[fileName];
                projectFile.file = file;
                projectFile.isNew = checkIfFileExistsInSPO(file.name, projectFiles);
                newProjectFiles.projectFile.push(projectFile);
            });
            setFileList(newProjectFiles?.projectFile);
            callUpdateFilesCb(updatedFiles);
            setChangeDetected(true);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();

    };

    // Updates the file project state if the file is successfully deleted from SPO
    // If the file is a new upload, then update the list
    const removeFileFromState = (file: any) => {
        if (projectContext && !file.isNew) {
            const localprojectFiles: IProjectFiles = {
                projectFile: [],
            };
            projectFiles?.projectFile.map((fileList: IProjectFile) => {
                if (fileList.file.name.toLowerCase() !== file.file.name.toLowerCase()) {
                    const projectFile: IProjectFile = {};
                    projectFile.file = fileList.file;
                    projectFile.isNew = fileList.isNew;
                    localprojectFiles.projectFile.push(projectFile);
                }
            });
            dispatch(updateProjectStateForFileUpload(localprojectFiles));
            //Set Change detected for the files which are uploaded to SPO and not the local ones they are deleting after uploading
            setChangeDetected(true);
        }
        if (typeof files === "object") {
            for (const [key, value] of Object.entries(files)) {
                if (value.name === file?.file?.name) {
                    console.log(files[key]);
                    delete files[key];
                }
            }
        } else {
            delete files[file?.file?.name];
        }
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
        var tempFileList = fileList.filter(files => files.file.name.toLowerCase() !== file.file.name.toLowerCase());
        setFileList(tempFileList);
    }

    const doValidation = () => {
        // Do all the required Validations
        // If validation fails, set the Validation Message and display proper error message
        let message: string = "";
        if (title?.trim().length == 0) {
            setValidationMessage("Title is a mandatory field");
            message = "Title is a mandatory fields";
        }
        else if (description?.trim().length == 0) {
            setValidationMessage("Description is a mandatory field");
            message = "Description is a mandatory fields";
        }
        else if (workArea?.trim().length == 0) {
            setValidationMessage("Work Area is required");
            message = "Work Area is required";
          }
        else if (endDate && startDate && endDate <= startDate) {
            setValidationMessage("End Date should be after the Start Date");
            message = "End Date should be after the Start Date";
        }

        else {
            setValidationMessage("");
            message = "";
        }
        return message
    }

    const createFileObject = (): IProjectFiles => {
        const newProjectFiles: IProjectFiles = {
            projectFile: [],
        };
        fileList?.map((files: any) => {
            let projectFile: IProjectFile = {};
            let file = files.file;
            projectFile.file = file;
            projectFile.isNew = checkIfFileExistsInSPO(file.name, projectFiles);;
            newProjectFiles.projectFile.push(projectFile);
        })
        return newProjectFiles;
    }

    function togglePermissionsToCelaGroup(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setChangeDetected(true);
        setIsCELALegalGroupChecked(isChecked);
    }

    // Check validation for last owner in project
    const deleteOwnerdialogContentProps = {
        type: DialogType.largeHeader,
        title: "No Owner found for project",
    };
    const toggleMemberDeleteHideDialog = () => setMemberDeleteHideDialog(value => !value);
    const [hideMemberDeleteDialog, setMemberDeleteHideDialog] = React.useState(true);

    const createProject = () => {
        const message: string = doValidation();
        if (message.trim().length === 0) {
            const projectProps: IProjectProps = {};
            projectCreate.projectFiles = createFileObject();
            projectProps.title = title;
            projectProps.description = description;
            projectProps.workArea = workArea;
            projectProps.status = selectedStatus.text.toString();
            projectProps.startDate = formatDate(startDate);
            projectProps.endDate = formatDate(endDate);
            projectCreate.projectProps = projectProps;
            projectCreate.projectTags = localProjectTags;
            projectCreate.projectFileTags = fileTags;
            projectCreate.projectMembers = projectMember;
            projectCreate.projectOwners = projectOwners;
            projectCreate.userMailbox = loginUserpersonaProps?.secondaryText
            projectCreate.isCELAPeopleCheckedLocally = isCELALegalGroupChecked;

            console.log("Create Project", projectCreate, changeDetected);
            if (projectContext?.number) {
                if (projectOwners && projectOwners?.membersPicked?.length > 0) {
                    dispatch(requestProjectEdit(projectCreate, projectContext, deletedProjectMembers));
                }
                else {
                    toggleMemberDeleteHideDialog();
                }
            }
            else {
                dispatch(requestProjectCreation(projectCreate));
            }
        }
        else {
            console.log("Validation", message)
        }
    }

    React.useEffect(() => {
        if (projectCreationSuccess && projectCreateRedirect) {
            if (isNavigationFromDashboard) {
                if (pages.currentApp.isSupported) {
                    const tabNavigation: ITabNavigation = {
                        isNewProjectCreation: true,
                        projectCreationSuccess: true,
                        title
                    };
                    const navPromise = pages.navigateToApp({ appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectdashboard', subPageId: JSON.stringify(tabNavigation) });
                    navPromise.then(() => {
                        console.log('Navigated to project list page')
                    }).catch((error) => {

                    })
                }
            } else {
                history.push("/projectlist");
            }
        }
    }, [projectCreationSuccess, projectCreateRedirect]);

    React.useEffect(() => {
        if (projectEditSuccess && projectEditRedirect && projectContext?.number) {
            history.push(`/viewproject?projectNumber=${projectContext?.number}&backToProjectList=true`);
        }
    }, [projectEditSuccess, projectEditRedirect, projectContext?.number]);

    const handleCancelClick = () => {
        if (projectContext?.number) {
            history.push("/viewproject");
        }
        else {
            if (isNavigationFromDashboard) {
                if (pages.currentApp.isSupported) {
                    const navPromise = pages.navigateToApp({ appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectdashboard' });
                    navPromise.then(() => {
                        console.log('Navigated to project list page')
                    }).catch((error) => {

                    })
                }
            } else {
                history.push("/projectlist");
            }

        }
    }

    // Confirmation Popup Start
    const [hideConfirmationDialog, setHideConfirmationDialog] = React.useState(true);
    const [createDate, setCreateDate] = React.useState("");
    const dialogConfirmationContentProps = {
        type: DialogType.largeHeader,
        title: 'Unsaved Changes',
    };

    const toggleHideConfirmationDialog = () => setHideConfirmationDialog(value => !value);

    const goBackAfterConfirmationRedirect = () => {
        history.goBack();
        toggleHideConfirmationDialog();
    }

    // Confirmation Popup End

    const goBackRedirect = () => {
        if (changeDetected) {
            toggleHideConfirmationDialog();
        }
        else if (backToProjectList) {
            history.push(`/viewproject?projectNumber=${projectContext?.number}&backToProjectList=true`);
        }
        else {
            if (isNavigationFromDashboard) {
                if (pages.currentApp.isSupported) {
                    const navPromise = pages.navigateToApp({ appId: __TEAM_APP_ENTITY_ID__, pageId: 'projectdashboard' });
                    navPromise.then(() => {
                        console.log('Navigated to project list page')
                    }).catch((error) => {

                    })
                }
            } else {
                history.goBack();
            }
        }
    }



    return (
        <Stack style={{ marginLeft: '12px' }}>
            {validationMessage && validationMessage.length > 0 && (
                <>
                    <DisplayMessage
                        accessabilityMessage={validationMessage}
                        messageType={MessageBarType.error}
                        message={validationMessage}
                    />
                </>
            )}
            {isProjectGettingCreated && (
                <>
                    <span role="alert" aria-label={GenericMessages.projectsCreating} aria-live="assertive" />
                    <div style={spinnerCss}>
                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.projectsCreating}
                            spinnerPosition={"right"}
                            spinnerText={GenericMessages.projectsCreating}
                        />
                    </div>
                </>
            )}
            {isProjectGettingEdited && (
                <>
                    <span role="alert" aria-label={GenericMessages.projectsEditing} aria-live="assertive" />
                    <div style={spinnerCss}>
                        <DisplaySpinner
                            accessabilityMessage={GenericMessages.projectsEditing}
                            spinnerPosition={"right"}
                            spinnerText={GenericMessages.projectsEditing}
                        />
                    </div>
                </>
            )}
            {!isProjectGettingEdited && projectEditSuccess && (
                <>
                    <DisplayMessage
                        accessabilityMessage={projectProps.title + " has been edited successfully"}
                        messageType={MessageBarType.success}
                        message={projectProps.title + " has been edited successfully"}
                    />
                </>
            )}
            {!isProjectGettingCreated && projectCreationFailure && (
                <>
                    <ErrorDisplay source={projectCreateError?.errorMessage} />
                </>
            )}
            {!isProjectGettingEdited && projectEditFailure && (
                <>
                    <ErrorDisplay source={projectEditError?.errorMessage} />
                </>
            )}
            {!isProjectGettingCreated && !isProjectGettingEdited && (
                <>
                    <Stack horizontal style={goBack} onClick={goBackRedirect}>
                        <Stack>
                            <Icon
                                iconName={"ChevronLeft"}
                                tabIndex={0}
                                style={goBackIconStyle}
                            />
                        </Stack>
                        <Stack> Go Back </Stack>
                    </Stack>
                    <Stack>
                        <div style={createProjectTitle}>
                            {pageTitle}
                        </div>
                    </Stack>

                    {/* Title */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Title <span style={colorred}>*</span>
                        </div>
                        <div>
                            <TextField
                                name="title"
                                placeholder="Enter title"
                                value={title}
                                onChange={onChangeTitleValue}
                                styles={textFieldStyles}
                                disabled={projectContext?.isBillable}
                            />
                        </div>
                    </Stack>

                    {/* Description */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Description <span style={colorred}>*</span>
                        </div>
                        <div>
                            <TextField
                                name="description"
                                placeholder="Enter description"
                                value={description}
                                onChange={onChangeDescriptionValue}
                                styles={textFieldDescStyles}
                                disabled={projectContext?.isBillable}
                                multiline
                                autoAdjustHeight />
                        </div>
                    </Stack>

                    {/* Owner */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Owner
                        </div>
                        {buttonName === "Create" && (
                            <div style={controlPeoplePickerStyles}>
                                <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                    <PeoplePicker
                                        peopleType="owner"
                                        scenario={buttonName}
                                    />
                                </ProjectContext.Provider>
                            </div>
                        )}
                        {buttonName != "Create" && (
                            <div style={controlPeoplePickerStyles}>
                                <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                    <PeoplePicker
                                        peopleType="owner"
                                        scenario={buttonName}
                                    />
                                </ProjectContext.Provider>
                            </div>
                        )}
                        <Dialog
                            hidden={hideMemberDeleteDialog}
                            onDismiss={toggleMemberDeleteHideDialog}
                            dialogContentProps={deleteOwnerdialogContentProps}
                        >
                            There should be atleast 1 owner present for the project. Please add a owner for this project.
                            <DialogFooter>
                                <DefaultButton onClick={toggleMemberDeleteHideDialog} text="Close" />
                            </DialogFooter>
                        </Dialog>
                    </Stack>

                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Work Area
                        </div>
                        <div style={controlWidth}>
                            {!projectContext?.number && (
                                <>
                                    <Dropdown
                                        //label="Work Area"
                                        selectedKey={selectedItem ? selectedItem.key : undefined}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onChange={onChangeWorkArea}
                                        placeholder="Select Work Area"
                                        options={dropdownWorkAreaOptions}
                                        styles={dropdownStyles}
                                        
                                        disabled={projectContext?.number == ""}
                                        hidden={!isProjectGettingCreated && projectContext?.workArea === ''}
                                    />
                                </>
                            )}
                            {projectContext?.number && (
                                <>
                                <ProjectWorkAreaView projectProps={projectProps} />
                                </>
                            )}

                        </div>

                    </Stack>

                    {/* Upload Document */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            <Stack horizontal style={controlWidthAttachementLabel}>

                                <Stack style={addAttachment}>
                                    <label htmlFor="attachment" style={attachmentLabel}>
                                        Upload your document
                                    </label>
                                </Stack>
                                <Stack>
                                    <button disabled={fileList.length === 0} onClick={toggleHideDialog} style={{ border: 'none', backgroundColor: 'transparent' }}>
                                        <TooltipHost content="Add File Tags">
                                            <Icon
                                                iconName={"Tag"}
                                                tabIndex={0}
                                                style={iconStyle}
                                            />
                                        </TooltipHost>
                                        <Dialog
                                            hidden={hideDialog}
                                            onDismiss={toggleHideDialog}
                                            dialogContentProps={dialogContentProps}
                                            modalProps={modelProps}
                                        >

                                            <div style={{ border: "1px solid black" }}>

                                                <CustomTagPicker
                                                    onAddTag={onAddTag}
                                                    onCategorySelected={onCategorySelected}
                                                    onResolveSuggestions={onResolveSuggestions}
                                                    onChange={onChangeFileTags}
                                                    selectedItems={selectedFileTags}
                                                    scenario="FileTags"
                                                    isBillable={projectContext.isBillable}
                                                />


                                            </div>
                                            <div style={{ color: '#fff', height: 100 }}>
                                                This div is placed to occupy some space for tags suggestion container</div>
                                            <DialogFooter>
                                                <PrimaryButton onClick={toggleHideDialog} text="Add" />
                                                <DefaultButton onClick={toggleHideDialog} text="Close" />
                                            </DialogFooter>

                                        </Dialog>

                                    </button>
                                </Stack>
                                <input
                                    style={addFileControl}
                                    type="file"
                                    name="file[]"
                                    id="attachment"
                                    onChange={handleChange}
                                    multiple />

                            </Stack>
                        </div>
                        <Stack horizontal style={controlWidthAttachment}>
                            <Stack style={controlWidthFiles}>
                                <div>
                                    {fileList?.map((file: any, index: number) => {
                                        return (
                                            <FormAttachmentView index={index} file={file} removeFileFromState={removeFileFromState} showDate={false} />
                                        )
                                    })}
                                </div>

                            </Stack>

                        </Stack>

                    </Stack>

                    {/* Team Member */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Team Member
                        </div>
                        <Stack horizontal style={controlPeoplePickerStyles}>
                            <ProjectContext.Provider value={{ projectMember, setProjectMember, projectOwners, setProjectOwners, deletedProjectMembers, setChangeDetected }}>
                                <PeoplePicker
                                    peopleType="member"
                                />
                            </ProjectContext.Provider>
                        </Stack>
                    </Stack>

                    {/* CELA People */}
                    <Stack style={assingPadding}>
                        <Stack style={controlWidth}>
                            <div style={{ display: "inline", width: "100%", float: "left", height: "30px" }}>
                                <div style={{ display: "inline", width: "80%", float: "left" }}>
                                    <div style={formText}>People in CELA</div>
                                </div>
                                <div style={{ display: "inline", width: "8%", float: "right" }}>
                                    <div style={chkStyle}>
                                        <Checkbox
                                            onChange={togglePermissionsToCelaGroup}
                                            ariaLabel="Select checkbox to include all peopel in CELA"
                                            checked={isCELALegalGroupChecked}
                                            disabled={projectContext?.isBillable}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Stack>
                    </Stack>

                    {/* Start and End Date */}

                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Start Date
                        </div>
                        <div>
                            <DatePicker
                                isRequired={false}
                                allowTextInput={true}
                                showMonthPickerAsOverlay={true}
                                onSelectDate={onSelectStartDate}
                                formatDate={formatDate}
                                value={startDate}
                                showGoToToday={false}
                                styles={customDatePickerStyles}
                                disabled={projectContext?.isBillable}
                            />
                        </div>
                    </Stack>
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Closing Date
                        </div>
                        <div>
                            <DatePicker
                                isRequired={false}
                                allowTextInput={true}
                                showMonthPickerAsOverlay={true}
                                onSelectDate={onSelectEndDate}
                                formatDate={formatDate}
                                value={endDate}
                                showGoToToday={false}
                                styles={customDatePickerStyles}
                                disabled={projectContext?.isBillable}
                            />
                        </div>
                    </Stack>


                    {/* Status */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Status
                        </div>
                        <div style={controlWidth}>
                            <Dropdown
                                selectedKey={selectedStatus ? selectedStatus.key : undefined}
                                onChange={onStatusChange}
                                options={dropDownOptions}
                                styles={dropdownStyles}
                                disabled={projectContext?.isBillable}
                            />
                        </div>
                    </Stack>

                    {/* Project Tags */}
                    <Stack style={assingPadding}>
                        <div style={formText}>
                            Project Tags
                        </div>
                        <div style={tagPickerStyling}>
                            <CustomTagPicker
                                onAddTag={onAddTag}
                                onCategorySelected={onCategorySelected}
                                onResolveSuggestions={onResolveSuggestions}
                                onChange={onChange}
                                selectedItems={selectedTags}
                                scenario="ProjectTags"
                                isBillable={projectContext.isBillable}
                            />
                        </div>
                    </Stack>


                    {/* Buttons */}
                    <Dialog
                        hidden={hideConfirmationDialog}
                        onDismiss={toggleHideConfirmationDialog}
                        dialogContentProps={dialogConfirmationContentProps}
                    >
                        You have some unsaved changes which can be lost if you navigate back. Do you want to navigate back?
                        <DialogFooter>
                            <PrimaryButton onClick={() => goBackAfterConfirmationRedirect()} text="Go Back" />
                            <DefaultButton onClick={toggleHideConfirmationDialog} text="Stay on Page" />
                        </DialogFooter>
                    </Dialog>
                    <Stack horizontal>
                        <Stack>
                            <button onClick={() => createProject()} style={createButtonStyle}>
                                <div style={createButtonText}>{buttonName}</div>
                            </button>
                        </Stack>
                        <Stack>
                            <button style={cancelButtonStyle}>
                                <div style={cancelButtonText} onClick={() => handleCancelClick()}> Cancel </div>
                            </button>
                        </Stack>
                    </Stack>
                </>
            )}
        </Stack>
    )
}

export default CreateProject;