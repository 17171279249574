import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import {
    IRecentDocumentsResponses,
    IRecentDocumentsResponse
} from '../../../../Models/IRecentDocumentsResponse';
import {
    carousel,    
    btnLeft,
    btnIcon,
    btnRight
} from './Styles/Carouse.styles';
import CarouselItem from './CarouselItem';
export interface ICarouseProps {
    items?: IRecentDocumentsResponse[];
}
const Carousel = (carouseProps: ICarouseProps) => {
    const { items } = carouseProps;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [carouselItems, setCarouseItems] = useState<
        IRecentDocumentsResponse[]
    >([]);

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [showLeftCarouselButton, setShowLeftCarouselButton] =
        useState<boolean>(true);
    const [showRightCarouselButton, setShowRightCarouselButton] =
        useState<boolean>(true);
    const ITEMS_TO_SHOW_PER_PAGE: number = 3;
    useEffect(() => {
        const startIndex = pageNumber * ITEMS_TO_SHOW_PER_PAGE;
        const endIndex = startIndex + ITEMS_TO_SHOW_PER_PAGE;
        const carouselItems = items.slice(startIndex, endIndex);
        setCarouseItems(carouselItems);
        if (pageNumber === 0) {
            setShowLeftCarouselButton(false);
        }

        if (items?.length > ITEMS_TO_SHOW_PER_PAGE) {
            setShowRightCarouselButton(true);
        } else {
            setShowRightCarouselButton(false);
        }
    }, []);

    const nextItemsHandler = () => {
        setPageNumber((p) => p + 1);
    };
    const prevItemsHandler = () => {
        setPageNumber((p) => p - 1);
    };

    useEffect(() => {
        const startIndex = pageNumber * ITEMS_TO_SHOW_PER_PAGE;
        const endIndex = startIndex + ITEMS_TO_SHOW_PER_PAGE;
        const carouselItems = items.slice(startIndex, endIndex);
        setCarouseItems(carouselItems);
        if (pageNumber > 0) {
            if (pageNumber * ITEMS_TO_SHOW_PER_PAGE < items?.length && carouselItems.length<ITEMS_TO_SHOW_PER_PAGE) {
                setShowRightCarouselButton(false);
            } else if((pageNumber+1) * ITEMS_TO_SHOW_PER_PAGE >= items?.length && carouselItems.length<=ITEMS_TO_SHOW_PER_PAGE){
                setShowRightCarouselButton(false);
            }else {
                setShowRightCarouselButton(true);
            }
            setShowLeftCarouselButton(true);
        } else if (pageNumber === 0) {
            setShowLeftCarouselButton(false);
            if (ITEMS_TO_SHOW_PER_PAGE < items?.length) {
                setShowRightCarouselButton(true);
            }
        }
    }, [pageNumber]);
    return (
        <Stack style={carousel}>
            <Stack horizontal style={{ }}>
                {carouselItems.map(
                    (item: IRecentDocumentsResponse, index: number) => {
                        return (
                            <Stack
                                tokens={{ childrenGap: 12 }}
                                style={{ marginTop: -12 }}
                            >
                                <CarouselItem item={item} />
                            </Stack>
                        );
                    }
                )}
            </Stack>
            
            <button
                type="reset"
                style={btnLeft(showLeftCarouselButton)}
                onClick={prevItemsHandler}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={btnIcon}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
            </button>
            <button
                type="reset"
                style={btnRight(showRightCarouselButton)}
                onClick={nextItemsHandler}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={btnIcon}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                    />
                </svg>
            </button>
        </Stack>
    );
};

export default Carousel;
