import React, { useState, useEffect, useRef } from 'react';
import { Reducer } from "redux";
import { Icon } from '@fluentui/react/lib/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectAppState } from '../../../../../Shared/ProjectState';
import { ProjectReducerName, projectInitialState, projectReducer } from '../../../../../Shared/Reducers/Project.reducer';
import './QueryDropdown.css'; // Adjust import path as per your file structure
import { useAppContext } from './LitigationFilterContext';
import { requestDeleteUserQuery, requestLitigationUserQuery, requestSaveUserQuery, requestUpdateUserQuery } from '../../../../../Shared/Actions/Project.action';
import { ProjectActionTypes } from '../../../../../Shared/Actions/Project.action-types';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from '../../../../../Shared/Sagas/Project.sagas';
import { Filter, ISaveQueryRequest } from '../../../../../Models/ISaveUserQuery';
import { TooltipHost } from '@fluentui/react';
import { truncateText } from '../../../../../Helpers/ProjectHelper';
import DisplaySpinner from '../../../../../Common/Components/DisplaySpinner';

interface QueryDropdownProps {
    onOptionClick: (userSearchText:string,userViewKey:string, userFolders:string) => void;
    chatReferencesButtonClicked?: boolean;
}

const QueryDropdown: React.FC<QueryDropdownProps> = ({ onOptionClick, chatReferencesButtonClicked }) => {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectContext,
        userQueries,
        isLoadingLitigationUserQuery,
        isLoadingSaveUserQuery,
        isLoadingUpdateUserQuery,
        isLoadingDeleteUserQuery
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const { loginUserpersonaProps } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );
    const { advancefilters, selectView, searchBoxText,selectedFolders, addAdvanceFilters, addSelectView, addSearchText,addSelectedFolders } = useAppContext();

    const [selectedQuery, setSelectedQuery] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSaveOptionsOpen, setIsSaveOptionsOpen] = useState(false); // State to manage save options dropdown
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [isSaveAsClicked, setIsSaveAsClicked] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shareWithMembers, setShareWithMembers] = useState(true); // Default selected checkbox
    const [queries, setQueries] = useState([]);
    const [queryToDelete, setQueryToDelete] = useState(null);
    const [error, setError] = useState('');
    const [editQueryId, setEditQueryId] = useState<number | null>(null); // Track the query being edited
    const dropdownRef = useRef<HTMLDivElement>(null);
    const saveOptionsRef = useRef<HTMLDivElement>(null); // Ref for save options dropdown
    const [showModal, setShowModal] = useState(false);
    const handleButtonClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const convertFirstLetterToLowercase = (obj: any): any => {
        if (Array.isArray(obj)) {
          return obj.map(convertFirstLetterToLowercase);
        } else if (typeof obj === 'object' && obj !== null) {
          return Object.keys(obj).reduce((acc, key) => {
            const newKey = key.charAt(0).toLowerCase() + key.slice(1);
            acc[newKey] = convertFirstLetterToLowercase(obj[key]);
            return acc;
          }, {} as any);
        }
        return obj;
      };

    const handleOptionClick = (queryName: string, queryId: string) => {
        setSelectedQuery(queryName);
        setIsDropdownOpen(false);

        if (queryId) {
            const selectedQuery = queries.find(query => query.queryId === queryId) as ISaveQueryRequest;

            if (selectedQuery) {
                if ((selectedQuery.filters as any) === "[]") {
                    selectedQuery.filters = [];
                }

                if (typeof selectedQuery.filters === 'string' && selectedQuery.filters) {
                    try {
                        selectedQuery.filters = JSON.parse(selectedQuery.filters);
                    } catch (error) {
                      console.error("Failed to parse JSON string:", error);
                      selectedQuery.filters = []; // Fallback to an empty array if parsing fails
                    }
                }

                selectedQuery.filters = selectedQuery.filters?.map(filter => convertFirstLetterToLowercase(filter) as Filter);
                addAdvanceFilters(selectedQuery.filters || []);
                addSelectView(selectedQuery.caseId || '');
                addSearchText(selectedQuery.searchText || '');
                addSelectedFolders(selectedQuery.exportIDs || '');

                if (onOptionClick) {
                    onOptionClick(selectedQuery.searchText,selectedQuery.caseId,selectedQuery.exportIDs);
                }
            }

            
        }

    };

    const handleDeleteClick = (query) => {
        setQueryToDelete(query);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        // Your delete logic here

        dispatch(requestDeleteUserQuery(queryToDelete.queryId, projectContext?.number, loginUserpersonaProps?.secondaryText));
        setShowModal(false);
        setQueryToDelete(null);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setQueryToDelete(null);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
        if (saveOptionsRef.current && !saveOptionsRef.current.contains(event.target as Node)) {
            setIsSaveOptionsOpen(false);
        }
    };

    const handleEscapePress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsDropdownOpen(false);
            setIsSaveOptionsOpen(false);
        }
    };

    const handleSaveView = () => {
        if (selectedQuery && selectedQuery !== 'Saved Queries') {
            const selectedQueryObj = queries.find(query => query.queryName === selectedQuery);
            if (selectedQueryObj && selectedQueryObj.userAlias === loginUserpersonaProps?.secondaryText) {
                setEditQueryId(selectedQueryObj.queryId); // Set the query being edited
                setTitle(selectedQueryObj.queryName); // Pre-populate title
                setDescription(selectedQueryObj.description); // Pre-populate description
            } else {
                setEditQueryId(null); // Reset edit mode
                setTitle(''); // Clear title
                setDescription(''); // Clear description
            }
        } else {
            setEditQueryId(null); // Reset edit mode
            setTitle(''); // Clear title
            setDescription(''); // Clear description
        }
        setIsSaveDialogOpen(true);
        
        document.body.style.overflow = 'hidden'; // Prevent background scrolling
    };

    const handleSaveAsView = () => {
        setEditQueryId(null); // Reset edit mode for Save As
        setTitle(''); // Clear title
        setDescription(''); // Clear description
        setIsSaveDialogOpen(true);
        document.body.style.overflow = 'hidden'; // Prevent background scrolling
    };

    const handleDialogClose = () => {
        setIsSaveDialogOpen(false);
        document.body.style.overflow = 'auto'; // Restore background scrolling
    };

    const handleSave = () => {
        if (!title) {
            setError('Title is mandatory');
            return;
        }
        const saveQuery: ISaveQueryRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            caseId: selectView,
            queryName: title,
            description: description,
            filters: advancefilters,
            searchText: searchBoxText,
            exportIDs: selectedFolders,
            isShared: shareWithMembers
        };
        if (editQueryId !== null) {
            dispatch(requestUpdateUserQuery(saveQuery, loginUserpersonaProps?.secondaryText, projectContext?.number, editQueryId?.toString()));
        } else {
            dispatch(requestSaveUserQuery(saveQuery, loginUserpersonaProps?.secondaryText, projectContext?.number));
        }

        setIsSaveDialogOpen(false);
        document.body.style.overflow = 'auto'; // Restore background scrolling
    };

    const handleEdit = (query) => {
        setEditQueryId(query.queryId); // Set the query being edited
        setTitle(query.queryName);
        setDescription(query.description);
        setIsSaveDialogOpen(true);
        document.body.style.overflow = 'hidden'; // Prevent background scrolling
    };

    const handleSaveIconClick = () => {
        setIsSaveOptionsOpen(!isSaveOptionsOpen);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapePress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapePress);
        };
    }, []);

    useEffect(() => {
        if (loginUserpersonaProps?.secondaryText) {
            dispatch(requestLitigationUserQuery(projectContext?.number, loginUserpersonaProps?.secondaryText));
        }
    }, [loginUserpersonaProps, dispatch, projectContext]);

    useEffect(() => {
        const defaultQuery = {
            queryId: 0,
            projectNumber: '',
            caseId: '',
            userAlias: '',
            queryName: 'Saved Queries',
            description: '',
            filters: [],
            searchText: '',
            exportIDs: '',
            isShared: true
        };

        if (userQueries.length > 0) {
            setQueries([defaultQuery, ...userQueries]);
        } else {
            setQueries([defaultQuery]);
        }
    }, [userQueries]);

    return (
     !chatReferencesButtonClicked && 
        <div className="query-dropdown-container">
            <div className="dropdown-wrapper" ref={dropdownRef}>
                <button
                    className={`dropdown-button ${isDropdownOpen ? 'dropdown-button-open' : ''} ${selectedQuery ? 'dropdown-button-selected' : ''}`}
                    onClick={handleButtonClick}
                >
                   
                    {isLoadingLitigationUserQuery || isLoadingSaveUserQuery || isLoadingUpdateUserQuery || isLoadingDeleteUserQuery ? (
                        <div className="button-spinner">
                            <div className="spinner" aria-label="Loading user queries" aria-live="assertive" />
                            
                        </div>
                    ) : (<div className="dropdown-button-text">
                            <span>
                                {selectedQuery ? (
                                    <TooltipHost content={selectedQuery}>
                                        {truncateText(selectedQuery.trim(), 12, 12)}
                                    </TooltipHost>
                                ) : (
                                    'Saved Queries'
                                )}
                            </span>
                            <Icon iconName="ChevronDown" className="dropdown-icon" />
                        </div>
                    )}
                </button>


                {isDropdownOpen && (
                    <div className="dropdown-content">
                        {/* {selectedQuery && (
                            <div
                                className="dropdown-item"
                                onClick={() => handleOptionClick('', '')}
                            >
                                <span>Saved Queries</span>
                            </div>
                        )} */}
                        {queries.map((query) => (
                            <div
                                key={query.queryId}
                                className="dropdown-item"
                                onClick={() => handleOptionClick(query.queryName, query.queryId)}
                                title={query.description} // Tooltip for description
                            >
                                {/* <span>{query.queryName}</span> */}
                                <TooltipHost content={query.queryName}>
                                    {truncateText(query.queryName?.trim(), 18, 18)}
                                </TooltipHost>
                                {query.userAlias === loginUserpersonaProps?.secondaryText && (
                                    <div className="icon-buttons">
                                        <Icon
                                            iconName="Edit"
                                            onClick={(e) => { e.stopPropagation(); handleEdit(query); }}
                                            className="icon-button"
                                        />
                                        <Icon
                                            iconName="Delete"
                                            onClick={(e) => { e.stopPropagation(); handleDeleteClick(query) }}
                                            className="icon-button"
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

              <div className="save-icon-wrapper" ref={saveOptionsRef}>
                <button className="icon-button-save" onClick={handleSaveIconClick}>
                    <Icon title='Click here to save this view for future reference' iconName="Save" />
                </button>

                {isSaveOptionsOpen && (
                    <div className="save-options-dropdown">
                        {(!selectedQuery || selectedQuery === 'Saved Queries') ? (
                            <div className="dropdown-item" onClick={handleSaveView}>
                                <span>Save</span>
                            </div>
                        ) : (
                            <>
                                {queries.find(query => query.queryName === selectedQuery)?.userAlias === loginUserpersonaProps?.secondaryText && (
                                    
                                    <div className="dropdown-item" onClick={handleSaveView}>
                                        <span>Save</span>
                                    </div>
                                )}
                                <div className="dropdown-item" onClick={handleSaveAsView}>
                                    <span>Save As</span>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            {isSaveDialogOpen && (
                <div className="dialog-overlay">
                    <div className="dialog">
                        <h2>{editQueryId !== null ? 'Edit View' : 'Save View'}</h2>
                        <label htmlFor="title">Title *</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                        {error && <div className="error-message">{error}</div>}
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="shareWithMembers"
                                checked={shareWithMembers}
                                onChange={(e) => setShareWithMembers(e.target.checked)}
                            />
                            <label htmlFor="shareWithMembers">Share the query with other project members to view</label>
                        </div>
                        <div className="button-container">
                            <button className="primary-button" onClick={handleSave}>Save</button>
                            <button className="secondary-button" onClick={handleDialogClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <p>Are you sure you want to delete the user preference?</p>
                        <div className="modal-button-container">
                            <button className="primary-button" onClick={handleConfirmDelete}>Yes</button>
                            <button className="secondary-button" onClick={handleCloseModal}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QueryDropdown;
