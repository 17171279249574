import { ITextFieldStyles } from "@fluentui/react";

export const container: React.CSSProperties =
{
    width: "100%",
    borderRadius: "2px",
    minHeight: 40,
    height: "auto"

}

export const peoplePickerStyle: React.CSSProperties = {
    width: "100%",
    minHeight: 40,
    maxHeight: 64,
    overflowY: "auto",
    overflowX: "hidden",
  };
  export const peoplePickerDescStyles: Partial<ITextFieldStyles> = {
    fieldGroup: {
      width: "100%",
      border: "none !important",
      display: "flex",
      flexDirection: "column",
      
    },
    field: {
        border:"none !important",
      flex: 1,
      padding: "4px 8px",
    },
  };

  export const customStyles = `
  div.ms-BasePicker-text.text {
    /* Apply your custom styles here */
    /* For example: */
    color: red;
    font-weight: bold;
    overflow-y: auto;
    overflow-X:hidden;
    max-height: 64px;
    min-height:40px;
    border:none;
  }
`;

export const reset: React.CSSProperties =
{
    color: "#5B5FC7",
    marginLeft: "-10px",
    marginTop: "2px",
    fontSize: "16px",
}

export const memberPickerStyle: React.CSSProperties =
{
    width: "100%",
    minHeight: 40,

}