import { IIconProps, MessageBar, MessageBarType} from "@fluentui/react";
import * as React from "react";
import { IChatError } from "../../Models/Chat/IChatError";
import './ErrorDisplay.styles.css'
export interface IErrorDisplay {
  source?: string;
  isQuestionResponseError?: boolean;
  error?: IChatError
}
const messageBarIconProps: IIconProps = {
  "aria-label": "click on close icon to dismiss the message bar",
};
const ErrorDisplay = (props: IErrorDisplay): JSX.Element => {
  const { error } = props; 
  const [errorMessage, setErrorMessage] = React.useState<any>();
  const [exceptionMessage, setExceptionMessage] = React.useState<any>();
  const [showMessageBar] = React.useState(true);
  const [statusCode, setStatusCode] = React.useState<number>();  
  React.useEffect(() => {
    if (Object.keys(error).length > 0) {
      let errorStatus: any = error?.errorMessage?.status
      let responseStatus: any = error?.errorMessage?.response?.status
      let responseStatusText: any = error?.errorMessage?.response?.statusText
      if ( error?.error.isAxiosError && error?.error?.message) {
        setErrorMessage(error?.error?.message);
        if(responseStatus===401 ) {
          setStatusCode(responseStatus);
          setErrorMessage(responseStatusText);
        }
      }
      else if(error?.error.isAxiosError && error?.errorMessage?.data?.message) {
        setErrorMessage(error?.errorMessage?.data.message);
      }
      else if(error?.error.isAxiosError && responseStatus===401) {
        setStatusCode(responseStatus);
        setErrorMessage(responseStatusText);
      }
      
      if(responseStatus === 404 ||errorStatus===404 || 
        errorStatus===400 || errorStatus===500 || responseStatus===400 ||
        responseStatus===500 || error?.error?.response?.status===400){
        setStatusCode(error?.error?.response?.status??errorStatus??responseStatus??error?.errorMessage?.statusText);
        setErrorMessage(error?.errorMessage?.data?.Exception??error?.errorMessage?.statusText??error?.error?.message??error?.errorMessage?.messageerror?.errorMessage?.response?.statusText??responseStatusText)
        const exceptionMessage: string = error?.error?.Exception?.toString()??responseStatusText??error?.errorMessage?.statusText??error?.error?.message
        if(exceptionMessage){
          setExceptionMessage(exceptionMessage)
        }
        else{
          setExceptionMessage('The requested information is not available in the retrieved data. Please try another query or topic')
        }
      } 
      else if(errorStatus=== 403 || errorStatus=== 401){
        setStatusCode(401);
        setExceptionMessage(error?.errorMessage?.data??'You do not have permission to view this directory or page.')
        setErrorMessage('You do not have permission to view this directory or page.');
      }
      else if(errorStatus===-1||error?.errorMessage?.toJSON()?.message?.toLowerCase()==='network error'){
        setStatusCode(-1);
      }      //
    }
  }, [error]);

  const displayErrorByErrorStatus = (): JSX.Element => {
    const correlationId = error?.CorrelationId;
    return (
      <>
        {statusCode === 400 && (
          <>
            <div className='error-400'>{errorMessage}</div>
          </>
        )}
        {statusCode === 404 && (
          <>
            <div className='error-404'>{errorMessage}</div>
          </>
        )}
        {statusCode === 401 && (
          <>
            <div className='error-401'>{errorMessage}</div>
            <br />
            <div className='error-401'>
              <a
                href={`mailto:celalmengcrew@microsoft.com?subject=${__APP_NAME__} Error: ${errorMessage}.
                                      &body=An error occurred in  "${__APP_NAME__}". Get more information about this error in app insights using the corelation-id: ${correlationId} and error reason is ${errorMessage} `}>
                Report this issue to CELA Engineering
              </a>
            </div>
          </>
        )}
        {(statusCode === 500 || statusCode === -1) && (
          <>
            <div className='error-500x'>
              An error occurred. Please
              <a href={`mailto:celalmengcrew@microsoft.com?subject=${__APP_NAME__} Error ${errorMessage}.
                                      &body=An error occurred in  "${__APP_NAME__}". Get more information about this error in app insights using the corelation-id: ${correlationId} and reason is ${errorMessage??exceptionMessage} `}>
                Report this issue to CELA Engineering
              </a>
            </div>
          </>
        )}
      </>
    );
  };

  const displayErrorMessage = (): JSX.Element => {
    return (
      <>
        {showMessageBar && (
          <>
            <div className="error-container">              
                <MessageBar
                  messageBarIconProps={messageBarIconProps}                  
                  messageBarType={MessageBarType.error}
                  tabIndex={0}                  
                  isMultiline={false}>
                  {displayErrorByErrorStatus()}
                </MessageBar>             
            </div>
          </>
        )}
      </>
    );
  };
  return <>{error && displayErrorMessage()}</>;
};
export default ErrorDisplay;
