export const genericMessageIds = [
  "projectsLoading",
  "projectsCreating",
  "projectsEditing",
  "myProjectsLoading",
  "dueDateProjectsLoading",
  "deletingFile",
  "deletingProject",
  "deletingMember",
  "updatingMemberStatus",
  "projectsListLoading",
  "loadingUserProfile",
  "updatingProjectStatus",
  "uploadingFileToProject",
  "addingMembersToProject",
  "searchingProject",
  "userNotificationsLoading",
  "loadingDashboard",
  "loadingBookmarkPage",
  "fetchingProjectToEdit",
  "gettingWorkArea",
  "getLitigationCases"
] as const;

export const appActions = [
  "projectSearchAction",
  "projectDueDateAction",
  "projectCreateAction",
  "projectEditAction",
  "projectDetailsLoading",
  "getProjectList",
  "deleteFile",
  "deleteProject",
  "deleteMember",
  "updateMemberStatus",
  "getLoginUserProfile",
  "updateProjectStatus",
  "uploadFileToProject",
  "searchSimilarProject",
  "BannerInformationAction",
  "BannerInformationUserAction",
  "FeatureFlagInformationAction",
  "userNotificationsRequest",
  "getProjectWorkArea",
  "userHistoryView",
  "doingProjectBookmarkToggle",
  "bookmarkView",
  "addProjectToUserHistory",
  "recentDocumentsView",
  "getLitigationCases",
  "getLitigationCaseData",
  "getLitigationCaseFiles",
  "getLitigationFileContent",
  "requestLitigationFeatureFlag",
  "requestCheckIsUserPartOfLitigationSGGroup",
  "litigationSaveUserTags"
] as const;

export const toolTipMessageIds = [] as const;

export const ProjectEventTypeNames = [
  "projectsLoadedSuccess",
  "projectSearchSuccess",
  "projectsCreatedSuccess",
  "projectEditSuccess",
  "projectDeleteSuccess",
  "singleProjectDeleteSuccess",
  "projectsDueDateSuccess",
  "projectDetailsSuccess",
  "fileDeleteSuccess",
  "memberDeleteSuccess",
  "memberStatusUpdateSuccess",
] as const;

export const ProjectEventTypeIds = [
  "projectSearchSuccess",
  "projectCreatingSuccess",
  "projectSearchFailure",
  "projectEditFailure",
  "projectCreationFailure",
  "fileDeleteFailure",
  "memberDeleteFailure",
  "memberStatusUpdateFailure"
] as const;

export const ExcludedGroupAlias = ["celaLegalEngineeringGroup", 
"KMSITOwners",
"CELADMSOwners", 
"CELADMSTestIntellectualProperty", 
"CELADMSTestTaxGroup", 
"CELADMSTestCorporateLaw", 
"CELADMSTestOther", 
"CELADMSTestLitigation", 
"CELADMSProdIntellectualProperty", 
"CELADMSProdTaxGroup", 
"CELADMSProdCorporateLaw", 
"CELADMSProdOther", 
"CELADMSProdLitigation",
"ResponsibleAI"] as const;

export const collapsibleToolTipIds = ["ProjectDetails"] as const;

export const paginationTypeIds = ["recordsPerPage", "limitUsersFromGraph"] as const;

export const celaLegalGroup = ["celaLegalGroup"] as const;

type ToolTipMessageIds = typeof toolTipMessageIds[number];
type GenericMessageIds = typeof genericMessageIds[number];
type ProjectEventTypeNames = typeof ProjectEventTypeNames[number];
type ProjectEventTypeIds = typeof ProjectEventTypeIds[number];
type PaginationTypeId = typeof paginationTypeIds[number];
type AppActionIds = typeof appActions[number];
type ExcludedGroupAlias = typeof ExcludedGroupAlias[number];
type CollapsibleToolTipId = typeof collapsibleToolTipIds[number];
type CelaLegalGroupIDs = typeof celaLegalGroup[number];

export const AppActions: Record<AppActionIds, string> = {
  projectSearchAction: "ProjectSearch_View",
  projectDueDateAction: "ProjectDueDate_View",
  projectCreateAction: "ProjectCreation_Action",
  projectEditAction: "ProjectEdit_Action",
  projectDetailsLoading: "ProjectDetails_View",
  deleteFile: "DeleteFile_Action",
  deleteProject: "DeleteProject_Action",
  deleteMember: "DeleteMember_Action",
  updateMemberStatus: "UpdateMemberStatus_Action",
  getProjectList: "getProjectList_Action",
  getLoginUserProfile: "GetLoginUserProfile_Action",
  updateProjectStatus: "UpdateProjectStatus_Action",
  uploadFileToProject: "UploadFileToProject_Action",
  searchSimilarProject: "SimilarProjectSearch_Action",
  BannerInformationAction: "BannerInformation_Action",
  BannerInformationUserAction: "BannerInformationUser_Action",
  FeatureFlagInformationAction: "FeatureFlagInformationAction",
  userNotificationsRequest: "UserNotificationsRequest",
  getProjectWorkArea: "ProjectWorkArea_Action",
  userHistoryView: "UserHistoryView_View",
  doingProjectBookmarkToggle: "DoingProjectBookmarkToggle_Action",
  bookmarkView: "BookmarkView_View",
  addProjectToUserHistory: "AddProjectToUserHistory_Action",
  recentDocumentsView: "RecentDocumentsView_Action",
  getLitigationCases: "GetLitigationCases_Action",
  getLitigationCaseData: "GetLitigationCaseData_Action",
  getLitigationCaseFiles: "GetLitigationCaseFiles_Action",
  getLitigationFileContent: "GetLitigationFileContent_Action",
  requestLitigationFeatureFlag: "RequestLitigationFeatureFlag_Action",
  requestCheckIsUserPartOfLitigationSGGroup: "RequestCheckIsUserPartOfLitigationSGGroup_Action",
  litigationSaveUserTags: "RequestLitigationSaveUserTags_Action",
};

export const ToolTipMessages: Record<ToolTipMessageIds, string> = {};

export const PaginationType: Record<PaginationTypeId, string> = {
  recordsPerPage: "10",
  limitUsersFromGraph: "10",
};

export const excludedGroups: Record<ExcludedGroupAlias, string> = {
  celaLegalEngineeringGroup: "celalmengcrew@microsoft.com",
  KMSITOwners: "kmsit@microsoft.onmicrosoft.com",
  CELADMSOwners:"CELADMS@microsoft.onmicrosoft.com",
  CELADMSTestIntellectualProperty:'CELADMSTest-IntellectualProperty@microsoft.onmicrosoft.com',
  CELADMSTestTaxGroup:'CELADMSTest-TaxGroup@microsoft.onmicrosoft.com', 
  CELADMSTestCorporateLaw:'CELADMSTest-CorporateLaw@microsoft.onmicrosoft.com', 
  CELADMSTestOther:'CELADMSTest-Other@microsoft.onmicrosoft.com',
  CELADMSTestLitigation:'CELADMSTest-Litigation@microsoft.onmicrosoft.com',
  CELADMSProdIntellectualProperty:'CELADMS-IntellectualProperty@microsoft.onmicrosoft.com',
  CELADMSProdTaxGroup:'CELADMS-TaxGroup@microsoft.onmicrosoft.com', 
  CELADMSProdCorporateLaw:'CELADMS-CorporateLaw@microsoft.onmicrosoft.com', 
  CELADMSProdOther:'CELADMS-Other@microsoft.onmicrosoft.com',
  CELADMSProdLitigation:'CELADMS-Litigation@microsoft.onmicrosoft.com',
  ResponsibleAI:'ResponsibleAI@microsoft.com'
};

export const CelaLegalGroupNames: Record<CelaLegalGroupIDs, string> = {
  celaLegalGroup: "lca@microsoft.com",
};

export const GenericMessages: Record<GenericMessageIds, string> = {
  projectsLoading: "Loading project details...",
  projectsCreating: "Creating the project...",
  projectsEditing: "Editing project details...",
  myProjectsLoading: "Loading my projects...",
  dueDateProjectsLoading: "Loading projects due dates...",
  deletingFile: "Deleting file...",
  deletingProject: "Deleting project(s)...",
  deletingMember: "Deleting member...",
  updatingMemberStatus: "Updating member status...",
  projectsListLoading: "Loading assigned projects...",
  loadingUserProfile: "Loading user profile...",
  updatingProjectStatus: "Updating project status...",
  uploadingFileToProject: "Uploading file(s) to project...",
  addingMembersToProject: "Adding member(s) to project...",
  searchingProject: "Searching similar projects...",
  userNotificationsLoading: "Loading user notifications...",
  loadingDashboard: "Loading user dashboard...",
  loadingBookmarkPage : "Loading bookmarks...",
  fetchingProjectToEdit: "Fetching project details to edit...",
  gettingWorkArea: "Getting work area...",
  getLitigationCases: "Getting litigation cases...",
};

export const ProjectEventNames: Record<ProjectEventTypeNames, string> = {
  projectSearchSuccess: "ProjectSearchSuccess",
  projectsLoadedSuccess: "Projects loaded successfully",
  projectsCreatedSuccess: "Projects created Successfully",
  projectEditSuccess: "Projects edited Successfully",
  projectDeleteSuccess: "Selected project(s) deleted successfully",
  singleProjectDeleteSuccess: "Project deleted successfully",
  projectsDueDateSuccess: "projectsDueDateFetchedSuccessfully",
  projectDetailsSuccess: "projectDetailsFetchedSuccessfully",
  fileDeleteSuccess: "File Deleted Successfully",
  memberDeleteSuccess: "Member Deleted Successfully",
  memberStatusUpdateSuccess: "Member Status Updated Successfully",
};

export const ProjectEventIds: Record<ProjectEventTypeIds, string> = {
  projectSearchSuccess: "ProjectSearchSuccess",
  projectCreatingSuccess: "ProjectCreatingSuccess",
  projectSearchFailure: "ProjectSearchFailure",
  projectEditFailure: "ProjectEditFailure",
  projectCreationFailure: "ProjectCreationFailure",
  fileDeleteFailure: "FileDeleteFailure",
  memberDeleteFailure: "MemberDeleteFailure",
  memberStatusUpdateFailure: "MemberStatusUpdateFailure",
};

export const CollapsibleToolTips: Record<CollapsibleToolTipId, string> = {
  ProjectDetails: "Project details view",
};
