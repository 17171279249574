import { IDropdownStyles } from "@fluentui/react/lib/Dropdown";

export const container: React.CSSProperties =
{
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    // borderRadius: "5px",
    // minHeight: "15%",
    paddingBottom: 10
}

export const projectTitle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "#000000",
   
}

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" ,border:"none",backgroundColor:"transparent"},
    title:{border:"none"}
};

export const dropdownStyle: React.CSSProperties = {
    width: 120,
    marginTop:-5,
    marginLeft:5
};


export const opennumber: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    color: "#237B4B",
    cursor: "pointer"
}

export const statustext: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    cursor: "pointer",
    marginLeft:8
}
export const statustext2: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    cursor: "pointer",
    
}
export const paddingTop: React.CSSProperties =
{
    paddingTop: "12px"
}
export const closenumber: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "40px",
    color: "#616161",
    cursor: "pointer"
}

export const titleStyle: React.CSSProperties =
{
    marginLeft: 16,
    marginTop: 16
}

export const numberStyle: React.CSSProperties =
{
    marginLeft: 16,
    marginTop: 10
}

export const closeNumberStyle: React.CSSProperties =
{
    marginLeft: 35
}

export const seeProjects: React.CSSProperties =
{
    marginLeft: 16,
}

export const seeProjectsText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
   
    marginTop:10,
    cursor:"pointer"
}
