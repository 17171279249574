import { IUser } from '@micro-frontend-react/employee-experience/lib/IUser';
import { IChatQuestionRequest } from '../../Models/Chat/IChatQuestionRequest';
import { ITopic, ITopicResponses } from '../../Models/Chat/ITopic';
import { IChatFeedbackRequest } from '../../Models/Chat/IChatFeedbackRequest';
import { ISystemPromptsList } from '../../Models/Chat/ISystemPrompts';
import { IUserChatLogsRequest } from '../../Models/Chat/IUserChatLogsRequest';
import { IUserChatLogsResponse } from '../../Models/Chat/IUserChatLogsResponse';
import { IChatTopicDeleteRequest } from '../../Models/Chat/IChatTopicDeleteRequest';
import { IChatTopicDeleteResponse } from '../../Models/Chat/IChatTopicDeleteResponse';
import { IChatTopicEditRequest } from '../../Models/Chat/IChatTopicEditRequest';
import { IChatTopicEditResponse } from '../../Models/Chat/IChatTopicEditResponse';
import { IChatTopicRequest } from 'src/Models/Chat/IChatTopicRequest';
export enum ChatActionTypes {
	NEW_QUESTION_REQUEST = 'NEW_QUESTION_REQUEST',
	NEW_ANSWER_RESPONSE = 'NEW_ANSWER_RESPONSE',
	NEW_ANSWER_RESPONSE_ERROR = 'NEW_ANSWER_RESPONSE_ERROR',

	NEW_DEFAULT_QUESTION_REQUEST = 'NEW_DEFAULT_QUESTION_REQUEST',
	NEW_DEFAULT_ANSWER_RESPONSE = 'NEW_DEFAULT_ANSWER_RESPONSE',
	NEW_DEFAULT_ANSWER_RESPONSE_ERROR = 'NEW_DEFAULT_ANSWER_RESPONSE_ERROR',

	IS_NEW_TOPIC = "IS_NEW_TOPIC",
	NEW_USER_PROMPT = "NEW_USER_PROMPT",
	RETRY_USER_PROMPT = "RETRY_USER_PROMPT",
	IS_FEEDBACK_SUBMISSION_SUCCESS = "IS_FEEDBACK_SUBMISSION_SUCCESS",
	REQUEST_SYSTEM_PROMPTS = "REQUEST_SYSTEM_PROMPTS",
	RESPONE_SYSTEM_PROMPTS = "RESPONE_SYSTEM_PROMPTS",
	RESPONSE_SYSTEM_PROMPTS_ERROR = "RESPONSE_SYSTEM_PROMPTS_ERROR",
	TOPIC_IN_CONTEXT = "TOPIC_IN_CONTEXT",
	SHOULD_CLEAR_CHAT_RESPONSE = "SHOULD_CLEAR_CHAT_RESPONSE",
	SHOULD_SHOW_CHAT_LOGS = "SHOULD_SHOW_CHAT_LOGS",
	SHOULD_DISPLAY_CHAT_VIEW = "SHOULD_DISPLAY_CHAT_VIEW",
	RESPONSE_CHAT_LOGS = "RESPONSE_CHAT_LOGS",
	REQUEST_CHAT_LOGS = "REQUEST_CHAT_LOGS",
	RESPONSE_ERROR_CHAT_LOGS = "RESPONSE_ERROR_CHAT_LOGS",

	RESPONSE_DELETE_CHAT_TOPIC = "RESPONSE_DELETE_CHAT_TOPIC",
	REQUEST_DELETE_CHAT_TOPIC = "REQUEST_DELETE_CHAT_TOPIC",
	RESPONSE_ERROR_DELETE_CHAT_TOPIC = "RESPONSE_ERROR_DELETE_CHAT_TOPIC",

	RESPONSE_EDIT_CHAT_TOPIC = "RESPONSE_EDIT_CHAT_TOPIC",
	REQUEST_EDIT_CHAT_TOPIC = "REQUEST_EDIT_CHAT_TOPIC",
	RESPONSE_ERROR_EDIT_CHAT_TOPIC = "RESPONSE_ERROR_EDIT_CHAT_TOPIC",

	REQUEST_GET_CHAT_TOPIC = 'REQUEST_GET_CHAT_TOPIC',
	RESPONSE_GET_CHAT_TOPIC = 'RESPONSE_GET_CHAT_TOPIC',
	RESPONSE_ERROR_GET_CHAT_TOPIC = 'RESPONSE_ERROR_GET_CHAT_TOPIC',
}

export type ChatActions = IRequestNewAnswer | IResponseNewAnswer | IResponseErrorNewAnswer | IRequestIsNewTopic | ISetnewUserPrompt | IRetryUserPrompt | IFeedbackSubmissionSuccess | IRequestSystemPrompts | IResponseSystemPrompts | IResponseSystemPromptsError | IShouldClearChatResponse | ISetTopicInContext | IShouldShowChatLogs | IShouldDisplayChatView | IResponseUserChatLogs
	| IRequestUserChatLogs
	| IResponseErrorUserChatLogs | IRequestDeleteChatTopic
	| IResponseDeleteChatTopic
	| IResponseErrorDeleteChatTopic
	| IRequestEditChatTopic
	| IResponseEditChatTopic
	| IResponseErrorEditChatTopic
	| IResponseGetChatTopic
	| IResponseErrorGetChatTopic
	| IRequestGetChatTopic
	| IRequestDefaultNewAnswer
	| IResponseDefaultNewAnswer
	| IResponseDefaultErrorNewAnswer

export interface IRequestNewAnswer {
	type: ChatActionTypes.NEW_QUESTION_REQUEST;
	chatQuestionRequest: IChatQuestionRequest;
}

export interface IResponseNewAnswer {
	type: ChatActionTypes.NEW_ANSWER_RESPONSE;
	topic: ITopic;
}

export interface IResponseErrorNewAnswer {
	type: ChatActionTypes.NEW_ANSWER_RESPONSE_ERROR;
	error: Error;
	errorMessage: string;
	CorrelationId: string;
}

export interface IRequestDefaultNewAnswer {
	type: ChatActionTypes.NEW_DEFAULT_QUESTION_REQUEST;
	chatQuestionRequest: IChatQuestionRequest;
}

export interface IResponseDefaultNewAnswer {
	type: ChatActionTypes.NEW_DEFAULT_ANSWER_RESPONSE;
	topic: ISystemPromptsList;
}

export interface IResponseDefaultErrorNewAnswer {
	type: ChatActionTypes.NEW_DEFAULT_ANSWER_RESPONSE_ERROR;
	error: Error;
	errorMessage: string;
	CorrelationId: string;
}

export interface IRequestIsNewTopic {
	type: ChatActionTypes.IS_NEW_TOPIC;
	isNewTopic: boolean;
}


export interface ISetnewUserPrompt {
	type: ChatActionTypes.NEW_USER_PROMPT;
	newUserPrompt: string;
}

export interface IRetryUserPrompt {
	type: ChatActionTypes.RETRY_USER_PROMPT;
	shouldRetryUserPrompt: boolean;
}

export interface IFeedbackSubmissionSuccess {
	type: ChatActionTypes.IS_FEEDBACK_SUBMISSION_SUCCESS;
	isFeedbackSubmissionSuccess: boolean;
	feedbackRequest: IChatFeedbackRequest;
}

export interface IRequestSystemPrompts {
	type: ChatActionTypes.REQUEST_SYSTEM_PROMPTS;
}

export interface IResponseSystemPrompts {
	type: ChatActionTypes.RESPONE_SYSTEM_PROMPTS;
	systemPrompts: ISystemPromptsList;
}

export interface IResponseSystemPromptsError {
	type: ChatActionTypes.RESPONSE_SYSTEM_PROMPTS_ERROR;
	error: Error;
	errorMessage: string;
	CorrelationId?: string;
}

export interface ISetTopicInContext {
	type: ChatActionTypes.TOPIC_IN_CONTEXT;
	topicIdInContext: string;
	topicUser: string;
}

export interface IShouldClearChatResponse {
	type: ChatActionTypes.SHOULD_CLEAR_CHAT_RESPONSE;
}

export interface IShouldShowChatLogs {
	type: ChatActionTypes.SHOULD_SHOW_CHAT_LOGS;
	showChatLogs: boolean;
}

export interface IShouldDisplayChatView {
	type: ChatActionTypes.SHOULD_DISPLAY_CHAT_VIEW;
	shouldShowChatView: boolean;
}

export interface IRequestUserChatLogs {
	type: ChatActionTypes.REQUEST_CHAT_LOGS;
	userChatLogsRequest: IUserChatLogsRequest;
}
export interface IResponseUserChatLogs {
	type: ChatActionTypes.RESPONSE_CHAT_LOGS;
	userChatLogsResponse: IUserChatLogsResponse[];
}
export interface IResponseErrorUserChatLogs {
	type: ChatActionTypes.RESPONSE_ERROR_CHAT_LOGS;
	error: Error;
	errorMessage: string;
	CorrelationId: string;
}

export interface IRequestDeleteChatTopic {
	type: ChatActionTypes.REQUEST_DELETE_CHAT_TOPIC;
	chatTopicDeleteRequest: IChatTopicDeleteRequest;
}

export interface IResponseDeleteChatTopic {
	type: ChatActionTypes.RESPONSE_DELETE_CHAT_TOPIC;
	chatTopicDeleteResponse: IChatTopicDeleteResponse;
}

export interface IResponseErrorDeleteChatTopic {
	type: ChatActionTypes.RESPONSE_ERROR_DELETE_CHAT_TOPIC;
	error: Error;
	errorMessage: string;
	CorrelationId: string;
}

export interface IRequestEditChatTopic {
	type: ChatActionTypes.REQUEST_EDIT_CHAT_TOPIC;
	chatTopicEditRequest: IChatTopicEditRequest;
}

export interface IResponseEditChatTopic {
	type: ChatActionTypes.RESPONSE_EDIT_CHAT_TOPIC;
	chatTopicEditResponse: IChatTopicEditResponse;
}

export interface IResponseErrorEditChatTopic {
	type: ChatActionTypes.RESPONSE_ERROR_EDIT_CHAT_TOPIC;
	error: Error;
	errorMessage: string;
	CorrelationId: string;
}

export interface IRequestGetChatTopic {
	type: ChatActionTypes.REQUEST_GET_CHAT_TOPIC;
	chatTopicRequest: IChatTopicRequest;
}

export interface IResponseGetChatTopic {
	type: ChatActionTypes.RESPONSE_GET_CHAT_TOPIC;
	topicResponses: ITopicResponses;
}

export interface IResponseErrorGetChatTopic {
	type: ChatActionTypes.RESPONSE_ERROR_GET_CHAT_TOPIC;
	error: Error;
	errorMessage: string;
	CorrelationId: string;
}
