import React, { useEffect, useState } from 'react'
import { Reducer } from "redux";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { IChatFeedbackRequest } from '../../../../Models/Chat/IChatFeedbackRequest';
import { Stack } from '@fluentui/react';
import {feedbackSubmissionSuccess} from '../../../../Shared/Actions/Chat.action'
import DownVote from './DownVote';
import UpVote from './UpVote';
import './Feedback.styles.css'
import FeedbackForm from './FeedbackForm';
import ProgressBar from '../../../../Common/Components/ProgressBar/ProgressBar';
import { ChatAPI } from '../../../../Services/ChatAPI';
import { v4 as uuidv4 } from "uuid";
import { ChatProviderContext } from "../ChatDisplay/ChatConversations";
import { LitigationContext } from "../ChatHome";
export interface IFeedbackProps {
  topicId?: string;
  QNAId?: string;
  userAlias?: string;
  answer?: string;
  feedbackText?: string;
  answerid?: string;
}


const Feedback = (feedbackProps: IFeedbackProps) => {
  const { user } = React.useContext(LitigationContext);
  const { chatAIToken } = React.useContext(ChatProviderContext);
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, httpClient, dispatch } = reduxContext;
  

  const [showFeedbackUI, setShowFeedbackUI] = useState<boolean>(false);
  const [showNegativeFeedbackUI, setShowNegativeFeedbackUI] = useState<boolean>(false);
  const [selectedUPButton, setSelectedUPButton] = useState<string>("");
  const [selectedDownButton, setSelectedDownButton] = useState<string>("");


  const [isPositiveFeedback, setIsPositiveFeedback] = useState<boolean>(false);
  const [isSubmitfeedBackSuccess, setIsSubmitfeedBackSuccess] = useState<boolean>(false);
  const [isSubmiFeedbackLoading, setIsSubmiFeedbackLoading] = useState<boolean>(false);
  const [isSubmitFeedbackError, setIsSubmitFeedbackError] = useState<boolean>(false);
  const { topicId, QNAId, feedbackText, answerid } = feedbackProps;


  const handleUpVoteHandler = () => {
    setIsPositiveFeedback(true);
    setShowFeedbackUI(!showFeedbackUI);
    setSelectedUPButton("up");
    setSelectedDownButton("");
  };

  const handleDownVoteHandler = () => {
    setIsPositiveFeedback(false);
    setShowNegativeFeedbackUI(!showNegativeFeedbackUI);
    setSelectedDownButton("down");
    setSelectedUPButton("");
  };

  const userFeedbackCloseClickHandler = () => {
    setIsPositiveFeedback(false);
    setShowFeedbackUI(false);
    setShowNegativeFeedbackUI(false);
    setSelectedUPButton("");
    setSelectedDownButton("");
  };

  useEffect(() => {
    if (!showFeedbackUI) {
      setSelectedUPButton("");
    }
    if (!showNegativeFeedbackUI) {
      setSelectedDownButton("");
    }
  }, [showFeedbackUI, showNegativeFeedbackUI]);

  const submitFeedbackHandler = async (chatRequestFeedback: IChatFeedbackRequest) => {
    try {
      setIsSubmitfeedBackSuccess(false);
      chatRequestFeedback.topicId = topicId;
      chatRequestFeedback.QNAId = QNAId;
      chatRequestFeedback.isPositiveFeedback = isPositiveFeedback;
      chatRequestFeedback.userAlias = user?.secondaryText;
      chatRequestFeedback.answerId = answerid;
      chatRequestFeedback.appName = __APP_NAME__
      setIsSubmiFeedbackLoading(true);
      await ChatAPI.requestFeedBackSubmission(chatRequestFeedback, httpClient, uuidv4(), chatAIToken);
      dispatch(feedbackSubmissionSuccess(true,chatRequestFeedback))
      setIsSubmitfeedBackSuccess(true);
      setShowFeedbackUI(false);
      setShowNegativeFeedbackUI(false);
    }
    catch (error: any) {
      dispatch(feedbackSubmissionSuccess(false,chatRequestFeedback))
      setIsSubmitFeedbackError(true);
      setIsSubmitfeedBackSuccess(false);
    } finally {
      setIsSubmiFeedbackLoading(false);
      
    }
  };
  
  return (
    <>
      {isSubmiFeedbackLoading && !isSubmitFeedbackError && (
        <>
          <ProgressBar progressbarText='Feedback is getting submitted...'/>
        </>
      )}
      
      {!isSubmiFeedbackLoading && (
        <Stack className='feedback-container'>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack className='feedback-text'>
              {feedbackText && <span>{feedbackText}</span>}
              {!feedbackText && <span style={{ marginLeft: 180 }}> </span>}
            </Stack>
            <Stack className='feedback-icon' horizontal>
              <Stack>
                <UpVote upVoteClick={handleUpVoteHandler} selectedButton={selectedUPButton} />
              </Stack>
              <Stack>
                <DownVote downVoteClick={handleDownVoteHandler} selectedButton={selectedDownButton} />
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            {(showFeedbackUI || showNegativeFeedbackUI)  && (
              <FeedbackForm
                isPositivefeedback={isPositiveFeedback}
                userFeedbackCloseClick={userFeedbackCloseClickHandler}
                submitFeedbackClick={submitFeedbackHandler}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  )
}


export default Feedback 