import { Stack, Icon, TextField, Checkbox, DefaultButton } from '@fluentui/react';
import { IChatFeedbackRequest } from '../../../../Models/Chat/IChatFeedbackRequest';
import React, {useState, useEffect} from 'react'
import DownVoteSVG from './DownVoteSVG';
import UpVoteSVG from './UpVoteSVG';
import './FeedbackForm.styles.css'

export interface IFeedbackFormProps {
	isPositivefeedback?: boolean;
	userFeedbackCloseClick(): void;
	submitFeedbackClick(responseUserFeedback: IChatFeedbackRequest): void;
}

const FeedbackForm = (feedbackFormProps: IFeedbackFormProps) => {
  const { isPositivefeedback, userFeedbackCloseClick, submitFeedbackClick } = feedbackFormProps;
  const [inputFeedback, setInputFeedback] = useState<string>('');
  const [placeHolder, setPlaceHolder] = useState<string>('');
  const [feedbackUserErrorTypes, setFeedbackUserErrorTypes] = useState<string[]>([]);

  const submitFeedbackHandler = () => {
		const responseUserFeedback: IChatFeedbackRequest = {
			feedbackText: inputFeedback,
			feedbackUserErrorTypes: feedbackUserErrorTypes			
		};
		submitFeedbackClick(responseUserFeedback);
	};

  const handleInputChange = React.useCallback((event: any, newValue?: string) => {
		setInputFeedback(event.target.value || '');
	}, []);

  const _onCheckChangehandler = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
		const target = ev.target as HTMLInputElement;
		const element = target?.parentElement?.children[1] as HTMLElement;
		const feedbackUserErrorType = element?.innerText?.trim();
		if (isChecked) {
			const newFeedbackUserErrorTypes = [...feedbackUserErrorTypes];
			const errorExists = newFeedbackUserErrorTypes.some(data => data.toLocaleLowerCase().trim() === feedbackUserErrorType.toLocaleLowerCase().trim());
			if (!errorExists) {
				newFeedbackUserErrorTypes.push(feedbackUserErrorType);
			}
			setFeedbackUserErrorTypes(newFeedbackUserErrorTypes);
		} else if (!isChecked) {
			const newFeedbackUserErrorTypes = [...feedbackUserErrorTypes];
			let filteredArray = newFeedbackUserErrorTypes.filter(item => item.toLocaleLowerCase().trim() !== feedbackUserErrorType.toLocaleLowerCase().trim());
			setFeedbackUserErrorTypes(filteredArray);
		}
	};

  const closeFeedbackUIHandler = () => {
		userFeedbackCloseClick();
	};

  const handleKeyBoardEvent = (event: any) => {
		if (event.key === 'Enter') {
			userFeedbackCloseClick();
		}
	};

  return (
    <>
			<Stack horizontal className='feedback-options-container' tokens={{ childrenGap: 20 }}>
				<Stack>
					{isPositivefeedback && (
						<Stack>
							<UpVoteSVG />
						</Stack>
					)}
					{!isPositivefeedback && <DownVoteSVG />}
					<span className='additional-feedback'>Provide additional feedback</span>
				</Stack>

				<Stack style={{ marginLeft: 'auto', marginRight: 5 }}>
					<Icon iconName="ChromeClose" tabIndex={0} onClick={closeFeedbackUIHandler} style={{ cursor: 'pointer' }} onKeyUp={(event: any) => handleKeyBoardEvent(event)} />
				</Stack>
			</Stack>
			<Stack className='text-input-feedback'>
				<TextField multiline autoAdjustHeight onChange={handleInputChange} value={inputFeedback} placeholder={placeHolder}/>
			</Stack>
			<Stack>
				{!isPositivefeedback && (
					<Stack className='feedback-options-container'>
						<Stack>
							<Checkbox label="This is harmful / unsafe" onChange={_onCheckChangehandler} />
						</Stack>
						<Stack className='feedback-options-container'>
							<Checkbox label="This isn't true" onChange={_onCheckChangehandler} />
						</Stack>
						<Stack className='feedback-options-container'>
							<Checkbox label="This isn't helpful" onChange={_onCheckChangehandler} />
						</Stack>
					</Stack>
				)}
			</Stack>
			<Stack>
				<DefaultButton className='feedback-submit' onClick={submitFeedbackHandler}>
					Submit feedback
				</DefaultButton>
			</Stack>
		</>
  )
}


export default FeedbackForm