import { IAuthClient } from "@micro-frontend-react/employee-experience/lib/IAuthClient";
import { ITelemetryClient } from "@micro-frontend-react/employee-experience/lib/ITelemetryClient";
import { ProjectReducerName } from "../Shared/Reducers/Project.reducer";
import {v4 as uuidv4} from 'uuid';
type LogData = {
  BusinessProcessName: string;
  AppAction: string;
  EventOccurenceTime: Date;
  SessionId: string;
  EventType?: string;
  EventId: number;
  EventResult?: string;
  "X-CorrelationId"?: string,
  RequestPayLoad?: any,
  Exception?:any;
  UserAlias?: string,
  retryData?: any
};

export const getProjectStateBusinessProcessProperties = (state: any): any => {
  const correlationUUID = uuidv4();
  const logData = {};
  if (state.dynamic && state.dynamic[ProjectReducerName]) {
    const userTeamsProfile = state.dynamic[ProjectReducerName].loginUserpersonaProps;
    const  userProfile  = userTeamsProfile;
    if (userProfile) {
      Object.assign(logData, { UserAlias: userTeamsProfile.secondaryText, correlationId: correlationUUID});
    }
  }

  return logData;
};

export const getContextCommonTelemetryProperties = (
  authClient: IAuthClient,
  telemetryClient: ITelemetryClient,
  appAction: string,
  eventName: string,
  eventId: number
): any => {
  const occurenceTime = new Date();
  const correlationId = telemetryClient.getCorrelationId();
  const logData = {
    AppAction: appAction,
    EventOccurenceTime: occurenceTime,
    SessionId: correlationId,
    EventId: eventId,
    EventName: eventName,
    ComponentType: "Web",
  };
  return logData;
};

export const getProjectContextBusinessProcessProperties = (
  telemetryClient: ITelemetryClient,
  businessProcessName: string,
  appAction: string,
  eventId: number,
  eventResult?: string,
  stateBusinessProcessProperties?: any,
  additionalProperties?:any,
  exception?: any
): LogData => {
  const occurenceTime = new Date();
  const logData: LogData = {
    BusinessProcessName: businessProcessName,
    AppAction: appAction,
    EventOccurenceTime: occurenceTime,
    SessionId: telemetryClient.getCorrelationId(),
    EventId: eventId,
    EventResult: eventResult,
    "X-CorrelationId":stateBusinessProcessProperties?.correlationId,
    RequestPayLoad: additionalProperties,
    Exception: exception??"",
    UserAlias: stateBusinessProcessProperties?.UserAlias
  };
  return logData;
};

export const getProjectContextBusinessProcesRetryProperties = (
  telemetryClient?: ITelemetryClient,
  businessProcessName?: string,
  appAction?: string,
  eventId?: number,
  retryData?: any
): LogData => {
  const occurenceTime = new Date();
  const logData: LogData = {
    BusinessProcessName: businessProcessName,
    AppAction: appAction,
    EventOccurenceTime: occurenceTime,
    SessionId: telemetryClient.getCorrelationId(),
    EventId: eventId,
    retryData: retryData,
  };
  return logData;
};

export const trackProjectRetryAPIEvents = (
  telemetryClient: ITelemetryClient,
  businessProcessName: string,
  appAction: string,  
  eventId?: number,
  retryData?: any
): void => {
  const contextBusinessProcessProperties = getProjectContextBusinessProcesRetryProperties(
    telemetryClient,
    businessProcessName,
    appAction,
    eventId,
    retryData    
  ); 
  telemetryClient.trackCustomEvent(
    {
      name: appAction,
    },
    contextBusinessProcessProperties
  );
};



export const trackProjectEvents = (
  telemetryClient: ITelemetryClient,
  businessProcessName: string,
  appAction: string,
  stateBusinessProcessProperties: any,
  eventId?: number,
  eventResult?: string,
  additionalProperties: any = {}
): void => {
  const contextBusinessProcessProperties = getProjectContextBusinessProcessProperties(
    telemetryClient,
    businessProcessName,
    appAction,
    eventId,
    eventResult,
    stateBusinessProcessProperties,
    additionalProperties
  ); 
  telemetryClient.trackCustomEvent(
    {
      name: appAction,
    },
    contextBusinessProcessProperties
  );
};

export const trackProjectException = (
  telemetryClient: ITelemetryClient,
  businessProcessName: string,
  appAction: string,
  stateCommonProperties: any,
  exception: Error,
  eventId?: number,
  eventResult?: string,
  additionalProperties: any = {}
): void => {
  try {
    const contextCommonProperties = getProjectContextBusinessProcessProperties(
      telemetryClient,
      businessProcessName,
      appAction,
      eventId,
      eventResult,
      stateCommonProperties,
      additionalProperties,
      exception
    );
    
    telemetryClient.trackCustomEvent(
      {
        name: appAction,
      },
      contextCommonProperties
    );

    telemetryClient.trackException({ exception: exception, properties: contextCommonProperties });
  } catch (error) {

  }
};
