export enum ProjectTrackingEventId {
    ProjectSearchSuccess = 200,
    projectsLoadedSuccess,
    projectsCreatedSuccess,
    projectsEditSuccess,
    projectsDueDateSuccess,
    projectDetailsSuccess,
    fileDeleteSuccess,
    memberDeleteSuccess,
    memberStatusUpdateSuccess,
    GetLoginUserSucess,
    projectStatusUpdateSuccess,
    fileUploadToProjectSuccess,
    getSimilarProjectSuccess,
    projectDeleteSuccess,
    projectsListSuccess,
    BannerActionSuccess,
    BannerActionUpdateSuccess,
    FeatureFlagActionSuccess,
    UserNotificationActionSuccess,
    GetProjectWorkAreaSuccess,
    GetUserHistorySuccess,
    toggleBookmarkSuccess,
    GetBookmarkSuccess,
    AddProjectToHistorySuccess,
    ProjectFileTagsEditUpdateSuccess,
    RecentDocumentsViewSuccess,
    GetLitigationCasesSuccess,
    GetLitigationCaseDataSuccess,
    GetLitigationCaseFilesSuccess,
    GetLitigationFileContentSuccess,
    GetLitigationFeatureFlagSuccess,
    UserCheckSuccess,
    GetLitigationUserQuerySuccess,
    SaveUserQuerySuccess,
    LitigationSaveUserTags,
    DeleteUserQuerySuccess,
    ClassifyCaseFileSuccess,
    ClassifySummaryByUserSuccess,
    HistoryFileSuccess
}

export enum ProjectTrackingFailureEventId {
    projectSearchFailure = 400,
    projectCreationFailure,
    projectsDueDateFailure,
    projectDetailsFailure,
    fileDeleteFailure,
    memberDeleteFailure,
    memberStatusUpdateFailure,
    projectEditFailure,
    GetLoginUserFailure,
    projectStatusUpdateFailure,
    fileUploadToProjectFailure,
    getSimilarProjectFailure,
    projectDeleteFailure,
    projectsListFailure,
    BannerActionFailure,
    BannerActionUpdateFailure,
    FeatureFlagActionFailure,
    UserNotificationActionFailure,
    GetProjectWorkAreaFailure,
    GetUserHistoryFailure,
    toggleBookmarkFailure,
    GetBookmarkFailure,
    AddProjectToHistoryFailure,
    ProjectFileTagsEditUpdateFailure,
    RecentDocumentsViewFailure,
    GetLitigationCasesFailure,
    GetLitigationCaseDataFailure,
    GetLitigationCaseFilesFailure,
    GetLitigationFileContentFailure,
    GetLitigationFeatureFlagFailure,
    UserCheckFailure,
    GetLitigationUserQueryFailure,
    SaveUserQueryFailure,
    LitigationSaveUserTagsFailure,
    DeleteUserQueryFailure,
    ClassifyCaseFileFailure,
    ClassifySummaryByUserFailure,
    HistoryFileFailure
}

export enum ProjectTrackingInformationeEventId {
    projectsAPIRetry = 600
}

export enum ProjectListType {
    DEFAULT = "DEFAULT",
    HISTORY = "HISTORY",
    BOOKMARKS = "BOOKMARKS",
    SHARED = "SHARED"
}

export enum ProjectListFilter {
    ALL = "ALL",
    MY = "MY",
    TEAM = "TEAM"
}