import { IPersonaProps, Stack } from '@fluentui/react'
import * as React from 'react'
import AppLayout from './Layout/AppLayout'
import ErrorBoundary from '../../../Common/Components/ErrorBoundary'
import './ChatHome.styles.css'
import { ILitigationCase, ILitigationCaseFiles } from '../../../Models/ILitigationCase'
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Reducer } from 'redux';
import { ChatReducerName, chatInitialState, chatReducer } from '../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../Shared/IChatState';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { isNewTopicRequested } from '../../../Shared/Actions/Chat.action'
export const LitigationContext = React.createContext(null);
export type IChatHomeProps = {
  cases?: ILitigationCase[]
  filter?: string;
  user?: IPersonaProps;
  projectNumber?:string;
  isChatExpanded?: boolean;
  files?:ILitigationCaseFiles[];
  onFolderClick?: (param: string[]) => void;
  isCasePage?: boolean;
  disableFolderButton?: boolean;
}

const ChatHome = (chatHomeProps: IChatHomeProps) => {

  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);
    const { cases, filter, user, projectNumber, isChatExpanded, onFolderClick, isCasePage, disableFolderButton, files } = chatHomeProps 
  const [litCases, setListCases] = React.useState<ILitigationCase[]>([])
  React.useEffect(()=>{        
    setListCases(cases);
    dispatch(isNewTopicRequested(true))
  },[])
  return (
    <Stack className='home'>

      <ErrorBoundary>
        <LitigationContext.Provider value={{ litCases, filter, user, projectNumber, isChatExpanded , onFolderClick, isCasePage, disableFolderButton, files}}>
          <AppLayout />
        </LitigationContext.Provider>
      </ErrorBoundary>
    </Stack>
  )
}


export default ChatHome