export const container: React.CSSProperties =
{
   
}

export const dueDateTitle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "#000000",
    marginTop: "15px",
    marginLeft: "10px",
}

export const dueDateText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#252525",
    marginTop: "10px",
    marginLeft: "10px",
}

export const nodueDateText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#252525",
    marginTop: "10px",
    marginLeft: "10px",
    textAlign: "center",
}

export const dueDateTextWidth: React.CSSProperties =
{
    width: "350px" 
}

export const dateText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#E43F3F",
    marginTop: "10px",
    marginLeft: "10px",
}

export const dateTextWidth: React.CSSProperties =
{
    width: "100px" 
}

export const detailsText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "2%",
    cursor: "pointer"
}

export const seeDueDates: React.CSSProperties =
{
    marginLeft: "10px",
}

export const seeDueDatesText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    marginTop: "5%",
    marginBottom: "-2%",
    marginLeft: "10px",
    cursor:"pointer"
}

export const stackStyle: React.CSSProperties =
{
    marginLeft: 12,
    marginTop: 5
}

export const stackStyle1: React.CSSProperties =
{
    paddingRight: 10,
    width: "70px"
}
