import { Stack, Icon, TooltipHost } from "@fluentui/react";
import React from "react"
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../../Shared/ProjectState";
import { IFileHistoryRequest } from "../../../../../../Models/FileHistory/IFileHistoryRequest";
import { requestFileHistory } from "../../../../../../Shared/Actions/Project.action";
import FileHistoryItems from "./FileHistoryItems"
import InlineDisplaySpinner from "../../../../../../Common/Components/InlineDisplaySpinner";
import * as XLSX from 'xlsx'; // Import the XLSX library
import { IFileHistoryResponse } from "src/Models/FileHistory/IFileHistoryResponse";

export interface IFileHistoryColumnProps {
    id?: string;
    fileId?: string;
}

const FileHistory = (fileHistoryColumn: IFileHistoryColumnProps) => {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const { loginUserpersonaProps, fileHistoryResponses, isLoadingFileHistory, isLoadingFileHistoryError
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );
    const { id, fileId } = fileHistoryColumn;


    React.useEffect(() => {
        const fileHistoryRequest: IFileHistoryRequest = {
            fileId: id,
            userAlias: loginUserpersonaProps?.secondaryText
        }

        dispatch(requestFileHistory(fileHistoryRequest))
    }, [id])

    const handleExportHistoryFiles = ()=>{


        const flattenData: any = (data: IFileHistoryResponse[]) => {
            return data.map(item => ({                
                "File id": fileId,                
                "Created by": item?.createdBy,
                "Project number": item?.projectNumber,
                "Project hash description": item?.projectHashDescription,
                "Project description": item?.projectDescription,                
                "OperationType": item?.operationType,
                "Created date": item?.createdDate,
                "Case name": item?.fileClassification?.caseName,
                "Is classification error": item?.fileClassification?.isClassificationError,
                "Exception": item?.fileClassification?.exception,                
                "Relevance score": item?.fileClassification?.openAICaseFileClassificationResponse?.fileRelevanceScore,
                "Decision": item?.fileClassification?.openAICaseFileClassificationResponse?.decision,
                "Justification": item?.fileClassification?.openAICaseFileClassificationResponse?.justification,
                "Document summary": item?.fileClassification?.openAICaseFileClassificationResponse?.documentSummary,
                "Document theme": item?.fileClassification?.openAICaseFileClassificationResponse?.documentTheme
            }));
        };
        const flattenedData = flattenData(fileHistoryResponses);
        // Create a new workbook and add the data
        const worksheet = XLSX.utils.json_to_sheet(flattenedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Items');

        // Export the workbook as an Excel file
        XLSX.writeFile(workbook, 'detailslist_items.xlsx');
    }

    return (<>
        <Stack horizontal tokens={{ childrenGap: 5 }}>
            {isLoadingFileHistory && (
                <Stack style={{ marginTop: 5 }}>
                    <InlineDisplaySpinner spinnerPosition="right" accessabilityMessage="Loading file history" spinnerText="Loading file classification history..." />
                </Stack>
            )}
            {!isLoadingFileHistory && !isLoadingFileHistoryError && fileHistoryResponses?.length === 0 && (
                <Stack style={{ marginTop: 5 }}>
                    No history found.
                </Stack>
            )}
            {!isLoadingFileHistory && !isLoadingFileHistoryError && fileHistoryResponses?.length > 0 && (
                <>
                    <Stack horizontal style={{ marginTop: 5, width: '100%' }}>                        
                        <Stack style={{ marginTop: 5, width: '80%' }}>
                            <FileHistoryItems fileId={fileId}/>
                        </Stack>
                        <Stack style={{ marginTop: 40, marginLeft: 100 }}>
                            <Icon iconName='ExcelLogo' onClick={handleExportHistoryFiles} style={{cursor:'pointer'}}/>
                        </Stack>
                    </Stack>
                </>
            )}
            {!isLoadingFileHistory && isLoadingFileHistoryError && (
                <Stack style={{ marginTop: 5, width: '90%' }}>
                    Error occurred when loading case file history
                </Stack>
            )}
        </Stack>
    </>)
}

export default FileHistory