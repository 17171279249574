import React, { useState } from "react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { Reducer } from "redux";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import {
  isNewTopicRequested,  
  setTopicInContext,
  shouldClearChatResponse,
  shouldShowChatLogs,
} from "../../../../Shared/Actions/Chat.action";

import { ActionButton, IIconProps, Stack } from "@fluentui/react";
import Disabled from "../../../../Common/Components/Disabled";
const ChatNavigation = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { shouldShowChatView, loginUserpersonaProps, isRequestingNewAnswer } = useSelector(
    (state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState
  );
  
  const [showChatLogs, setShowChatLogs] = React.useState(false); 
  const chatLogsHandler = () => {    
    dispatch(shouldShowChatLogs(!showChatLogs));
  };

  const newTopicHandler = () => {
    dispatch(isNewTopicRequested(true));
    dispatch(shouldShowChatLogs(false));    
    dispatch(shouldClearChatResponse());    
  };

  const chatLogsIcon: IIconProps = { iconName: "History" };
  const newTopicIcon: IIconProps = { iconName: "Add" };
  return (
    <>
      <Disabled disabled={isRequestingNewAnswer ? true : false}>
        <Stack style={{paddingTop: 15}} horizontal>
          <ActionButton
            iconProps={newTopicIcon}
            allowDisabledFocus
            onClick={newTopicHandler}
            style={{              
              whiteSpace: 'nowrap', // Keeps the text from wrapping
          }}
          >
            New topic
          </ActionButton>
          <ActionButton iconProps={chatLogsIcon} allowDisabledFocus onClick={chatLogsHandler} style={{
                    whiteSpace: 'nowrap', // Keeps the text from wrapping
                }}>
            Chat history
          </ActionButton>
        </Stack>
      </Disabled>
    </>
  );
};

export default ChatNavigation;
