import React, { useEffect, useRef, useState } from "react";
import { IPersonaProps, Stack } from "@fluentui/react";

import './ChatConversation.styles.css'
import { IChatTopicResponse } from "../../../../Models/Chat/IChatTopicResponse";
import ChatQuestion from "./ChatQuestion";
import ChatAnswer from "./ChatAnswer";
import { ITopic } from "../../../../Models/Chat/ITopic";

import { IUser } from "@micro-frontend-react/employee-experience/lib/IUser";
import { IChatError } from "../../../../Models/Chat/IChatError";
import ErrorDisplay from "../../../../Helpers/ErrorDisplay/ErrorDisplay";



type IchatTopicResponseProps = {
  chatTopicResponse?: ITopic;
  topicId?: string;
  loginUserPersonaProps?: IPersonaProps;
  rowNumber?: number;
  isRequestingNewAnswer?: boolean;
  showAttorney?: boolean;
  user?: IUser;
  isAttorneyLoading?: boolean;
  isLastResponse?: boolean
  isAttorneyLoadingError?: boolean
  isResponseNewAnswerError?: boolean
  responseNewAnswerError?: IChatError;
  chatTopic?: ITopic;
  topicUser?: string; 
};

const ChatConversation = (chatTopicResponseProps: IchatTopicResponseProps) => {
  const myRefMessage = useRef(null);
  const [isQuestionResponseError, setIsQuestionResponseError] = useState<boolean>(false);

  const { chatTopicResponse, loginUserPersonaProps, rowNumber, user,  topicUser, chatTopic } = chatTopicResponseProps;

  useEffect(() => {
    //setIsQuestionResponseError(chatTopicResponse?.isQuestionResponseError);
    const responseString = chatTopicResponse?.questions?.[0]?.searchResponsesList?.length > 0;
    if (responseString || chatTopicResponse.user === "me") {
      if (myRefMessage && myRefMessage?.current) myRefMessage.current.scrollIntoView(false, { behavior: "smooth" });
    }
  }, [chatTopicResponse?.user]);
  return (
    <>
      <div className="chat-message">
        <Stack className='chat-conversation-container'>
          <Stack>
            {chatTopicResponse?.user?.toLocaleLowerCase() === 'me' && (
              <ChatQuestion
                isFirstQuestion={rowNumber === 0 ? true : false}
                question={chatTopicResponse.questions[0].userQuestion}
                userType="me"
                topicUser={topicUser}
                isNewPrompt={chatTopicResponse?.isNewQuestion}
                user={user} />
            )}
          </Stack>
          <div ref={myRefMessage} style={{ marginTop: 10 }}></div>
          <Stack className='chat-answer'>
            {chatTopicResponse?.user?.toLocaleLowerCase() === 'gpt' && (
              <>               
                
                  <ChatAnswer
                    chatTopicResponse={chatTopicResponse}
                    loginUserPersonaProps={loginUserPersonaProps}
                    question={chatTopicResponse.questions?.[0]?.userQuestion} topicId={chatTopicResponse?.topicId}
                    topicProjectNumber={chatTopic?.projectNumber}
                  />                
              </>
            )}            
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default ChatConversation;
