import { SimpleEffect, getContext, put, all, call, takeLatest, select } from "redux-saga/effects";
import { IHttpClient } from "@micro-frontend-react/employee-experience/lib/IHttpClient";
import { ITelemetryClient } from "@micro-frontend-react/employee-experience/lib/ITelemetryClient";
import {
    IRequestAddMembersToProject,
    IRequestBannerInformation,
    IRequestDeleteFile,
    IRequestDeleteMember,
    IRequestDeleteProject,
    IRequestFeatureFlagInformation,
    IRequestGetSimilarProject,
    IRequestLoginuserProfile,
    IRequestMemberStatusUpdate,
    IRequestProjectCreate,
    IRequestProjectDetails,
    IRequestProjectDueDate,
    IRequestProjectEdit,
    IRequestProjectListDetails,
    IRequestProjectSearch,
    IRequestProjectStatusUpdate,
    IRequestUpdateProjectMembersNames,
    IRequestUpdateProjectOwnersNames,
    IRequestUploadFilesToProject,
    IRequestUserNotification,
    IUpdateUserNotification,
    ProjectActionTypes,
    IRequestProjectWorkArea,
    IRequestProjectListDetailsV2,
    IRequestUserHistory,
    IRequestToggleBookmark,
    IRequestBookmark,
    IRequestAddProjectToUserHistory,
    IRequestProjectFileTagsUpdate,
    IRequestRecentDocumentsList,
    IResponseRecentDocumentsList,
    IRequestLawFirmSearch,
    IRequestLitiagtionCases,
    IRequestLitiagtionCaseData,
    IRequestLitiagtionCaseFiles,
    IRequestLitiagtionFileContent,
    IRequestDownloadLitiagtionCaseFiles,
    IRequestLitiagtionFileURL,
    IRequestLitigationFeatureFlag,
    IRequestIsUserPartofLitigationSgGroup,
    IRequestLitigationSaveUserTags,
    IRequestLitigationUserQuery,
    IRequestLitigationSaveUserQuery,
    IRequestLitigationUpdateUserQuery,
    IRequestLitigationDeleteQuery,
    IClassifyFilesRequest,
    IFilesClassifySummaryByUserRequest,
    IRequestFileHistory
} from "../Actions/Project.action-types";
import {
    responseAddMembersToProject,
    responseAddMembersToProjectFailure,
    responseBannerInformation,
    responseDeleteFile,
    responseDeleteFileFailure,
    responseDeleteMember,
    responseDeleteMemberFailure,
    responseDeleteProject,
    responseErrorBannerInformation,
    responseErrorUserNotification,
    responseFeatureFlagInformation,
    responseGetSimilarProjects,
    responseGetSimilarProjectsFailure,
    responseLoginuserProfile,
    responseLoginUserProfileError,
    responseMemberStatusUpdate,
    responseMemberStatusUpdateFailure,
    responseProjectCreationFailure,
    responseProjectCreationSuccess,
    responseProjectDetails,
    responseProjectDetailsFailure,
    responseProjectDueDate,
    responseProjectDueDateFailure,
    responseProjectEditFailure,
    responseProjectEditSuccess,
    responseProjectListDetail,
    responseProjectListDetailFailure,
    responseProjectSearch,
    responseProjectSearchFailure,
    responseProjectStatusUpdate,
    responseProjectStatusUpdateFailure,
    responseUploadFilesToProject,
    responseUploadFilesToProjectFailure,
    responseUserNotification,
    updateMembers,
    updateOwners,
    updateProjectProps,
    updateProjectStateForFileUpload,
    updateTagsForFile,
    updateTagsForProject,
    responseProjectWorkArea,
    responseErrorProjectWorkArea,
    responseUserHistory,
    responseUserHistoryError,
    responseToogleBookmark,
    responseErrorToggleBookmark,
    responseBookmark,
    responseBookmarkError,
    responseFileTagsUpdate,
    responseErrorFileTagsUpdate,
    responseRecentDocumentsList,
    responseRecentDocumentsErrorList,
    failedLawFirmSearch,
    responseLawFirmSearch,
    responseLitigationCases,
    responseLitigationCasesError,
    responseLitigationCaseData,
    responseLitigationCaseDataError,
    responseLitigationCaseFiles,
    responseLitigationCaseFilesError,
    responseLitigationFileContent,
    responseLitigationFileContentError,
    responseDownloadLitigationCaseFiles,
    responseDownloadLitigationCaseFilesError,
    responseLitigationURL,
    responseLitigationURLError,
    responseLitigationFeatureFlag,
    responseIsUserPartofLitigationSgGroup,
    isUserPartofLitigationSgGroupResponseError,
    responseLitigationUserQuery,
    responseLitigationUserQueryError,
    responseSaveUserQuery,
    saveUserQueryError,
    responseUpdateUserQuery,
    updateUserQueryError,
    responseSaveUserTags,
    responseDeleteUserQuery,
    deleteUserQueryError,
    classifyFilesResponse,
    classifyFilesResponseError,
    filesClassifySummaryByUserResponse,
    responseErrorFileHistory,
    responseFileHistory,
} from "../Actions/Project.action";
import {
    getProjectStateBusinessProcessProperties,
    trackProjectEvents,
    trackProjectException,
} from "../../Helpers/ProjectTelemtry";
import { ProjectListType, ProjectTrackingEventId, ProjectTrackingFailureEventId } from "../../Helpers/ProjectEnum";
import { AppActions, CelaLegalGroupNames, excludedGroups } from "../../Helpers/ProjectConstants";
import { IProjectSearchResponse } from "../../Models/IProjectSearchResponse";
import { IProjectFileTags } from "../../Models/IProjectFileTags";
import { ProjectAPI } from "../../Services/ProjectAPI";
import { IProjectProps } from "../../Models/IProjectProps";
import { IProjectCreationResponse } from "../../Models/IProjectCreationResponse";
import { IProjectTags } from "../../Models/IProjectTags";
import { IProjectFile, IProjectFiles } from "../../Models/IProjectFile";
import { IProjectCreate } from "../../Models/IProjectCreate";
import { IProjectMembers } from "../../Models/IProjectMembers";
import { IColumn, IPersonaProps, IPersonaSharedProps } from "@fluentui/react";
import { IProjectListDetails } from "../../Models/IProjectListDetails";
import { ILitigationCaseData, ILitigationCaseFiles, ILitigationFileContentResponses, ILitigationSaveUserTagsResponses } from "../../Models/ILitigationCase";
import { IProjectListResponse } from "../../Models/IProjectListResponse";
import { IResponse, ISimilarFileResponseObject, ISimilarProjectResponse } from "../../Models/ISimilarProjectResponse";
import { IBannerInformation } from "../../Models/Banner/IBannerInformation";
import { IFeatureFlagInfo } from "../../Models/IFeatureFlagInfo";
import { IUserNotificationResponses } from "../../Models/UserNotification/IUserNotificationResponse";
import { IProjectWorkArea, IProjectWorkAreas } from "../../Models/IProjectWorkArea";
import { IHistoryResponse, IHistoryResponses } from "src/Models/IHistoryResponses";
import { IBookmarkRequest } from "src/Models/IBookmarkRequest";
import { IRecentDocumentsResponses, IRecentDocumentsResponse } from "src/Models/IRecentDocumentsResponse";

import { IBookmarkResponse, IBookmarkResponses } from "src/Models/IBookmarkResponses";
import { ILawFirmSearchRequestParams } from "src/Models/ILawFirmSearchParams";
import { ILawFirmAdaptiveDetails } from "src/Models/ILawFirmAdaptiveDetails";
import { ILitigationCaseDataResponses, ILitigationCaseFilesResponses, ILitigationCaseResponses } from "src/Models/ILitigationCase";
import { ILitigationSaveUserTagsRequest } from "src/Models/ILitigationCaseRequest";
import { Filter } from "src/Models/ISaveUserQuery";
import { ILitigationClassifyFileResponse } from "src/Models/ILitigationClassifyFileResponse";
import { IResponseFilesClassifySummaryByUser } from "src/Models/IResponseFilesClassifySummaryByUser";
import { IFileHistoryResponse, IFilePayloaad } from "src/Models/FileHistory/IFileHistoryResponse";

const logException = (
    telemetryClient: ITelemetryClient,
    appAction: string,
    stateCommonProperties: any,
    exception: any,
    inputData?: any,
    eventId?: number
) => {
    trackProjectException(
        telemetryClient,
        "LegalEaseTeamsApp",
        appAction,
        stateCommonProperties,
        exception,
        eventId,
        appAction + ":Failure",
        inputData
    );
};

const logSuccess = (
    telemetryClient: ITelemetryClient,
    appAction: string,
    stateCommonProperties: any,
    inputData?: any,
    eventId?: number
) => {
    trackProjectEvents(
        telemetryClient,
        "LegalEaseTeamsApp",
        appAction,
        stateCommonProperties,
        eventId,
        appAction + ":Success",
        inputData);
};

const mapToFilter = (filter: any): Filter => {
    const isDateString = (str: string): boolean => !isNaN(Date.parse(str));
    
    return {
        condition: filter.Condition,
        fieldKey: filter.FieldKey,
        operator: filter.Operator,
        fieldValue: (typeof filter.FieldValue === 'string' && isDateString(filter.FieldValue))
            ? formatDate(filter.FieldValue)
            : filter.FieldValue,
        groupName: filter.GroupName,
        isChecked: filter.IsChecked,
        type: filter.Type
    };
};
const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toString(); // This will format the date as "Mon Aug 22 2022 00:00:00 GMT-0700 (Pacific Daylight Time)"
};


function* createProject(action: IRequestProjectCreate): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const appAction: string = AppActions.projectCreateAction;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response: any = yield call(ProjectAPI.createProject, action, httpClient, correlationId);
        const projectCreationResponse: IProjectCreationResponse = { projectNumber: "", projectTitle: "" };
        if (response.data) {
            projectCreationResponse.projectNumber = response.data.id,
                projectCreationResponse.projectTitle = response.data.name
        }
        yield put(responseProjectCreationSuccess(projectCreationResponse));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectCreate,
            ProjectTrackingEventId.projectsCreatedSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectCreationFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectCreate,
            ProjectTrackingFailureEventId.projectCreationFailure
        );
    }
}

function* editProject(action: IRequestProjectEdit): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const appAction: string = AppActions.projectEditAction;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        yield call(ProjectAPI.editProject, action, httpClient, correlationId);
        yield call(ProjectAPI.requestProjectFileTagsUpdate, action, httpClient, correlationId);
        yield put(responseProjectEditSuccess());
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectEdit,
            ProjectTrackingEventId.projectsEditSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectEditFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectEdit,
            ProjectTrackingFailureEventId.projectEditFailure
        );
    }
}

function* projectStatusCounts(action: IRequestProjectSearch): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const appAction: string = AppActions.projectSearchAction;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response: any = yield call(ProjectAPI.projectStatusCounts, action, httpClient);
        const projectSearchResponse: IProjectSearchResponse = {
            responses: [],
            countOpen: 0,
            countClosed: 0,
        };
        if (response.data) {
            const apiResponse = response.data;

            projectSearchResponse.countOpen = apiResponse?.open;
            projectSearchResponse.countClosed = apiResponse?.closed;
        }
        yield put(responseProjectSearch(projectSearchResponse));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            "projectSearch",
            ProjectTrackingEventId.ProjectSearchSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectSearchFailure(exception, errorResponse, correlationId));

        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            "projectSearch",
            ProjectTrackingFailureEventId.projectSearchFailure
        );
    }
}

function* dueDateProject(action: IRequestProjectDueDate): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const appAction: string = AppActions.projectDueDateAction;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response: any = yield call(ProjectAPI.dueDateProject, action, httpClient);
        const projectDueResponse: IProjectSearchResponse = {
            responses: [],
            countOpen: 0,
            countClosed: 0,
        };
        if (response.data) {
            const apiResponse = response.data.myProjectsList;
            let countOpen: number = 0;
            let countClosed: number = 0;
            Object.keys(apiResponse).map((object) => {
                const projectProps: IProjectProps = { title: "", description: "", number: "", status: "", startDate: "", endDate: "" };
                let props_object = apiResponse[object];
                projectProps.description = props_object?.description;
                projectProps.title = props_object?.title;
                projectProps.number = props_object?.number;
                projectProps.startDate = props_object?.startDate;
                projectProps.endDate = props_object?.endDate;
                projectProps.status = props_object?.status
                if (props_object?.status === "Open") {
                    countOpen += 1;
                }
                if (props_object?.status === "Closed") {
                    countClosed += 1;
                }
                projectDueResponse.responses.push(projectProps);
            });
        }
        yield put(responseProjectDueDate(projectDueResponse));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            "projectDueDate",
            ProjectTrackingEventId.projectsDueDateSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectDueDateFailure(exception, errorResponse, correlationId));

        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            "projectDueDate",
            ProjectTrackingFailureEventId.projectsDueDateFailure
        );
    }
}

const isEmailNotPartOfExcludedMemberGroups = (email: string): boolean => {
    if (
        email !== excludedGroups.celaLegalEngineeringGroup &&
        email !== excludedGroups.KMSITOwners &&
        email !== excludedGroups.CELADMSOwners.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSTestIntellectualProperty.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSTestTaxGroup.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSTestCorporateLaw.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSTestOther.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSTestLitigation.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSProdIntellectualProperty.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSProdTaxGroup.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSProdCorporateLaw.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSProdOther.toLowerCase().trim() &&
        email !== excludedGroups.CELADMSProdLitigation.toLowerCase().trim() &&
        email !== excludedGroups.ResponsibleAI.toLowerCase().trim()
    ) {
        return true;
    }
    return false;
};

function* getProjectDetailsById(action: IRequestProjectDetails): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.projectDetailsLoading;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const projectSearchResponse: any = yield call(
            ProjectAPI.getProjectDetailsById,
            action?.projectContext?.number,
            action?.userAlias,
            httpClient,
            correlationId
        );

        const projectCreate: IProjectCreate = {};

        if (projectSearchResponse?.data) {
            const apiResponse = projectSearchResponse.data;
            const projectFiles: IProjectFiles = {
                projectFile: [],
                folderPath: "",
            };
            if (apiResponse?.files) {
                apiResponse?.files.map((file: File) => {
                    const projectFile: IProjectFile = {};
                    projectFile.file = file;
                    projectFile.isNew = false;
                    projectFiles.projectFile.push(projectFile);
                });
                if (apiResponse?.folderPath) {
                    projectFiles.folderPath = apiResponse?.folderPath;
                }
                yield put(updateProjectStateForFileUpload(projectFiles));
            }
            if (apiResponse?.tags) {
                const projectTags: IProjectTags = { tags: [] };
                apiResponse?.tags?.map((tag: string) => {
                    projectTags.tags.push(tag);
                });
                yield put(updateTagsForProject(projectTags));
            }
            if (apiResponse?.members) {
                const projectMembers: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };
                const projectOwners: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: true };
                //const projectMembersSharepoint: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };
                apiResponse?.members?.map((member: any) => {
                    if (isEmailNotPartOfExcludedMemberGroups(member.alias.toLowerCase().trim())) {
                        const teamMember: IPersonaProps = {
                            text: member?.alias,
                            secondaryText: member?.alias,
                            optionalText: member?.personalNumber,
                            tertiaryText: "false",
                            showSecondaryText:true

                        };
                        if (member.isResponsible) {
                            projectOwners.membersPicked.push(teamMember);
                            projectOwners.alias.push(member?.alias);
                        }
                        else {
                            projectMembers.membersPicked.push(teamMember);
                            projectMembers.alias.push(member?.alias);
                        }
                        //projectMembersSharepoint.membersPicked.push(teamMember);
                        //projectMembersSharepoint.alias.push(member?.alias);
                    }
                });
                yield put(updateMembers(projectMembers));
                yield put(updateOwners(projectOwners));
            }
            const projectProps: IProjectProps = {};
            projectProps.description = apiResponse?.description;
            projectProps.title = apiResponse?.name;
            projectProps.startDate = apiResponse?.startDate !== null ? apiResponse?.startDate.replace(/-/g, "/") : ""
            projectProps.endDate = apiResponse?.endDate !== null ? apiResponse?.endDate.replace(/-/g, "/") : ""
            projectProps.status = apiResponse?.status;
            projectProps.workArea = apiResponse?.workArea;
            projectProps.caseIds = apiResponse?.caseIds;
            projectProps.isBookmarked = apiResponse?.isBookmarked;
            projectProps.spoUrl = apiResponse?.spoUrl;
            projectProps.number = action?.projectContext?.number
            yield put(updateProjectProps(projectProps));
        }
        yield put(responseProjectDetails(projectCreate));
        if (projectSearchResponse?.data?.files?.length > 0) {
            const files = projectSearchResponse.data.files;
            const fileTagResponses: IProjectFileTags[] = [];

            // Loop through each file and make the API call
            for (const file of files) {
                const fileTagResponse: any = yield call(
                    ProjectAPI.getFileTagsByIdAndFileName,
                    file.name,
                    action.projectContext.number,
                    httpClient,
                    correlationId
                );

                if (fileTagResponse && fileTagResponse.fileDetail) {
                    // Assuming fileTagResponse.fileDetail is an array
                    for (const fileDetail of fileTagResponse.fileDetail) {
                        const { fileName, tags } = fileDetail;
                        if (fileName && tags) {
                            fileTagResponses.push({ fileName, tags });
                        }
                    }
                }
            }

            // Dispatch the action with the array of file tag responses
            if (fileTagResponses.length > 0) {
                yield put(updateTagsForFile(fileTagResponses));
            }
        }
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectContext,
            ProjectTrackingEventId.projectDetailsSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectDetailsFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectContext,
            ProjectTrackingFailureEventId.projectDetailsFailure
        );
    }
}

function* updateProjectMembersNames(action: IRequestUpdateProjectMembersNames): IterableIterator<SimpleEffect<{}, {}>> {
    const projectMember: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: false };
    action.projectMembers?.membersPicked?.map((member: IPersonaProps) => {
        var name = member?.text
        if (member?.secondaryText === action.userAlias) {
            name = action.userName
        }
        const teamMember: IPersonaProps = {
            text: name,
            secondaryText: member?.secondaryText,
            optionalText: member?.optionalText,
            tertiaryText: member?.tertiaryText,
            showSecondaryText:true
        };
        projectMember.membersPicked.push(teamMember);
        projectMember.alias.push(member?.secondaryText);
    });
    yield put(updateMembers(projectMember));
}

function* updateProjectOwnersNames(action: IRequestUpdateProjectOwnersNames): IterableIterator<SimpleEffect<{}, {}>> {
    const projectOwner: IProjectMembers = { membersPicked: [], accessPolicy: [], alias: [], isResponsible: true };
    action.projectOwners?.membersPicked?.map((member: IPersonaProps) => {
        var name = member?.text
        if (member?.secondaryText === action.userAlias) {
            name = action.userName
        }
        const teamMember: IPersonaProps = {
            text: name,
            secondaryText: member?.secondaryText,
            optionalText: member?.optionalText,
            tertiaryText: member?.tertiaryText,
            showSecondaryText:true
        };
        projectOwner.membersPicked.push(teamMember);
        projectOwner.alias.push(member?.secondaryText);
    });
    yield put(updateOwners(projectOwner));
}

function* requestDeleteFile(action: IRequestDeleteFile): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.deleteFile;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId;
    try {
        yield call(ProjectAPI.deleteFile, action, httpClient, correlationId);
        yield put(responseDeleteFile());
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            [action.projectContext, action.fileName],
            ProjectTrackingEventId.fileDeleteSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseDeleteFileFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            [action.projectContext, action.fileName],
            ProjectTrackingFailureEventId.fileDeleteFailure
        );
    }
}

function* requestDeleteMember(action: IRequestDeleteMember): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.deleteMember;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId;
    try {
        yield call(ProjectAPI.deleteMember, action, httpClient, correlationId);
        yield put(responseDeleteMember());
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            [action.projectContext, action?.member?.text],
            ProjectTrackingEventId.memberDeleteSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseDeleteMemberFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            [action.projectContext, action?.member?.text],
            ProjectTrackingFailureEventId.memberDeleteFailure
        );
    }
}

function* requestMemberStatusUpdate(action: IRequestMemberStatusUpdate): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.updateMemberStatus;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId;
    try {
        yield call(ProjectAPI.deleteMember, action, httpClient, correlationId);
        yield call(ProjectAPI.updateMemberStatus, action, httpClient, correlationId);
        yield put(responseMemberStatusUpdate());
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            [action.projectContext, action?.member?.text],
            ProjectTrackingEventId.memberStatusUpdateSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseMemberStatusUpdateFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            [action.projectContext, action?.member?.text],
            ProjectTrackingFailureEventId.memberStatusUpdateFailure
        );
    }
}

function* getProjectListDetails(action: IRequestProjectListDetails): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const appAction: string = AppActions.getProjectList;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response: any = yield call(ProjectAPI.getProjectList, action, httpClient);
        const projectListResponse: IProjectListDetails = {
            items: []
        };
        if (response.data) {
            const apiResponse = response.data.myProjectsList;
            Object.keys(apiResponse).map((object) => {
                const projectProps: IProjectListResponse = { title: "", description: "", number: "", status: "", startDate: "", endDate: "", members: [], owners: [] };
                let props_object = apiResponse[object];
                projectProps.description = props_object?.description;
                projectProps.title = props_object?.title;
                projectProps.number = props_object?.number;
                projectProps.startDate = props_object?.startDate !== null ? props_object?.startDate.replace(/-/g, "/") : null
                projectProps.endDate = props_object?.endDate !== null ? props_object?.endDate.replace(/-/g, "/") : null
                projectProps.status = props_object?.status;
                projectProps.members = props_object?.members;
                projectProps.owners = props_object?.owners;
                projectListResponse.items.push(projectProps);
            });
        }
        yield put(responseProjectListDetail(projectListResponse,0));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            "projectListDetail",
            ProjectTrackingEventId.projectsListSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectListDetailFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            "projectListDetail",
            ProjectTrackingFailureEventId.projectsListFailure
        );
    }
}

function* getProjectListDetailsV2(action: IRequestProjectListDetailsV2): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const appAction: string = AppActions.getProjectList;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        let response: any;
        response = yield call(ProjectAPI.getProjectListV2, action, httpClient);
        const projectListResponse: IProjectListDetails = {
            items: [],
            totalRecords: 0,
        };
        if (response.data) {
            const apiResponse = response.data.myProjectsList;
            projectListResponse.totalRecords= response.data?.totalRecords;
            Object.keys(apiResponse).map((object) => {
                const projectProps: IProjectListResponse = { title: "", description: "", number: "", status: "", startDate: "", endDate: "", members: [], owners: [], isBookmarked: false, spoUrl: '', lastModifiedDate: '' };
                let props_object = apiResponse[object];
                projectProps.description = props_object?.description;
                projectProps.title = props_object?.title;
                projectProps.number = props_object?.number;
                projectProps.startDate = props_object?.startDate !== null ? props_object?.startDate.replace(/-/g, "/") : null
                projectProps.endDate = props_object?.endDate !== null ? props_object?.endDate.replace(/-/g, "/") : null
                projectProps.status = props_object?.status;
                projectProps.members = props_object?.members;
                projectProps.owners = props_object?.owners;
                projectProps.isBookmarked = props_object?.isBookmarked;
                projectProps.spoUrl = props_object?.spoUrl;
                projectProps.lastModifiedDate = props_object?.lastModifiedDate;
                projectListResponse.items.push(projectProps);
            });
        }
        yield put(responseProjectListDetail(projectListResponse,action.currentPage));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            "projectListDetailV2",
            ProjectTrackingEventId.projectsListSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectListDetailFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            "projectListDetailV2",
            ProjectTrackingFailureEventId.projectsListFailure
        );
    }
}

function* getLoginUserProfile(action: IRequestLoginuserProfile): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = AppActions.getLoginUserProfile;
    const correlationId = stateCommonProperties?.correlationId;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const userDetails: any = yield call(ProjectAPI.getLoginUser, action.loginUserAlias, httpClient)
        const userFromGraph = userDetails[0];
        const userProfileBlobImage = yield call(ProjectAPI.getUserPhoto, action.loginUserAlias, httpClient)
        let userProfileImage = "";
        if(userProfileBlobImage!=="No image found"){
            userProfileImage = URL.createObjectURL(userProfileBlobImage)
        }else{
            userProfileImage = "";
        }
        
        const personaObjet: IPersonaSharedProps = {
            imageUrl: userProfileImage,
            showSecondaryText: true,
            text: userFromGraph.displayName,
            secondaryText: userFromGraph.userType === "Guest" 
                ? userFromGraph?.mail 
                : userFromGraph?.userPrincipalName,
            primaryText: userFromGraph.displayName,
            optionalText: userFromGraph.userType === "Guest" 
                ? userFromGraph?.mail 
                : userFromGraph?.onPremisesImmutableId
        };
        yield put(responseLoginuserProfile(personaObjet));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            { userAlias: action.loginUserAlias },
            ProjectTrackingEventId.GetLoginUserSucess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseLoginUserProfileError(exception, errorResponse, correlationId));
        logException(telemetryClient, appAction, stateCommonProperties, exception, action.loginUserAlias, ProjectTrackingFailureEventId.GetLoginUserFailure);
    }
}

function* requestProjectStatusUpdate(action: IRequestProjectStatusUpdate): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.updateProjectStatus;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId
    try {
        yield call(ProjectAPI.updateProjectStatus, action, httpClient, correlationId);
        yield put(responseProjectStatusUpdate(action.newStatus));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectContext,
            ProjectTrackingEventId.projectStatusUpdateSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseProjectStatusUpdateFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectContext,
            ProjectTrackingFailureEventId.projectStatusUpdateFailure
        );
    }
}

function* requestFileUploadToExistingProject(action: IRequestUploadFilesToProject): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.uploadFileToProject;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId;
    try {
        let response: any;
        response = yield call(ProjectAPI.uploadFileToExistingProject, action, httpClient, correlationId);
        const projectFiles: IProjectFiles = {
            projectFile: [],
            folderPath: "",
        };
        if (response?.files) {
            response?.files.map((file: File) => {
                const projectFile: IProjectFile = {};
                projectFile.file = file;
                projectFile.isNew = true;
                projectFiles.projectFile.push(projectFile);
            });
            if (response?.folderPath) {
                projectFiles.folderPath = response?.folderPath;
            }
        }

        yield put(responseUploadFilesToProject(projectFiles));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectContext,
            ProjectTrackingEventId.fileUploadToProjectSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseUploadFilesToProjectFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectContext,
            ProjectTrackingFailureEventId.fileUploadToProjectFailure
        );
    }
}

function* requestAddMembersToExistingProject(action: IRequestAddMembersToProject): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.uploadFileToProject;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId
    try {
        yield call(ProjectAPI.addMemberToExistingProject, action, httpClient, correlationId);
        yield put(responseAddMembersToProject(action.newMember));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectContext,
            ProjectTrackingEventId.fileUploadToProjectSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseAddMembersToProjectFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectContext,
            ProjectTrackingFailureEventId.fileUploadToProjectFailure
        );
    }
}

function* searchSimilarProject(action: IRequestGetSimilarProject): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.searchSimilarProject;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId
    try {
        const response: any = yield call(ProjectAPI.searchSimilarProjects, action, httpClient, correlationId);
        const projectSearchResponse: ISimilarProjectResponse = {
            responses: [],
            fileResponse: [],
        };
        if (response) {

            var fileCount = 0
            response.responses.map(async (responseItem) => {

                const response: IResponse = {}
                response.content = responseItem?.content;
                response.documentUrl = responseItem?.documentUrl;
                response.isBookmarked = responseItem?.isBookmarked;
                if (responseItem?.documentUrl !== null) {
                    response.documentName = responseItem?.documentName;
                    const fileResponseObject: ISimilarFileResponseObject = {}
                    fileCount = fileCount + 1;
                    fileResponseObject.documentURL = responseItem?.documentUrl;
                    fileResponseObject.documentName = responseItem?.documentName;
                    fileResponseObject.content = responseItem?.content;
                    projectSearchResponse.fileResponse.push(fileResponseObject);
                }
                response.description = responseItem?.description;
                response.title = responseItem?.title;
                response.number = responseItem?.number;
                if (responseItem?.number !== action.projectProps?.number) {
                    projectSearchResponse.responses.push(response);
                }
            });
            projectSearchResponse.totalFileCount = fileCount
            yield put(responseGetSimilarProjects(projectSearchResponse));
            logSuccess(
                telemetryClient,
                appAction,
                stateCommonProperties,
                action.projectProps,
                ProjectTrackingEventId.getSimilarProjectSuccess
            );
        }
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseGetSimilarProjectsFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectProps,
            ProjectTrackingFailureEventId.getSimilarProjectFailure
        );
    }
}

function* requestDeleteProject(action: IRequestDeleteProject): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    let appAction: string = "";
    appAction = AppActions.deleteProject;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const correlationId = stateCommonProperties?.correlationId
    try {
        yield call(ProjectAPI.deleteProject, action.projectNumber, httpClient, correlationId);
        yield put(responseDeleteProject());
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectNumber,
            ProjectTrackingEventId.projectDeleteSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseDeleteFileFailure(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectNumber,
            ProjectTrackingFailureEventId.projectDeleteFailure
        );
    }
}

function* requestBannerInformation(action: IRequestBannerInformation): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.BannerInformationAction;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const bannerInformation: IBannerInformation = yield call(ProjectAPI.requestBannerInformation, action, httpClient, correlationId);

        yield put(responseBannerInformation(bannerInformation));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.loginUserAlias,
            ProjectTrackingEventId.BannerActionSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseErrorBannerInformation(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.loginUserAlias,
            ProjectTrackingFailureEventId.BannerActionFailure
        );
    }
}

function* requestFeatureFlagInformation(action: IRequestFeatureFlagInformation): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.FeatureFlagInformationAction;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response = yield call(ProjectAPI.requestFeatureFlag, action, httpClient, correlationId);
        const featureFlagInformation: IFeatureFlagInfo = response
        yield put(responseFeatureFlagInformation(featureFlagInformation));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.loginUserAlias,
            ProjectTrackingEventId.FeatureFlagActionSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.loginUserAlias,
            ProjectTrackingFailureEventId.FeatureFlagActionFailure
        );
    }
} 
function* requestUserNotification(action: IRequestUserNotification): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.userNotificationsRequest;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response = yield call(ProjectAPI.requestNotifications, action, httpClient, correlationId);
        const responseUserNotificationResponses: IUserNotificationResponses = response
        yield put(responseUserNotification(responseUserNotificationResponses));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action?.userNotificationRequest?.userAlias,
            ProjectTrackingEventId.UserNotificationActionSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseErrorUserNotification(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action?.userNotificationRequest?.userAlias,
            ProjectTrackingFailureEventId.UserNotificationActionFailure
        );
    }
}

function* requestUpdateUserNotification(action: IUpdateUserNotification): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.userNotificationsRequest;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        yield call(ProjectAPI.updateNotifications, action, httpClient, correlationId);

        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action?.userNotification?.notificationId,
            ProjectTrackingEventId.UserNotificationActionSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        //yield put(responseErrorUserNotification(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action?.userNotification?.notificationId,
            ProjectTrackingFailureEventId.UserNotificationActionFailure
        );
    }
}

function* getProjectWrokArea(action: IRequestProjectWorkArea): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.getProjectWorkArea;
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const getProjectWorkAreaResponse: any = yield call(ProjectAPI.getProjectWorkArea, httpClient, correlationId);

        const projectWorkAreas: IProjectWorkAreas = {
            projectWorkAreas: [],
        };
        getProjectWorkAreaResponse.data?.map((workArea: IProjectWorkArea) => {
            var key = workArea?.displayPath.replace("Microsoft\\", "");
            var arr = key?.split("\\");
            if (arr[0] != "") {
                const projectworkArea: IProjectWorkArea = {
                    workAreaId: workArea.workAreaId,
                    name: workArea?.name,
                    displayPath: workArea?.displayPath,
                    hasChildren: workArea?.hasChildren,
                    parentId: workArea.parentId,
                    headerRow: arr.length == 2 ? true : false,
                    key: arr.length == 2 ? arr[0] : arr[0] + "//" + arr[1] + "//",
                    value: arr.length == 2 ? arr[0] : arr[1],
                };
                if (!projectworkArea?.displayPath.includes("Other")) {
                    projectWorkAreas.projectWorkAreas.push(projectworkArea);
                } else if (projectworkArea?.displayPath.includes("Other") && projectworkArea?.headerRow) {
                    projectWorkAreas.projectWorkAreas.push(projectworkArea);
                }
            }
        });

        yield put(responseProjectWorkArea(projectWorkAreas));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            "Success while getting project work area",
            ProjectTrackingEventId.GetProjectWorkAreaSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseErrorProjectWorkArea(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            "Error while getting project work area",
            ProjectTrackingFailureEventId.GetProjectWorkAreaFailure
        );
    }
}

function* getUserHistory(action: IRequestUserHistory): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.userHistoryView;
    const actionObject = { userAlias: action.historySearchRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const userHistory: any = yield call(ProjectAPI.getProjectHistoryList, action, httpClient);

        const userHistoryResponses: IHistoryResponses = {
            historyResponses: userHistory?.userHistory,
            totalRecords: userHistory?.totalRecords
        }

        yield put(responseUserHistory(userHistoryResponses));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetUserHistorySuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseUserHistoryError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetUserHistoryFailure);
    }
}


function* toggleProjectBookmark(action: IRequestToggleBookmark): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = "";
    appAction = AppActions.doingProjectBookmarkToggle;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const actionObject: IBookmarkRequest = {
        userAlias: action.userAlias,
        projectNumber: action.projectNumber,
        isBookmarked: action.isBookmarked,
    };
    try {
        const bookmarkStatus: boolean = yield call(
            ProjectAPI.toggleProjectBookmark,
            actionObject,
            httpClient,
            correlationId
        );
        yield put(responseToogleBookmark(bookmarkStatus));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            actionObject,
            ProjectTrackingEventId.toggleBookmarkSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseErrorToggleBookmark(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            actionObject,
            ProjectTrackingFailureEventId.toggleBookmarkFailure
        );
    }
}

function* addProjectToUserHistory(action: IRequestAddProjectToUserHistory): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = "";
    appAction = AppActions.addProjectToUserHistory;
    const httpClient: IHttpClient = yield getContext("httpClient");
    const actionObject = {
        userAlias: action.userAlias,
        projectNumber: action.projectNumber,
    };
    try {
        yield call(ProjectAPI.addProjectToUserHistory, action, httpClient, correlationId);
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            actionObject,
            ProjectTrackingEventId.AddProjectToHistorySuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            actionObject,
            ProjectTrackingFailureEventId.AddProjectToHistoryFailure
        );
    }
}

function* getBookmark(action: IRequestBookmark): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.bookmarkView;
    const actionObject = { userAlias: action.bookmarkSearchRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const bookmarks: any = yield call(ProjectAPI.getProjectBookmarksList, action, httpClient);

        const bookmarkResponses: IBookmarkResponses = {
            bookmarkResponses: bookmarks?.bookmarks,
            totalRecords: bookmarks?.totalRecords
        }

        yield put(responseBookmark(bookmarkResponses));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetBookmarkSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseBookmarkError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetBookmarkFailure);
    }
}


function* requestProjectFileTagsUpdate(action: IRequestProjectFileTagsUpdate): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = "";
    //appAction = AppActions.projectFileTagsUpdate;
    const httpClient: IHttpClient = yield getContext("httpClient");
    try {
        yield call(ProjectAPI.requestProjectFileTagsUpdateinView, action, httpClient, correlationId);
        yield put(responseFileTagsUpdate(action.projectFileTags));
        logSuccess(
            telemetryClient,
            appAction,
            stateCommonProperties,
            action.projectFileTags,
            ProjectTrackingEventId.ProjectFileTagsEditUpdateSuccess
        );
    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        yield put(responseErrorFileTagsUpdate(exception, errorResponse, correlationId));
        logException(
            telemetryClient,
            appAction,
            stateCommonProperties,
            exception,
            action.projectFileTags,
            ProjectTrackingFailureEventId.ProjectFileTagsEditUpdateFailure
        );
    }
}
function* getRecentDocuments(action: IRequestRecentDocumentsList): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.recentDocumentsView;


    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const responseRecentDocuments: any = yield call(ProjectAPI.getUserRecentDocuments, action, httpClient);
        const responseRecentDocumentsListobj: IRecentDocumentsResponses = {
            recentDocumentsResponses: []
        }
        responseRecentDocumentsListobj.recentDocumentsResponses = responseRecentDocuments
        yield put(responseRecentDocumentsList(responseRecentDocumentsListobj));

        logSuccess(telemetryClient, appAction, stateCommonProperties, action, ProjectTrackingEventId.RecentDocumentsViewSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseRecentDocumentsErrorList(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, action, ProjectTrackingFailureEventId.RecentDocumentsViewFailure);
    }
}

const isFilterApplied = (searchCriteria: ILawFirmSearchRequestParams) => {

    if (searchCriteria?.filter && Object.keys(searchCriteria?.filter).length > 0) {
        for (let key in searchCriteria.filter) {
            if (searchCriteria['filter'][key] !== '') {
                return true;
            }
        }
        return false;
    }
    return false;
}

function* fetchLawFirms(action: IRequestLawFirmSearch): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getProjectStateBusinessProcessProperties);
    const filterApplied = isFilterApplied(action?.searchCriteria);
    let appAction: string = ''
    if (filterApplied) {
        appAction = 'LawFirmSearch_View_WithFilters';
    }
    else {
        appAction = 'LawFirmSearch_View';
    }

    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const lawfirmDetailsJSON: ILawFirmAdaptiveDetails = yield call(ProjectAPI.getLawFirms, action, httpClient);
        yield put(responseLawFirmSearch(lawfirmDetailsJSON));
        logSuccess(telemetryClient, appAction, stateCommonProperties, action, ProjectTrackingEventId.GetBookmarkSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        yield put(failedLawFirmSearch(errorResponse, error.message ? error.message : error));
        const exception = error.data ? new Error(error.data) : error;
        logException(telemetryClient, appAction, stateCommonProperties, exception, action, ProjectTrackingFailureEventId.RecentDocumentsViewFailure);

    }
}

function* getLitigationCases(action: IRequestLitiagtionCases): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.getLitigationCases;
    const actionObject = { userAlias: action.litigationCaseRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationCases: any = yield call(ProjectAPI.getLitigationCases, action, httpClient,correlationId);

        // const litigationCaseResponses: ILitigationCaseResponses = {
        //     litigationCaseResponses: litigationCases,
        //     totalRecords: litigationCases?.totalRecords
        // }

        yield put(responseLitigationCases(litigationCases));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationCasesSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationCasesError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationCasesFailure);
    }
}

function* getLitigationCaseData(action: IRequestLitiagtionCaseData): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.getLitigationCaseData;
    const actionObject = { userAlias: action.litigationCaseDataRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationCaseData: any = yield call(ProjectAPI.getLitigationCaseData, action, httpClient, correlationId);

        // const litigationCaseDataResponses: ILitigationCaseDataResponses = {
        //     litigationCaseDataResponses: litigationCaseData?.litigationCaseData,
        //     totalRecords: litigationCaseData?.totalRecords
        // }
        let litigationCases = litigationCaseData.litigationCaseDataResponses;
        litigationCases = litigationCases.map((litigationCase: ILitigationCaseData): ILitigationCaseData => {
            return {
                ...litigationCase,
                isChecked: false
            }
        })
        litigationCaseData.litigationCaseDataResponses = litigationCases

        yield put(responseLitigationCaseData(litigationCaseData));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationCaseDataSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationCaseDataError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationCaseDataFailure);
    }
}

function* getLitigationFileContent(action: IRequestLitiagtionFileContent): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.bookmarkView;
    const actionObject = { userAlias: action.litigationFileContentRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationFileContent: ILitigationFileContentResponses = yield call(ProjectAPI.getLitigationFileContent, action, httpClient, correlationId);

        yield put(responseLitigationFileContent(litigationFileContent));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationFileContentSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationFileContentError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationFileContentFailure);
    }
}

function* getLitigationFileURL(action: IRequestLitiagtionFileURL): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.bookmarkView;
    const actionObject = { userAlias: action.litigationFileURLRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationFileURL: any = yield call(ProjectAPI.getLitigationFileURL, action, httpClient, correlationId);

        yield put(responseLitigationURL(litigationFileURL));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationFileContentSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationURLError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationFileContentFailure);
    }
}

function* getLitigationCaseFiles(action: IRequestLitiagtionCaseFiles): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.getLitigationCaseFiles;
    const actionObject = { userAlias: action.litigationCaseFilesRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationCaseFiles: any = yield call(ProjectAPI.getLitigationCasesFiles, action, httpClient, correlationId);

        // const litigationCaseFilesResponses: ILitigationCaseFilesResponses = {
        //     litigationCaseFilesResponses: litigationCaseFiles?.litigationCaseFiles,
        //     totalRecords: litigationCaseFiles?.totalRecords
        // }

        yield put(responseLitigationCaseFiles(litigationCaseFiles));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationCaseFilesSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationCaseFilesError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationCaseFilesFailure);
    }
}

function* downloadLitigationCaseFiles(action: IRequestDownloadLitiagtionCaseFiles): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.bookmarkView;
    const actionObject = { userAlias: action.litigationCaseFilesRequest.userAlias };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationCaseFiles: any = yield call(ProjectAPI.downloadLitigationCasesFiles, action, httpClient, correlationId);
        yield put(responseDownloadLitigationCaseFiles());
        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationCaseFilesSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseDownloadLitigationCaseFilesError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationCaseFilesFailure);
    }
}
function* requestLitigationFeatureFlag(action: IRequestLitigationFeatureFlag): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.requestLitigationFeatureFlag;
    const actionObject = { userAlias: action?.loginUserAlias, featureName: action?.featureName};

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const litigationFeatuerFlag: any = yield call(ProjectAPI.requestLitigationFeatureFlag, action, httpClient, correlationId);

        yield put(responseLitigationFeatureFlag(litigationFeatuerFlag));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationFeatureFlagSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationCaseFilesError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationFeatureFlagFailure);
    }
}

function* requestLitigationSaveUserTags(action: ILitigationSaveUserTagsRequest): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.litigationSaveUserTags;
    const actionObject = { userAlias: action?.userAlias, caseFileDetails: action?.caseFileDetails, tags: action?.tags};
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
        const response : ILitigationSaveUserTagsResponses = yield call(ProjectAPI.requestLitigationSaveUserTags, action, httpClient, correlationId);
        yield put(responseSaveUserTags(response));
        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.LitigationSaveUserTags);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;
        //yield put(responseErrorUserNotification(exception, errorResponse, correlationId));
        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationCaseFilesFailure);
    }
}

//requestCheckIsUserPartOfLitigationSGGroup
function* requestCheckIsUserPartOfLitigationSGGroup(action: IRequestIsUserPartofLitigationSgGroup): IterableIterator<SimpleEffect<{}, {}>> {

    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = AppActions.requestCheckIsUserPartOfLitigationSGGroup;
    const actionObject = { userAlias: action?.userAlias};

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const isUserPartOfLitigationSGGroup: boolean = yield call(ProjectAPI.requestCheckIsUserPartOfLitigationSGGroup, action, httpClient, correlationId);

        yield put(responseIsUserPartofLitigationSgGroup(isUserPartOfLitigationSGGroup));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.UserCheckSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(isUserPartofLitigationSgGroupResponseError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.UserCheckFailure);
    }
}

function* requestLitigationUserQuery(action: IRequestLitigationUserQuery): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "requestLitigationUserQuery";
    const actionObject = { userAlias: action?.userAlias, projectNumber: action?.projectNumber };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const userQueries: any = yield call(ProjectAPI.requestLitigationUserQuery, action, httpClient, correlationId);

        if (userQueries && Array.isArray(userQueries)) {
            userQueries.forEach((query: any) => {
                if (query.filters && typeof query.filters === 'string') {
                    try {
                        const filtersArray: any[] = JSON.parse(query.filters); // Convert filters from string to array
            
                        // Map each filter to the Filter interface
                        query.filters = filtersArray.map(mapToFilter);
                    } catch (e) {
                        console.error('Error parsing filters: ', e);
                        query.filters = []; // Set to empty array if JSON parsing fails
                    }
                } else if (!query.filters) {
                    query.filters = []; // Convert to empty filters array if filters is empty or undefined
                }
            });
        }

        yield put(responseLitigationUserQuery(userQueries));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.GetLitigationUserQuerySuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(responseLitigationUserQueryError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.GetLitigationUserQueryFailure);
    }
}

function* saveUserQuery(action: IRequestLitigationSaveUserQuery): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "saveUserQuery";
    const actionObject = { userAlias: action?.userAlias, projectNumber: action?.projectNumber };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const userQueries: any = yield call(ProjectAPI.saveLitigationUserQuery, action, httpClient, correlationId);

        yield put(responseSaveUserQuery(userQueries));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.SaveUserQuerySuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(saveUserQueryError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.SaveUserQueryFailure);
    }
}

function* updateUserQuery(action: IRequestLitigationUpdateUserQuery): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "updateUserQuery";
    const actionObject = { userAlias: action?.userAlias, projectNumber: action?.projectNumber };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const userQueries: any = yield call(ProjectAPI.updateLitigationUserQuery, action, httpClient, correlationId);

        yield put(responseUpdateUserQuery(userQueries));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.SaveUserQuerySuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(updateUserQueryError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.SaveUserQueryFailure);
    }
}

function* requestLitigationDeleteUserQuery(action: IRequestLitigationDeleteQuery): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "deleteUserQuery";
    const actionObject = { queryID: action.queryID };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        // Call API function to delete the query
        const queries: any[] = yield call(ProjectAPI.deleteUserQuery, action, httpClient, correlationId);

        // Dispatch success action with updated queries
        yield put(responseDeleteUserQuery(queries));

        // Log success event
        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.DeleteUserQuerySuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        // Dispatch error action if deletion fails
        yield put(deleteUserQueryError(error, exception, correlationId));

        // Log failure event
        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.DeleteUserQueryFailure);
    }
}

function* requestClassifyCaseFiles(action: IClassifyFilesRequest): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "ClassifyCaseFiles";
    const actionObject = { userAlias: action?.litigationClassifyFileRequest?.userAlias, caseFiles: action?.litigationClassifyFileRequest?.caseFileDetails };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const classifyCaseFilesResponse: ILitigationClassifyFileResponse = yield call(ProjectAPI.requestClassifyCaseFiles, action, httpClient, correlationId);

        yield put(classifyFilesResponse(classifyCaseFilesResponse));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.ClassifyCaseFileSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(classifyFilesResponseError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.ClassifyCaseFileFailure);
    }
}

function* filesClassifySummaryByUserRequest(action: IFilesClassifySummaryByUserRequest): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "ClassifySummaryByUser";
    const actionObject = { userAlias: action?.requestFilesClassifySummaryByUser?.userAlias, caseFiles: action?.requestFilesClassifySummaryByUser };

    try {
        const httpClient: IHttpClient = yield getContext("httpClient");

        const classifyCaseFilesResponse: IResponseFilesClassifySummaryByUser = yield call(ProjectAPI.filesClassifySummaryByUserRequest, action, httpClient, correlationId);

        console.log(classifyCaseFilesResponse)

        yield put(filesClassifySummaryByUserResponse(classifyCaseFilesResponse));

        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.ClassifySummaryByUserSuccess);

    } catch (errorResponse: any) {
        const error = errorResponse?.data ?? errorResponse;
        const exception = error.data ? new Error(error.data) : error;

        yield put(classifyFilesResponseError(error, exception, correlationId));

        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.ClassifySummaryByUserFailure);
    }
}

function* fileHistoryRequest(action: IRequestFileHistory): IterableIterator<any> {
    const telemetryClient: ITelemetryClient = yield getContext("telemetryClient");
    const stateCommonProperties: any = yield select(getProjectStateBusinessProcessProperties);
    const correlationId: string = stateCommonProperties?.correlationId;
    let appAction: string = "RequestFileHistory";
    const actionObject = { userAlias: action?.fileHistoryRequest?.userAlias, caseFiles: action?.fileHistoryRequest?.fileId };
 
    try {
        const httpClient: IHttpClient = yield getContext("httpClient");
 
        const fileHistoryResponses: IFileHistoryResponse[] = yield call(ProjectAPI.fileHistoryRequest, action, httpClient, correlationId);       

        
       
        yield put(responseFileHistory(fileHistoryResponses));
 
        logSuccess(telemetryClient, appAction, stateCommonProperties, actionObject, ProjectTrackingEventId.HistoryFileSuccess);
 
    } catch (errorResponse: any) {

        console.log(errorResponse)
        const error = errorResponse?.data ?? errorResponse;
        
        const exception = error.data ? new Error(error.data) : error;
 
        yield put(responseErrorFileHistory(error, exception, correlationId));
 
        logException(telemetryClient, appAction, stateCommonProperties, exception, actionObject, ProjectTrackingFailureEventId.HistoryFileFailure);
    }
}
 

export function* projectSagas(): IterableIterator<{}> {
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_CREATE, createProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_SEARCH, projectStatusCounts)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_DUE_DATE, dueDateProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_DETAILS_BY_ID, getProjectDetailsById)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_UPDATE_PROJECT_MEMBERS_NAMES, updateProjectMembersNames)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_UPDATE_PROJECT_OWNERS_NAMES, updateProjectOwnersNames)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_DELETE_FILE, requestDeleteFile)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_DELETE_MEMBER, requestDeleteMember)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_EDIT, editProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_MEMBER_STATUS_UPDATE, requestMemberStatusUpdate)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_Detail_List, getProjectListDetails)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_DETAIL_LIST_V2, getProjectListDetailsV2)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LOGIN_USER_PROFILE, getLoginUserProfile)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_STATUS_UPDATE, requestProjectStatusUpdate)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_UPLOAD_FILES_TO_PROJECT, requestFileUploadToExistingProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_ADD_MEMBERS_TO_PROJECT, requestAddMembersToExistingProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_GET_SIMILAR_PROJECT, searchSimilarProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_DELETE_PROJECT, requestDeleteProject)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_BANNER_INFORMATION, requestBannerInformation)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_FEATURE_FLAG_INFORMATION, requestFeatureFlagInformation)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_USER_NOTIFICATION, requestUserNotification)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_UPDATE_USER_NOTIFICATION, requestUpdateUserNotification)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_WORKAREA, getProjectWrokArea)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_USER_HISTORY, getUserHistory)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_TOGGLE_BOOKMARK, toggleProjectBookmark)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_BOOKMARK, getBookmark)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_ADD_PROJECT_TO_USER_HISTORY, addProjectToUserHistory)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_PROJECT_FILE_TAGS_UPDATE, requestProjectFileTagsUpdate)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_RECENT_DOCUMENTS_LIST, getRecentDocuments)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LAWFIRM_SEARCH, fetchLawFirms)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_CASES, getLitigationCases)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_CASEDATA, getLitigationCaseData)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_CASEFILES, getLitigationCaseFiles)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_FILECONTENT, getLitigationFileContent)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_FILEURL, getLitigationFileURL)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_DOWNLOAD_LITIGATION_CASEFILES, downloadLitigationCaseFiles)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_FEATURE_FLAG, requestLitigationFeatureFlag)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_IS_USER_PART_OF_LITIGATION_SG_GROUP, requestCheckIsUserPartOfLitigationSGGroup)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_USER_QUERY, requestLitigationUserQuery)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_SAVE_USER_QUERY, saveUserQuery)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_UPDATE_USER_QUERY, updateUserQuery)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_LITIGATION_SAVEUSERTAGS, requestLitigationSaveUserTags)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_DELETE_USER_QUERY, requestLitigationDeleteUserQuery)]);

    yield all([takeLatest(ProjectActionTypes.CLASSIFY_FILES_REQUEST, requestClassifyCaseFiles)]);
    yield all([takeLatest(ProjectActionTypes.FILES_CLASSIFY_SUMMARY_BY_USER_REQUEST, filesClassifySummaryByUserRequest)]);
    yield all([takeLatest(ProjectActionTypes.REQUEST_FILE_HISTORY, fileHistoryRequest)]);
 
   
    //
}
