import { ITagItemStyles, ICommandBarStyles, IInputProps } from "@fluentui/react";

export const toggleStyles: Partial<ITagItemStyles> = {
    text: { minWidth: "100%", minHeight: "40px", maxHeight: "60px", overflowY: "scroll", overflowX: "hidden",  border: "none" , background:"white" },

};

export const buttonStyles: Partial<ICommandBarStyles> = {
    root: {
        height: 30,
    },
};

export const inputProps: IInputProps = {
    style: { margin: 0 },
    'aria-label': "Please enter or select tags as needed. The total character limit for all tags is restricted to 255",
  };