import * as React from "react";
import { DialogFooter } from '@fluentui/react/lib/Dialog';
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { IProjectMembers } from "../../../Models/IProjectMembers";
import FilterMemberPicker from "../../PickerComponents/FilterMemberPicker";
export const FilterDialogContext = React.createContext(null);

const FilterDialog = ({ isOpen, onDismiss, onSubmit }) => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [status, setStatus] = React.useState('All');
  const [projectMember, setProjectMember] = React.useState<IProjectMembers>();

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setStatus('All');
    setProjectMember(null);
  };

  const handleSubmit = () => {
    // Call the onSubmit function with the selected filter values
    let owneremail = projectMember?.membersPicked[0]?.secondaryText;
    onSubmit({ startDate, endDate, status, owneremail });
    onDismiss();
  };



  return (
    <div style={{
      width: 320, overflowX: "hidden",
      position: "fixed",
      top: 0,
      right: isOpen ? 0 : "-320px",
      height: "100%",
      transition: "0.5s",
      backgroundColor: "#fff",
      boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.2)",
      padding: "20px",
      zIndex: 999
    }}>
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        style={{ position: 'absolute', top: 0, right: 0,color:'#000000' }}
        onClick={() => onDismiss()}
      />
      <div style={{ marginBottom: "20px" }}>
        <DatePicker
          label="Created Date"
          value={startDate}
          onSelectDate={(date) => setStartDate(date)}
          placeholder="Select a start date"
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <DatePicker
          label="Close Date"
          value={endDate}
          onSelectDate={(date) => setEndDate(date)}
          placeholder="Select an end date"
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Dropdown
          label="Status"
          selectedKey={status}
          options={[
            { key: 'All', text: 'All' },
            { key: 'Open', text: 'Open' },
            { key: 'Closed', text: 'Closed' },
          ]}
          onChange={(event, option) => setStatus(option.key.toString())}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label style={{ color: "#323130", fontFamily: "Segoe UI", fontSize: 14, fontWeight: 600 }}>
          Owner
        </label>
        <div>
          <FilterDialogContext.Provider value={{ projectMember, setProjectMember }}>
            <FilterMemberPicker
              peopleType="member"
              projectMember={projectMember}
              setProjectMember={setProjectMember}
            />
          </FilterDialogContext.Provider>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <DefaultButton text="Clear" onClick={handleClear} style={{ marginRight: "10px" }} />
        <PrimaryButton text="Submit" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FilterDialog;
