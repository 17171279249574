import {Stack } from "@fluentui/react";
import { Context, withContext } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import { getSimilarProjects } from "../../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import SearchResponse from "./SearchResponse";
import { insightsTitleStyle, projectInsightsWidth } from "./Styles/SearchProject";

interface ISearchProjectProps {
    projectNumber?: string;
}
function SearchProject(searchProjectProps:ISearchProjectProps): React.ReactElement {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {projectNumber} = searchProjectProps;
    const {
        projectProps,        
        loginUserpersonaProps
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    
    React.useEffect(() => {        
        if(projectProps?.title){
            dispatch(getSimilarProjects(projectProps, loginUserpersonaProps?.secondaryText));
        }
    }, []);

    return (
        <>
            <Stack style={projectInsightsWidth}>              
                <SearchResponse projectNumber={projectNumber}></SearchResponse>
            </Stack>
        </>
    );
}


export default SearchProject ;