import { IDetailsListStyles, IStackStyles } from '@fluentui/react';
import { getTheme } from '@fluentui/react/lib/Styling';

const theme = getTheme();
export const collapsibleStyle: React.CSSProperties = {
    justifyContent: 'space-evenly',
    width: '100%'
};
export const mainStyle: React.CSSProperties = { paddingTop: 10, marginTop: -10, marginLeft: -30 };
export const detailsListStyles: Required<Pick<IDetailsListStyles, 'root'>> = {
    root: { overflow: 'hidden', marginLeft: 30 }
};
export const messageStyles: IStackStyles = {
    root: { marginTop: 10, width:'auto', marginLeft: -26 }
};

export const displayMessageStyles: IStackStyles = {
    root: { width: "100%",  }
};

