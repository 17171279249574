import { Icon, Stack, TooltipHost } from "@fluentui/react";
import React from "react";
import './Vote.styles.css'
export interface IDownVoteProps {
  downVoteClick(): void;
  selectedButton: string;
}

const DownVote = (downVoteProps: IDownVoteProps) => {
  const { downVoteClick, selectedButton } = downVoteProps;
  const showFeedbackUIHandler = () => {
    downVoteClick();
  };

  const handleKeyDownOnDownVote = (event: any)=>{
		if (event.key === 'Enter' || event.keyCode === 13) {
			downVoteClick();
		}
	}

  return (
    <Stack className="vote-container">
      <TooltipHost content={"Provide negative feedback"} aria-label="Provide negative feedback">
        <Icon
          tabIndex={0}
          onClick={showFeedbackUIHandler}
          onKeyDown={handleKeyDownOnDownVote}
          iconName={selectedButton === "down" ? "DislikeSolid" : "Dislike"}
          className='vote'
        />
      </TooltipHost>
    </Stack>
  );
};

export default DownVote;
