import { IColumn } from "@fluentui/react"

export const ChatLogColumns: IColumn[] = [
    {
        key: 'promptText',
        name: 'Prompt Text',
        ariaLabel: 'promptText',
        fieldName: 'promptText',
        minWidth: 200,
        maxWidth: 200,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isPadded: true,
        isSorted: false,
        isSortedDescending: false,
        isResizable: true,               
    },
    {
        key: 'createdBy',
        name: 'Created By',
        ariaLabel: 'Chat text created by user',

        fieldName: 'createdBy',
        minWidth: 100,
        maxWidth: 100,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isResizable: true,
        isPadded: true,
        isSorted: false,
        isSortedDescending: false
    },
    {
        key: 'createdDate',
        name: 'Date',
        ariaLabel: 'Chat text created date',

        fieldName: 'createdDate',
        minWidth: 50,
        maxWidth: 50,
        styles: {
            cellTitle: {
                marginTop: 12,
                lineHeight: 16
            },
        },
        isResizable: true,
        isPadded: true,
        isSorted: false,
        isSortedDescending: false
    }       
]