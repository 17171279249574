import { Icon, Stack, TooltipHost } from '@fluentui/react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import * as React from 'react';
import { Reducer } from 'redux';
import { useHistory } from 'react-router-dom';
import {
    getIconByFileExtension,
    truncateText
} from '../../../Helpers/ProjectHelper';
import { IProjectContext } from '../../../Models/IProjectContext';
import {
    requestProjectDetails,
    setProjectContext
} from '../../../Shared/Actions/Project.action';
import { IProjectAppState } from '../../../Shared/ProjectState';
import {
    ProjectReducerName,
    projectReducer,
    projectInitialState
} from '../../../Shared/Reducers/Project.reducer';
import { projectSagas } from '../../../Shared/Sagas/Project.sagas';
import {
    descriptionText,
    detailsText,
    fileiconStyle,
    itemContainer,
    separator,
    titleText,
    viewProjectLink
} from './Styles/SearchResponse';
import ProjectDetailView from './ProjectDetailView';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { IResponse } from 'src/Models/ISimilarProjectResponse';
import View from '../History/View';

export interface ResponseProps {
    index?: number;
    responseItem?: IResponse;
}

const SearchResponseItem = (responseProps: ResponseProps) => {
    let history = useHistory();
    const { index, responseItem } = responseProps;
    const reduxContext = React.useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [
        projectSagas
    ]);
    const { useSelector, dispatch } = reduxContext;
    const { loginUserpersonaProps } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const [isProjectExists, setIsProjectExists] = React.useState(false);

    const handleIsProjectExists = (isProjectExists: boolean)=> {
        setIsProjectExists(isProjectExists)
    }
    const handleProjectClick = (): void => {
        const projectContext: IProjectContext = {
            number: responseItem?.number
        };
        dispatch(setProjectContext(projectContext));
        if (history.location.pathname === '/viewproject') {
            dispatch(
                requestProjectDetails(
                    projectContext,
                    loginUserpersonaProps?.secondaryText
                )
            );
        } else {
            history.push('/viewproject');
        }
    };

    return (
        <>
            <Stack style={isProjectExists?itemContainer:null}>
                <Stack style={titleText}>
                    <TooltipHost
                        content={
                            responseItem?.number + ': ' + responseItem?.title
                        }
                    >
                        {responseItem?.title}
                    </TooltipHost>
                </Stack>
                {/* <Stack style={descriptionText}>
                    {truncateText(responseItem?.description, 60, 60)}
                </Stack> */}
                <Stack style={isProjectExists?detailsText:null}>
                    <ProjectDetailView
                        responseItem={responseItem}
                        userAlias={loginUserpersonaProps?.secondaryText}
                        number={responseItem?.number}
                        onHandleProjectExists={handleIsProjectExists}
                    />
                </Stack>
                {isProjectExists && (
                    <Stack>
                        <Stack
                            horizontal
                            style={{ marginTop: 10, marginBottom: -10 }}
                        >
                            <Stack
                                style={{
                                    left: '16px',
                                    //color: "#185ABD",
                                    color: 'black',
                                    mixBlendMode: 'normal',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    marginLeft: -15
                                }}
                            >
                                <View item={responseItem} />
                            </Stack>
                            <Stack
                                style={{
                                    fontSize: '12px',
                                    marginLeft: 'auto',
                                    color: '#0078d4',
                                    cursor: 'pointer',
                                    marginRight: 10
                                }}
                                onClick={handleProjectClick}
                            >
                                <TooltipHost content="View Project">
                                    View project
                                </TooltipHost>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default SearchResponseItem;
