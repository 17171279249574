import * as React from "react";
import { ContextualMenu, DirectionalHint, IContextualMenuItem, Icon, Stack } from "@fluentui/react";
import { IProjectListResponse } from "src/Models/IProjectListResponse";
import { useHistory } from "react-router";
import "../Styles/ProjectListsPageStyles.css"

interface IProjectOptionsMenuProps {
  project: IProjectListResponse;
  handleEdit(projectNumber: string): any;
  handleDelete(projectNumber: string): any;
}
export type ProjectOptionsMenuProps = IProjectOptionsMenuProps;

const ProjectOptionsMenu: React.FunctionComponent<ProjectOptionsMenuProps> = (props) => {
  let history = useHistory();
  const { project, handleEdit, handleDelete } = props;
  
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  
  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
  const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault(); // don't navigate
    setShowContextualMenu(true);
  }, []);
  
  const menuItems: IContextualMenuItem[] = [
    {
      key: "edit",
      text: "Edit",
      onClick: () => {
        console.log(`Editing project ${project.title}`);
        handleEdit(project.number);
      }
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => {
        console.log(`Deleting project ${project.title}`);
        handleDelete(project.number);
      }
    }
  ];
  const moreIconRef = React.useRef(null);
  const moreIcon = <div ref={moreIconRef}><Icon  iconName="More" onClick={onShowContextualMenu} /></div>
  
  return (
    <>
      <Stack style={{marginLeft:5,marginTop:3}}>
        {moreIcon}
        <ContextualMenu
          items={menuItems}
          hidden={!showContextualMenu}
          target={moreIconRef}
          onItemClick={onHideContextualMenu}
          onDismiss={onHideContextualMenu}
          directionalHint={DirectionalHint.rightTopEdge}
          coverTarget={false}
          alignTargetEdge
        />
      </Stack>
    </>
  )
}

export default ProjectOptionsMenu;