import { MessageBarType, Stack } from "@fluentui/react";
import { Context, withContext } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import { IProjectAppState } from "../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import DueDate from "./DueDates/DueDate";
import MyProject from "./MyProjects/MyProject";
import Notifications from "./Notifications/Notification";
import { createProjectBtn, dueDateDeck, greetingContainer, greetingText, mainContainer, notificationDeck, projectsDeck, tileContainer, } from "./Styles/Dashboard";
import DisplaySpinner from "../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../Helpers/ProjectConstants";
import Banner from "../Banner/Banner";
import { FeatureFlag } from "../FeatureFlag/FeatureFlag";
import { app } from "@microsoft/teams-js";
import { ITabNavigation } from "../../Models/TabNavigation/ITabNavigation";
import DisplayMessage from "../../Common/Components/DisplayMessage";
import { container } from "../NotificationsPanel/NotificationsPanelStyling";
import { useHistory } from "react-router-dom";

// const Dashboard = () => {
function Dashboard(): React.ReactElement {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        loginUserpersonaProps,
        loadingLoginUserProfile,
        isloadingLoginUserProfileError,
        isLoadingBannerInformation,
        featureFlagInformation,
        isLoadingFeatureFlagInformation,
        isLoadingErrorBannerInformation,
        isBannerAPICallComplete,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
    const [tabNavigation, setTabNavigation] = React.useState<ITabNavigation>({});
    const [greeting, setGreeting] = React.useState("");
    React.useEffect(() => {
        const currentTime = new Date();
        const hours = currentTime.getHours();


        if (hours >= 0 && hours < 12) {
            setGreeting('Good morning');
        } else if (hours >= 12 && hours < 16) {
            setGreeting('Good afternoon');
        } else {
            setGreeting('Good evening');
        }
    }, []);
    React.useEffect(() => {
        app.getContext().then((context) => {
            if (context?.page?.subPageId !== '') {
                setTabNavigation(JSON.parse(context.page.subPageId));
            }
        });
    }, []);


    const createProject = () => {

        history.push("/createproject");
    }

    return (
        <>

            {loadingLoginUserProfile && (
                <>
                    <span role="alert" aria-label={GenericMessages.loadingUserProfile} aria-live="assertive" />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.loadingUserProfile}
                        spinnerText={GenericMessages.loadingUserProfile}
                        spinnerPosition={"right"}
                    />
                </>
            )}
            {(isLoadingBannerInformation || isLoadingFeatureFlagInformation) && (
                <>
                    <span role="alert" aria-label={GenericMessages.loadingDashboard} aria-live="assertive" />
                    <DisplaySpinner
                        accessabilityMessage={GenericMessages.loadingDashboard}
                        spinnerText={GenericMessages.loadingDashboard}
                        spinnerPosition={"right"}
                    />
                </>
            )}
            {!isLoadingBannerInformation && <Banner />}
            {featureFlagInformation && !featureFlagInformation.isFeatureEnabled && <FeatureFlag />}
            {!loadingLoginUserProfile && !isloadingLoginUserProfileError && !isLoadingBannerInformation && isBannerAPICallComplete && !isLoadingErrorBannerInformation && featureFlagInformation.isFeatureEnabled && !isLoadingFeatureFlagInformation && (
                <>
                    <Stack>
                        {tabNavigation?.projectCreationSuccess && (
                            <>
                                <DisplayMessage
                                    accessabilityMessage={tabNavigation.title + " has been created."}
                                    messageType={MessageBarType.success}
                                    message={`${tabNavigation?.title}` + " has been created."}
                                />
                            </>
                        )}
                    </Stack>
                    <div style={mainContainer}>
                        <>

                            <div style={greetingContainer}>
                                <div style={greetingText}>{greeting}, {loginUserpersonaProps?.text}</div>
                                <button style={createProjectBtn} onClick={() => createProject()}>Create Project</button>
                            </div>
                            <div style={tileContainer}>
                                <div style={projectsDeck}>
                                    <MyProject></MyProject>
                                </div>
                                <div style={dueDateDeck}>
                                    <Notifications></Notifications>
                                </div>
                                <div style={notificationDeck}>
                                    <DueDate></DueDate>
                                </div>
                            </div>
                        </>
                    </div>
                </>

            )
            }
        </>
    );
}

const connected = withContext(Dashboard);
export { connected as Dashboard };