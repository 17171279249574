import React from 'react'
import { Checkbox, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, IBasePickerSuggestionsProps, IChoiceGroupOption, Icon, IPersonaProps, ITag, MessageBarType, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import Button from "../Helpers/Button/Button";
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import { useHistory } from "react-router-dom";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { assingPadding, colorred, formText, textFieldStyles } from '../../../Styles/CreateProject';

export default function AdditionalInformation() {
  let history = useHistory();

  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);

  const { useSelector, dispatch } = reduxContext;

  const {
    projectCreate,
    isProjectGettingCreated,
    projectCreationSuccess,
    projectCreateError,
    projectCreationFailure,
    projectCreateRedirect,
    projectContext,
    projectFiles,
    projectFileTags,
    projectProps,
    projectOwner,
    projectMembers,
    deletedProjectMembers,
    projectTags,
    isProjectGettingEdited,
    projectEditSuccess,
    projectEditRedirect,
    projectEditFailure,
    projectEditError,
    loginUserpersonaProps,
    isGettingProjectWorkArea,
    isProjectWorkAreaSuccess,
    isProjectWorkAreaError,
    projectWorkAreaCollection,
    lawFirmAdaptiveDetails,
    isLoading,
    hasError

  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  const { updateNavItem } = useCreateNavContext();

  const handleContinueClick = () => {
    updateNavItem(['fileAttachment','firm', 'outside', 'finance', 'additional', 'review'], 'review')
    history.push('/createproject?componentName=review')
  }
  const handleCancelClick = () => {
    //clear the state
    history.push('/')
  }


  return (
    <div style={{ marginLeft: 20 }} className="pageContainer">

      <div className="contentContainer">
        <Stack tokens={{ childrenGap: 10 }}>
          <div>
            <h2 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#242424' }}>
              Additional information
            </h2>
            <p style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644', marginTop: 15 }}>
              Fill out additional info.
            </p>
          </div>

        </Stack>

        <Stack tokens={{ childrenGap: 10 }}>
          <div>
            <h3 style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 600, color: '#242424', marginTop: 15 }}>
              Litigation Matter Profile
            </h3>

          </div>

        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              First Named Plantiff
              {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>


          <Stack style={assingPadding}>
            <div style={formText}>
              First Named Defendant {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>


        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Type of Plaintiff {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>

            <div style={formText}>
              Co-Defandants {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Judge {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Served Date {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Service of Process {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Filed date {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Case number {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Where case was orginally filed {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Used for venue charges {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Orginal jurisdiction {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Current jurisdiction {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              City (orginal) {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              City (current) {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Court (original) {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Court (current) {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Initial tier category {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Patent(s) at issue {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Change in tier category {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Number of patents {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Final tier category {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
          <Stack style={assingPadding}>
            <div style={formText}>
              Accused products {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Business segment {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          <div>
            <h3 style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 600, color: '#242424', marginTop: 15 }}>
              Case Support Team
            </h3>

          </div>

        </Stack>


        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              Lead paralegal {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>

          <Stack style={assingPadding}>
            <div style={formText}>
              Assistant paralegal {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { marginBottom: 15 } }}>
          <Stack style={assingPadding}>
            <div style={formText}>
              eDiscovery PM {/* <span style={colorred}>*</span> */}
            </div>
            <div>
              <TextField
                styles={textFieldStyles}
              />
            </div>
          </Stack>
        </Stack>
      </div>
      {/* Buttons at the bottom */}
      <div className="buttonContainer">
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 'auto' }}>
          <Button type='primary' onClick={handleContinueClick}>
            Continue
          </Button>
          <Button type='cancel' onClick={handleCancelClick}>
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
  )
}
