import { IDropdownStyles } from "@fluentui/react"
import * as React from "react"

export const paddingTop4: React.CSSProperties = {
    paddingTop: "4px"
}

export const projectInsightsWidth: React.CSSProperties =
{
    // width: "300px",
    paddingLeft: "35px"
}

export const insightsTitleStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "20px",
    color: "#000000",
    marginTop: "2%",
    marginLeft: "4%",
}

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%", },
    dropdownItem: {
        fontFamily: "'Segoe UI'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#171717",
        marginLeft: "2%",
    }
};

export const dropdownPadding: React.CSSProperties = {
    paddingTop: "12px",
    marginLeft: "4%"
}


export const generateInsightsLink: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    marginTop:'67px',
    cursor:"pointer"
}

export const seeGenerateInsightsText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    marginTop:'15px',
    marginBottom: '20px'
}

export const cardTitle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    color: "#000",
    marginLeft: "10px",
    marginTop: "5px"
}

export const iconStyles: React.CSSProperties = {
        fill: '#000',
        width: '13.333px',
        height: '12px',
        marginTop: 3,
}

export const generateInsightsCardStyle: React.CSSProperties = {
    width: '250px',
    height: '250px',
    flexShrink: 0,
    borderRadius: '4px',
    background: '#FFF',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
    padding: '16px 16px 16px 16px',
    marginBottom: '20px'

}