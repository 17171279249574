import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1"

export const SectionStyle: React.CSSProperties =
{
    marginTop: 10,
    marginLeft: "2%",
    maxHeight: "150px",
    overflowY: "scroll"
}

export const paddingTop4: React.CSSProperties = {
    paddingBottom: 10
}

export const paddingLeft5: React.CSSProperties =
{
    paddingLeft: "5px",
    fontSize: "14px",

}

export const width65: React.CSSProperties =
{
    width: "75%",
}
export const width50: React.CSSProperties =
{
    width: "50%",
}

export const width40: React.CSSProperties =
{
    width: "40%",
}

export const width30: React.CSSProperties =
{
    width: "30%",
}
export const width20: React.CSSProperties =
{
    width: "30%",
}

export const width15: React.CSSProperties =
{
    width: "15%",
}

export const sectionheaderStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    color: "#242424",
    marginTop: "5%",
    marginLeft: "2%",
    flex: 1
}

export const fileNameStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",

    color: "#171717",
    marginLeft: "10px",
    marginTop:10
}

export const fileNameStyle2: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",

    color: "#171717",
    marginLeft: "auto",
    marginRight: "5px",
    marginTop: "11px"
}

export const fileiconStyle: React.CSSProperties =
{
    marginTop:12
}
export const fileNameWidth: React.CSSProperties =
{
    marginLeft: 5,
    color:'#616161',
    fontSize:12,
    width:"80%"
}
export const fileiconStyleCP: React.CSSProperties =
{
   
}
export const fileNameWidthCP: React.CSSProperties =
{
    marginLeft: 5,
    color:'#616161',
    fontSize:12,
   
}
export const deleteiconStyle: React.CSSProperties =
{
    verticalAlign: "bottom",
    paddingLeft: "10px",
    cursor: "pointer",

    marginRight: "10px"
}

export const addAttachment: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    paddingTop: "10px",
    marginTop: 10,
    marginLeft: "auto",
    //marginRight: "6%",
    cursor: "pointer",
    textAlign: 'right'
}
export const uploadBtnStyle: React.CSSProperties =
{
    backgroundColor: 'white',
    border: 'none',
    width: '100px',
    height: '32px',
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Segoe UI',
    fontWeight: '600', // semi-bold
    fontSize: '14px',
    color: '#242424'
}

export const viewAttachment: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    paddingTop: "10px",
    marginLeft: "2%",
    marginTop: "10px",
    cursor: "pointer"
}

export const userinput: React.CSSProperties =
{
    padding: "5px 3px",
    marginLeft: "auto",
    //marginRight: "2%",
    border: "none",
    //flex: '0 0 225px',
    marginTop: 25
}

export const attachmentContainer: React.CSSProperties =
{
    display: 'flex',
    justifyContent: 'space-between'
}

export const userInputBoxStyle: React.CSSProperties =
{
    border: "none"
}

export const bold: React.CSSProperties =
{
    fontWeight: 600,
}

export const addFileControl: React.CSSProperties = {
    visibility: "hidden",
    position: "absolute",
    cursor: "pointer",
    display: 'none'
}
