import * as React from 'react';

const CreateNavContext = React.createContext(null);

const CreateNavProvider = ({ children }) => {
  const [currentNavItem, setCurrentNavItem] = React.useState<string>('');
  const [navItemsCompleted, setNavItemsCompleted] = React.useState<string[]>([]);
  const [mainSidePanel, setMainSidePanel] = React.useState(false);
  const [subMenuSidePanel, setSubMenuSidePanel] = React.useState(false);
  const updateNavItem = (navItems: string[], navItem: string) => {
    setCurrentNavItem(navItem)
    setNavItemsCompleted(navItems);

  }
  const updateMainSidePanel = (value: boolean) => {
    setMainSidePanel(value);
  };

  const updateSubMenuSidePanel = (value: boolean) => {
    setSubMenuSidePanel(value);
  };
  return <CreateNavContext.Provider value={{ currentNavItem, updateNavItem, navItemsCompleted, mainSidePanel,updateMainSidePanel, subMenuSidePanel,updateSubMenuSidePanel }}>{children}</CreateNavContext.Provider>;
}
function useCreateNavContext() {
  const context = React.useContext(CreateNavContext);
  if (context === undefined) throw new Error('CitiesContext was used outside the CitiesProvider');
  return context;
}

export { CreateNavProvider, useCreateNavContext }