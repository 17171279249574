import { Stack, TooltipHost, TextField, ITextField,Icon } from "@fluentui/react"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { truncateText } from "../../../../Helpers/ProjectHelper"
import ChatSvg from "../ChatSvg"
import { IUserChatLogsResponse } from "../../../../Models/Chat/IUserChatLogsResponse"
import { LitigationContext } from "../ChatHome";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import "./styles/PromptTextColumn.css";
import {
    requestDeleteChatTopic,
    requestEditChatTopic,
    setTopicInContext,
    shouldShowChatLogs,
} from "../../../../Shared/Actions/Chat.action";
import { IChatTopicDeleteRequest } from "../../../../Models/Chat/IChatTopicDeleteRequest"
import { IChatTopicEditRequest } from "../../../../Models/Chat/IChatTopicEditRequest"

export interface IPromptTextColumnProps {
    isMyPrompts?: boolean;
    isTeamPromps?: boolean;
    item?: IUserChatLogsResponse
}

const PromptTextColumn = (promptTextColumnProps: IPromptTextColumnProps) => {
    const { user, projectNumber } = React.useContext(LitigationContext);
    const { isMyPrompts, item } = promptTextColumnProps
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { dispatch } = reduxContext;
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [topicText, setTopicText] = useState<string>(item?.topicName || "");
    const topicNameRef = useRef(null);

    const editQuestion = useCallback(() => {
        setIsEditing(true);        
        setTimeout(() => topicNameRef.current?.focus(), 0);
    }, []);

    const deleteChatTopic = useCallback(() => {
        const chatTopicDeleteRequest: IChatTopicDeleteRequest = {
            topicId: item?.topicId || "",
            userAlias: user?.secondaryText || "",
            projectNumber: projectNumber,
        };
        dispatch(requestDeleteChatTopic(chatTopicDeleteRequest));
    }, [dispatch, item, user, projectNumber]);

    const cancelEditQuestion = useCallback(() => {
        setIsEditing(false);
        setTopicText(item?.topicName || ""); // Reset to the original topic name
    }, [item]);

    const showMessages = useCallback(
        (messageId?: string) => {
            if (!messageId) return;
            dispatch(setTopicInContext(messageId, item?.topicUser || ""));
            dispatch(shouldShowChatLogs(false));
        },
        [dispatch, item]
    );

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTopicText(event.target.value || "");
    }, []);

    useEffect(() => {
        if (!isEditing) {
            setTopicText(item?.topicName || "");
        }
    }, [item, isEditing]);

    const updateChatTopic = () => {
        setIsEditing(false);
        const chatTopicEditRequest: IChatTopicEditRequest = {
          topicId: item.topicId,
          topicName: topicText,
          userAlias: user?.secondaryText,
          projectNumber: projectNumber
        };
        setTopicText(chatTopicEditRequest.topicName);
        dispatch(requestEditChatTopic(chatTopicEditRequest));
      };

    return (
        <>
            <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 1 }}
                aria-expanded={isEditing}
                className="prompt-container">
                {/* Left Section: SVG and Topic Name */}
                <Stack horizontal className="prompt-left">
                    {/* SVG Section */}
                    <Stack className="prompt-svg">
                        <ChatSvg />
                    </Stack>
                    {/* Topic Name Section with Fixed Width */}
                    <Stack className="prompt-topic">
                        {!isEditing ? (
                            <TooltipHost setAriaDescribedBy={true} content={item?.topicName}>
                                <div className="prompt-topic__name" 
                                ref={topicNameRef}
                                onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && showMessages(item?.topicId)}
                                onClick={() => showMessages(item?.topicId)} role="button" tabIndex={0}>
                                    {truncateText(item?.topicName, 20, 20)}
                                </div>
                            </TooltipHost>
                        ) :
                            (
                                <TextField
                                   
                                    name="topic title"
                                    value={topicText}
                                    onChange={handleInputChange}
                                    placeholder="topic title"
                                    title="topic title"
                                    ariaLabel="Edit the topic title"
                                    errorMessage={!topicText ? "Topic title cannot be empty" : undefined}
                                />
                            )
                        }
                    </Stack>
                    <Stack
                        horizontal
                        horizontalAlign="end"
                        tokens={{ childrenGap: 10 }}
                        className="prompt-actions">
                        {isMyPrompts && !isEditing && (
                            <>
                                <Icon iconName="Edit" onClick={() => editQuestion()} className="icon" tabIndex={0}
                                    aria-label="Edit this topic" />
                                <Icon iconName="Delete" onClick={() => deleteChatTopic()} className="icon" tabIndex={0}
                                    aria-label="Delete this topic" />
                            </>
                        )}
                        {isEditing && (
                            <>
                            <Icon
                                tabIndex={0}
                                iconName="CheckMark"
                                onClick={updateChatTopic}
                                style={{ cursor: "pointer", fontSize: 22 }}
                                 aria-label="update topic"
                                className="close-icon"
                            />
                            <Icon
                                iconName="ChromeClose"
                                onClick={cancelEditQuestion}
                                tabIndex={0}
                                aria-label="Cancel editing"
                                className="close-icon"
                            /></>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}

export default PromptTextColumn