import { ICheckboxStyles, IComboBoxOptionStyles, IDatePickerStyles, IDropdownStyles, ITextFieldStyles } from "@fluentui/react";
import { reduce } from "lodash";
import * as React from "react";

export const container: React.CSSProperties =
{
  marginLeft: "0px",
  marginRight: "20px",
  background: "#F5F5F5",
  marginTop: "20px",
  boxShadow: "0px 5.2px 5.6px rgba(0, 0, 0, 0.13), 0px 10.4px 14.4px rgba(0, 0, 0, 0.07)",
  minHeight: 750,
  height: "auto"
}

export const assingPadding: React.CSSProperties =
{
  marginTop: "10px",
  marginLeft: "25px",
}

export const reset: React.CSSProperties =
{
  color: "#5B5FC7",
  marginLeft: "-10px",
  marginTop: "2px",
  fontSize: "16px",
}

export const colorred: React.CSSProperties =
{
  color: "red",
}

export const paddingTop10: React.CSSProperties = {
  paddingTop: "10px"
}

export const createProjectTitle: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "20px",
  color: "#242424",
  marginTop: "20px",
  marginLeft: "25px",
}

export const formText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "28px",
  color: "#242424",
}
export const datePicker: React.CSSProperties =
{
  width: "100px"
}
export const controlWidth: React.CSSProperties =
{
  width: "332px",
  height: 40

}
export const controlWidthAttachementLabel: React.CSSProperties =
{
  width: "332px",
  height: 40

}
export const controlWidthAttachment: React.CSSProperties =
{
  width: "332px",

}
export const controlWidthFiles: React.CSSProperties =
{
  width: "332px",
  maxHeight: "124px",
  overflowY: "scroll",
  background:"#fff",
  paddingLeft:10
}
export const controlPeoplePickerStyles: React.CSSProperties =
{
  width: "332px",
  minHeight: 40,
  height: "auto"
}

export const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { width: "332px", minHeight: "40px",border:"none" }
};

export const textFieldDescStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    width: "332px",
    border: "none",
    maxHeight: 64,
    display: "flex",
    flexDirection: "column",
  },
  field: {
    flex: 1,
    overflowY: "auto",
    minHeight: 40,
    padding: "4px 8px",
  },
};


export const textFieldStylesFull: Partial<ITextFieldStyles> = {
  fieldGroup: { width: "100%" }
};


export const dateCSS: Partial<IDatePickerStyles> =
 { textField: {width: 332, height: 40,border:"none" }
};

export const customDatePickerStyles: Partial<IDatePickerStyles> = {
  textField: { 
  width: 332, height: 40,border:"none","& .ms-TextField-fieldGroup": {
    border: "none"}
  },
 
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "332px", height: 40, },
  title:{border:"none"},
  
};

export const dropdownOptionStyles: Partial<IComboBoxOptionStyles> = {
  optionText: {
    // Styles for the text inside the options
    overflow:"visible"
  },
  
};

export const tagPickerStyling: React.CSSProperties =
{
  width: "332px"
}

export const createButtonText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  color: "#FFFFFF",
}

export const createButtonStyle: React.CSSProperties =
{
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 12px",
  width: "125px",
  height: "35px",
  background: "#4F52B2",
  borderRadius: "6px",
  borderWidth: "0px",
  boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.32), 0px 1.6px 3.6px rgba(0, 0, 0, 0.28)",
  cursor: "pointer",
  marginTop: 20,
  marginLeft: 25,
  marginRight: 15,
  marginBottom: 20
}

export const cancelButtonText: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  color: "#000000",
}

export const cancelButtonStyle: React.CSSProperties =
{
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 12px",
  width: "125px",
  height: "35px",
  background: "#FFFFFF",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#000000",
  borderRadius: "6px",
  boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.32), 0px 1.6px 3.6px rgba(0, 0, 0, 0.28)",
  cursor: "pointer",
  marginTop: 20
}

export const iconStyle: React.CSSProperties =
{
  width: "14px",
  height: "14px",
  color: "#000000",
  border: "none",
  fontSize: "14px",
  marginTop: "12px",
  paddingLeft: "0px"
}

export const addAttachment: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#0000FF",
  paddingTop: "5px",
  
  cursor: "pointer",
  width: 125,
  
}

export const attachmentLabel: React.CSSProperties = {
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "28px",
  color: "#242424",
  cursor: "pointer",
}

export const addFileControl: React.CSSProperties = {
  visibility: "hidden",
  position: "absolute",
  cursor: "pointer",
}

export const goBack: React.CSSProperties =
{
  fontFamily: "'Segoe UI'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#242424",
  marginTop: "10px",
  marginLeft: "10px",
  float: "left",
  cursor: "pointer"
}

export const goBackIconStyle: React.CSSProperties =
{
  // verticalAlign: "bottom", 
  height: "20px",
  width: "20px",
  marginTop: 3,
  color: "#242424 !important",
  fontSize:14
}

export const toolboxIconStyle: React.CSSProperties =
{
  width: "16px",
  height: "16px",
  fontSize: "16px",
  marginTop: "3px",
  marginLeft: "14px",
  color: "#185ABD",
}

export const chkStyle: React.CSSProperties = { marginLeft: "auto", marginRight: "13px" };