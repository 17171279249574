import React from 'react'
import './AppLayout.styles.css'
import { Stack } from '@fluentui/react'
import LeftNavigation from './LeftNavigation/LeftNavigation'
import Middle from './Middle/Middle'
import RightNavigation from './RightNavigation/RightNavigation'

const AppLayout = () => {
  return (
    <Stack horizontal className='app-layout'>      
      <Stack className='middle-section'>
        <Middle />
      </Stack>      
    </Stack>
  )
}
export default AppLayout