import React from 'react'
import './Sidebar.module.css'
import { Link } from 'react-router-dom';
import CreateProjectNav from '../CreateProjectNav/CreateProjectNav';
const Sidebar = () => {
  return (
    <div className='sidebar'>       
      <div className='sidebarNav'>       
        <CreateProjectNav/>
      </div>      
    </div>
  )
}

export default Sidebar
