import { IDetailsListStyles } from "@fluentui/react";

export const fileHistoryItemsStyles: Partial<IDetailsListStyles> = {
    root: {
        height: 250,
        selectors: {
            '.ms-DetailsRow': {
                borderBottom: '1px solid #D1D1D1', // Adjust border style as needed
                height: 48,
                fontSize: 14,
                color: '#242424',
                width: '100%',
            },
        },
    }
};