import * as React from "react";
import { Reducer } from "redux";
import { NavLink, useHistory } from 'react-router-dom';
import './CreateProjectNav.module.css'
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import Disabled from '../../../../../Common/Components/Disabled'
import { Icon, Stack } from '@fluentui/react';
import { IProjectAppState } from 'src/Shared/ProjectState';
import { ProjectReducerName, projectInitialState, projectReducer } from '../../../../../../src/Shared/Reducers/Project.reducer';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { projectSagas } from '../../../../../../src/Shared/Sagas/Project.sagas';
const CreateProjectNav = () => {
	const { currentNavItem, navItemsCompleted, updateNavItem, subMenuSidePanel, updateSubMenuSidePanel } = useCreateNavContext();
	const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
	useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
	const { useSelector, dispatch } = reduxContext;
	const {

		projectContext,
		isProjectDetailsLoading,
		projectProps

	} = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
	let history = useHistory();
	const selectedNavItem: string = currentNavItem === '' ? 'view' : currentNavItem
	let isFileAttachmentCompleted: boolean = navItemsCompleted?.indexOf('files') >= 0 ? true : false;
	let isFirmCompleted: boolean = navItemsCompleted?.indexOf('firm') >= 0 ? true : false;
	let isReviewCompleted: boolean = navItemsCompleted?.indexOf('review') >= 0 ? true : false;;
	let isFinanceCompleted: boolean = navItemsCompleted?.indexOf('finance') >= 0 ? true : false;;
	let isOutsideCounselCompleted: boolean = navItemsCompleted?.indexOf('outside') >= 0 ? true : false;
	let isadditionalCompleted: boolean = navItemsCompleted?.indexOf('additional') >= 0 ? true : false;

	const isFileAttachmentDisabled: boolean = (selectedNavItem !== 'fileAttachment' && !isFileAttachmentCompleted) ? true : false
	const isFirmDisabled: boolean = (selectedNavItem !== 'firm' && !isFirmCompleted) ? true : false
	const isReviewDisabled: boolean = (selectedNavItem !== 'review' && !isReviewCompleted) ? true : false
	const isFinancewDisabled: boolean = (selectedNavItem !== 'finance' && !isFinanceCompleted) ? true : false
	const isOutsideDisabled: boolean = (selectedNavItem !== 'outside' && !isOutsideCounselCompleted) ? true : false
	const isAdditionalDisabled: boolean = (selectedNavItem !== 'additional' && !isadditionalCompleted) ? true : false
	const [isCollapsed, setCollapsed] = React.useState(false);
	const [isEdit, setIsEdit] = React.useState(false);

	React.useEffect(() => {
		if (projectContext?.number && projectContext?.number.indexOf('KMTeamAPP') === -1) {
			setIsEdit(true);
		}
		else {
			setIsEdit(false);
		}

	}, [projectContext]);

	const collapsed: React.CSSProperties =
	{
		marginLeft: -150
	}
	const expanded: React.CSSProperties =
	{
		transition: 'margin-left 0.5s'
	}

	const iconExpanded: React.CSSProperties =
	{
		paddingTop: 15,

	}

	const iconCollpased: React.CSSProperties =
	{

		marginLeft: -230,
	}

	const collpasedIcon: React.CSSProperties =
	{

		marginLeft: 240,
		paddingTop: 15,
	}

	const expandedIcon: React.CSSProperties =
	{


	}

	const classNameHightlightNav = isCollapsed ? "hightlightNavCollapsed" : "hightlightNavExpanded";

	const handleToggleCollapse = () => {
		setCollapsed(!isCollapsed);
		updateSubMenuSidePanel(!isCollapsed);
	};
	return (
		<nav className='nav' style={isCollapsed ? iconCollpased : iconExpanded}>
			<Stack onClick={handleToggleCollapse} >
				{/* Insert your hamburger menu icon here */}
				<Icon
					onClick={handleToggleCollapse}
					iconName="CollapseMenu"
					style={{
						color: "rgb(79, 82, 178)",
						fontSize: 18,
						cursor: "pointer",
						...(isCollapsed ? collpasedIcon : expandedIcon)
					}}
				/>
			</Stack>

			{!isEdit && (
				<Icon
					onClick={() => {
						updateNavItem(['basic'], 'basic');
						history.push("/createproject?componentName=basic");
					}}
					title="Basic Information"
					iconName="PageList"
					style={{
						color: "rgb(79, 82, 178)",
						fontSize: 18,
						cursor: "pointer",
						display: isCollapsed ? "block" : "none",
						marginLeft: 245,
						marginTop: 56
					}}
				/>
			)}

			{isEdit && !isProjectDetailsLoading && (
				<>
					<Icon
						onClick={() => {
							updateNavItem(['basic'], 'basic');
							history.push("/createproject?componentName=basic");
						}}
						title="Basic Information"
						iconName="PageList"
						style={{
							color: "rgb(79, 82, 178)",
							fontSize: 18,
							cursor: "pointer",
							display: isCollapsed ? "block" : "none",
							marginLeft: 245,
							marginTop: 56
						}}
					/>
					<Icon
						onClick={() => {
							updateNavItem(['basic', 'fileAttachment'], 'fileAttachment');
							history.push("/createproject?componentName=fileAttachment");
						}}
						iconName="Document"
						title="Documents To Review"
						style={{
							color: "rgb(79, 82, 178)",
							fontSize: 18,
							cursor: "pointer",
							display: isCollapsed ? "block" : "none",
							marginLeft: 245,
							marginTop: 21
						}}
					/>
				</>
			)}

			<div className='createProjectText'>
				{isEdit ? 'Edit a project' : 'Create a project'}
			</div>
			<ul>

				{!isEdit && (
					<li className={`navItemLayout ${selectedNavItem === 'basic' ? classNameHightlightNav : ''}`}>
						<NavLink className='navLink' to="/createproject?componentName=basic" onClick={() => (updateNavItem(['basic'], 'basic'))}>Basic Information</NavLink>
					</li>
				)}

				{isEdit && !isProjectDetailsLoading && (
					<>
						<li className={`navItemLayout ${selectedNavItem === 'basic' ? classNameHightlightNav : ''}`}>
							<NavLink className='navLink' to="/createproject?componentName=basic" onClick={() => (updateNavItem(['basic'], 'basic'))}>Basic Information</NavLink>
						</li>
						{projectProps?.workArea?.toLowerCase().includes('litigation') && (
							<li className={`navItemLayout ${selectedNavItem === 'fileAttachment' ? classNameHightlightNav : ''}`}>
								<Disabled disabled={false}>
									<NavLink className={`navLink`} to="/createproject?componentName=fileAttachment" onClick={() => (updateNavItem(['basic', 'fileAttachment'], 'fileAttachment'))}>Documents To Review</NavLink>
								</Disabled>
							</li>
						)}
					</>
				)}




				{/* <li className={`navItemLayout ${!isFirmDisabled && selectedNavItem === 'firm' ? classNameHightlightNav : ''}`}>
					<Disabled disabled={isFirmDisabled}>
						<NavLink className={`navLink`} to="/createproject?componentName=firm" onClick={() => (updateNavItem(['basic', 'fileAttachment', 'firm'], 'firm'))}>Firm selection</NavLink>
					</Disabled>
				</li>
				<li className={`navItemLayout ${!isOutsideDisabled && selectedNavItem === 'outside' ? classNameHightlightNav : ''}`}>
					<Disabled disabled={isOutsideDisabled}>
						<NavLink className={`navLink`} to="/createproject?componentName=outside" onClick={() => (updateNavItem(['basic', 'fileAttachment', 'firm', 'outside'], 'outside'))}>Outside counsel selection</NavLink>
					</Disabled>
				</li>
				<li className={`navItemLayout ${!isFinancewDisabled && selectedNavItem === 'finance' ? classNameHightlightNav : ''}`}>
					<Disabled disabled={isFinancewDisabled}>
						<NavLink className={`navLink`} to="/createproject?componentName=finance" onClick={() => (updateNavItem(['basic', 'fileAttachment', 'firm', 'outside', 'finance'], 'finance'))}>Financial information</NavLink>
					</Disabled>
				</li>
				<li className={`navItemLayout ${!isAdditionalDisabled && selectedNavItem === 'additional' ? classNameHightlightNav : ''}`}>
					<Disabled disabled={isAdditionalDisabled}>
						<NavLink className={`navLink`} to="/createproject?componentName=additional" onClick={() => (updateNavItem(['basic', 'fileAttachment', 'firm', 'outside', 'finance', 'additional'], 'additional'))}>Additional information</NavLink>
					</Disabled>
				</li>
				<li className={`navItemLayout ${!isReviewDisabled && selectedNavItem === 'review' ? classNameHightlightNav : ''}`}>
					<Disabled disabled={isReviewDisabled}>
						<NavLink className={`navLink`} to="/createproject?componentName=review" onClick={() => (updateNavItem(['basic', 'fileAttachment', 'firm', 'outside', 'finance', 'additional', 'review'], 'review'))}>Review</NavLink>
					</Disabled>
				</li> */}
			</ul>

		</nav>
	)
}
export default CreateProjectNav