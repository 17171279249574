import { IProjectFiles } from "../Models/IProjectFile";

export const getFileExtension = (fileName: string): string => {
    var extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    return extension
}

const getFileName = (fileName: any) => new URL(fileName).pathname.split("/").pop();

export const getIconByFileExtension = (fileName: string): string => {
    if (fileName?.startsWith("https")) {
        fileName = getFileName(fileName)
    }
    let extension = getFileExtension(fileName);
    switch (extension.toLowerCase()) {
        case "pdf":
            return "pdf"
        case "pptx":
        case "ppt":
            return "ppt"
        case "docx":
        case "doc":
            return "docx"
        case "png":
        case "jpeg":
        case "jpg":
        case "bmp":
        case "icon":
            return "jpg"
        case "xlsx":
        case "xls":
        case "csv":
            return "xlsx"
        case "txt":
        case "json":
            return "txt"
        case "msg":
            return "msg"
        case "one":
            return "one"
        case "zip":
            return "zip"
        case "msg":
            return "mail"
        default:
            return "txt"
    }
}

export const truncateText = (textToTruncate: string, length: number, numCharsToShow: number): string => {
    return textToTruncate?.length > length ? textToTruncate.substring(0, numCharsToShow) + " ..." : textToTruncate;
}

export const checkIfFileExistsInSPO = (fileName: string, projectFiles: IProjectFiles): boolean => {
    let isNew = true;
    projectFiles?.projectFile?.map((projectFile) => {
        if (projectFile.file.name === fileName) {
            isNew = false;
        }
    });
    return isNew;
}


export const getCurrentDate = (separator = '/') => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
}

export const getFutureDate = (days: number, separator = "/") => {
    var today = new Date();
    let newDate = new Date(new Date().setDate(today.getDate() + days));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
}

export const getPastDate = (days: any, separator = "/") => {
    var today = new Date();
    let newDate = new Date(new Date().setDate(today.getDate() - days));
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${year}`
}

export const formatDate = (date?: Date): string => {
    return !date ? '' : ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + (date.getFullYear());
};

export const getDateFromString = (dateString?: string): Date => {
    // var UTCDate = new Date(dateString).toUTCString()
    var date = new Date(dateString)
    date.setHours(date.getHours() + 8);
    return date;
};

export const isObjectsSame = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}



export const getFormattedDate = (dateString?: string): string => {
    if(dateString!=='') {
        var date = new Date(dateString);
        var formattedDate = formatDate(date);
        return formattedDate.toString();
    }
    return '';
};

export const  formatDateString = (dateString: string): string =>{
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }
  export const  formatDateStringMMMdd = (dateString: string): string =>{
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric'
      });
    return formattedDate;
  }