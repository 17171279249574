import { Stack } from "@fluentui/react"
import React, { useEffect, useRef, useState } from "react"
import "./styles/ChatLogsTab.css";
export interface IChatLogsTabProps {
    onSelect: (isMyPrompts: boolean) => void;
}
const ChatLogsTab = (chatLogsTabProps: IChatLogsTabProps) => {
    const { onSelect } = chatLogsTabProps
    const [isMyPrompts, setIsMyPrompts] = useState<boolean>(true)
    const myPromptsRef = useRef<HTMLButtonElement>(null);
    const handleMyPrompts = () => {
        onSelect(true);
        setIsMyPrompts(true)
    }
    const handleTeamPrompts = () => {
        onSelect(false);
        setIsMyPrompts(false)
    }
    useEffect(() => {
        // Focus the "My Prompts" button on component mount
        myPromptsRef.current?.focus();
    }, []);
    const getClassNames = (isActive: boolean) => `button ${isActive ? "active" : ""}`;
    return (

        <Stack horizontal tokens={{ childrenGap: 10 }} className="chatlogs-tab">
            <button onClick={handleMyPrompts} aria-pressed={isMyPrompts}
                ref={myPromptsRef}
                className={getClassNames(isMyPrompts)}
                title="View my prompts"
                aria-label="My prompts"
            >My prompts</button>
            <button onClick={handleTeamPrompts} aria-pressed={!isMyPrompts}
                className={getClassNames(!isMyPrompts)}
                title="View team prompts"
                aria-label="Team prompts"
            >Team prompts</button>
        </Stack>
    )
}
export default ChatLogsTab