import { Icon, Stack, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { Reducer } from "redux";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { IProjectAppState } from "../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import { dueDateText, container, detailsText, stackStyle, stackStyle1, dueDateTextWidth, nodueDateText, dateTextWidth } from "./Styles/Bookmark";
import { truncateText } from "../../../../Helpers/ProjectHelper";
import { requestBookmark } from "../../../../Shared/Actions/Project.action";
import DisplaySpinner from "../../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../../Helpers/ProjectConstants";
import ErrorDisplay from "../../../../Common/Components/ErrorDisplay";
import { useHistory } from "react-router-dom";
import { projectTitle, titleStyle } from "../MyProjects/Styles/MyProject";
import { IBookmarkResponse } from "src/Models/IBookmarkResponses";
const MyBookmark = () => {
    let history = useHistory();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        bookmarkResponses,
        isBookmarkResultsLoading,
        isBookmarkResultsLoadingError,
        bookmarkSearchRequest,
        loginUserpersonaProps,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            bookmarkSearchRequest.userAlias = loginUserpersonaProps?.secondaryText;
            dispatch(requestBookmark(bookmarkSearchRequest));
        }
    }, [loginUserpersonaProps]);



    function openProjectURL(e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string): void {

        history.push(`/viewproject?componentName=view&projectNumber=${projectNumber}`);

    }

    return (
        <Stack style={container}>
            <Stack horizontal style={titleStyle}>
                <Stack>
                    <Icon
                        iconName="SingleBookmark"
                        style={{
                            fill: '#000',
                            width: '13.333px',
                            height: '12px',
                            marginTop: 3
                        }}
                    />
                </Stack>
                <Stack>
                    <div style={projectTitle}>
                        Bookmarks
                    </div>
                </Stack>

            </Stack>
            {
                isBookmarkResultsLoading && (
                    <Stack style={{ marginTop: -75 }}>
                        <span role="alert" aria-label={GenericMessages.dueDateProjectsLoading} aria-live="assertive" />

                        <DisplaySpinner
                            accessabilityMessage={"Loading bookmark details"}
                            spinnerText={"Loading bookmark details..."}
                            spinnerPosition={"right"}
                        />
                    </Stack>
                )}

            {!isBookmarkResultsLoading && isBookmarkResultsLoadingError && (
                <>
                    <ErrorDisplay source={"Error in loading bookmark details"} />
                </>
            )}

            {!isBookmarkResultsLoading && !isBookmarkResultsLoadingError && bookmarkResponses && bookmarkResponses?.bookmarkResponses?.length > 0 && (
                <>
                    <Stack>
                        {bookmarkResponses.bookmarkResponses.slice(0, 5).map((response: IBookmarkResponse) => {
                            return (

                                <Stack horizontal style={stackStyle}>
                                    <Stack style={dueDateTextWidth}>
                                        <div style={dueDateText}>
                                            <TooltipHost
                                                content={response?.title}
                                                aria-label={response?.title}
                                                tabIndex={0}
                                            >
                                                {truncateText(response?.title, 25, 25)}
                                            </TooltipHost>
                                        </div>
                                    </Stack>

                                    <Stack style={dateTextWidth}>
                                        <div style={dueDateText}>
                                            {response?.startDate}
                                        </div>
                                    </Stack>

                                    <Stack style={stackStyle1}>                                        
                                        {response?.title?.toLowerCase() !== 'project not available' && (
                                            <div style={detailsText} onClick={(e) => openProjectURL(e, response.number)} tabIndex={0}>
                                                Details
                                            </div>
                                        )}
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>

                </>
            )}
            {!isBookmarkResultsLoadingError && bookmarkResponses?.bookmarkResponses?.length === 0 && (
                <>
                    <Stack>
                        <div style={nodueDateText}>
                            {"No bookmarks found"}
                        </div>

                    </Stack>
                </>
            )}


        </Stack>
    )
}

export default MyBookmark;