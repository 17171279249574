import { IButtonStyles, mergeStyleSets, getTheme, FontWeights, IStackStyles } from "@fluentui/react";
const theme = getTheme();
export const primaryButtonStyles: IButtonStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 20px",
    width: "200px",
    height: "32px",
    background: "#FFFFFF",
    borderRadius: "2px",
  },
};
export const buttonParentStackStyles: IStackStyles = {
  root: { marginTop: 17, marginLeft: "auto", marginRight: 24 },
};
export const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: "#fff",
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    backgroundColor: "#0078D4",
    color: "#fff",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  heading: {
    width: "32px",
    height: "22px",
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    margin: "0",
    color: "#FFFFFF",
  },
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  bodyContent: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    marginLeft: 24,
    marginRight: 24,
  },
});
