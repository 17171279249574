import { ProgressIndicator } from "@fluentui/react";
import { Stack } from "@fluentui/react";
import React from "react";
import {
  OkContainerStyles,
  ProgressBarContainerStyles,
  ProgressTextContainerStyles,
  ProgressIndicatorContainerStyles,
  ProgressIndicatorStyles,
} from "./Styles/ProgressBar.Style";

import './styles/ProgressBar.styles.css'

export interface IProgressBarProps {
  progressbarText?: string;
  progressbarStyle?: string
}

const ProgressBar = (progressBarProps: IProgressBarProps) => {
  const { progressbarText, progressbarStyle } = progressBarProps;
  return (
    <>
      <Stack style={ProgressBarContainerStyles} className={progressbarStyle}>
        <Stack style={OkContainerStyles}>
          <Stack style={ProgressTextContainerStyles}>{progressbarText ?? "Loading..."}</Stack>
        </Stack>
        <Stack style={ProgressIndicatorContainerStyles}>
          <ProgressIndicator styles={ProgressIndicatorStyles} barHeight={4}/>
        </Stack>
      </Stack>
    </>
  );
};

export default ProgressBar;
