import { IDropdownStyles } from "@fluentui/react"

export const sectionheaderStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    color: "#242424",
    marginTop: "5%",
    marginLeft: "2%",
    flex: 1
}

export const teamContainer: React.CSSProperties =
{
    display: 'flex',
  justifyContent: 'space-between'
}

export const sectionStyle: React.CSSProperties = {
    width: '100%',
    padding: '20px',
};

export const paddingTop4: React.CSSProperties = {
    paddingTop: "4px"
}

export const paddingLeft5: React.CSSProperties =
{
    paddingLeft: "5px",
    fontSize: "14px",

}

export const width65: React.CSSProperties =
{
    width: "75%",
}
export const width50: React.CSSProperties =
{
    width: "50%",
}

export const width40: React.CSSProperties =
{
    width: "40%",
}

export const width30: React.CSSProperties =
{
    width: "30%",
}
export const width20: React.CSSProperties =
{
    width: "20%",
}

export const width15: React.CSSProperties =
{
    width: "15%",
}

export const memberSectionStyle: React.CSSProperties =
{
    marginLeft: "2%",
    paddingTop: "1.5%",
    maxHeight: "212px",
    overflowY: "scroll"
}

export const memberNameStyle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171717",
    marginLeft: "2%",
}

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%", },
    dropdownItem: {
        fontFamily: "'Segoe UI'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        color: "#171717",
        marginLeft: "2%",
    },
    title:{border:"none"}
    
};

export const deleteiconStyle: React.CSSProperties =
{
    verticalAlign: "bottom",
    paddingLeft: "3%",
    cursor: "pointer",
    marginTop: "10px"
}

export const addMember: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    paddingTop: "10px",
    marginLeft: "auto",
    //marginRight: "6%",
    cursor: "pointer"
}

export const userinput: React.CSSProperties =
{
   
    padding: "5px 3px",
    marginLeft: "auto",
    //marginRight: "2%",
    border:"none",
    //flex: '0 0 225px',
    marginTop:25
}

export const userInputBoxStyle:React.CSSProperties =
{
    border:"none"
}

export const bold: React.CSSProperties =
{
    fontWeight: 600,
}



