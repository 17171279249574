import { IProgressIndicatorStyles } from "@fluentui/react";

export const ProgressBarContainerStyles: React.CSSProperties = { 
  
  marginTop:10
};

export const OkContainerStyles: React.CSSProperties = {
  display: "flex",
  //width: "319px",
  padding: "12px 16px 16px 16px",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  //gap: "12px",
  borderRadius: "8px",
  background: "var(--neutral-background-1-rest, #FFF)",
  /* Elevation/Light/Shadow 02 */
  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.14)",
};

export const ProgressTextContainerStyles: React.CSSProperties = {
  alignSelf: "stretch",
  color: "var(--neutral-foreground-1-rest, #242424)",
  fontFamily: "Segoe UI",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
};

export const ProgressIndicatorContainerStyles: React.CSSProperties = {
  display: "flex",
  //width: "319px",
  //height: "4px",
  paddingRight: "0px",
  alignItems: "flex-start",
  bottom: "0px",
  border: "1px solid var(--neutral-stroke-transparent-rest, rgba(255, 255, 255, 0.00))",
  marginTop: -13,
};

export const ProgressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: 100,
  },
};
