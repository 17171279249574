import React from "react";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { Icon, Stack } from "@fluentui/react";
import InlineDisplaySpinner from "../../../../../Common/Components/InlineDisplaySpinner";
import { ILitigationCase, ILitigationCaseFiles } from "../../../../../Models/ILitigationCase";
import { filesClassifySummaryByUserRequest } from "../../../../../Shared/Actions/Project.action";
import { IRequestFilesClassifySummaryByUser } from "../../../../../Models/IRequestFilesClassifySummaryByUser";
export interface IClassificationProgressWebJobProps {
    cases?: ILitigationCase[];
}

const ClassificationProgressWebJob = (classifyFilesProps: IClassificationProgressWebJobProps) => {
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const { cases } = classifyFilesProps

    const { isLoadingFileSummaryByUser,
        isLoadingFileSummaryByUserError,
        responseFilesClassifySummaryByUser, loginUserpersonaProps, projectProps } = useSelector(
            (state: IProjectAppState) =>
                state.dynamic?.[ProjectReducerName] || projectInitialState
        );

        const refreshStatus = () => {
            if (cases && Object.keys(cases).length > 0) {
                const litigationCase: ILitigationCase = cases?.find((caseNameValue: ILitigationCase) => caseNameValue.isSelected === true);
                if(litigationCase){
                    const requestFilesClassifySummaryByUser: IRequestFilesClassifySummaryByUser = {
                        userAlias: loginUserpersonaProps?.secondaryText,
                        projectNumber: projectProps?.number,
                        caseName: litigationCase?.caseName
                    }
                    dispatch(filesClassifySummaryByUserRequest(requestFilesClassifySummaryByUser));
                }            
            }
        }
    return (<>
        {isLoadingFileSummaryByUser && !isLoadingFileSummaryByUserError && (
            <div style={{ marginTop: 5 }}>
                <InlineDisplaySpinner spinnerPosition={"right"} spinnerText="Loading classification summary..." accessabilityMessage="Loading classification summary..." />
            </div>
        )}
        {!isLoadingFileSummaryByUser && !isLoadingFileSummaryByUserError && Object.keys(responseFilesClassifySummaryByUser).length > 0
            && (responseFilesClassifySummaryByUser?.status !== 'NotStarted') && (
                <div style={{ marginTop: 5 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <span>
                            Classification status: <b>{responseFilesClassifySummaryByUser?.status}</b>
                        </span>
                        <span>
                            Files classified:
                            <b>
                                <span style={{ marginLeft: 5 }}>{`${(responseFilesClassifySummaryByUser?.successCount?.toString())}/${responseFilesClassifySummaryByUser?.totalRecords?.toString()}`}</span>
                            </b>
                        </span>
                        {responseFilesClassifySummaryByUser?.status === 'InProgress' && (
                            <span>
                                <Icon iconName="Refresh" style={{ fontSize: 12, cursor:'pointer', marginLeft: 5 }} onClick={refreshStatus} aria-label="refresh"/>
                            </span>
                        )}
                    </Stack>
                </div>
            )}

    </>);
}

export default ClassificationProgressWebJob;