import { Icon, Stack, TooltipHost } from "@fluentui/react"
import React from "react"
import { bookmarkIconStyle } from "./Styles/History.Styles"
import { IHistoryResponse } from "src/Models/IHistoryResponses";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context"
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext"
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer"
import { requestToggleBookmark } from "../../../Shared/Actions/Project.action"
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../Shared/Reducers/Project.reducer"
import { projectSagas } from "../../../Shared/Sagas/Project.sagas"
import { Reducer } from "redux";
import { IProjectAppState } from "../../../Shared/ProjectState"
import { IProjectListResponse } from "src/Models/IProjectListResponse";
import { IProjectProps } from "src/Models/IProjectProps";
import { IResponse } from "src/Models/ISimilarProjectResponse";

export interface IHistoryView {
  item?: IHistoryResponse|IProjectListResponse|IProjectProps|IResponse;

}
const View = (hitoryView: IHistoryView)=>{
  const {item} = hitoryView
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
      historySearchRequest,
      loginUserpersonaProps,
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  const toggleBookmark = (historyResponse: IHistoryResponse) => {
    historyResponse.isBookmarked = !historyResponse.isBookmarked;   
    dispatch(requestToggleBookmark(historyResponse.isBookmarked, historyResponse.number, loginUserpersonaProps?.secondaryText));
    
  };
  return(
    <Stack className="column-clickable-cell" >   
      <TooltipHost content={'Bookmark'}>                   
        <Icon
            tabIndex={0}
            iconName={item.isBookmarked ? "SingleBookmarkSolid" : "SingleBookmark"}
            onClick={() => toggleBookmark(item)}
            style={bookmarkIconStyle}
        />             
        </TooltipHost>            
    </Stack>
  )
}
export default View