import React from 'react'
import './CreateProjectLayout.module.css'
import CreateProject from './CreateProject/CreateProject'
import Sidebar from './Sidebar/Sidebar'
import { CreateNavProvider } from './CreateNavContext/CreateNavContext';
import CreateProjectPage from '../CreateProjectPage';
import { LitigationFilterProvider } from '../CreateProject/CaseDocumentsReview/LitigationFilterContext';

const CreateProjectLayout = () => {
  return (
    <div className='app'>
      <CreateNavProvider>
        <LitigationFilterProvider>
          <Sidebar />
          <CreateProject />
        </LitigationFilterProvider>
      </CreateNavProvider>
    </div>
  )
}
export default CreateProjectLayout