import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import React, { useEffect, useMemo, useState } from "react";
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { Reducer } from "redux";
import { IUserChatLogsRequest } from "../../../../Models/Chat/IUserChatLogsRequest";
import { requestUserChatLogs } from "../../../../Shared/Actions/Chat.action";
import ErrorDisplay from "../../../../Helpers/ErrorDisplay/ErrorDisplay";
import ChatLog from "./ChatLog";
import DisplayMessage from "../../../../Common/Components/DisplayMessage";
import { MessageBarType } from "@fluentui/react";
import { LitigationContext } from "../ChatHome";
import ProgressBar from "../../../../Common/Components/ProgressBar/ProgressBar";
import { Stack } from "@fluentui/react";
const ChatLogs = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { user, projectNumber } = React.useContext(LitigationContext);
  const {
    userChatLogsResponse,
    isRequestingChatLogs,
    isUserChatLogsResponseError,
    chatTopicDeleteResponse,
    isDeletingChatTopic,
    isEditingChatTopic,
    chatTopicEditResponse,
    userChatLogsResponseError,
  } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);;
  const [selectedKey, setSelectedKey] = useState<string>("ProjectPrompts");
  useEffect(() => {
    const userChatLogsRequest: IUserChatLogsRequest = {
      userAlias: user?.secondaryText,
      chatLogsCount: 10,
      projectNumber,
    };
    dispatch(requestUserChatLogs(userChatLogsRequest));
  }, [dispatch, user, projectNumber]);

  useEffect(() => {
    if (chatTopicEditResponse?.isEditTopicSuccess) {
      const userChatLogsRequest: IUserChatLogsRequest = {
        userAlias: user?.secondaryText,
        chatLogsCount: 10,
        projectNumber,
      };
      dispatch(requestUserChatLogs(userChatLogsRequest));
    }
  }, [chatTopicEditResponse, dispatch, user, projectNumber]);

  const chatLogs = useMemo(() => {
    if (userChatLogsResponse?.length > 0) {
      return userChatLogsResponse;
    }
    return [];
  }, [userChatLogsResponse]);

  useEffect(() => {
    if (chatTopicDeleteResponse?.isDeleteTopicSuccess) {
      const userChatLogsRequest: IUserChatLogsRequest = {
        userAlias: user?.secondaryText,
        chatLogsCount: 10,
        projectNumber,
      };
      dispatch(requestUserChatLogs(userChatLogsRequest));
    }
  }, [chatTopicDeleteResponse, dispatch, user, projectNumber]);


  const renderProgressBar = useMemo(
    () => (message: string) => (
      <Stack style={{ marginTop: 100, marginLeft: "auto", marginRight: 50 }} aria-live="polite"
        role="status">
        <ProgressBar progressbarText={message} />
      </Stack>
    ),
    []
  );

  const onAllPromptsSelected= (isAllPromptsSelected: boolean)=>{
    setSelectedKey(isAllPromptsSelected ? "AllPrompts" : "ProjectPrompts");
    const userChatLogsRequest: IUserChatLogsRequest = {
      userAlias: user?.secondaryText,
      chatLogsCount: 10,
      projectNumber:!isAllPromptsSelected?projectNumber:'',
    };
    dispatch(requestUserChatLogs(userChatLogsRequest));
  }

  return (
    <>
      {isRequestingChatLogs && renderProgressBar("Loading chat history...")}
      {isDeletingChatTopic && renderProgressBar("Deleting chat topic...")}
      {isEditingChatTopic && renderProgressBar("Editing chat topic...")}
      {!isRequestingChatLogs && isUserChatLogsResponseError && <ErrorDisplay error={userChatLogsResponseError} />}

      {!isRequestingChatLogs &&
        !isUserChatLogsResponseError &&
        !isDeletingChatTopic &&
        !isEditingChatTopic &&
        chatLogs.length > 0 && (
          <Stack style={{ marginLeft: 40, marginTop: 15 }}>
            <ChatLog chatLogs={chatLogs} onAllPromptsSelected={onAllPromptsSelected} selectedKey={selectedKey}/>
          </Stack>
        )}
      {!isRequestingChatLogs &&
        !isUserChatLogsResponseError &&
        !isDeletingChatTopic &&
        !isEditingChatTopic &&
        chatLogs.length === 0 && (
          <DisplayMessage
            accessabilityMessage="No chat logs are present"
            messageType={MessageBarType.warning}
            message="No chat logs are present"            
          />
        )}
    </>
  );
};

export default ChatLogs;
