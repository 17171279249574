import { IStackStyles } from "@fluentui/react";

export const chatLogContainerStyles: IStackStyles = {
  root: {
    minWidth: "319px",    
    padding: "10px",    
    marginBottom: 5,
    marginLeft: "-35px",
  },
};
export const chatLogStyles: IStackStyles = {
  root: {
    padding: "12px",
    border: "1px solid white",
    borderRadius: "5px",
    margin: "10px",
    transition: "ease 0.25s all",
    textAlign: "left",
    fontSize: "large",
  },
};

export const ChatIconSvgTyles: React.CSSProperties = {
  width: 15,
  height: 15,
  marginLeft: 10,
  marginTop: 2,
  fill: "linear-gradient(0deg, #737270 0%, #737270 100%), Global.Color.Grey.14",
};

export const ChatLogTextViewStyle: React.CSSProperties = {
  width: "85%",
  overflow: "hidden",
  color: "#242424",
  textOverflow: "ellipsis",
  marginLeft: 10,
  fontFamily: "Segoe UI",
  fontSize: "14px",
  fontStyle: "normal",  
  lineHeight: "20px",
  cursor: "pointer",
};

export const ChatLogTextEditStyle: React.CSSProperties = {
  width: "175px",
  overflow: "hidden",
  color: "#242424",
  textOverflow: "ellipsis",
  marginLeft: 10,
  fontFamily: "Segoe UI",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
  marginTop: -6,
};

export const ChatLogButtonContainerStyle: React.CSSProperties = {
  padding: "2px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "var(--Medium, 4px)",
  background: "NeutralBackgroundTransparent.Rest",
};

export const ChatLogHorizontalStyle: React.CSSProperties = { marginLeft: "auto", marginRight: 5 };
