import { IDropdownStyles } from "@fluentui/react/lib/Dropdown";

export const container: React.CSSProperties =
{
    // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    // borderRadius: "5px",
    // minHeight: "15%",
    paddingBottom: 10
}


 export const titleIcon: React.CSSProperties = {
    fill: '#000',
    width: '13.333px',
    height: '12px',
    flexShrink: 0,
    margin: '0px 10px 0px 2px',
    alignSelf: 'center'
  }
  
  export const titleText: React.CSSProperties = {
    color: '#000',
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: '0px 8px',
    height: '20px',
    width: '100%'
  }

export const projectTitle: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000",
    margin: '0px 8px'
}

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" ,border:"none",backgroundColor:"transparent"},
    title:{border:"none"}
};

export const dropdownStyle: React.CSSProperties = {
    width: 120,
    marginTop:-5,
    marginLeft:5
};


export const opennumber: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    color: "#237B4B",
    cursor: "pointer"
}

export const statustext: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    cursor: "pointer",
    marginLeft:8
}
export const statustext2: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    cursor: "pointer",
    marginBottom:10
    
}
export const paddingTop: React.CSSProperties =
{
    paddingTop: "12px"
}
export const closenumber: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "40px",
    color: "#616161",
    cursor: "pointer"
}

export const titleStyle: React.CSSProperties =
{
   
}

export const numberStyle: React.CSSProperties =
{
    marginLeft: 16,
    marginTop: 25
}

export const closeNumberStyle: React.CSSProperties =
{
    marginLeft: 35
}

export const seeProjects: React.CSSProperties =
{
   
}

export const seeProjectsText: React.CSSProperties =
{
    fontFamily: "'Segoe UI'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#6264A7",
    marginTop:'157px',
    cursor:"pointer"
}
