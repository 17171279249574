import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { IIconProps, ITooltipHostStyles, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import * as React from "react";
import { messageStyles } from "../Styles/ProjectCommonStyles";
import { Reducer } from "redux";
import { IProjectAppState, IProjectState } from "../../Shared/ProjectState";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../Shared/Reducers/Project.reducer";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { projectSagas } from "../../Shared/Sagas/Project.sagas";
import { IProjectErrorDetails } from "../../Models/IProjectErrorDetails";

export interface IErrorDisplay {
  source?: any;
  customText?: string;
  error?: IProjectErrorDetails;
}
const messageBarIconProps: IIconProps = {
  "aria-label": 'click on close icon to dismiss the message bar'
}
const ErrorDisplay = (props: IErrorDisplay): JSX.Element => {
  const { source, customText, error } = props;
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector } = reduxContext;
  const { projectErrorDetails,
    projectCreateError,
    projectEditError,
    projectSearchError,
    projectDueDateError,
    projectGetDetailsError,
    projectDeleteFileError,
    projectDeleteMemberError,
    projectStatusUpdateError,
    FileUploadingToProjectError,
    loginUserProfileError,
    userNotificationError,
    bannerResponseError,
    projectMemberStatusUpdateError,
    litigationClassifyFileResponseError,
    recentDocumentsResponsesError } = useSelector(
      (state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState
    );
  const [errorDetails, setErrorDetails] = React.useState<IProjectErrorDetails>();
  const [errorMessage, setErrorMessage] = React.useState<any>();
  const [showMessageBar, setShowMessageBar] = React.useState(true);
  const [statusCode, setStatusCode] = React.useState<number>();
  const closeMessageBar = () => {
    setShowMessageBar(false)
  }
  React.useEffect(() => {
    if (Object.keys(bannerResponseError).length > 0) {
      setStatusCode(
        bannerResponseError?.error?.StatusCode ?? bannerResponseError?.errorMessage?.status ?? bannerResponseError?.error?.response?.status
      );
      setErrorMessage(bannerResponseError?.error?.message ?? bannerResponseError?.error ?? "Exception occurred when fetching project details");
      setErrorDetails(bannerResponseError);
    }
    else if (Object.keys(projectErrorDetails).length > 0) {
      setErrorMessage("Not able to find the resource");
      setErrorDetails(projectErrorDetails);
    }
    else if (Object.keys(projectSearchError).length > 0) {
      setErrorMessage("Error in searching project details");
      setErrorDetails(projectSearchError);
    }
    else if (Object.keys(projectDueDateError).length > 0) {
      setErrorMessage("Error in getting the project dues");
      setErrorDetails(projectDueDateError);
    }
    else if (Object.keys(projectCreateError).length > 0) {
      setErrorMessage("Error occurred while creating the project");
      setErrorDetails(projectCreateError);
    }
    else if (Object.keys(projectEditError).length > 0) {
      setErrorMessage("Error occurred while editing the project");
      setErrorDetails(projectEditError);
    }
    else if (Object.keys(loginUserProfileError).length > 0) {
      if (loginUserProfileError?.errorMessage?.data?.Message) {
        setErrorMessage(loginUserProfileError?.errorMessage?.data?.Message);
      } else {
        setErrorMessage("Error loading the current User Profile");
      }
      setStatusCode(loginUserProfileError?.errorMessage?.data?.StatusCode);
      setErrorDetails(loginUserProfileError);
    }
    else if (Object.keys(projectGetDetailsError).length > 0) {
      setStatusCode(
        projectGetDetailsError?.error?.StatusCode ?? projectGetDetailsError?.errorMessage?.status ?? projectGetDetailsError?.error?.response?.status
      );
      setErrorMessage(projectGetDetailsError?.error?.Message ?? projectGetDetailsError?.error ?? "Exception occurred when fetching project details");
      setErrorDetails(projectGetDetailsError);
    }
    
    else if (Object.keys(projectDeleteFileError).length > 0) {
      setErrorMessage("An error occurred while deleting the file");
      setErrorDetails(projectDeleteFileError);
    }
    else if (Object.keys(projectDeleteMemberError).length > 0) {
      setErrorMessage("An error occurred while deleting the member");
      setErrorDetails(projectDeleteMemberError);
    }
    else if (Object.keys(projectMemberStatusUpdateError).length > 0) {
      setErrorMessage("An error occurred while changing the member status");
      setErrorDetails(projectMemberStatusUpdateError);
    }
    else if (Object.keys(projectStatusUpdateError).length > 0) {
      setErrorMessage("An error occurred while changing the project status");
      setErrorDetails(projectStatusUpdateError);
    }
    else if (Object.keys(FileUploadingToProjectError).length > 0) {
      setErrorMessage("An error occurred while uploading the file(s) to the project ");
      setErrorDetails(FileUploadingToProjectError);
    }
    else if (Object.keys(litigationClassifyFileResponseError).length > 0) {
      setErrorMessage("An error occurred while classifying the text ");
      setErrorDetails(litigationClassifyFileResponseError);
    }
    else if (error && Object.keys(error).length > 0) {
      setStatusCode(
        error?.errorMessage?.StatusCode
      );
      setErrorMessage(error?.errorMessage?.Message);
      setErrorDetails(error?.errorMessage?.Message);
    }
    else if (Object.keys(recentDocumentsResponsesError).length > 0) {
      let error = recentDocumentsResponsesError?.error;
      if(error?.StatusCode===404){
        setStatusCode(error?.StatusCode)
        if(error?.Message){
          setErrorMessage(error?.Message);
        }
        else{
          setErrorMessage('Not found');
        }
        setErrorDetails(recentDocumentsResponsesError);
      }
      else{
        setErrorMessage("An error occurred while getting recent documents");
        setErrorDetails(recentDocumentsResponsesError);
      }
      
    }
    //
    else if (Object.keys(userNotificationError).length > 0) {
      const statusCode: number = userNotificationError?.errorMessage?.status ?? userNotificationError?.error?.response?.status
      setStatusCode(
        statusCode
      );
      if (statusCode === 404) {
        setErrorMessage("No notifications");
      }
      else {
        setErrorMessage("An error occurred while fetching notifications ");
      }

      setErrorDetails(userNotificationError);
    }
    else if (Object.keys(source).length > 0) {
      setStatusCode(
        source?.error?.status ?? source?.error?.StatusCode ?? source?.error?.status
      );
      setErrorMessage(source?.error?.data?.Message ?? "Exception occurred when fetching project details");
      setErrorDetails(source);
    }
  }, [projectErrorDetails, userNotificationError, recentDocumentsResponsesError]);
  const displayErrorByErrorStatus = (): JSX.Element => {
    const correlationId = errorDetails?.CorrelationId?? error?.CorrelationId;
    return (
      <Stack>
        {(statusCode === 401) && (
          <>
            <div style={{ padding: 2, overflowWrap: "break-word", width: "100%" }}>You dont have permissions {customText}</div>
            <br />
          </>
        )}
        {statusCode === 404 && (
          <>
            <div className='error-404'>{errorMessage}</div>
          </>
        )}
        {(statusCode === 403) && (
          <>
            <div style={{ padding: 2, overflowWrap: "break-word", width: "100%" }}>{errorMessage}</div>
            <br />
            <div style={{ fontSize: "small", overflowWrap: "break-word", width: "100%" }}>
              <a
                href={`mailto:celalmengcrew@microsoft.com?subject=CELA Legal Ease Team App Error: ${errorMessage}.
                                      &body=An error occurred in  CELA Ease Legal Team App". Get more information about this error using the corelation-id: ${correlationId} and error reason is ${errorMessage}`}
              >
                Report this issue to CELA Engineering...
              </a>
            </div>
          </>
        )}
        {(statusCode === 500 || statusCode === -1) && (
          <>
            <div className='error-500x'>
              An error occurred. Please
              <a href={`mailto:celalmengcrew@microsoft.com?subject=${__APP_NAME__} Error Internal error occurred.
                                      &body=An error occurred in  "${__APP_NAME__}". Get more information about this error in app insights using the corelation-id: ${correlationId} and reason is Internal error occurred `}>
                Report this issue to CELA Engineering
              </a>
            </div>
          </>
        )}
        {errorDetails?.errorMessage && statusCode !== 401 && statusCode !== 403 && statusCode !== 404 && (
          <>            
            {errorDetails?.errorMessage && errorDetails?.errorMessage?.status !== 404 && (
              <>
                <div style={{ fontSize: "small", padding: 2, overflowWrap: "break-word", width: "100%" }}>
                  An error occurred. Please{" "}
                  <a
                    href={`mailto:celalmengcrew@microsoft.com?subject=CELA Legal Ease Team App Error - ${errorDetails?.errorMessage?.data?.message ?? source?.errorMessage?.Message ?? source?.errorMessage ?? source?.data?.message ?? source?.data?.Message ?? source
                      }
                                      &body=An error occurred in CELA Ease Legal Team App: "${errorDetails?.errorMessage?.data?.message ?? source?.errorMessage?.Message ?? source?.errorMessage ?? source?.data?.Message ?? source?.data?.message ?? source
                      }". Get more information about this error using the corelation-id: ${correlationId}`}
                  >
                    Report this issue to CELA Engineering
                  </a>
                </div>
              </>
            )}
          </>
        )}
      </Stack>
    );
  };

  const displayErrorMessage = (): JSX.Element => {
    return (
      <>
        {showMessageBar && (
          <><div className="container">
            <Stack.Item styles={messageStyles}>
              <MessageBar
                messageBarIconProps={messageBarIconProps}
                //onDismiss={() => closeMessageBar()}
                messageBarType={MessageBarType.error}
                tabIndex={0}
                //dismissButtonAriaLabel="click on close icon to dismiss the message bar"
                isMultiline={false}>
                {displayErrorByErrorStatus()}
              </MessageBar>
            </Stack.Item>
          </div></>
        )}

      </>
    );
  };
  return <>{errorDetails && displayErrorMessage()}</>;
};
export default ErrorDisplay;
