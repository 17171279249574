import { Stack } from "@fluentui/react";
import React from "react";
import {
  SystemPromptContainerStyles,
  SystemPromptTextContainerStyles,
  SystemPromptTextStyles,
  SystemTextStyles,
} from "./Styles/SystemPrompt.Styles";
import { SvgStyles } from "./Styles/SystemPrompts.Styles";
import { ISystemPrompts } from "src/Models/Chat/ISystemPrompts";

export interface ISystemPromptProps {
  systemPrompt?: string;
  onSystemPromptClick(): void;
  prompt?: ISystemPrompts;
}

const SystemPrompt = (systemPromptProps: ISystemPromptProps) => {
  const { systemPrompt, onSystemPromptClick,prompt } = systemPromptProps;

  const onPromptClick = (prompt: string) => {
    onSystemPromptClick();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onSystemPromptClick();
    }
  };

  return (
    <>
      <div onClick={() => onPromptClick(prompt?.prompt)} tabIndex={0} onKeyDown={handleKeyDown}>
        <Stack styles={SystemPromptContainerStyles} horizontal>
          <Stack styles={SystemPromptTextContainerStyles}></Stack>
          <Stack styles={SystemPromptTextStyles} horizontal>
            <Stack styles={SystemTextStyles}>{prompt?.question}</Stack>
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default SystemPrompt;
