import * as React from "react";
import { Checkbox, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IBasePickerSuggestionsProps, Icon, IDropdownOption, IPersonaProps, ITag, Label, MessageBarType, PrimaryButton, SearchBox, Stack, TextField, TooltipHost } from "@fluentui/react";
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardActivity,
  DocumentCardLocation,
  DocumentCardPreview,
  DocumentCardTitle,
  IDocumentCardPreviewProps,
} from '@fluentui/react/lib/DocumentCard';

import Button from "../Helpers/Button/Button";
import { useCreateNavContext } from '../CreateNavContext/CreateNavContext';
import { useHistory } from "react-router-dom";
import { getHistoryDefaultView } from "src/Components/Shared/SharedComponents.selectors";
import { requestLawFirmSearch } from "../../../../../../src/Shared/Actions/Project.action";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../../src/Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../../src/Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { IProjectAppState } from "../../../../../../src/Shared/ProjectState";
import { PaginationType } from "src/Helpers/ProjectConstants";
import { ILawFirmSearchRequestParams } from "src/Models/ILawFirmSearchParams";
import './FirmSelection.module.css';
import { truncateText } from "../../../../../../src/Helpers/ProjectHelper";
import { AISearchChat } from "../../../../../../src/Components/V2/AIChat/AIChat";
import SidePanelAI from "../../../../../../src/Components/V2/AIChat/SidePanelAI";

export default function FirmSelection() {
  let history = useHistory();
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);

  const { useSelector, dispatch } = reduxContext;

  const {
    projectCreate,
    isProjectGettingCreated,
    projectCreationSuccess,
    projectCreateError,
    projectCreationFailure,
    projectCreateRedirect,
    projectContext,
    projectFiles,
    projectFileTags,
    projectProps,
    projectOwner,
    projectMembers,
    deletedProjectMembers,
    projectTags,
    isProjectGettingEdited,
    projectEditSuccess,
    projectEditRedirect,
    projectEditFailure,
    projectEditError,
    loginUserpersonaProps,
    isGettingProjectWorkArea,
    isProjectWorkAreaSuccess,
    isProjectWorkAreaError,
    projectWorkAreaCollection,
    lawFirmAdaptiveDetails,
    isLoading,
    hasError

  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

  const { updateNavItem } = useCreateNavContext();
  const [showChat, setshowChat] = React.useState(true);
  
  const handleContinueClick = () => {
    updateNavItem(['firm', 'outside'], 'outside')
    history.push('/createproject?componentName=outside')
  }

  const handleCancelClick = () => {
    //clear the state
    history.push('/')
  }

  //DropDown Options
  const countryDropDownOptions: IDropdownOption[] = [
    { key: 'Canada', text: 'Canada', title: 'Canada' },
    { key: 'India', text: 'India', title: 'India' },
    { key: 'USA', text: 'USA', title: 'USA' },
  ];

  const workAreaDropDownOptions: IDropdownOption[] = [
    { key: 'Litigation', text: 'Litigation', title: 'Litigation' },
    { key: 'Other', text: 'Other', title: 'Other' },

  ];
  const rateDropDownOptions: IDropdownOption[] = [
    { key: 'Litigation', text: 'Litigation', title: 'Litigation' },
    { key: 'Other', text: 'Other', title: 'Other' },

  ];
  const controlStyles = {
    root: {
      width: 212,
      backgroundColor: 'white',
      border: 'none',
    },
  };


  React.useEffect(() => {

    let searchParamsObject: ILawFirmSearchRequestParams = {};
    // if (searchParams) {
    //     searchParamsObject = searchParams
    // }
    // if (isNaN(+searchText) || searchText === '') {
    searchParamsObject.name = '';
    searchParamsObject.vendorNumber = '';
    //}
    //else {
    searchParamsObject.vendorNumber = '';
    searchParamsObject.name = '';
    //}
    searchParamsObject.includeAdaptiveCard = true;
    searchParamsObject.pagination = {
      currentPage: 1,
      recordsPerPage: 25
    }

    dispatch(requestLawFirmSearch(searchParamsObject));
  }, []);

  function onDismissAiSearchPanel(): void {
    setshowChat(false);
  }
  const handleIconClick = () => {
    setshowChat(true);
  };


  

 

  
  return (

    <>
    <div style={{ marginLeft: 20 }} className="pageContainer">

      <AISearchChat
        showAiSearch={showChat}
        onDismissAiSearch={onDismissAiSearchPanel}
        type={"firm"}
      />

     


      <div className="contentContainer">
        <Stack tokens={{ childrenGap: 10 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack horizontal>
            <Stack>
              <h2 style={{ fontFamily: 'Segoe UI', fontSize: 14, fontWeight: 600, color: '#242424' }}>
                Firm Selection
              </h2>
              <p style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644', marginTop: 15 }}>
                Select one or more firms
              </p>
            </Stack>
            <Stack style={{ marginLeft: 'auto' }}>
              <img
                src="https://copilot.microsoft.com/rp/vE266_E90czuUc-Fs55Qoq9hIBc.svg" // Replace "your-icon-url.png" with the URL of your icon
                alt="CoPilot Icon"
                onClick={handleIconClick}
                style={{ cursor: 'pointer', width: 24, height: 24, color: showChat ? '#007bff' : '#999999' }} // Change color based on selection
              />
            </Stack>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 10 }}>
            <div style={{ backgroundColor: 'white', height: 32, marginTop: 26 }}>
              <SearchBox
                placeholder="Find"
                underlined
                styles={controlStyles}
              />
            </div>
            <Stack>
              <Label style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644' }}>Firm Country</Label>
              <Dropdown
                placeholder="Firm Country"
                options={countryDropDownOptions}
                styles={controlStyles}
              />
            </Stack>

            <Stack>
              <Label style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644' }}>Work Area</Label>
              <Dropdown
                placeholder="Work Area"
                options={workAreaDropDownOptions}
                styles={controlStyles}
              />
            </Stack>

            <Stack>
              <Label style={{ fontFamily: 'Segoe UI', fontSize: 12, fontWeight: 400, color: '#484644' }}>Rate</Label>
              <Dropdown
                placeholder="Rate"
                options={rateDropDownOptions}
                styles={controlStyles}
              />
            </Stack>
          </Stack>
        </Stack>

        {/*Law firms document card */}
        <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 15, display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))', gap: '10px', alignItems: 'baseline' }}>

          {lawFirmAdaptiveDetails?.payload?.payload?.length > 0 && (
            lawFirmAdaptiveDetails.payload.payload.map((firmDetails, index) => (
              <div className="law-firm-card" key={index}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <div className="initials-circle">
                    {firmDetails.firmName.split(' ').map(word => word[0]).join('')}
                  </div>
                  <div className="firm-details">
                    <div className="firm-name">
                      <TooltipHost content={firmDetails.firmName}>
                        {truncateText(firmDetails.firmName, 15, 15)}
                      </TooltipHost>

                    </div>
                    <div className="rate-per-hour">$50 / hr</div>
                    <div className="firm-rating">
                      Rating: {Array.from({ length: 5 }, (_, i) => (
                        <span key={i} className={i < firmDetails.rating ? 'filled-star' : 'empty-star'}>&#9733;</span>
                      ))}
                    </div>
                  </div>
                  <div className="select-checkbox">
                    <input type="checkbox" />
                  </div>
                </div>

                <div style={{ marginLeft: '10px', marginTop: '0px' }}>
                  {/* 1st row */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Icon className="firm-metadata-icon" iconName="FileComment" />
                    <span className="firm-metadata">Citizenship</span>
                  </div>

                  {/* 2nd row */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Icon className="firm-metadata-icon" style={{ marginTop: 5 }} iconName="Money" />
                    <span className="firm-metadata">US Dollar</span>
                  </div>

                  {/* 3rd row */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Icon className="firm-metadata-icon" iconName="IDBadge" />
                    <span className="firm-metadata">{firmDetails.vendorNumber}</span>
                  </div>

                  {/* 4th row */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Icon className="firm-metadata-icon" iconName="POI" />
                    <span className="firm-metadata">
                      <TooltipHost content={firmDetails.address ? `${firmDetails.address}${firmDetails.state ? `, ${firmDetails.state}` : ''}${firmDetails.country ? `, ${firmDetails.country}` : ''}` : ''}>
                        {truncateText(firmDetails.address ? `${firmDetails.address}${firmDetails.state ? `, ${firmDetails.state}` : ''}${firmDetails.country ? `, ${firmDetails.country}` : ''}` : '', 32, 32)}
                      </TooltipHost>

                    </span>
                  </div>

                  {/* 5th row */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Icon className="firm-metadata-icon" iconName="Contact" />
                    <span className="firm-metadata">Sankar Tippala</span>
                  </div>
                </div>

                <div>
                  {/* Horizontal Line */}
                  <hr style={{ marginTop: '50px', border: '0.5px solid #f0f0f0' }} />

                  {/* View More Information Link */}
                  <div style={{ display: 'flex', marginLeft: '10px' }}>
                    <a href="#" style={{ color: '#5B5FC7', fontSize: 12 }}>View More Information</a>
                  </div>
                </div>
              </div>
            ))
          )}





        </Stack>
      </div>
      {/* Buttons at the bottom */}
      <div className="buttonContainer">
        <Stack horizontal tokens={{ childrenGap: 10 }} style={{ marginTop: 'auto' }}>
          <Button type='primary' onClick={handleContinueClick}>
            Continue
          </Button>
          <Button type='cancel' onClick={handleCancelClick}>
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
    </>
  )
}


