import { IStyleFunctionOrObject, INavStyleProps, INavStyles } from "@fluentui/react";
import { IStyle } from "@fluentui/style-utilities";

export const menuNavChevronStyle: IStyle = {
  position: 'relative',
  left: 'none'
}

export const menuNavStyles: IStyleFunctionOrObject<INavStyleProps, INavStyles> = {
  root: {
    height: '100vh',
  },
  chevronButton: menuNavChevronStyle,
}